<div class="summary-container" *ngIf="display === 'absolute'">
  <div class="summary-label">
    This Month: {{ config.thisMonth | number: '1.0-2' }}
  </div>
  <div class="summary-label">
    Last Month: {{ config.lastMonth | number: '1.0-2' }}
  </div>
  <div class="summary-label">
    Year Average: {{ config.monthAverageThisYear | number: '1.0-2' }}
  </div>
</div>

<div class="summary-container" *ngIf="display === 'percent'">
  <div class="summary-label">This Month: {{ config.thisMonth | percent }}</div>
  <div class="summary-label">Last Month: {{ config.lastMonth | percent }}</div>
  <div class="summary-label">
    Year Average: {{ config.monthAverageThisYear | percent }}
  </div>
</div>
