<app-users-edit
  *ngIf="editing"
  [user]="user"
  (saveEvent)="onSave($event)"
></app-users-edit>
<ng-container *ngIf="!editing">
  <div [clrLoading]="loading">
    <div *ngIf="user">
      <!-- Buttons -->
      <div class="clr-row">
        <div class="clr-col-6">
          <h2 id="heading">User Details</h2>
        </div>
        <div id="actions" class="clr-col-6">
          <button class="btn btn-danger-outline" (click)="delete()">
            Delete User
          </button>
          <button class="btn btn-outline" (click)="editView()">
            Edit User
          </button>
        </div>
      </div>

      <!-- Basic Info -->
      <div class="clr-col-6">
        <table class="table">
          <tbody>
            <tr>
              <td class="left">First Name</td>
              <td class="left">{{ safeGet(user, 'firstName') }}</td>
            </tr>
            <tr>
              <td class="left">Last Name</td>
              <td class="left">{{ safeGet(user, 'lastName') }}</td>
            </tr>
            <tr>
              <td class="left">Username</td>
              <td class="left">{{ safeGet(user, 'username') }}</td>
            </tr>
            <tr>
              <td class="left">Role</td>
              <td class="left">{{ safeGet(user, 'role') }}</td>
            </tr>
            <tr>
              <td class="left">email</td>
              <td class="left">{{ safeGet(user, 'email') }}</td>
            </tr>
            <tr>
              <td class="left">Phone</td>
              <td class="left">{{ safeGet(user, 'phone') }}</td>
            </tr>
            <tr>
              <td class="left">Status</td>
              <td class="left">{{ safeGet(user, 'status') }}</td>
            </tr>
            <tr>
              <td class="left">Facility</td>
              <td class="left">{{ safeGet(user, 'facility.name') }}</td>
            </tr>
            <tr>
              <td class="left">Subject to IP restrictions</td>
              <td class="left">{{ safeGet(user, 'restrictedIP') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
