import { Component, OnInit, Input } from '@angular/core'
import { SafeResourceUrl } from '@angular/platform-browser'
import { SafeUrlPipe } from '../safe-url.pipe'

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  @Input() src: string

  safeUrl: SafeResourceUrl

  constructor(private safeUrlPipe: SafeUrlPipe) {}

  ngOnInit() {
    this.safeUrl = this.safeUrlPipe.transform(this.src)
  }
}
