<app-studies-edit
  *ngIf="editing"
  [study]="study"
  (saveEvent)="onSave($event)"
></app-studies-edit>
<ng-container *ngIf="!editing">
  <div [clrLoading]="loading">
    <div *ngIf="study">
      <!--  Header and actions -->
      <div
        class="clr-row"
        appPageHeader
        [title]="title"
        [actions]="actions"
        (clicks)="actionsHandler($event)"
      ></div>
      <app-record-subtitle
        [facility]="facility"
        [room]="room"
        [scanner]="scanner"
      ></app-record-subtitle>

      <!-- Main Body -->
      <div class="clr-row">
        <!-- Info, perform a test, questions -->
        <div class="clr-col-12 clr-col-lg-6 clr-col-xl-6">
          <!-- Info -->
          <table class="table">
            <tbody>
              <tr>
                <td class="left">Study Date:</td>
                <td class="left">{{ studyDate | customDate }}</td>
              </tr>
              <tr>
                <td class="left">Date Received:</td>
                <td class="left">{{ dateReceived | customDate }}</td>
              </tr>
              <tr>
                <td class="left">Facility:</td>
                <td class="left">{{ facilityName }}</td>
              </tr>
              <tr>
                <td class="left">Modality</td>
                <td class="left">{{ modality }}</td>
              </tr>
              <tr *ngIf="room">
                <td class="left">Room</td>
                <td class="left">{{ roomName }}</td>
              </tr>
              <tr>
                <td class="left">Staff:</td>
                <td class="left">{{ extra.filmedBy || 'unknown' }}</td>
              </tr>
            </tbody>
          </table>
          <!-- Perform a Test -->
          <app-perform-test [data]="queryResult.data"></app-perform-test>
          <!-- Recent Tests -->
          <app-recent-tests [tests]="phantomTests"></app-recent-tests>
        </div>
        <!-- Images -->
        <div class="clr-col-12 clr-col-lg-6 clr-col-xl-6">
          <app-image-viewer
            *ngIf="!featuresService.features.dicomViewer"
            [images]="images"
          ></app-image-viewer>
          <app-dicom-viewer
            *ngIf="featuresService.features.dicomViewer"
            [study]="study"
          ></app-dicom-viewer>
        </div>
      </div>
    </div>
  </div>
</ng-container>
