import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment-timezone'

@Pipe({
  name: 'toMonthName',
})
export class ToMonthNamePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string {
    let val = value
    if (typeof val === 'string') {
      val = parseInt(val, 10)
    }
    if (typeof val === 'number') {
      return moment().month(val).format('MMMM')
    }
    return 'none'
  }
}
