<app-comment-categories-edit
  *ngIf="editing"
  [comment_category]="comment_category"
  (saveEvent)="onSave($event)"
></app-comment-categories-edit>
<ng-container *ngIf="!editing">
  <div [clrLoading]="loading">
    <div *ngIf="queryResult" class="">
      <!-- Buttons -->
      <div class="clr-row">
        <div id="actions" class="clr-col-12">
          <button class="btn btn-danger-outline" (click)="delete()">
            Delete Category
          </button>
          <button class="btn btn-outline" (click)="editView()">
            Edit Category
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
