import { gql } from 'apollo-angular'

export default gql`
  query dashboard(
    $startDate: timestamp
    $distinct_on: [scanner_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [scanner_order_by!]
    $where: scanner_bool_exp
  ) {
    scanner(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      name
      facility_room {
        name
      }
      facility {
        name
      }
      modality {
        id
      }
      survey_tests(order_by: { dateCreated: desc }, limit: 1) {
        dateCreated
        id
        result
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      missed_phantom_tests(
        order_by: { scheduledDate: desc }
        where: { scheduledDate: { _gte: $startDate } }
      ) {
        id
        scheduledDate
        result
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      studies(
        order_by: { scanDate: asc }
        where: { scanDate: { _gte: $startDate } }
      ) {
        id
        scanDate
        phantom_tests(order_by: { dateCreated: desc }) {
          dateCreated
          id
          result
          comments_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
    scanner_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
