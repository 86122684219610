<div id="perform-test-container">
  <div class="card">
    <!-- Header -->
    <div class="card-header">
      Perform a Test
    </div>
    <!-- Main Body -->
    <div
      *ngIf="state.routineSelected && !state.answersSubmitted"
      class="card-block"
    >
      <form clrForm>
        <button
          id="allpass-button"
          class="btn btn-outline-success"
          (click)="allPass()"
        >
          All correct
        </button>
        <div
          *ngFor="let question of state.questions; let i = index"
          [ngClass]="{ highlight: i === highlightedQuestionIndex }"
        >
          <clr-select-container
            *ngIf="question.type === 'PhantomTestSelectQuestion'"
          >
            <label class="clr-col-md-6">{{ question.text }}</label>
            <select
              class="clr-col-md-6"
              [(ngModel)]="state.answers[question.id]"
              required
              clrSelect
              (keyup)="ignoreNavKeys($event)"
              (keydown)="ignoreNavKeys($event)"
              name="{{ question.id }}"
              id="ptq-{{ i }}"
            >
              <option
                *ngFor="let option of question.options"
                value="{{ option }}"
                >{{ option }}</option
              >
            </select>
          </clr-select-container>
          <clr-input-container
            *ngIf="question.type === 'PhantomTestNumberQuestion'"
          >
            <label class="clr-col-md-6">{{ question.text }}</label>
            <input
              class="clr-col-md-6"
              [(ngModel)]="state.answers[question.id]"
              clrInput
              required
              type="number"
              (keyup)="ignoreNavKeys($event)"
              (keydown)="ignoreNavKeys($event)"
              name="{{ question.id }}"
              id="ptq-{{ i }}"
            />
          </clr-input-container>
          <clr-input-container
            *ngIf="question.type === 'PhantomTestTextQuestion'"
          >
            <label class="clr-col-md-6">{{ question.text }}</label>
            <input
              class="clr-col-md-6"
              [(ngModel)]="state.answers[question.id]"
              clrInput
              required
              type="text"
              name="{{ question.id }}"
              id="ptq-{{ i }}"
            />
          </clr-input-container>
          <clr-radio-container
            *ngIf="question.type === 'PhantomTestYesNoQuestion'"
          >
            <label class="clr-col-md-9">{{ question.text }}</label>
            <clr-radio-wrapper>
              <input
                class="clr-col-md-3"
                (keyup)="handleRadioKeypress($event)"
                (keydown)="handleRadioKeypress($event)"
                [(ngModel)]="state.answers[question.id]"
                required
                type="radio"
                clrRadio
                value="yes"
                name="{{ question.id }}"
                id="ptq-{{ i }}"
              />
              <label
                class="{{
                  question.expected === 'yes'
                    ? 'pass'
                    : question.expected === 'no'
                    ? 'fail'
                    : ''
                }}"
                >Yes</label
              >
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input
                class="clr-col-md-3"
                (keyup)="handleRadioKeypress($event)"
                (keydown)="handleRadioKeypress($event)"
                [(ngModel)]="state.answers[question.id]"
                required
                type="radio"
                clrRadio
                value="no"
                name="{{ question.id }}"
              />
              <label
                class="{{
                  question.expected === 'no'
                    ? 'pass'
                    : question.expected === 'yes'
                    ? 'fail'
                    : ''
                }}"
                >No</label
              >
            </clr-radio-wrapper>
            <clr-control-error>Please select an option</clr-control-error>
          </clr-radio-container>
        </div>
      </form>
    </div>
    <!-- Footer for selecting a routine-->
    <div *ngIf="!state.routineSelected" class="card-footer">
      <!-- Tests that have already been run on this scanner -->
      <button
        *ngFor="let routine of associatedRoutinesForModality"
        class="btn btn-success-outline"
        (click)="test(routine.id)"
      >
        {{ routine.name }}
      </button>
      <!-- All available tests for this modality -->
      <clr-dropdown>
        <button class="btn btn-success btn-link" clrDropdownTrigger>
          {{ anyAssociatedRoutines ? 'More' : 'Select A Test' }}
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen>
          <label class="dropdown-header">{{ modality }} Tests</label>
          <div
            clrDropdownItem
            *ngFor="let routine of routinesForModality"
            (click)="associateAndTest(routine.id)"
          >
            {{ routine.name }}
          </div>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
    <!-- Footer for submitting questions -->
    <div *ngIf="state.routineSelected" class="card-footer">
      <button
        [clrLoading]="state.submitButton"
        (click)="submitQuestions()"
        class="btn submit-button"
      >
        submit
      </button>
      <ng-container *ngIf="state.answersSubmitted">
        <span *ngIf="!state.unevaluatedTestArrived">Analyzing images...</span>
        <span *ngIf="state.unevaluatedTestArrived">Evaluating test...</span>
      </ng-container>
    </div>
  </div>
</div>
