import { gql } from 'apollo-angular'

export default gql`
  query commentCategories(
    $distinct_on: [comment_category_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [comment_category_order_by!]
    $where: comment_category_bool_exp
  ) {
    comment_category(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      name
      color
    }
    comment_category_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
