import { gql } from 'apollo-angular'

export default gql`
  query studies(
    $distinct_on: [study_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [study_order_by!]
    $where: study_bool_exp
  ) {
    study(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      scanner {
        facility_room {
          name
        }
        facility {
          name
        }
      }
      modality {
        id
      }
      scanner {
        name
      }
      dateCreated
      scanDate
      study_images_aggregate {
        aggregate {
          count
        }
      }
      phantom_tests_aggregate {
        aggregate {
          count
        }
      }
    }
    study_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
