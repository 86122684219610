import { gql } from 'apollo-angular'

export default gql`
  query comments(
    $distinct_on: [comment_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [comment_order_by!]
    $where: comment_bool_exp
  ) {
    comment(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      dateCreated
      user {
        firstName
        lastName
      }
      subject
      comment_category {
        name
        color
      }
      phantom_test {
        result
        id
        study {
          scanDate
        }
      }
      survey_test {
        result
        id
        dateCreated
      }
      missed_phantom_test {
        result
        id
        scheduledDate
      }
      comment_files {
        stored_file {
          key
        }
      }
      comment_files_aggregate {
        aggregate {
          count
        }
      }
    }
    comment_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
