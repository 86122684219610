import { gql } from 'apollo-angular'

export default gql`
  query missedPhantomTests(
    $distinct_on: [missed_phantom_test_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [missed_phantom_test_order_by!]
    $where: missed_phantom_test_bool_exp
  ) {
    missed_phantom_test(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      scanner {
        facility {
          name
        }
        facility_room {
          name
        }
        name
      }
      scheduledDate
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    missed_phantom_test_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
