import { Component, Input, OnInit } from '@angular/core'
import { ClrDatagridFilter, ClrDatagridFilterInterface } from '@clr/angular'
import { Subject } from 'rxjs'
import { DevError } from 'src/app/core/errors/errors'
import { TimezoneService } from 'src/app/timezone.service'
import { FilterType, NumberFilterInterface } from '../filters.util'

@Component({
  selector: 'app-number-filter',
  templateUrl: './number-filter.component.html',
  styleUrls: ['./number-filter.component.scss'],
})
export class NumberFilterComponent
  implements OnInit, ClrDatagridFilterInterface<number>, NumberFilterInterface {
  type: FilterType.number = FilterType.number
  changes = new Subject<number>()
  @Input() min: number
  @Input() max: number
  @Input() property: string

  constructor(
    private filterContainer: ClrDatagridFilter,
    private timezoneService: TimezoneService,
  ) {
    filterContainer.setFilter(this)
  }

  isActive(): boolean {
    return typeof this.min === 'number' || typeof this.max === 'number'
  }

  accepts(num: number | undefined, whichNumber?: 'min' | 'max'): boolean {
    if (whichNumber === 'min') {
      this.min = num
    } else if (whichNumber === 'max') {
      this.max = num
    } else {
      throw new DevError('number was not min or max')
    }
    this.changes.next(num)
    return true
  }

  ngOnInit() {}
}
