import { gql } from 'apollo-angular'

export const currUserEmail = gql`
  query currUserEmail($userId: Int!) {
    user(where: { id: { _eq: $userId } }) {
      email
    }
  }
`

export const globalSupport = gql`
  query setting {
    setting(where: { key: { _eq: "supportContact" } }) {
      json
    }
  }
`

export const scannerDetails = gql`
  query scannerDetails($id: Int!) {
    scanner_by_pk(id: $id) {
      phantom_tests(limit: 1, order_by: { study: { scanDate: desc } }) {
        study {
          scanDate
        }
      }
      id
      facility {
        name
      }
      modality {
        id
      }
      facility_room {
        name
      }
      name
      scanner_manufacturer {
        name
      }
      scanner_model {
        name
      }
      facilityId
      modalityId
      facilityRoomId
      scannerManufacturerId
      scannerModelId
      schedule
      scheduleGrace
      extra
      supportContacts
    }
  }
`

export const lookupStExist = gql`
  query lookupStExist($scannerID: Int! = 32, $routineID: [Int!] = [4]) {
    survey_test(
      where: { scannerId: { _eq: $scannerID }, routineId: { _in: $routineID } }
      order_by: { dateCreated: desc }
      limit: 1
    ) {
      id
      dateCreated
    }
  }
`

export const lookupPtExist = gql`
  query lookupPtExist($scannerID: Int! = 32, $routineID: [Int!] = [4]) {
    phantom_test(
      where: { scannerId: { _eq: $scannerID }, routineId: { _in: $routineID } }
      order_by: { dateCreated: desc }
      limit: 1
    ) {
      id
      dateCreated
    }
  }
`
