<div *ngIf="test.rawOutput.report_summaryFile">
  <app-test-reports [test]="test"></app-test-reports>
</div>
<h3>Test Questions</h3>
<div>
  <app-questions-display [questions]="questions"></app-questions-display>
</div>
<ng-container *ngIf="!isEmpty(displayItems); else noDisplay">
  <app-test-summary [data]="displayItems" [test]="test"></app-test-summary>
  <h3>Test Details</h3>
  <div class="view-controls">
    <button (click)="setState('expand')" class="btn btn-outline btn-sm action">
      Expand All
    </button>
    <button
      (click)="setState('collapse')"
      class="btn btn-outline btn-sm action"
    >
      Collapse All
    </button>
    <button (click)="setState(undefined)" class="btn btn-outline btn-sm action">
      Reset Default
    </button>
  </div>
  <app-generic-test-display
    *ngFor="let displayItem of displayItems"
    [test]="test"
    [data]="displayItem"
    [stateChanges]="stateEmitter"
  ></app-generic-test-display>
</ng-container>
<ng-template #noDisplay
  ><p>This test cannot be displayed. Please re-test this study</p></ng-template
>
