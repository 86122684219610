import { gql } from 'apollo-angular'

export default gql`
  mutation performSurveyTest(
    $routineId: Int!
    $scannerId: Int!
    $testerId: Int!
    $questions: String!
  ) {
    performSurveyTest(
      data: {
        routineId: $routineId
        scannerId: $scannerId
        testerId: $testerId
        questions: $questions
      }
    ) {
      id
    }
  }
`
