import { gql } from 'apollo-angular'

export default gql`
  query surveyTests(
    $distinct_on: [survey_test_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [survey_test_order_by!]
    $where: survey_test_bool_exp
  ) {
    survey_test(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      survey_test_routine {
        name
      }
      id
      scanner {
        facility_room {
          name
        }
        facility {
          name
        }
      }
      scanner {
        modality {
          id
        }
      }
      scanner {
        name
      }
      dateCreated
      result
      user {
        firstName
        lastName
      }
    }
    survey_test_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    survey_test_routine {
      id
      name
      survey_test_routine_questions_survey_test_questions {
        survey_test_question {
          id
          text
          section
        }
      }
    }
  }
`
