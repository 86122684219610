import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Apollo, gql } from 'apollo-angular'
import moment from 'moment-timezone'
import { join } from 'path'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

export enum ComponentStatus {
  'Operational', //	The component is working.
  'Performance Issues', //	The component is working but slow.
  'Disconnected', //	The component is unresponsive
}

export const GATEWAY_HEARTBEAT_INTERVAL_SECONDS = 60
export const ALLOWED_MISSED_HEARTBEATS = 3
export const ACCEPTABLE_RESPONSE_TIME_SECONDS = 5

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  checkServer(): Observable<ComponentStatus> {
    return new Observable((subscriber) => {
      const start = moment()
      this.http
        .post(
          join(environment.appUri, '/image/addImage'),
          {},
          { responseType: 'text' },
        )
        .subscribe({
          next: (_) => {
            const end = moment()
            if (
              Math.abs(start.diff(end, 'seconds')) <
              ACCEPTABLE_RESPONSE_TIME_SECONDS
            ) {
              subscriber.next(ComponentStatus.Operational)
            } else {
              subscriber.next(ComponentStatus['Performance Issues'])
            }
          },
          error: (err) => {
            console.log(err)
            subscriber.next(ComponentStatus.Disconnected)
          },
        })
    })
  }

  checkAPI(): Observable<ComponentStatus> {
    return new Observable((subscriber) => {
      const start = moment()
      const query = gql`
        query helloWorld {
          hello
        }
      `
      this.apollo.query({ query }).subscribe({
        next: (result) => {
          if (result.error || result.errors) {
            subscriber.next(ComponentStatus.Disconnected)
          } else {
            const end = moment()
            if (
              Math.abs(start.diff(end, 'seconds')) <
              ACCEPTABLE_RESPONSE_TIME_SECONDS
            ) {
              subscriber.next(ComponentStatus.Operational)
            } else {
              subscriber.next(ComponentStatus['Performance Issues'])
            }
          }
        },
        error: (err) => {
          console.log(err)
          subscriber.next(ComponentStatus.Disconnected)
        },
      })
    })
  }

  checkGateway(): Observable<ComponentStatus> {
    return new Observable((subscriber) => {
      this.http
        .get(join(environment.appUri, '/api/gateway/heartbeat'), {})
        .subscribe({
          next: (result: any) => {
            let status = ComponentStatus.Disconnected
            if (typeof result.lastHeartbeat === 'number') {
              const lastHeartbeat = moment(result.lastHeartbeat)
              const now = moment()
              // check when the last heartbeat was
              if (
                Math.abs(lastHeartbeat.diff(now, 'seconds')) <
                GATEWAY_HEARTBEAT_INTERVAL_SECONDS * ALLOWED_MISSED_HEARTBEATS
              ) {
                // if we've missed no more than allowed heartbeats, we're good.
                status = ComponentStatus.Operational
              }
            }
            subscriber.next(status)
          },
          error: (err) => {
            console.log(err)
            subscriber.next(ComponentStatus.Disconnected)
          },
        })
    })
  }
}
