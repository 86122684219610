import { gql } from 'apollo-angular'

export default gql`
  query survey($id: Int!) {
    scanner {
      id
      name
      modalityId
      facility_room {
        name
      }
    }
    survey_test_routine_by_pk(id: $id) {
      id
      name
      modalityId
      survey_test_routine_questions_survey_test_questions(
        order_by: { survey_test_question: { order: asc_nulls_first } }
      ) {
        survey_test_question {
          id
          type
          text
          section
          options
        }
      }
    }
  }
`
