import { Component, Input, OnInit } from '@angular/core'

import { colors } from '../metrics.component'

export interface GagueConfig {
  label: string
  value: number
}

@Component({
  selector: 'app-gague',
  templateUrl: './gague.component.html',
  styleUrls: ['./gague.component.scss'],
})
export class GagueComponent implements OnInit {
  @Input() config: GagueConfig
  chartOptions: any

  constructor() {}

  ngOnInit(): void {
    const scale = 0.4

    this.chartOptions = {
      series: [
        {
          type: 'gauge',
          min: -100,
          max: 100,
          axisLine: {
            lineStyle: {
              width: 30 * scale, // scale
              color: [
                [0.2499, colors.fail],
                [0.4999, colors.problem],
                [1, colors.pass],
              ],
            },
          },
          pointer: {
            width: 6 * scale,
            itemStyle: {
              color: 'auto',
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            formatter: (value: number) => {
              if (!value && value !== 0) return '-'
              let str = ''
              if (value > 0) str += '+'
              str += value.toFixed(Math.abs(value) >= 10 ? 0 : 1)
              str += '%'
              return str
            },
            color: 'auto',
            offsetCenter: [0, '65%'],
            fontSize: 40 * scale,
          },
          title: {
            offsetCenter: [0, '95%'],
            fontSize: 30 * scale,
            color: 'auto',
          },
          data: [
            {
              value: this.config.value * 100,
              name: this.config.label,
            },
          ],
        },
      ],
    }
  }
}
