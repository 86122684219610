import { gql } from 'apollo-angular'

export default gql`
  query report_get_related_entities {
    survey_test_routine(order_by: { name: asc }) {
      id
      name
    }
    scanner(order_by: { name: asc }) {
      id
      name
    }
    facility_room(order_by: { name: asc }) {
      id
      name
    }
    facility(order_by: { name: asc }) {
      id
      name
    }
  }
`
