<clr-input-container>
  <input
    #filterInputElement
    clrInput
    placeholder="{{ currentlyHighlitedOption?.val || 'filter...' }}"
    name="filterInputElement"
    [(ngModel)]="userTypedFilterValue"
    (keydown.enter)="userPressedEnter()"
    (keydown.arrowup)="userPressedArrowUp()"
    (keydown.arrowdown)="userPressedArrowDown()"
  />
</clr-input-container>

<div class="options-container">
  <ng-container *ngFor="let option of filteredOptions; let i = index">
    <p
      class="select-option"
      [ngClass]="{
        highlighted: currentlyHighlightedIndex === i,
        clearOption: option.key === undefined
      }"
      (click)="userSelectedAnOption(option)"
      (mouseover)="currentlyHighlightedIndex = i"
    >
      {{ option.val }}
    </p>
  </ng-container>
</div>
