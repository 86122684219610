import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ClrForm, ClrLoadingState } from '@clr/angular'

import { flatten } from 'lodash'
import { AuthService } from 'src/app/auth/auth.service'
import { MessageError } from 'src/app/core/errors/errors'
import performSurveyTestGql from './performSurveyTest.gql'
import surveyGql from './survey.gql'
import { performSurveyTestVariables } from './types/performSurveyTest'
import {
  survey,
  survey_survey_test_routine_by_pk_survey_test_routine_questions_survey_test_questions_survey_test_question as QueryQuestion,
} from './types/survey'

interface Question extends QueryQuestion {
  answer?: string | number
}

interface QuestionsInput {
  [id: number]: string
}

export interface Sections {
  [key: string]: Question[]
}

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit {
  @ViewChild(ClrForm) clrForm: ClrForm

  surveyId: number

  testerId: number

  queryResult: ApolloQueryResult<survey>

  sections: Sections = {}

  selectedScanner: number

  test: string

  submitButtonState: ClrLoadingState

  results = ['pass', 'problem', 'fail', 'na']

  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo,
    private auth: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.surveyId = parseInt(this.route.snapshot.params.id, 10)
    this.fetchSurveyTestRoutine()
    this.testerId = this.auth.user.id
  }

  fetchSurveyTestRoutine() {
    const variables = { id: this.surveyId }
    const query = surveyGql
    this.apollo
      .query<survey>({ query, variables })
      .subscribe({
        next: (result) => {
          this.queryResult = result
          if (result.errors) {
            throw result.errors[0]
          }
          if (!this.survey) {
            throw new MessageError('survey not found')
          }
          this.assembleSections()
        },
      })
  }

  assembleSections() {
    this.queryResult.data.survey_test_routine_by_pk.survey_test_routine_questions_survey_test_questions
      .map((manyToMany) => manyToMany.survey_test_question)
      .forEach((question) => {
        const section = question.section || ''
        if (!this.sections[section]) {
          this.sections[section] = []
        }
        this.sections[section].push(question)
      })
  }

  get survey() {
    return this.queryResult?.data?.survey_test_routine_by_pk
  }

  get surveyName() {
    return this.survey?.name
  }

  get scanners() {
    return this.queryResult.data.scanner.filter(
      (s) => !this.survey.modalityId || this.survey.modalityId === s.modalityId,
    )
  }

  submitQuestions() {
    this.clrForm.markAsTouched()
    if (this.allQuestionsAnswered) {
      this.submitButtonState = ClrLoadingState.LOADING
      console.log(this.sections)
      const questionsInput: QuestionsInput = {}
      const questions = flatten(Object.values(this.sections))
      questions.forEach((q) => {
        questionsInput[q.id] = q.answer.toString()
      })
      const questionsAsString: string = JSON.stringify(questionsInput)
      const mutation = performSurveyTestGql
      const variables: performSurveyTestVariables = {
        questions: questionsAsString,
        routineId: this.surveyId,
        testerId: this.testerId,
        scannerId: this.selectedScanner,
      }
      console.log(variables)
      this.apollo.mutate({ mutation, variables }).subscribe({
        next: (result) => {
          console.log(result)
          if (result.errors) {
            this.submitButtonState = ClrLoadingState.ERROR
            throw result.errors[0]
          }
          this.router.navigate(['home/surveyTests'])
        },
        error: (err) => {
          this.submitButtonState = ClrLoadingState.ERROR
          throw err
        },
      })
    }
  }

  get allQuestionsAnswered() {
    let allAnswered = true
    console.log(this.sections)
    Object.values(this.sections).forEach((questionArr) => {
      const qArr = questionArr.map((q) => q.answer)
      if (
        qArr.includes(undefined) ||
        qArr.includes(null) ||
        qArr.includes('')
      ) {
        allAnswered = false
      }
    })
    return allAnswered
  }
}
