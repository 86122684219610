import { DatePipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { FullCalendarModule } from '@fullcalendar/angular'
import * as echarts from 'echarts'
import { TagInputModule } from 'ngx-chips'
import { NgxEchartsModule } from 'ngx-echarts'
import { NgxImageGalleryModule } from 'ngx-image-gallery'
import { CoreModule } from '../core/core.module'
import { HumanReadablePipe } from '../core/human-readable.pipe'
import { FiltersModule } from '../filters/filters.module'
import { HasuraModule } from '../hasura/hasura.module'
import { ChangePasswordComponent } from './change-password/change-password.component'
import { CommentCategoriesDetailsComponent } from './comment-categories-details/comment-categories-details.component'
import { CommentCategoriesEditComponent } from './comment-categories-edit/comment-categories-edit.component'
import { CommentCategoriesComponent } from './comment-categories/comment-categories.component'
import { CommentsDetailsComponent } from './comments-details/comments-details.component'
import { CommentsEditComponent } from './comments-edit/comments-edit.component'
import { CommentsComponent } from './comments/comments.component'
import { ViewTestComponent } from './comments/view-test/view-test.component'
import { CustomDatePipe } from './custom-date.pipe'
import { DashboardTestsComponent } from './dashboard-tests/dashboard-tests.component'
import { IconLegendComponent } from './dashboard-tests/icon-legend/icon-legend.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { DocumentViewerComponent } from './document-viewer/document-viewer.component'
import { FacilitiesDetailsComponent } from './facilities-details/facilities-details.component'
import { FacilitiesEditComponent } from './facilities-edit/facilities-edit.component'
import { FacilitiesComponent } from './facilities/facilities.component'
import { FacilitySupportContactsComponent } from './facility-support-contacts/facility-support-contacts.component'
import { GenericTestDisplayComponent } from './generic-test-display/generic-test-display.component'
import { HomeRoutingModule } from './home-routing.module'
import { HomeComponent } from './home.component'
import { ImageViewerComponent } from './image-viewer/image-viewer.component'
import { InboxComponent } from './inbox/inbox.component'
import { IncludesPipe } from './includes.pipe'
import { MissedPhantomTestsDetailsComponent } from './missed-phantom-tests-details/missed-phantom-tests-details.component'
import { MissedPhantomTestsEditComponent } from './missed-phantom-tests-edit/missed-phantom-tests-edit.component'
import { MissedPhantomTestsComponent } from './missed-phantom-tests/missed-phantom-tests.component'
import { NaTestOptionsComponent } from './na-test-options/na-test-options.component'
import { PageHeaderComponent } from './page-header/page-header.component'
import { PhantomTestDetailsComponent } from './phantom-test-details/phantom-test-details.component'
import { CustomLimitComponent } from './phantom-test-details/testing-output/components/custom-limit/custom-limit.component'
import { QuestionsDisplayComponent } from './phantom-test-details/testing-output/components/questions-display/questions-display.component'
import { RawOutputDisplayComponent } from './phantom-test-details/testing-output/components/raw-output-display/raw-output-display.component'
import { TestReportsComponent } from './phantom-test-details/testing-output/components/test-reports/test-reports.component'
import { TestingOutputComponent } from './phantom-test-details/testing-output/testing-output.component'
import { PhantomTestsEditComponent } from './phantom-tests-edit/phantom-tests-edit.component'
import { PhantomTestsComponent } from './phantom-tests/phantom-tests.component'
import { PlaceholderComponent } from './placeholder/placeholder.component'
import { ProblemsComponent } from './problems/problems.component'
import { RecordSubtitleComponent } from './record-subtitle/record-subtitle.component'
import { ReportsDetailsComponent } from './reports-details/reports-details.component'
import { ReportsEditComponent } from './reports-edit/reports-edit.component'
import { ReportsComponent } from './reports/reports.component'
import { GenerateReportComponent } from './generate-report/generate-report.component'
import { ScannerCalendarComponent } from './scanner-calendar/scanner-calendar.component'
import { ScannerMultiSelectComponent } from './scanner-multi-select/scanner-multi-select.component'
import { ScannerSupportContactsComponent } from './scanner-support-contacts/scanner-support-contacts.component'
import { ScannersDetailsComponent } from './scanners-details/scanners-details.component'
import { ScannersEditComponent } from './scanners-edit/scanners-edit.component'
import { ScannersComponent } from './scanners/scanners.component'
import { WorkstationsComponent } from './workstations/workstations.component'
import { WorkstationsCreateComponent } from './workstations-create/workstations-create.component'
import { WorkstationsDetailsComponent } from './workstations-details/workstations-details.component'
import { WorkstationsEditComponent } from './workstations-edit/workstations-edit.component'
import { SeriesViewerComponent } from './series-viewer/series-viewer.component'
import { SboxComponent } from './settings/sbox/sbox.component'
import { SettingsComponent } from './settings/settings.component'
import { SideControlsComponent } from './side-controls/side-controls.component'
import { StatsComponent } from './stats/stats.component'
import { PerformTestComponent } from './studies-details/perform-test/perform-test.component'
import { RecentTestsComponent } from './studies-details/recent-tests/recent-tests.component'
import { StudiesDetailsComponent } from './studies-details/studies-details.component'
import { StudiesEditComponent } from './studies-edit/studies-edit.component'
import { StudiesComponent } from './studies/studies.component'
import { SurveyTestDetailsQuestionsComponent } from './survey-tests-details/survey-test-details-questions/survey-test-details-questions.component'
import { SurveyTestsDetailsComponent } from './survey-tests-details/survey-tests-details.component'
import { SurveyTestsEditComponent } from './survey-tests-edit/survey-tests-edit.component'
import { SurveyTestsComponent } from './survey-tests/survey-tests.component'
import { SurveyComponent } from './survey/survey.component'
import { TestSummaryComponent } from './test-summary/test-summary.component'
import { TrendsTabsComponent } from './trends-tabs/trends-tabs.component'
import { TrendsTooltipComponent } from './trends/trends-tooltip/trends-tooltip.component'
import { TrendsComponent } from './trends/trends.component'
import { UserSettingsComponent } from './user-settings/user-settings.component'
import { UsersDetailsComponent } from './users-details/users-details.component'
import { UsersEditComponent } from './users-edit/users-edit.component'
import { UsersComponent } from './users/users.component'
import { MetricsComponent } from './metrics/metrics.component'
import { GagueComponent } from './metrics/gague/gague.component'
import { BarComponent } from './metrics/bar/bar.component'
import { ByMetricSummaryComponent } from './metrics/by-metric-summary/by-metric-summary.component'
import { MetricGroupsComponent } from './metrics/metric-groups/metric-groups.component'
import { PercentagePipe } from './percentage.pipe'
import { MetricCellComponent } from './metrics/metric-groups/metric-cell/metric-cell.component'
import { ClrPopoverToggleService } from '@clr/angular'
import { StatusComponent } from './status/status.component'
import { RruleGeneratorComponent } from './rrule-generator/rrule-generator.component'
import { DicomViewerComponent } from './dicom-viewer/dicom-viewer.component'
import { SeriesThumbnailsComponent } from './series-thumbnails/series-thumbnails.component'
import { EvensPipe } from './evens.pipe'
import { OddsPipe } from './odds.pipe'
import { SchedulesComponent } from './schedules/schedules.component'
import { RruleTextPipe } from './rrule-text.pipe'
import { ScheduleIconComponent } from './schedules/schedule-icon/schedule-icon.component'
import { SingleScheduleComponent } from './schedules/single-schedule/single-schedule.component'
import { JoinPipe } from './join.pipe'
import { ScannerRruleGeneratorComponent } from './scanner-rrule-generator/scanner-rrule-generator.component'
import { DurationPickerComponent } from './duration-picker/duration-picker.component'
const init = echarts.init

@NgModule({
  declarations: [
    HomeComponent,
    DashboardComponent,
    ReportsComponent,
    GenerateReportComponent,
    TrendsComponent,
    ScannersComponent,
    WorkstationsComponent,
    FacilitiesComponent,
    UsersComponent,
    SettingsComponent,
    StudiesComponent,
    PhantomTestDetailsComponent,
    CustomDatePipe,
    PhantomTestsComponent,
    InboxComponent,
    ScannersDetailsComponent,
    ScannersEditComponent,
    WorkstationsCreateComponent,
    WorkstationsDetailsComponent,
    WorkstationsEditComponent,
    UsersDetailsComponent,
    UsersEditComponent,
    FacilitiesDetailsComponent,
    FacilitiesEditComponent,
    ReportsDetailsComponent,
    ReportsEditComponent,
    SurveyTestsComponent,
    SurveyTestsDetailsComponent,
    SurveyTestsEditComponent,
    StudiesDetailsComponent,
    StudiesEditComponent,
    PhantomTestsEditComponent,
    DashboardTestsComponent,
    IconLegendComponent,
    ChangePasswordComponent,
    UserSettingsComponent,
    TestingOutputComponent,
    RawOutputDisplayComponent,
    CustomLimitComponent,
    QuestionsDisplayComponent,
    PerformTestComponent,
    RecentTestsComponent,
    CommentsComponent,
    CommentsDetailsComponent,
    CommentsEditComponent,
    CommentCategoriesComponent,
    CommentCategoriesDetailsComponent,
    CommentCategoriesEditComponent,
    SurveyComponent,
    SurveyTestDetailsQuestionsComponent,
    ScannerCalendarComponent,
    SboxComponent,
    MissedPhantomTestsComponent,
    MissedPhantomTestsDetailsComponent,
    MissedPhantomTestsEditComponent,
    ViewTestComponent,
    NaTestOptionsComponent,
    TestReportsComponent,
    PageHeaderComponent,
    ImageViewerComponent,
    RecordSubtitleComponent,
    SeriesViewerComponent,
    DocumentViewerComponent,
    TrendsComponent,
    GenericTestDisplayComponent,
    TestSummaryComponent,
    ProblemsComponent,
    StatsComponent,
    IncludesPipe,
    ScannerSupportContactsComponent,
    FacilitySupportContactsComponent,
    PlaceholderComponent,
    ScannerMultiSelectComponent,
    SideControlsComponent,
    TrendsTabsComponent,
    TrendsTooltipComponent,
    MetricsComponent,
    GagueComponent,
    BarComponent,
    ByMetricSummaryComponent,
    MetricGroupsComponent,
    PercentagePipe,
    MetricCellComponent,
    StatusComponent,
    RruleGeneratorComponent,
    DicomViewerComponent,
    SeriesThumbnailsComponent,
    EvensPipe,
    OddsPipe,
    SchedulesComponent,
    RruleTextPipe,
    ScheduleIconComponent,
    SingleScheduleComponent,
    JoinPipe,
    ScannerRruleGeneratorComponent,
    DurationPickerComponent,
  ],
  imports: [
    TagInputModule,
    CoreModule,
    HomeRoutingModule,
    FiltersModule,
    FullCalendarModule,
    NgxImageGalleryModule,
    HasuraModule,
    NgxEchartsModule.forRoot({ echarts: { init } }),
  ],
  providers: [
    DatePipe,
    CustomDatePipe,
    HumanReadablePipe,
    ClrPopoverToggleService,
  ],
})
export class HomeModule {}
