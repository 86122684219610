<form clrForm>
  <clr-input-container>
    <label>Old Password</label>
    <input
      required
      type="password"
      clrInput
      name="oldPw"
      [(ngModel)]="pw.old"
      (keyup.enter)="submitPw()"
    />
    <clr-control-error>Please enter your old password</clr-control-error>
  </clr-input-container>
  <clr-input-container>
    <label>New Password</label>
    <input
      required
      type="password"
      clrInput
      name="newPw"
      [(ngModel)]="pw.new"
      (keyup.enter)="submitPw()"
    />
    <clr-control-error>Please enter your new password</clr-control-error>
  </clr-input-container>
  <clr-input-container>
    <label>Confirm Password</label>
    <input
      required
      type="password"
      clrInput
      name="conPw"
      [(ngModel)]="pw.con"
      (keyup.enter)="submitPw()"
    />
    <clr-control-error>Please confirm your old password</clr-control-error>
  </clr-input-container>
  <clr-alert
    class="form-alert"
    *ngIf="!passwordsMatch()"
    [clrAlertType]="'danger'"
  >
    <clr-alert-item>
      <span class="alert-text">
        Passwords do not match
      </span>
    </clr-alert-item>
  </clr-alert>
</form>
<button type="button" class="btn btn-success-outline" (click)="submitPw()">
  Submit
</button>
<button type="button" class="btn btn-outline" (click)="cancel()">Cancel</button>
