import { Component, Input, OnInit } from '@angular/core'
import { Breakdown } from 'src/app/metrics.service'

const sorter = (a: string, b: string) => {
  if (a === 'none' || a === 'undefined') return 1
  if (b === 'none' || b === 'undefined') return -1
  return a.localeCompare(b)
}

const transpose = (array: number[][]): number[][] =>
  array[0].map((_, colIndex) => array.map((row) => row[colIndex]))

@Component({
  selector: 'app-metric-groups',
  templateUrl: './metric-groups.component.html',
  styleUrls: ['./metric-groups.component.scss'],
})
export class MetricGroupsComponent implements OnInit {
  @Input() config: Breakdown
  chartOptions: any
  group1Labels: string[]
  group2Labels: string[]
  group1Totals: number[]
  group2Totals: number[]
  matrix: number[][]

  constructor() {}

  ngOnInit(): void {
    const group1Labels = Object.keys(this.config.group1).sort(sorter)
    const group2Labels = Object.keys(this.config.group2).sort(sorter)
    const group1Totals = group1Labels.map(
      (key) => this.config.group1[key]?.changeInMetric.vsLastMonth ?? 0,
    )
    const group2Totals = group2Labels.map(
      (key) => this.config.group2[key]?.changeInMetric.vsLastMonth ?? 0,
    )
    const matrix = transpose([
      ...group1Labels.map((group1Key) =>
        group2Labels.map(
          (group2Key) =>
            this.config.both[group1Key]?.[group2Key]?.changeInMetric
              ?.vsLastMonth ?? 0,
        ),
      ),
    ])

    this.group1Labels = group1Labels
    this.group2Labels = group2Labels
    this.group1Totals = group1Totals
    this.group2Totals = group2Totals
    this.matrix = matrix
  }
}
