import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import * as moment from 'moment-timezone'
import { MessageError } from 'src/app/core/errors/errors'
import { ReportService } from 'src/app/core/report.service'
import { TimezoneService } from 'src/app/timezone.service'
import { safeGet } from '../../util/util'
import { PageHeaderAction } from '../page-header/page-header.component'
import deleteMutation from './delete-survey-tests-details.gql'
import query from './survey-tests-details.gql'
import { delete_survey_test_by_pk } from './types/delete_survey_test_by_pk'
import { surveyTestsDetails } from './types/surveyTestsDetails'
import { NavService } from 'src/app/core/nav.service'

@Component({
  selector: 'app-survey-tests-details',
  templateUrl: './survey-tests-details.component.html',
  styleUrls: ['./survey-tests-details.component.scss'],
})
export class SurveyTestsDetailsComponent implements OnInit {
  @Input() survey_testId: number

  loading = false

  editing = false

  queryResult: ApolloQueryResult<surveyTestsDetails>

  safeGet = safeGet

  @Output() updateEvent = new EventEmitter()

  months: moment.Moment[]

  modalOpened: boolean

  actions: PageHeaderAction[] = [
    { text: 'Delete Survey Test', class: 'danger' },
    { text: 'Edit Survey Test' },
    { text: 'Generate Report' },
  ]

  title = 'Test Details'

  constructor(
    private apollo: Apollo,
    private reportService: ReportService,
    private route: ActivatedRoute,
    private timezoneService: TimezoneService,
    private nav: NavService,
  ) {}

  actionsHandler($event: string) {
    switch ($event) {
      case 'Delete Survey Test':
        this.delete()
        break
      case 'Edit Survey Test':
        this.editView()
        break
      case 'Generate Report':
        this.openModal()
        break
      default:
        break
    }
  }

  ngOnInit() {
    if (!this.survey_testId) {
      this.survey_testId = this.route.snapshot.params.id
    }
    this.fetchsurveyTestsDetails()
  }

  fetchsurveyTestsDetails = () => {
    this.loading = true
    this.apollo
      .query<surveyTestsDetails>({
        query,
        variables: { id: this.survey_testId },
      })
      .subscribe({ next: this.onNext, error: this.onError })
  }

  onNext = (result: ApolloQueryResult<surveyTestsDetails>) => {
    this.loading = false
    this.queryResult = result
    if (result.errors) {
      throw result.errors[0]
    }
    if (!this.survey_test) {
      throw new MessageError('test not found')
    }
    this.months = this.generateMonths()
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  get survey_test() {
    return this.queryResult?.data?.survey_test_by_pk
  }

  get scanner() {
    return this.survey_test.scanner
  }

  get facility() {
    return this.scanner.facility
  }

  get room() {
    return this.scanner.facility_room
  }

  get scannerName() {
    return this.survey_test.scanner.name
  }

  get facilityName() {
    return this.scanner.facility?.name
  }

  get roomName() {
    return this.scanner.facility_room.name
  }

  delete() {
    if (window.confirm('Delete Survey: are you sure?')) {
      this.apollo
        .mutate<delete_survey_test_by_pk>({
          mutation: deleteMutation,
          variables: { id: this.survey_testId },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.nav.navtree.surveyTests()
          },
          error: (err) => console.log(err),
        })
    }
  }

  editView() {
    this.editing = true
  }

  onSave($event) {
    this.editing = false
    this.updateEvent.emit($event)
    this.fetchsurveyTestsDetails()
  }

  get scannerId() {
    return this.survey_test.scannerId
  }

  generateReport(monthMoment: moment.Moment) {
    const periodLength: number = 1
    const month = monthMoment.toDate()
    const selectedRoutine = '' //survey will get routine in service
    this.modalOpened = false
    this.reportService.generateReport({
      scannerId: this.scannerId,
      surveyTestId: this.survey_test.id,
      month,
      selectedRoutine,
      periodLength,
    })
  }

  get testDate(): string {
    return moment(this.survey_test.dateCreated).format('MMMM DD')
  }

  generateMonths(): moment.Moment[] {
    let result: moment.Moment[] = []
    for (let i = 0; i < 14; i++) {
      const thisTestDateStartOfMonth = moment(this.survey_test.dateCreated)
        .tz(this.timezoneService.cachedTz)
        .add(-i, 'month')
        .startOf('month')
      result.push(thisTestDateStartOfMonth)
    }
    // const thisTestDateStartOfMonth = moment(this.survey_test.dateCreated)
    //   .tz(this.timezoneService.cachedTz)
    //   .startOf('month')
    // const oneMonthAgoStartOfMonth = moment(this.survey_test.dateCreated)
    //   .tz(this.timezoneService.cachedTz)
    //   .add(-12, 'month')
    //   .startOf('month')
    //return [oneMonthAgoStartOfMonth, thisTestDateStartOfMonth]
    return result
  }

  openModal() {
    this.modalOpened = true
  }
}
