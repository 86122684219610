import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'evens',
})
export class EvensPipe implements PipeTransform {
  transform<T>(value: T[]): T[] {
    return value.filter((_, index) => index % 2 === 0)
  }
}
