<div class="">
  <form clrForm>
    <clr-select-container>
      <label>Facility</label>
      <select
        *ngIf="relatedEntityOptionsQueryResult"
        clrSelect
        name="Facility"
        [(ngModel)]="scanner.facilityId"
        (ngModelChange)="onFacilityIdChange()"
      >
        <option
          *ngFor="let facility of relatedEntityOptions.facility"
          [ngValue]="facility.id"
          >{{ facility.name }}
        </option>
      </select>
    </clr-select-container>
    <clr-select-container>
      <label>Modality</label>
      <select
        *ngIf="relatedEntityOptionsQueryResult"
        clrSelect
        name="Modality"
        [(ngModel)]="scanner.modalityId"
      >
        <option
          *ngFor="let modality of relatedEntityOptions.modality"
          value="{{ modality.id }}"
          >{{ modality.id }}
        </option>
      </select>
    </clr-select-container>
    <clr-select-container>
      <label>Room</label>
      <select
        *ngIf="relatedEntityOptionsQueryResult"
        clrSelect
        name="Room"
        [disabled]="
          scanner.facilityId === undefined || scanner.facilityId === null
        "
        [(ngModel)]="scanner.facilityRoomId"
      >
        <option [ngValue]="null"></option>
        <option
          *ngFor="let facility_room of filteredFacilityRoomOptions"
          [ngValue]="facility_room.id"
        >
          {{ facility_room.name }}</option
        >
      </select>
    </clr-select-container>
    <clr-input-container>
      <label>Scanner</label>
      <input clrInput name="Scanner" [(ngModel)]="scanner.name" />
    </clr-input-container>
    <clr-select-container>
      <label>Manufacturer</label>
      <select
        *ngIf="relatedEntityOptionsQueryResult"
        clrSelect
        name="Manufacturer"
        [(ngModel)]="scanner.scannerManufacturerId"
      >
        <option
          *ngFor="
            let scanner_manufacturer of relatedEntityOptions.scanner_manufacturer
          "
          [value]="scanner_manufacturer.id"
          >{{ scanner_manufacturer.name }}</option
        >
      </select>
    </clr-select-container>
    <clr-select-container>
      <label>Model</label>
      <select
        *ngIf="relatedEntityOptionsQueryResult"
        clrSelect
        name="Model"
        [(ngModel)]="scanner.scannerModelId"
      >
        <option
          *ngFor="let scanner_model of relatedEntityOptions.scanner_model"
          [value]="scanner_model.id"
        >
          {{ scanner_model.name }}</option
        >
      </select>
    </clr-select-container>
    <clr-date-container>
      <label>Last Calibration Date</label>
      <input
        clrDate
        name="lastCalibrationDate"
        [(ngModel)]="lastCalibrationDate"
        [(max)]="today"
      />
    </clr-date-container>
  </form>

  <button (click)="save()" class="btn btn-success-outline">Save</button>
  <button (click)="back()" class="btn btn-outline">Back</button>
</div>
