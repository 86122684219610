<app-facilities-edit
  (saveEvent)="onUpdate($event)"
  *ngIf="creatingFacility"
></app-facilities-edit>
<ng-container *ngIf="!creatingFacility">
  <div
    class="clr-row"
    appPageHeader
    [title]="title"
    [actions]="actions"
    (clicks)="actionsHandler($event)"
  ></div>
  <clr-datagrid
    class="datagrid-striped"
    #datagrid
    (clrDgRefresh)="refreshGrid($event, fetchItems)"
    [clrDgLoading]="loading"
  >
    <clr-dg-placeholder>Nothing to Show</clr-dg-placeholder>

    <!-- Row Def -->
    <clr-dg-row *ngFor="let item of items">
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.name
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.address
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.phone
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.facilityId
      }}</clr-dg-cell>
    </clr-dg-row>

    <!-- Name Column -->
    <clr-dg-column
      [clrDgField]="properties.name"
      [clrFilterValue]="filterState[properties.name]?.value"
    >
      <ng-container>Name</ng-container>
    </clr-dg-column>

    <!-- Address Column -->
    <clr-dg-column
      [clrDgField]="properties.address"
      [clrFilterValue]="filterState[properties.address]?.value"
    >
      <ng-container>Address</ng-container>
    </clr-dg-column>

    <!-- Phone Column -->
    <clr-dg-column
      [clrDgField]="properties.phone"
      [clrFilterValue]="filterState[properties.phone]?.value"
    >
      <ng-container>Phone</ng-container>
    </clr-dg-column>

    <!-- Facility ID Column -->
    <clr-dg-column
      [clrDgField]="properties.facilityId"
      [clrFilterValue]="filterState[properties.facilityId]?.value"
    >
      <ng-container>Facility ID</ng-container>
    </clr-dg-column>

    <!-- Footer -->
    <clr-dg-footer>
      {{ pagination.firstItem + 1 | number }} -
      {{ pagination.lastItem + 1 | number }} of {{ totalItems | number }} items
      <clr-dg-pagination
        #pagination
        [clrDgTotalItems]="totalItems"
      ></clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</ng-container>
