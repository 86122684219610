import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { Phantom, Result } from 'common/aqsTypes'
import { DatagridStateService } from 'src/app/core/datagrid-state.service'
import { FriendlyPhantomNamePipe } from 'src/app/core/friendly-phantom-name.pipe'
import { NavService } from 'src/app/core/nav.service'
import { TimezoneService } from 'src/app/timezone.service'
import { DatagridComponent } from 'src/app/util/datagrid.class'
import { KeyVal, userFullname } from '../../util/util'
import phantom_test_get_related_entitiesGql from '../phantom-tests-edit/phantom_test_get_related_entities.gql'
import { phantom_test_get_related_entities } from '../phantom-tests-edit/types/phantom_test_get_related_entities'
import query from './phantom-tests.gql'
import { phantomTests, phantomTests_phantom_test } from './types/phantomTests'
import { ClrLoadingState } from '@clr/angular'
import { DevError } from 'src/app/core/errors/errors'
import { cloneDeep } from 'lodash'
import {
  exportPhantomTests,
  exportPhantomTestsVariables,
  exportPhantomTests_firstTest,
} from './types/exportPhantomTests'
import { FileService } from 'src/app/core/file.service'
import moment from 'moment-timezone'
import exportPhantomTestsGql from './export-phantom-tests.gql'
import { ExportTestsService } from 'src/app/export-tests.service'

@Component({
  selector: 'app-phantom-tests',
  templateUrl: './phantom-tests.component.html',
  styleUrls: ['./phantom-tests.component.scss'],
})
export class PhantomTestsComponent extends DatagridComponent {
  title = 'tests'

  getUserFullname = userFullname

  static properties = {
    scanDate: 'study.scanDate',
    facility: { filter: 'scanner.facility.id', sort: 'scanner.facility.name' },
    facility_room: {
      filter: 'scanner.facility_room.id',
      sort: 'scanner.facility_room.name',
    },
    modality: 'study.modality.id',
    scanner: { filter: 'scanner.id', sort: 'scanner.name' },
    phantom: 'phantom_test_routine.phantom',
    user: { sort: 'user.lastName', filter: 'user.id' },
    result: 'result',
  }
  properties = PhantomTestsComponent.properties

  constructor(
    private apollo: Apollo,
    private nav: NavService,
    protected router: Router,
    protected dgStateService: DatagridStateService,
    private friendlyPhantomNamePipe: FriendlyPhantomNamePipe,
    protected timezoneService: TimezoneService,
    private fileService: FileService,
    private exportTests: ExportTestsService,
  ) {
    super(dgStateService, router, timezoneService)
    this.defaultState = {
      sort: {
        by: this.properties.scanDate,
        reverse: true,
      },
    }
  }

  items: phantomTests_phantom_test[]
  totalItems: number

  selectedScannerId: number | undefined

  // things for data export
  showExportModal = false
  exportButtonState: ClrLoadingState
  tryToOpenModal() {
    if (this.exportButtonState !== ClrLoadingState.LOADING) {
      this.showExportModal = true
    }
  }
  anyFilterSelected(): boolean {
    return this.dgStateService.get(this.title)?.filters?.length > 0
  }
  scannerFilterSelected(): boolean {
    const scannerFilter = this.dgStateService
      .get(this.title)
      ?.filters?.findIndex((filter) => {
        return filter.property === 'scanner.id'
      })
    return scannerFilter !== undefined && scannerFilter !== -1
  }

  exportDataType: 'csv' | 'json' = 'json'
  async exportData() {
    if (!this.anyFilterSelected()) {
      if (
        !window.confirm(
          'Are you sure you want to export all tests for all scanners?',
        )
      ) {
        return
      }
    }
    this.exportButtonState = ClrLoadingState.LOADING
    const variablesNoLimit: exportPhantomTestsVariables = cloneDeep(
      this.variables,
    )
    delete variablesNoLimit.limit
    try {
      await this.exportTests.export(variablesNoLimit)
      this.exportButtonState = ClrLoadingState.SUCCESS
    } catch (e) {
      this.exportButtonState = ClrLoadingState.ERROR
      this.onError(e)
    }
  }

  fetchItems = () => {
    this.loading = true
    this.apollo
      .query<phantomTests>({ query, variables: this.variables })
      .subscribe({
        next: (result: ApolloQueryResult<phantomTests>) => {
          this.loading = false
          if (result.errors) {
            throw result.errors[0]
          }
          this.items = result.data.phantom_test
          this.totalItems = result.data.phantom_test_aggregate.aggregate.count
        },
        error: this.onError,
      })
    this.apollo
      .query<phantom_test_get_related_entities>({
        query: phantom_test_get_related_entitiesGql,
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.assembleRelatedEntityOptions(result.data)
        },
        error: this.onError,
      })
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  options: {
    facilities: KeyVal[]
    rooms: KeyVal[]
    modalities: KeyVal[]
    scanners: KeyVal[]
    phantoms: KeyVal[]
    results: KeyVal[]
    users: KeyVal[]
  } = {
    facilities: [],
    rooms: [],
    modalities: [],
    scanners: [],
    phantoms: [],
    results: [],
    users: [],
  }

  assembleRelatedEntityOptions(data: phantom_test_get_related_entities) {
    this.options = {
      facilities: data.facility.map((f) => ({
        key: f.id,
        val: f.name,
      })),
      rooms: data.facility_room.map((r) => ({
        key: r.id,
        val: r.name,
      })),
      modalities: data.modality.map((m) => ({
        key: m.id,
        val: m.id,
      })),
      scanners: data.scanner.map((s) => ({
        key: s.id,
        val: s.name,
      })),
      phantoms: Object.values(Phantom).map(
        (p): KeyVal => ({
          key: p,
          val: this.friendlyPhantomNamePipe.transform(p),
        }),
      ),
      results: Object.values(Result).map((r): KeyVal => ({ key: r, val: r })),
      users: data.user.map((u) => ({
        key: u.id,
        val: this.getUserFullname(u),
      })),
    }
  }

  view(test: phantomTests_phantom_test) {
    this.nav.navtree.phantomTestDetails(test.id)
  }

  onScannerChange($event: string | number | undefined) {
    console.log($event)
    if (typeof $event !== 'string') {
      this.selectedScannerId = $event
    }
  }
}
