<div class="">
  <form clrForm>
    <clr-input-container>
      <label>Workstation Name</label>
      <input
        clrInput
        name="Name"
        [ngModel]="getModel().name"
        (ngModelChange)="setModel('name', $event)"
      />
    </clr-input-container>
  </form>

  <button (click)="save()" class="btn btn-success-outline">Save</button>
  <button (click)="back()" class="btn btn-outline">Back</button>
</div>
