import { gql } from 'apollo-angular'

export default gql`
  query facilitiesDetails($id: Int!) {
    facility_by_pk(id: $id) {
      id
      name
      address
      phone
      facilityId
      facility_rooms {
        id
        name
      }
      supportContacts
    }
  }
`
