import { gql } from 'apollo-angular'

export default gql`
  query missed_phantom_test_get_related_entities {
    facility_room(order_by: { name: asc }) {
      id
      name
    }
    facility(order_by: { name: asc }) {
      id
      name
    }
    scanner(order_by: { name: asc }) {
      id
      name
    }
  }
`
