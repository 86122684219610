<div class="">
  <form clrForm>
    <clr-input-container>
      <label>Date Generated</label>
      <input clrInput name="Date Generated" [(ngModel)]="report.dateCreated" />
    </clr-input-container>

    <clr-input-container>
      <label>Month</label>
      <input clrInput name="Month" [(ngModel)]="report.month" />
    </clr-input-container>
    <clr-input-container>
      <label>Year</label>
      <input clrInput name="Year" [(ngModel)]="report.year" />
    </clr-input-container>
  </form>

  <button (click)="save()" class="btn btn-success-outline">Save</button>
  <button (click)="back()" class="btn btn-outline">Back</button>
</div>
