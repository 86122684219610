<span
  class="value-box"
  [ngClass]="{
    'custom-limit-set': customLimitIsSet
  }"
  (click)="edit()"
>
  {{ currentLimit | number: numberPipeString() }}
  <clr-icon class="edit-icon" shape="pencil"></clr-icon>
</span>

<clr-modal [(clrModalOpen)]="editing" [clrModalClosable]="false">
  <h3 class="modal-title">Set Custom Limit</h3>
  <div class="modal-body">
    <p>
      You can set custom high/low limits for any test. A limit for this scanner
      will supercede a limit for this model, which will supercede a limit for
      all scanners. <em>Changes take effect on the next test.</em>
    </p>
    <form clrForm>
      <clr-input-container>
        <label class="clr-col-6">All scanners using this test:</label>
        <input
          class="clr-col-6"
          clrInput
          name="allScanners"
          type="number"
          [(ngModel)]="allScannersCustomLimit"
        />
      </clr-input-container>
      <clr-input-container>
        <label class="clr-col-6"
          >This model ({{ scannerModelName }}) only:</label
        >
        <input
          class="clr-col-6"
          clrInput
          name="scannerModel"
          type="number"
          [(ngModel)]="scannerModelCustomLimit"
        />
      </clr-input-container>
      <clr-input-container>
        <label class="clr-col-6">This scanner ({{ scannerName }}) only:</label>
        <input
          class="clr-col-6"
          clrInput
          name="thisScanner"
          type="number"
          [(ngModel)]="thisScannerCustomLimit"
        />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="cancel()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">Save</button>
  </div>
</clr-modal>
