import { gql } from 'apollo-angular'

export default gql`
  mutation insert_facility($facility: facility_insert_input!) {
    insert_facility(objects: [$facility]) {
      returning {
        id
      }
    }
  }
`
