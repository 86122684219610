import { gql } from 'apollo-angular'

export const getLatestSurveyIdGql = gql`
  query latestSurveyId($scannerId: Int!) {
    survey_test(
      where: { scannerId: { _eq: $scannerId } }
      order_by: { dateCreated: desc }
      limit: 1
    ) {
      id
    }
  }
`
