<div id="alerts">
  <clr-alert
    *ngFor="let alert of alerts"
    [clrAlertType]="alert.type"
    (clrAlertClosedChange)="onClose($event, alert.id)"
  >
    <clr-alert-item>
      <span class="alert-text" [innerText]="alert.text"> </span>
      <div class="alert-actions">
        <a
          *ngIf="alert.viewLink"
          (click)="removeAlert(alert.id)"
          [routerLink]="[alert.viewLink]"
          >view</a
        >
      </div>
    </clr-alert-item>
  </clr-alert>
</div>
