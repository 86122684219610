import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import * as moment from 'moment-timezone'
import { join } from 'path'
import { MessageError } from 'src/app/core/errors/errors'
import { environment } from 'src/environments/environment'
import { TimezoneService } from 'src/app/timezone.service'
import { safeGet } from '../../util/util'
import { PageHeaderAction } from '../page-header/page-header.component'
import deleteMutation from './delete-reports-details.gql'
import query from './reports-details.gql'
import { delete_report_by_pk } from './types/delete_report_by_pk'
import { reportsDetails } from './types/reportsDetails'
import { NavService } from 'src/app/core/nav.service'

@Component({
  selector: 'app-reports-details',
  templateUrl: './reports-details.component.html',
  styleUrls: ['./reports-details.component.scss'],
})
export class ReportsDetailsComponent implements OnInit {
  @Input() reportId: number

  loading = false

  editing = false

  private queryResult: ApolloQueryResult<reportsDetails>

  safeGet = safeGet

  @Output() updateEvent = new EventEmitter()

  actions: PageHeaderAction[] = [
    { text: 'Delete Report', class: 'danger' },
    { text: 'Edit Report' },
  ]

  title = 'report details'

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private router: Router,
    private timezoneService: TimezoneService,
    private nav: NavService,
  ) {}

  actionsHandler($event: string) {
    switch ($event) {
      case 'Delete Report':
        this.delete()
        break
      case 'Edit Report':
        this.editView()
        break
      default:
        break
    }
  }

  ngOnInit() {
    if (!this.reportId) {
      this.reportId = this.route.snapshot.params.id
    }
    this.fetchreportsDetails()
  }

  fetchreportsDetails = () => {
    this.loading = true
    this.apollo
      .query<reportsDetails>({ query, variables: { id: this.reportId } })
      .subscribe({ next: this.onNext, error: this.onError })
  }

  onNext = (result: ApolloQueryResult<reportsDetails>) => {
    this.loading = false
    this.queryResult = result
    if (result.errors) {
      throw result.errors[0]
    }
    if (!this.report) {
      throw new MessageError('report not found')
    }
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  get report() {
    return this.queryResult?.data?.report_by_pk
  }

  get surveyTest() {
    return this.report.survey_test
  }

  get scanner() {
    return this.surveyTest.scanner
  }

  get facility() {
    return this.scanner.facility
  }

  get room() {
    return this.scanner.facility_room
  }

  delete() {
    if (window.confirm('Delete Report: are you sure?')) {
      this.apollo
        .mutate<delete_report_by_pk>({
          mutation: deleteMutation,
          variables: { id: this.reportId },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.nav.navtree.reports()
          },
          error: (err) => console.log(err),
        })
    }
  }

  editView() {
    this.editing = true
  }

  onSave($event) {
    this.editing = false
    this.updateEvent.emit($event)
    this.fetchreportsDetails()
  }

  get date() {
    return this.report.dateCreated
  }

  get surveyTestRoutineName() {
    return this.report.survey_test.survey_test_routine.name
  }

  get month() {
    return moment()
      .tz(this.timezoneService.cachedTz)
      .month(this.report.month)
      .format('MMMM')
  }

  get year() {
    return this.report.year
  }

  getReportFile(): string {
    if (this.report) {
      const { appUri } = environment
      const { hostedFilesPath } = environment
      const { key } = this.report.stored_file
      // Don't use this - join turns http:// into http:/
      // const url: string = join(appUri, hostedFilesPath, key);
      const url = `${appUri}${join(hostedFilesPath, key)}`
      return url
    }
    return ''
  }
}
