export function getUserTz() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const firstItem = (str: string, fn: (first: string) => string) =>
  fn(str[0]) + str.slice(1)
export const firstItemUpper = (str: string) =>
  firstItem(str, (str1: string) => (str1 ? str1.toUpperCase() : str1))
export const firstItemLower = (str: string) =>
  firstItem(str, (str1: string) => (str1 ? str1.toLowerCase() : str1))
export const unary = (fn: Function) => (...params: unknown[]) => fn(params[0])
export const delimiterMapper = (
  str: string,
  splitter: string | RegExp,
  mapper: (s: string) => string,
  joiner: string,
) => str.split(splitter).map(mapper).join(joiner)
export const snake = (str: string) =>
  delimiterMapper(str, /-|_|\s/g, unary(firstItemUpper), '')
export const camel = (str: string) => firstItemLower(snake(str))

/**
 * safely extracts a property from an object
 * useful in Angular views
 * @param item object to extract a property from
 * @param accessor dot-separated string accessor
 *
 * { a: 3 },        'a'   => 3
 * { a: 3 },        'b'   => undefined
 * { a: { b: 4 } }, 'a.b' => 4
 * { a: { b: 4 } }, 'x.y' => undefined
 */
export function safeGet(
  item: unknown,
  accessor: string,
  options: { warn?: boolean } = {},
): unknown {
  try {
    return accessor.split('.').reduce((acc, curr) => acc[curr], item)
  } catch (e) {
    if (options.warn) {
      console.warn(`${accessor} undefined`)
    }
    return undefined
  }
}

export function safeSet(
  item: unknown,
  accessor: string,
  value: unknown,
  options: { warn: boolean } = { warn: true },
): void {
  const accessorSplit = accessor.split('.')
  if (typeof item === 'object') {
    accessorSplit.forEach((path: string) => {
      // eslint-disable-next-line no-param-reassign
      item[path] = {}
    })
  } else if (options.warn) {
    console.warn(
      `safeSet found provided item to be of type ${typeof item}`,
      item,
    )
  }
}

export function generateId(len: number): string {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
}

export function hashCode(s: string) {
  /* eslint-disable no-bitwise */
  return s.split('').reduce((a, b) => {
    const c = (a << 5) - a + b.charCodeAt(0)
    return c & c
  }, 0)
  /* eslint-disable no-bitwise */
}

export enum PhantomTestResult {
  pass = 'pass',
  fail = 'fail',
  problem = 'problem',
  missed = 'missed',
  na = 'na',
}

export function anyOf(items: boolean[]): boolean {
  return items.reduce((acc, curr) => acc || curr, false)
}

export interface StringObjectLiteral<T = unknown> {
  [key: string]: T
}

export type PrimitiveType = string | number | boolean

export interface NestedObject {
  [key: string]: PrimitiveType | PrimitiveType[] | NestedObject
}

/**
 * https://stackoverflow.com/questions/42736031/remove-empty-objects-from-an-object
 * @param o object
 */
export function clearEmpties(o: object) {
  Object.keys(o).forEach((k) => {
    if (!o[k] || typeof o[k] !== 'object') {
      return // If null or not an object, skip to the next iteration
    }

    // The property is an object
    clearEmpties(o[k]) // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      // eslint-disable-next-line no-param-reassign
      delete o[k] // The object had no properties, so delete that property
    }
  })
}

export function printFileSizeString(fileSize: number): string {
  const sizeStr = fileSize.toString()
  const digits = sizeStr.length
  const suffixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
  const index = Math.floor((digits - 1) / 3)
  if (index >= suffixes.length) {
    return 'way too big'
  }
  const size = fileSize / 1000 ** index
  return `${size.toFixed(1)} ${suffixes[index]}`
}

export interface KeyVal<K = unknown, V = unknown> {
  key: K
  val: V
}

export const userFullname = (user: {
  firstName: string
  lastName: string
}): string => (user ? `${user.firstName} ${user.lastName}` : '')

export function isObjectLiteral(o: unknown): o is StringObjectLiteral {
  return o !== null && typeof o === 'object'
}

export function assertIsObjectLiteral(
  o: unknown,
): asserts o is StringObjectLiteral {
  if (!isObjectLiteral(o)) {
    console.log(o)
    throw new TypeError('assertion failed: o is not an object')
  }
}

export function filterLeaves(o: object, filter: (leaf: unknown) => boolean) {
  for (var k in o) {
    if (typeof o[k] === 'object' && o[k] !== null) {
      // the property is an object
      filterLeaves(o[k], filter) // <-- Make a recursive call on the nested object
    } else {
      // the property is a leaf
      if (!filter(o[k])) {
        delete o[k]
      }
    }
  }
}

export function stationName(scanner: { name: string }): string {
  if (!scanner) {
    return ''
  }
  const split = scanner.name.split('/')
  return split[split.length - 1]
}
