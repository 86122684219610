import { flatten } from 'lodash'
import { Component, OnInit, Input } from '@angular/core'
import { GALLERY_IMAGE } from 'ngx-image-gallery'
import { FileService } from 'src/app/core/file.service'
import {
  TestResult,
  TestResultSeries,
  TestOutputFile,
} from 'common/testing/ge_big'
import { DocxTestOutputFile, ImageTestOutputFile } from 'common/testing/manual'

@Component({
  selector: 'app-series-viewer',
  templateUrl: './series-viewer.component.html',
  styleUrls: ['./series-viewer.component.scss'],
})
export class SeriesViewerComponent implements OnInit {
  @Input() result: TestResult
  testImages: GALLERY_IMAGE[]
  seriesImages: GALLERY_IMAGE[][]
  documents: DocxTestOutputFile[]

  constructor(private fileService: FileService) {}

  ngOnInit(): void {
    const adapter = (file: TestOutputFile): GALLERY_IMAGE => ({
      title: file.label,
      url: this.src(file.location),
    })
    const imageFiles = (file: TestOutputFile): file is ImageTestOutputFile =>
      file.file_type === 'image'
    const docxFiles = (file: TestOutputFile): file is DocxTestOutputFile =>
      file.file_type === 'docx'
    const assembleGalleryImages = (series: TestResultSeries): GALLERY_IMAGE[] =>
      flatten(series.images.map((i) => i.annoFiles)).map(adapter)
    this.testImages = this.result.testFiles?.filter(imageFiles).map(adapter)
    this.documents = this.result.testFiles?.filter(docxFiles)
    this.seriesImages = this.result.series?.map(assembleGalleryImages)
  }

  src(key: string): string {
    return this.fileService.getHostedUrl(key)
  }
}
