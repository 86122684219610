import { LowHigh, NumEval, TestDisplay } from 'common/aqsTypes'
import { TestResult } from 'common/testing/ge_big'
import { flatten, mean, sortBy, uniqBy } from 'lodash'
import { std } from 'mathjs'
import moment from 'moment-timezone'
import { humanReadable } from 'src/app/core/human-readable.pipe'
import {
  trendsScannerDetails_scanner,
  trendsScannerDetails_scanner_phantom_tests,
  trendsScannerDetails_scanner_scanner_phantom_test_routines_phantom_test_routines_phantom_test_routine,
  trendsScannerDetails_scanner_survey_tests,
  trendsScannerDetails_scanner_survey_tests_survey_test_routine,
} from './types/trendsScannerDetails'
import { trendsScanners_scanner } from './types/trendsScanners'

// values
/** number of days to project */
export const TIME_PERIOD_DAYS = 15
/** chart precision */
export const MIN_INTERVAL = 0.01
/** a section must have at least this many points for a problem to be assigned */
export const MIN_SECTION_LENGTH = 7
/** the scanner must have at least this many points in total for a problem to be assigned */
export const MIN_TOTAL_LENGTH = 30
/** only use the past x number of points to perform predictive failure analysis */
const NUM_POINTS_FOR_PREDICTION = 9

// colors
export const PASS = '#0f0'
export const FAIL = '#f00'
export const PROBLEM = '#fdd008'
export const OUTLIER = '#fdd008'
export const NORMAL = '#1F77B4'
export const LIMITLINES = '#ff9999'

// types
export interface SideControlsStateInterface {
  selectedRoutine?: trends_phantom_test_routine
  selectedTrendKey?: string[]
  showTrendLines: boolean
  showControlLimits: boolean
  showOutliers: boolean
  sameYAxis: boolean
  disabled: boolean
  selectedSurveyTestRoutine?: trends_survey_test_routine
  selectedSurveyTestField?: string
}
export interface TrendsStateInterface {
  selectedScanners: trendsScanners_scanner[]
  left: SideControlsStateInterface
  right: SideControlsStateInterface
}

export type TrendDisplayType = 'singleScanner' | 'multipleScanners'

export type CSVDemoData = [number, number, number, number, number][]
export type trends_phantom_test_routine = trendsScannerDetails_scanner_scanner_phantom_test_routines_phantom_test_routines_phantom_test_routine
export type trends_survey_test_routine = trendsScannerDetails_scanner_survey_tests_survey_test_routine

export const axisColors = {
  left: '#4477aa',
  right: '#ee6677',
}

export interface Series {
  data: TrendableData
  type: string
  [key: string]: any
}

export function getTitleText(
  state: TrendsStateInterface,
  type: TrendDisplayType,
  tz: string,
): string {
  const today = dateFormatter(Date.now(), tz)
  const leftTrendKey = state.left.selectedTrendKey
  const rightTrendKey = state.right.selectedTrendKey
  let title: string = `as of ${today}`
  if ((!leftTrendKey && !rightTrendKey) || !state.selectedScanners[0]) {
    title = `Demo Trend ${title}`
  } else {
    switch (type) {
      case 'singleScanner':
        title = `${state.selectedScanners[0]?.name} ${title}`
        break
      case 'multipleScanners':
        title = `${displayTrendKey(leftTrendKey ?? [])} ${title}`
        break
      default:
        console.warn('missing switch case')
        return ''
    }
  }
  return title
}

export const dateFormatter = (value: number, tz: string) =>
  moment(value).tz(tz).format('MMM DD YYYY')

export function displayTrendKey(arr: string[]): string {
  return arr.map((s) => humanReadable(s)).join(' / ')
}

export function limitsToMarkLineData(limits: LowHigh): { yAxis?: number }[] {
  const data: { yAxis?: number }[] = []
  if (limits.low) {
    data.push({ yAxis: limits.low })
  }
  if (limits.high) {
    data.push({ yAxis: limits.high })
  }
  return data
}

export function findLimitsForPhantomTestData(
  selectedTrendKey: string[],
  data: PhantomTestData,
): LowHigh {
  let limits: LowHigh = {}
  const firstTest = data[data.length - 1]?.[2]
  if (!firstTest) {
    return limits
  }
  const testDisplay: TestDisplay | TestDisplay[] =
    firstTest.evaluationItems.testDisplay
  const allEvaluatedNumbers: NumEval[] = []
  let queue: TestDisplay[] = Array.isArray(testDisplay)
    ? testDisplay
    : [testDisplay]
  queue = queue.filter((item) => !!item)
  while (queue.length > 0) {
    const next = queue[0]
    queue = queue.slice(1)
    allEvaluatedNumbers.push(...next.evaluatedNumbers)
    queue.push(...next.children)
  }
  // this is sort of hacky, but we're looking for the limits for the value that corresponds to the selected trend key
  // by convention, the keys are dot separated based on where the data value comes from
  // for example, series.generic_data.materials.water.mean
  // if something goes wrong later, it's probably here lol
  const keyOfInterest = ['series', 'generic_data', ...selectedTrendKey].join(
    '.',
  )
  for (const numeval of allEvaluatedNumbers) {
    if (numeval.limitKey === keyOfInterest) {
      limits = numeval
      break
    }
  }
  return limits
}

export function findLimitsForSurveyTestData(
  selectedTrendKey: string[],
  data: SurveyTestData,
  surveyTestRoutine: trends_survey_test_routine,
): LowHigh {
  let limits: LowHigh = {}

  const questionOfInterest = surveyTestRoutine.survey_test_routine_questions_survey_test_questions.find(
    (q) => {
      q.survey_test_question.text === selectedTrendKey[0]
    },
  )
  if (questionOfInterest) {
    if (questionOfInterest.survey_test_question.min !== null) {
      limits.low = questionOfInterest.survey_test_question.min
    }
    if (questionOfInterest.survey_test_question.max !== null) {
      limits.high = questionOfInterest.survey_test_question.max
    }
  }
  return limits
}

export function extractPhantomTestDataFromScanner(
  selectedTrendKey: string[],
  phantomTests: trendsScannerDetails_scanner_phantom_tests[],
  selectedRoutine: trends_phantom_test_routine,
  tz: string,
): PhantomTestData {
  if (!selectedRoutine || !selectedTrendKey || !phantomTests) {
    return []
  }
  const data: PhantomTestData = []
  phantomTests
    .filter((p) => p.phantom_test_routine.id === selectedRoutine.id)
    .forEach((phantomTest) => {
      const momentDate = moment(phantomTest.study.scanDate).tz(tz).unix() * 1000

      const valueOfInterest = extractValueByTrendKey(
        phantomTest,
        selectedTrendKey,
      )
      if (valueOfInterest) {
        data.push([momentDate, valueOfInterest, phantomTest])
      }
    })
  return data
}

export function extractSurveyTestDataFromScanner(
  selectedTrendKey: string[],
  surveyTests: trendsScannerDetails_scanner_survey_tests[],
  selectedRoutine: trends_survey_test_routine,
  tz: string,
): SurveyTestData {
  if (!selectedRoutine || !selectedTrendKey || !surveyTests) {
    return []
  }
  const data: SurveyTestData = []
  surveyTests
    .filter((s) => s.survey_test_routine.id === selectedRoutine.id)
    .forEach((surveyTest) => {
      const momentDate = moment(surveyTest.dateCreated).tz(tz).unix() * 1000

      // for survey tests, the trend key will just be the question text
      const questionText = selectedTrendKey[0]
      const answeredQuestionOfInterest = surveyTest.answered_survey_test_questions.find(
        (q) => q.text === questionText,
      )
      if (answeredQuestionOfInterest) {
        const surveyTestRoutineQuestionOfInterest = selectedRoutine.survey_test_routine_questions_survey_test_questions.find(
          (q) => q.survey_test_question.text === questionText,
        )
        const answer = answeredQuestionOfInterest.answer
        switch (surveyTestRoutineQuestionOfInterest.survey_test_question.type) {
          case 'SurveyTestNumberQuestion':
            data.push([momentDate, parseFloat(answer), surveyTest])
            break
          case 'SurveyTestPassFailQuestion':
            const value = answer === 'pass' ? 1 : 0
            data.push([momentDate, value, surveyTest])
            break
          default:
            console.warn(
              `cannot trend on ${surveyTestRoutineQuestionOfInterest.survey_test_question.type}`,
            )
        }
      }
    })
  return data
}

export const extractValueByTrendKey = (
  test: {
    rawOutput: any
  },
  selectedTrendKey: string[],
): number | undefined => {
  function extract(data: any, keys: string[]) {
    let remainingKeys = keys
    let value = data
    while (remainingKeys.length > 0) {
      value = value?.[remainingKeys[0]]
      remainingKeys = remainingKeys.slice(1)
    }
    return value
  }
  let value: number | undefined
  const rawOutput = (test.rawOutput as unknown) as TestResult
  rawOutput.series?.forEach((series) => {
    const valueFromTest = extract(series.generic_data, selectedTrendKey)
    if (typeof valueFromTest === 'number') {
      value = valueFromTest
    }
  })
  return value
}

export const scannerColors = [
  '#4477aa',
  '#8dc1a9',
  '#e69d87',
  '#759aa0',
  '#eedd78',
  '#dd6b66',
  '#73a373',
  '#ea7e53',
  '#73b9bc',
  '#7289ab',
  '#91ca8c',
  '#f49f42',
]

export function zip<T, U>(arr1: T[], arr2: U[]): [T, U][] {
  if (arr1.length != arr2.length) {
    throw new Error(
      `zip length mismatch: arr1 length ${arr1.length}, arr2 length ${arr2.length}`,
    )
  }
  const zipped: [T, U][] = []
  arr1.forEach((value, index) => {
    zipped.push([arr1[index], arr2[index]])
  })
  return zipped
}

export function unzip<T, U>(arr: [T, U][]): [T[], U[]] {
  const tVals: T[] = []
  const uVals: U[] = []
  arr.forEach((value) => {
    tVals.push(value[0])
    uVals.push(value[1])
  })
  return [tVals, uVals]
}

/** mimics `np.split` except it removes the first item at the index because that point is an outlier */
export function npsplit<T>(dataArr: T[], indicies: number[]): T[][] {
  const sections: T[][] = []
  let currIndex = 0
  indicies.forEach((splitIndex) => {
    sections.push(
      dataArr.slice(currIndex === 0 ? currIndex : currIndex + 1, splitIndex),
    )
    currIndex = splitIndex
  })
  sections.push(dataArr.slice(currIndex === 0 ? currIndex : currIndex + 1))
  return sections
}

/** returns a linear fit for data provided as list of x values and list of y values */
export function fitAndCI(
  x: number[],
  y: number[],
): {
  a: number // y = ax + b
  b: number // y = ax + b
  y_fit: number[] // y value of linear fit
} {
  const poly = new Polyfit(x, y)
  const terms = poly.computeCoefficients(1)
  const b = terms[0]
  const a = terms[1]
  const y_fit = x.map((x) => a * x + b)

  return {
    a,
    b,
    y_fit,
  }
}

export type PhantomTestDatum = [
  number,
  number,
  trendsScannerDetails_scanner_phantom_tests?,
  number?, // error in # of days
  Date?, // error on this date
]
export type PhantomTestData = PhantomTestDatum[]

export type SurveyTestDatum = [
  number,
  number,
  trendsScannerDetails_scanner_survey_tests?,
  number?, // error in # of days
  Date?, // error on this date
]
export type SurveyTestData = SurveyTestDatum[]

export type TrendableDatum = [
  number,
  number,
  (
    | trendsScannerDetails_scanner_phantom_tests
    | trendsScannerDetails_scanner_survey_tests
  )?,
  number?, // error in # of days
  Date?, // error on this date
]
export type TrendableData = TrendableDatum[]

interface TooltipParams {
  componentType: 'series'
  // Series type
  seriesType: 'scatter' | 'line'
  // Series index in option.series
  seriesIndex: number
  // Series name
  seriesName: string
  // Data name, or category name
  name: string
  // Data index in input data array
  dataIndex: number
  // Original data as input
  data: PhantomTestDatum // has test only when type is scatter
  // Value of data. In most series it is the same as data.
  // But in some series it is some part of the data (e.g., in map, radar)
  value: PhantomTestDatum
  // encoding info of coordinate system
  // Key: coord, like ('x' 'y' 'radius' 'angle')
  // value: Must be an array, not null/undefined. Contain dimension indices, like:
  // {
  //     x: [2] // values on dimension index 2 are mapped to x axis.
  //     y: [0] // values on dimension index 0 are mapped to y axis.
  // }
  encode: { [key: string]: number[] }
  // dimension names list
  dimensionNames: string[]
  // data dimension index, for example 0 or 1 or 2 ...
  // Only work in `radar` series.
  dimensionIndex: number
  // Color of data
  color: string // hex
  // the percentage of pie chart
  percent: number
  // extras I pulled off the object
  axisDim: 'x'
  axisId: string
  axisIndex: number
  axisType: 'xAxis.time' | string
  axisValue: number | string
  axisValueLabel: string //"2020-08-16 07:06:48"
  borderColor: string | undefined
  componentIndex: string
  componentSubType: 'scatter' | 'line'
  dataType: undefined
  marker: string // raw HTML for the colored marker
  seriesId: string
}
export function tooltipFormatter(
  providedParams: TooltipParams | TooltipParams[],
  timezone: string,
): string | void {
  if (!Array.isArray(providedParams)) {
    providedParams = [providedParams]
  }
  const params = uniqBy(providedParams, (p) => p.value[1])
  if (params.length > 0) {
    const first = params[0]
    const xAxisValue = first.value[0] // this is a date in the trends component
    const xAxisDate = moment(xAxisValue).tz(timezone)
    const dateString = xAxisDate.format('MMM DD YYYY HH:MM')
    const innerText = (param: TooltipParams): string => {
      let text: string = ''
      if (param.seriesType === 'line') {
        const error_in = param.data[3]
        if (!error_in || error_in === Infinity) {
          text = `no predicted error within ${TIME_PERIOD_DAYS} days`
        } else {
          if (error_in === 1) {
            text = `currently outside limits`
          } else {
            text = `predicted error in ${error_in} days`
          }
        }
      } else if (param.seriesType === 'scatter') {
        text = `measurement: ${param?.value?.[1]?.toFixed(2)}`
      } else {
        console.warn('series type not line or scatter', param.seriesType)
      }
      return text
    }
    const drawSeries = (param: TooltipParams): string => `
    <div style="margin: 10px 0 0; line-height: 1">
          <div style="margin: 0px 0 0; line-height: 1">
            <span
              style="
                display: inline-block;
                margin-right: 4px;
                border-radius: 10px;
                width: 10px;
                height: 10px;
                background-color: ${param.color};
              "
            ></span
            ><span
              style="
                float: right;
                margin-left: 10px;
                font-size: 14px;
                color: #e7f5ff;
                font-weight: 900;
              "
              >${innerText(param)}</span
            >
            <div style="clear: both"></div>
          </div>
          <div style="clear: both"></div>
        </div>`
    return `
    <div style="margin: 0px 0 0; line-height: 1">
      <div
        style="font-size: 14px; color: #e7f5ff; font-weight: 400; line-height: 1"
      >
        ${dateString}
      </div>
      <div style="margin: 10px 0 0; line-height: 1">
        ${params.map(drawSeries).reduce((acc, curr) => acc + curr)}
      </div>
      <div style="clear: both"></div>
    </div>`
  }
}

/**
 * Since JavaScript's statistical ecosystem is woefully weak, here's a function that will give you a t score
 */
export function tScore(
  level: 0.1 | 0.05 | 0.025 | 0.01 | 0.005 | 0.001 | 0.0005,
  df: number,
): number {
  const table: {
    [df: number]: {
      0.1: number
      0.05: number
      0.025: number
      0.01: number
      0.005: number
      0.001: number
      0.0005: number
    }
  } = {
    1: {
      0.1: 3.078,
      0.05: 6.314,
      0.025: 12.706,
      0.01: 31.821,
      0.005: 63.656,
      0.001: 318.289,
      0.0005: 636.578,
    },
    2: {
      0.1: 1.886,
      0.05: 2.92,
      0.025: 4.303,
      0.01: 6.965,
      0.005: 9.925,
      0.001: 22.328,
      0.0005: 31.6,
    },
    3: {
      0.1: 1.638,
      0.05: 2.353,
      0.025: 3.182,
      0.01: 4.541,
      0.005: 5.841,
      0.001: 10.214,
      0.0005: 12.924,
    },
    4: {
      0.1: 1.533,
      0.05: 2.132,
      0.025: 2.776,
      0.01: 3.747,
      0.005: 4.604,
      0.001: 7.173,
      0.0005: 8.61,
    },
    5: {
      0.1: 1.476,
      0.05: 2.015,
      0.025: 2.571,
      0.01: 3.365,
      0.005: 4.032,
      0.001: 5.894,
      0.0005: 6.869,
    },
    6: {
      0.1: 1.44,
      0.05: 1.943,
      0.025: 2.447,
      0.01: 3.143,
      0.005: 3.707,
      0.001: 5.208,
      0.0005: 5.959,
    },
    7: {
      0.1: 1.415,
      0.05: 1.895,
      0.025: 2.365,
      0.01: 2.998,
      0.005: 3.499,
      0.001: 4.785,
      0.0005: 5.408,
    },
    8: {
      0.1: 1.397,
      0.05: 1.86,
      0.025: 2.306,
      0.01: 2.896,
      0.005: 3.355,
      0.001: 4.501,
      0.0005: 5.041,
    },
    9: {
      0.1: 1.383,
      0.05: 1.833,
      0.025: 2.262,
      0.01: 2.821,
      0.005: 3.25,
      0.001: 4.297,
      0.0005: 4.781,
    },
    10: {
      0.1: 1.372,
      0.05: 1.812,
      0.025: 2.228,
      0.01: 2.764,
      0.005: 3.169,
      0.001: 4.144,
      0.0005: 4.587,
    },
    11: {
      0.1: 1.363,
      0.05: 1.796,
      0.025: 2.201,
      0.01: 2.718,
      0.005: 3.106,
      0.001: 4.025,
      0.0005: 4.437,
    },
    12: {
      0.1: 1.356,
      0.05: 1.782,
      0.025: 2.179,
      0.01: 2.681,
      0.005: 3.055,
      0.001: 3.93,
      0.0005: 4.318,
    },
    13: {
      0.1: 1.35,
      0.05: 1.771,
      0.025: 2.16,
      0.01: 2.65,
      0.005: 3.012,
      0.001: 3.852,
      0.0005: 4.221,
    },
    14: {
      0.1: 1.345,
      0.05: 1.761,
      0.025: 2.145,
      0.01: 2.624,
      0.005: 2.977,
      0.001: 3.787,
      0.0005: 4.14,
    },
    15: {
      0.1: 1.341,
      0.05: 1.753,
      0.025: 2.131,
      0.01: 2.602,
      0.005: 2.947,
      0.001: 3.733,
      0.0005: 4.073,
    },
    16: {
      0.1: 1.337,
      0.05: 1.746,
      0.025: 2.12,
      0.01: 2.583,
      0.005: 2.921,
      0.001: 3.686,
      0.0005: 4.015,
    },
    17: {
      0.1: 1.333,
      0.05: 1.74,
      0.025: 2.11,
      0.01: 2.567,
      0.005: 2.898,
      0.001: 3.646,
      0.0005: 3.965,
    },
    18: {
      0.1: 1.33,
      0.05: 1.734,
      0.025: 2.101,
      0.01: 2.552,
      0.005: 2.878,
      0.001: 3.61,
      0.0005: 3.922,
    },
    19: {
      0.1: 1.328,
      0.05: 1.729,
      0.025: 2.093,
      0.01: 2.539,
      0.005: 2.861,
      0.001: 3.579,
      0.0005: 3.883,
    },
    20: {
      0.1: 1.325,
      0.05: 1.725,
      0.025: 2.086,
      0.01: 2.528,
      0.005: 2.845,
      0.001: 3.552,
      0.0005: 3.85,
    },
    21: {
      0.1: 1.323,
      0.05: 1.721,
      0.025: 2.08,
      0.01: 2.518,
      0.005: 2.831,
      0.001: 3.527,
      0.0005: 3.819,
    },
    22: {
      0.1: 1.321,
      0.05: 1.717,
      0.025: 2.074,
      0.01: 2.508,
      0.005: 2.819,
      0.001: 3.505,
      0.0005: 3.792,
    },
    23: {
      0.1: 1.319,
      0.05: 1.714,
      0.025: 2.069,
      0.01: 2.5,
      0.005: 2.807,
      0.001: 3.485,
      0.0005: 3.768,
    },
    24: {
      0.1: 1.318,
      0.05: 1.711,
      0.025: 2.064,
      0.01: 2.492,
      0.005: 2.797,
      0.001: 3.467,
      0.0005: 3.745,
    },
    25: {
      0.1: 1.316,
      0.05: 1.708,
      0.025: 2.06,
      0.01: 2.485,
      0.005: 2.787,
      0.001: 3.45,
      0.0005: 3.725,
    },
    26: {
      0.1: 1.315,
      0.05: 1.706,
      0.025: 2.056,
      0.01: 2.479,
      0.005: 2.779,
      0.001: 3.435,
      0.0005: 3.707,
    },
    27: {
      0.1: 1.314,
      0.05: 1.703,
      0.025: 2.052,
      0.01: 2.473,
      0.005: 2.771,
      0.001: 3.421,
      0.0005: 3.689,
    },
    28: {
      0.1: 1.313,
      0.05: 1.701,
      0.025: 2.048,
      0.01: 2.467,
      0.005: 2.763,
      0.001: 3.408,
      0.0005: 3.674,
    },
    29: {
      0.1: 1.311,
      0.05: 1.699,
      0.025: 2.045,
      0.01: 2.462,
      0.005: 2.756,
      0.001: 3.396,
      0.0005: 3.66,
    },
    30: {
      0.1: 1.31,
      0.05: 1.697,
      0.025: 2.042,
      0.01: 2.457,
      0.005: 2.75,
      0.001: 3.385,
      0.0005: 3.646,
    },
    60: {
      0.1: 1.296,
      0.05: 1.671,
      0.025: 2.0,
      0.01: 2.39,
      0.005: 2.66,
      0.001: 3.232,
      0.0005: 3.46,
    },
    120: {
      0.1: 1.289,
      0.05: 1.658,
      0.025: 1.98,
      0.01: 2.358,
      0.005: 2.617,
      0.001: 3.16,
      0.0005: 3.373,
    },
    1000: {
      0.1: 1.282,
      0.05: 1.646,
      0.025: 1.962,
      0.01: 2.33,
      0.005: 2.581,
      0.001: 3.098,
      0.0005: 3.3,
    },
    Infinity: {
      0.1: 1.282,
      0.05: 1.645,
      0.025: 1.96,
      0.01: 2.326,
      0.005: 2.576,
      0.001: 3.091,
      0.0005: 3.291,
    },
  }
  for (const key in table) {
    if (typeof key === 'string') {
      if (df <= parseInt(key)) {
        return table[key][level]
      }
    }
  }
  return table[Infinity][level]
}

/** modified version of Excel STEYX given that a regression has already been done */
export function steyx(yrange: number[], yfit: number[]): number {
  const sse: number = yrange
    .map((yval, index) => (yval - yfit[index]) ** 2)
    .reduce((acc, curr) => acc + curr, 0)
  return Math.sqrt((1 / (yrange.length - 2)) * sse)
}

/** see Excel DEVSQ */
export function devsq(x_values: number[]): number {
  const x_mean = mean(x_values)
  const sumSquaredDeviation = x_values
    .map((x) => (x - x_mean) ** 2)
    .reduce((acc, curr) => acc + curr, 0)
  return sumSquaredDeviation
}

export function toFixedNumber(input: number, digits: number) {
  var pow = Math.pow(10, digits)
  return Math.round(input * pow) / pow
}

export const numberFormatter = (value: string | number) => {
  return typeof value === 'number' ? value.toFixed(2) : value
}

/** Median Absolute Deviation of an array of numbers */
export function findMad(arr: number[]): number {
  const median = findMedian(arr)
  /** distance away from the median */
  const absoluteDeviations: number[] = arr.map((y_val) =>
    Math.abs(y_val - median),
  )
  /** median absolute deviation */
  const mad = findMedian(absoluteDeviations)
  return mad
}

export function findMedian(arr: number[]): number {
  const sorted = sortBy(arr)
  const median = sorted[Math.floor(sorted.length / 2)]
  return median
}

export class OutlierDetector {
  private x_arr: number[]
  private y_arr: number[]
  private limits?: LowHigh
  private std: number
  private median: number
  /** must have at least 30 points (arbitrary) */
  private minLength: number = 30
  constructor({
    x_arr,
    y_arr,
    limits,
  }: {
    x_arr: number[]
    y_arr: number[]
    limits?: LowHigh
  }) {
    this.x_arr = x_arr
    this.y_arr = y_arr
    this.limits = limits
    this.median = findMedian(y_arr)
    this.std = y_arr && y_arr.length > 0 ? std(y_arr) : 0
  }

  public outlierSeries(): PhantomTestData {
    return zip(this.x_arr, this.y_arr).filter(([_, y], index) =>
      this.isOutlier(y, index),
    )
  }

  /** indicies of the outliers */
  public findBreaks(): number[] {
    return this.y_arr
      .map((y_current, index) => ({
        index,
        isOutlier: this.isOutlier(y_current, index),
      }))
      .filter((item) => item.isOutlier)
      .map((item) => item.index)
  }

  public isOutlier = (y_current: number, index: number) => {
    if (this.x_arr.length < this.minLength) {
      return false
    }
    if (
      typeof this.limits?.high === 'number' &&
      typeof this.limits?.low === 'number'
    ) {
      return (
        this.isOutsideLimit(y_current) ||
        (this.isOutlierByDiff(y_current, index) &&
          this.isOutlierByLimit(y_current))
      )
    } else {
      return this.isOutlierByDiff(y_current, index, 2)
    }
  }

  /** closer to limit than median */
  private isOutlierByLimit = (y_current: number) => {
    const distanceFromMedian = Math.abs(y_current - this.median)
    const distanceFromLow: number | undefined = this.limits?.low
      ? Math.abs(y_current - this.limits.low)
      : undefined
    const distanceFromHigh: number | undefined = this.limits?.high
      ? Math.abs(y_current - this.limits.high)
      : undefined
    if (
      typeof distanceFromLow === 'number' &&
      distanceFromLow < distanceFromMedian
    )
      return true
    if (
      typeof distanceFromHigh === 'number' &&
      distanceFromHigh < distanceFromMedian
    )
      return true
    return false
  }
  /**
   * compared to the last point, this point has moved away from the median
   * by a distance greater than mad
   */
  private isOutlierByDiff = (
    y_current: number,
    index: number,
    factor: number = 1,
  ) => {
    if (index === 0) return false
    const distanceFromMedian = Math.abs(y_current - this.median)
    const y_prev = this.y_arr[index - 1]
    const prevDistanceFromMedian = Math.abs(y_prev - this.median)
    if (Math.abs(y_current - y_prev) > factor * this.std) {
      if (distanceFromMedian > prevDistanceFromMedian) return true
    }
    return false
  }
  private isOutsideLimit = (y_current: number) =>
    isOutsideLimits(y_current, this.limits)
}

const isOutsideLimits = (y_current: number, limits?: LowHigh) =>
  y_current > (limits?.high ?? Infinity) ||
  y_current < (limits?.low ?? -Infinity)

export function generatePlotsGivenData(opts: {
  data: TrendableData
  seriesName: string
  color: string
  showFits?: boolean
  showPredictionIntervals?: boolean
  showLimits?: boolean
  showOutliers?: boolean
  limits?: LowHigh
  yAxisIndex?: number
  selectedTrendKey?: string[]
}) {
  if (opts.limits === undefined) opts.limits = {}
  if (opts.yAxisIndex === undefined) opts.yAxisIndex = 0
  if (opts.selectedTrendKey === undefined) opts.selectedTrendKey = []
  const {
    data,
    seriesName,
    color,
    showFits,
    showLimits,
    showPredictionIntervals,
    showOutliers,
    limits,
    yAxisIndex,
    selectedTrendKey,
  } = opts

  let lastSeriesTrendingProblem: number | undefined = undefined
  // get x and y values from chart data
  const dates_x = data.map((d) => d[0])
  const values_y = data.map((d) => d[1])

  const outlierDetector = new OutlierDetector({
    x_arr: dates_x,
    y_arr: values_y,
    limits,
  })
  /**
   * indicies of where to break the graph to start a new trend line
   * let's just do it where there are outliers.
   *
   * EDIT: this was changed to not break the graph at outliers
   * but I'll leave the scaffolding here in case we need it later
   */
  const breaks: number[] = []
  const lastPointOutlier = false

  /* Second Plot: Trend analysis */

  const y_split = npsplit(values_y, breaks)
  const dates_split = npsplit(dates_x, breaks)

  const sections = y_split.map((cent_section, index) => {
    const date_section = dates_split[index]
    const zipped = zip(date_section, cent_section)
    return {
      data: zipped,
      type: 'scatter',
      z: 1,
      symbolSize: 5,
      itemStyle: {
        color,
        opacity: 1,
      },
    }
  })

  /** Perform the linear regression */
  const fits: Series[] = flatten(
    sections.map((sectionDef) => {
      const [x_points, y_points] = unzip(sectionDef.data)
      // take only the last NUM_POINTS_FOR_PREDICTION points
      const [x, y] =
        x_points.length > NUM_POINTS_FOR_PREDICTION
          ? [
              x_points.slice(x_points.length - NUM_POINTS_FOR_PREDICTION),
              y_points.slice(y_points.length - NUM_POINTS_FOR_PREDICTION),
            ]
          : [x_points, y_points]
      const polyfit = fitAndCI(x, y)

      // get error time based on slope
      let linear_fit_error_in = Infinity
      let linear_fit_error_on: Date
      const m = polyfit.a
      const b = polyfit.b

      let upperPredictionValues: [number, number][] = []
      let lowerPredictionValues: [number, number][] = []
      if (m !== 0) {
        const predictionUpper = (x: number) => m * x + b
        const predictionLower = (x: number) => m * x + b
        const testDate = moment(x[x.length - 1])
        // [0] -> tomorrow

        // start on the first day (i.e. tomorrow)
        for (let i = 1; i <= TIME_PERIOD_DAYS; i++) {
          testDate.add(1, 'days')
          const timestamp = testDate.unix() * 1000
          const predictionHighValue = predictionUpper(timestamp)
          upperPredictionValues.push([timestamp, predictionHighValue])
          const predictionLowValue = predictionLower(timestamp)
          lowerPredictionValues.push([timestamp, predictionLowValue])
          /**
           * there is a trending problem when
           * the low value is greater than the high limit
           * or the high value is less than the low limit
           */
          const problem =
            predictionLowValue > (limits.high ?? Infinity) ||
            predictionHighValue < (limits.low ?? -Infinity)
          if (problem) {
            linear_fit_error_in = i
            linear_fit_error_on = testDate.toDate()
            break
          }
        }
      }

      const timePeriod = TIME_PERIOD_DAYS * 3600 * 24 * 1000
      const trendingProblem =
        sectionDef.data.length > MIN_SECTION_LENGTH &&
        dates_x.length > MIN_TOTAL_LENGTH &&
        linear_fit_error_in < timePeriod
      lastSeriesTrendingProblem = linear_fit_error_in
      function makeFitSeries(
        ydata: PhantomTestData,
        opts: {
          error_in?: number
          error_on?: Date
          hideFromTooltip?: boolean
          lineStyle?: 'solid' | 'dashed'
        } = {},
      ): Series {
        let seriesData = ydata
        if (opts.error_in) {
          seriesData = seriesData.map((s) => [
            s[0],
            s[1],
            s[2],
            opts.error_in,
            opts.error_on,
          ])
        }
        return {
          tooltip: {
            show: !opts.hideFromTooltip,
          },
          data: seriesData,
          name: seriesName,
          yAxisIndex,
          type: 'line',
          itemStyle: {
            color: trendingProblem
              ? opts.error_in === 1
                ? FAIL
                : PROBLEM
              : PASS,
            opacity: 0,
          },
          z: 3,
          lineStyle: {
            color: trendingProblem
              ? opts.error_in === 1
                ? FAIL
                : PROBLEM
              : PASS,
            width: 1.5,
            opacity: 1,
            type: opts.lineStyle || 'solid',
          },
        }
      }

      const series: Series[] = []
      if (showFits) {
        series.push(
          makeFitSeries(zip(x, polyfit.y_fit), {
            error_in: trendingProblem ? linear_fit_error_in : undefined,
            error_on: trendingProblem ? linear_fit_error_on : undefined,
          }),
        )
      }
      if (showPredictionIntervals) {
        series.push(
          ...[
            makeFitSeries(upperPredictionValues, {
              hideFromTooltip: true,
              lineStyle: 'dashed',
            }),
            makeFitSeries(lowerPredictionValues, {
              hideFromTooltip: true,
              lineStyle: 'dashed',
            }),
          ],
        )
      }
      return series
    }),
  )

  /**
   * since coloring by item isn't supported, I'll make three scatterplots:
   * 1. items outside control limits: red
   * 2. items within control limits but outside a section: yellow
   * 3. items within control limits and inside a section: blue
   */
  const itemsOutsideLimits: TrendableData = []
  const outliers: TrendableData = []
  const inliers: TrendableData = []

  data.forEach(([x, y, test], index) => {
    if (isOutsideLimits(y, limits)) {
      itemsOutsideLimits.push([x, y, test])
    } else if (outlierDetector.isOutlier(y, index)) {
      outliers.push([x, y, test])
    } else {
      inliers.push([x, y, test])
    }
  })
  const scatterPlots = [
    {
      data: itemsOutsideLimits,
      name: seriesName,
      yAxisIndex,
      type: 'scatter',
      z: 2,
      symbolSize: 7,
      itemStyle: {
        color,
        borderColor: showLimits ? FAIL : undefined,
        borderWidth: showLimits ? 1.5 : undefined,
        opacity: 1,
      },
    },
    {
      data: outliers,
      name: seriesName,
      yAxisIndex,
      type: 'scatter',
      z: 2,
      symbolSize: 7,
      itemStyle: {
        color,
        borderColor: showOutliers ? OUTLIER : undefined,
        borderWidth: showOutliers ? 1.5 : undefined,
        opacity: 1,
      },
    },
    {
      data: inliers,
      name: seriesName,
      yAxisIndex,
      type: 'scatter',
      z: 2,
      symbolSize: 7,
      itemStyle: {
        color,
        opacity: 1,
      },
    },
  ]
  const series: Series[] = [...scatterPlots]
  if (showFits) {
    series.push(...fits)
  }
  if (showLimits) {
    const markLines: Series = {
      data: [],
      name: seriesName,
      yAxisIndex,
      type: 'scatter',
      symbolSize: 0,
      itemStyle: {
        opacity: 0,
      },
      tooltip: {
        show: false,
      },
      markLine: {
        symbol: ['', ''],
        silent: true,
        animation: false,
        data: limitsToMarkLineData(limits),
        label: {
          formatter: displayTrendKey(selectedTrendKey)
            ? displayTrendKey(selectedTrendKey) + ': {c}'
            : 'Demo Control Limit',
          position: yAxisIndex === 0 ? 'insideStartTop' : 'insideEndTop',
          color: LIMITLINES,
        },
        lineStyle: {
          color: LIMITLINES,
        },
      },
    }
    if (typeof limits.low === 'number') {
      markLines.data.push([data[0][0], limits.low - 1])
    }
    if (typeof limits.high === 'number') {
      markLines.data.push([data[0][0], limits.high + 1])
    }
    series.push(markLines)
  }
  return {
    series,
    lastPointOutlier,
    lastSeriesTrendingProblem,
  }
}

export function generateSeriesForOneScanner(
  scannerDetail: trendsScannerDetails_scanner,
  state: TrendsStateInterface,
  type: TrendDisplayType,
  tz: string,
  color?: string,
): {
  series: Series[]
  leftLastPointOutlier: boolean
  rightLastPointOutlier: boolean
  leftLastSeriesTrendingProblem: number | undefined
  rightLastSeriesTrendingProblem: number | undefined
} {
  const series: Series[] = []
  let leftLastPointOutlier: boolean = false
  let rightLastPointOutlier: boolean = false
  let leftLastSeriesTrendingProblem: number | undefined = undefined
  let rightLastSeriesTrendingProblem: number | undefined = undefined
  if (state.left.selectedTrendKey && !state.left.disabled) {
    let leftData: TrendableData
    let leftLimits: LowHigh
    if (state.left.selectedRoutine) {
      const extractedPhantomTestData = extractPhantomTestDataFromScanner(
        state.left.selectedTrendKey,
        scannerDetail.phantom_tests,
        state.left.selectedRoutine,
        tz,
      )
      leftLimits = findLimitsForPhantomTestData(
        state.left.selectedTrendKey,
        extractedPhantomTestData,
      )
      leftData = extractedPhantomTestData
    } else if (state.left.selectedSurveyTestRoutine) {
      const extractedSurveyTestData = extractSurveyTestDataFromScanner(
        state.left.selectedTrendKey,
        scannerDetail.survey_tests,
        state.left.selectedSurveyTestRoutine,
        tz,
      )
      leftLimits = findLimitsForSurveyTestData(
        state.left.selectedTrendKey,
        extractedSurveyTestData,
        state.left.selectedSurveyTestRoutine,
      )
      leftData = extractedSurveyTestData
    } else {
      throw new Error('one of phantom or survey test routine must be selected')
    }
    const plots = generatePlotsGivenData({
      data: leftData,
      color: color || axisColors.left,
      seriesName:
        type === 'singleScanner'
          ? displayTrendKey(state.left.selectedTrendKey)
          : scannerDetail.name,
      showFits: state.left.showTrendLines,
      showLimits: state.left.showControlLimits,
      showOutliers: state.left.showOutliers,
      limits: leftLimits,
      yAxisIndex: 0,
      selectedTrendKey: state.left.selectedTrendKey,
    })
    series.push(...plots.series)
    leftLastPointOutlier = plots.lastPointOutlier
    leftLastSeriesTrendingProblem = plots.lastSeriesTrendingProblem
  }
  if (state.right.selectedTrendKey && !state.right.disabled) {
    let rightData: TrendableData
    let rightLimits: LowHigh
    if (state.right.selectedRoutine) {
      const extractedPhantomTestData = extractPhantomTestDataFromScanner(
        state.right.selectedTrendKey,
        scannerDetail.phantom_tests,
        state.right.selectedRoutine,
        tz,
      )
      rightLimits = findLimitsForPhantomTestData(
        state.right.selectedTrendKey,
        extractedPhantomTestData,
      )
      rightData = extractedPhantomTestData
    } else if (state.right.selectedSurveyTestRoutine) {
      const extractedSurveyTestData = extractSurveyTestDataFromScanner(
        state.right.selectedTrendKey,
        scannerDetail.survey_tests,
        state.right.selectedSurveyTestRoutine,
        tz,
      )
      rightLimits = findLimitsForSurveyTestData(
        state.right.selectedTrendKey,
        extractedSurveyTestData,
        state.right.selectedSurveyTestRoutine,
      )
      rightData = extractedSurveyTestData
    }
    const plots = generatePlotsGivenData({
      data: rightData,
      color: color || axisColors.right,
      seriesName:
        type === 'singleScanner'
          ? displayTrendKey(state.right.selectedTrendKey)
          : scannerDetail.name,
      showFits: state.right.showTrendLines,
      showLimits: state.right.showControlLimits,
      showOutliers: state.right.showOutliers,
      limits: rightLimits,
      yAxisIndex: 0,
      selectedTrendKey: state.right.selectedTrendKey,
    })
    series.push(...plots.series)
    rightLastPointOutlier = plots.lastPointOutlier
    rightLastSeriesTrendingProblem = plots.lastSeriesTrendingProblem
  }
  return {
    series,
    leftLastPointOutlier,
    rightLastPointOutlier,
    leftLastSeriesTrendingProblem,
    rightLastSeriesTrendingProblem,
  }
}

/**
 * returns true if at least one scanner is in the scannerDetails array
 * and at least one trend key is selected
 */
export function scannersAndTrendKeySelected(
  scannerDetails: trendsScannerDetails_scanner[] | undefined,
  state: TrendsStateInterface,
): boolean {
  if (
    !scannerDetails ||
    !Array.isArray(scannerDetails) ||
    scannerDetails.length < 1
  ) {
    return false
  }
  if (
    (!state.left.selectedTrendKey ||
      !Array.isArray(state.left.selectedTrendKey) ||
      state.left.selectedTrendKey.length < 1) &&
    (!state.right.selectedTrendKey ||
      !Array.isArray(state.right.selectedTrendKey) ||
      state.right.selectedTrendKey.length < 1)
  ) {
    return false
  }
  return true
}

export function generateDemoSeries(
  csvData: CSVDemoData,
  state: TrendsStateInterface,
) {
  let data = csvData.map(([x, y, ..._]): [number, number] => [x, y])
  const today = Math.max(...data.map((d) => d[0]))
  data = data.map((d) => {
    const days = d[0]
    const toSubtract = today - days
    const timestamp = moment().subtract(toSubtract, 'days').unix() * 1000
    return [timestamp, d[1]]
  })

  return generatePlotsGivenData({
    data,
    color: scannerColors[0],
    seriesName: 'Demo',
    showFits: state.left.showTrendLines,
    showLimits: state.left.showControlLimits,
    showOutliers: state.left.showOutliers,
    limits: { low: -4, high: 4 },
  })
}

export interface ScannerTrendReport {
  key: string[] | undefined
  lastPointOutlier: boolean
  trendingProblem: number | undefined
}

export interface TrendingStatus {
  [scannerId: number]: ScannerTrendReport[]
}

export function generateDataOnly(
  scannerDetails: trendsScannerDetails_scanner[] | undefined,
  state: TrendsStateInterface,
  type: TrendDisplayType,
  tz: string,
) {
  let scannerSeriesResults: {
    [scannerName: string]: {
      leftData?: PhantomTestData
      rightData?: PhantomTestData
    }
  } = {}

  scannerDetails.forEach((scannerDetail) => {
    scannerSeriesResults[scannerDetail.name] = {}
    if (
      state.left.selectedTrendKey &&
      !state.left.disabled &&
      state.left.selectedRoutine
    ) {
      scannerSeriesResults[
        scannerDetail.name
      ].leftData = extractPhantomTestDataFromScanner(
        state.left.selectedTrendKey,
        scannerDetail.phantom_tests,
        state.left.selectedRoutine,
        tz,
      )
    }

    if (
      state.right.selectedTrendKey &&
      !state.right.disabled &&
      state.right.selectedRoutine
    ) {
      scannerSeriesResults[
        scannerDetail.name
      ].rightData = extractPhantomTestDataFromScanner(
        state.right.selectedTrendKey,
        scannerDetail.phantom_tests,
        state.right.selectedRoutine,
        tz,
      )
    }
  })

  return scannerSeriesResults
}

export async function generateChartConfig(
  scannerDetails: trendsScannerDetails_scanner[] | undefined,
  state: TrendsStateInterface,
  type: TrendDisplayType,
  tz: string,
  csvDemoData?: CSVDemoData,
): Promise<{
  config: any
  trendingStatus: TrendingStatus
}> {
  // assemble the PhantomTestData series
  let series: Series[] = []
  const trendingStatus: TrendingStatus = {}
  if (!scannersAndTrendKeySelected(scannerDetails, state) && csvDemoData) {
    // use the demo data if user hasn't selected any scanners
    series.push(...generateDemoSeries(csvDemoData, state).series)
  } else if (scannerDetails) {
    // plot each scanner
    const scannerSeriesResults = scannerDetails.map((scannerDetail, index) => ({
      generatedSeries: generateSeriesForOneScanner(
        scannerDetail,
        state,
        type,
        tz,
        scannerColors[index],
      ),
      scannerDetail,
    }))
    scannerSeriesResults.forEach((result) => {
      const scannerId = result.scannerDetail.id
      trendingStatus[scannerId] = [
        {
          key: state.left.selectedTrendKey,
          lastPointOutlier: result.generatedSeries.leftLastPointOutlier,
          trendingProblem: result.generatedSeries.leftLastSeriesTrendingProblem,
        },
        {
          key: state.right.selectedTrendKey,
          lastPointOutlier: result.generatedSeries.rightLastPointOutlier,
          trendingProblem:
            result.generatedSeries.rightLastSeriesTrendingProblem,
        },
      ]
    })
    series.push(
      ...flatten(scannerSeriesResults.map((s) => s.generatedSeries.series)),
    )
  }
  // Calculate x-axis buffer on edges, demo ignored since different layout
  let xAxisStart: number | null = null
  let xAxisEnd: number | null = null
  if (series[0]?.name !== 'Demo' && series[2]?.data.length > 0) {
    const datapointList = series[2].data
    // datapoint is in format [time, data, object]
    const startDatapoint = datapointList[0]
    const endDatapoint = datapointList[datapointList.length - 1]

    // 10% cushion for x-axis of edges
    console.log(series)
    const timeRange = endDatapoint[0] - startDatapoint[0]
    const timeBuffer = Math.round(timeRange * 0.1)

    xAxisStart = startDatapoint[0] - timeBuffer
    xAxisEnd = endDatapoint[0] + timeBuffer
  }

  let yAxis: any
  let dataZoom: any
  if (
    state.left.selectedTrendKey &&
    state.right.selectedTrendKey &&
    !state.right.sameYAxis
  ) {
    yAxis = [
      {
        minInterval: MIN_INTERVAL,

        splitLine: { show: false },
        scale: true,

        axisLine: {
          show: true,
          lineStyle: {
            color: axisColors.left,
          },
        },
        axisLabel: {
          formatter: numberFormatter,
          textStyle: {
            color: axisColors.left,
          },
        },
        axisTick: {
          show: true,
          lineStyle: {
            color: axisColors.left,
          },
        },
      },
      {
        minInterval: MIN_INTERVAL,

        splitLine: { show: false },
        scale: true,

        axisLine: {
          show: true,
          lineStyle: {
            color: axisColors.right,
          },
        },
        axisLabel: {
          formatter: numberFormatter,
          textStyle: {
            color: axisColors.right,
          },
        },
        axisTick: {
          show: true,
          lineStyle: {
            color: axisColors.right,
          },
        },
      },
    ]
    dataZoom = [
      {
        type: 'inside',
      },
      {
        type: 'slider',
        yAxisIndex: 0,
        left: 0,
        labelFormatter: numberFormatter,
        minSpan: MIN_INTERVAL,
        dataBackground: {
          lineStyle: { opacity: 0 },
          areaStyle: { opacity: 0 },
        },
        selectedDataBackground: {
          lineStyle: { opacity: 0 },
          areaStyle: { opacity: 0 },
        },
      },
      {
        type: 'slider',
        yAxisIndex: 1,
        labelFormatter: numberFormatter,
        minSpan: MIN_INTERVAL,
        dataBackground: {
          lineStyle: { opacity: 0 },
          areaStyle: { opacity: 0 },
        },
        selectedDataBackground: {
          lineStyle: { opacity: 0 },
          areaStyle: { opacity: 0 },
        },
      },
      {
        type: 'slider',
        xAxisIndex: 0,
        labelFormatter: numberFormatter,
        minSpan: MIN_INTERVAL,
        dataBackground: {
          lineStyle: { opacity: 0 },
          areaStyle: { opacity: 0 },
        },
        selectedDataBackground: {
          lineStyle: { opacity: 0 },
          areaStyle: { opacity: 0 },
        },
      },
    ]
  } else {
    yAxis = {
      minInterval: MIN_INTERVAL,
      axisLabel: {
        formatter: numberFormatter,
      },
      axisLine: { show: true },
      splitLine: { show: false },
      scale: true,
    }
    dataZoom = [
      {
        type: 'inside',
        xAxisIndex: 0,
      },
      {
        type: 'slider',
        yAxisIndex: 0,
        left: 0,
        dataBackground: {
          lineStyle: { opacity: 0 },
          areaStyle: { opacity: 0 },
        },
        selectedDataBackground: {
          lineStyle: { opacity: 0 },
          areaStyle: { opacity: 0 },
        },
      },
      {
        type: 'slider',
        xAxisIndex: 0,
        dataBackground: {
          lineStyle: { opacity: 0 },
          areaStyle: { opacity: 0 },
        },
        selectedDataBackground: {
          lineStyle: { opacity: 0 },
          areaStyle: { opacity: 0 },
        },
      },
    ]
  }
  // assemble the chart config
  const config: any = {
    title: {
      left: 'center',
      text: getTitleText(state, type, tz),
    },
    tooltip: {
      trigger: 'item',
      backgroundColor: 'hsl(0, 0%, 10%)' /* var(--aqs-bg-color) */,
      textStyle: {
        color: 'white',
      },
      formatter: (params: TooltipParams[]) => tooltipFormatter(params, tz),
    },
    toolbox: {
      show: true,
      right: 40,
      feature: {
        saveAsImage: {
          title: 'Download snapshot',
          backgroundColor: 'hsl(0, 0%, 10%)' /* var(--aqs-bg-color) */,
        },
        saveAsTable: {
          title: 'Download data',
          backgroundColor: 'hsl(0, 0%, 10%)' /* var(--aqs-bg-color) */,
        },
      },
    },
    xAxis: {
      type: 'time',
      min: xAxisStart,
      max: xAxisEnd,
      minInterval: 3600 * 24 * 1000,
      scale: true,
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.5,
          type: 'dotted',
        },
      },
    },
    yAxis,
    dataZoom,
    series,
  }
  if (scannerDetails && scannerDetails?.length > 0) {
    if (type === 'singleScanner') {
      config.legend = {
        data: [
          displayTrendKey(state.left.selectedTrendKey || []),
          displayTrendKey(state.right.selectedTrendKey || []),
        ],
        orient: 'horizontal',
        type: 'scroll',
        top: 30,
      }
    } else if (type === 'multipleScanners') {
      config.legend = {
        data: scannerDetails.map((s) => s.name),
        orient: 'horizontal',
        type: 'scroll',
        top: 30,
      }
    }
  }
  return { config, trendingStatus }
}

/**
 * Polyfit
 * @class
 */

interface NumberArrayConstructor {
  new (length: number): NumberArray
  new (buffer: ArrayBuffer, byteOffset?: number, length?: number): NumberArray

  BYTES_PER_ELEMENT?: number
}

interface NumberArray {
  length: number
  [index: number]: number
}

export class Polyfit {
  private x: NumberArray
  private y: NumberArray
  private FloatXArray: Float32ArrayConstructor | Float64ArrayConstructor

  /**
   * Polyfit
   * @constructor
   * @param {number[]|Float32Array|Float64Array} x
   * @param {number[]|Float32Array|Float64Array} y
   */
  constructor(x: NumberArray, y: NumberArray) {
    // Make sure we return an instance
    if (!(this instanceof Polyfit)) {
      return new Polyfit(x, y)
    }

    // Check that x any y are both arrays of the same type
    if (
      !(
        (x instanceof Array && y instanceof Array) ||
        (x instanceof Float32Array && y instanceof Float32Array) ||
        (x instanceof Float64Array && y instanceof Float64Array)
      )
    ) {
      throw new Error('x and y must be arrays')
    }

    if (x instanceof Float32Array) {
      this.FloatXArray = Float32Array
    } else if (x instanceof Float64Array) {
      this.FloatXArray = Float64Array
    }

    // Make sure we have equal lengths
    if (x.length !== y.length) {
      throw new Error('x and y must have the same length')
    }

    this.x = x
    this.y = y
  }

  /**
   * Perform gauss-jordan division
   *
   * @param {number[][]|Float32Array[]|Float64Array[]} matrix - gets modified
   * @param {number} row
   * @param {number} col
   * @param {number} numCols
   * @returns void
   */
  static gaussJordanDivide(
    matrix: NumberArray[],
    row: number,
    col: number,
    numCols: number,
  ): void {
    for (let i = col + 1; i < numCols; i++) {
      matrix[row][i] /= matrix[row][col]
    }

    matrix[row][col] = 1
  }

  /**
   * Perform gauss-jordan elimination
   *
   * @param {number[][]|Float64Array[]} matrix - gets modified
   * @param {number} row
   * @param {number} col
   * @param {number} numRows
   * @param {number} numCols
   * @returns void
   */
  static gaussJordanEliminate(
    matrix: NumberArray[],
    row: number,
    col: number,
    numRows: number,
    numCols: number,
  ): void {
    for (let i = 0; i < numRows; i++) {
      if (i !== row && matrix[i][col] !== 0) {
        for (let j = col + 1; j < numCols; j++) {
          matrix[i][j] -= matrix[i][col] * matrix[row][j]
        }
        matrix[i][col] = 0
      }
    }
  }

  /**
   * Perform gauss-jordan echelon method
   *
   * @param {number[][]|Float32Array[]|Float64Array[]} matrix - gets modified
   * @returns {number[][]|Float32Array[]|Float64Array[]} matrix
   */
  static gaussJordanEchelonize(matrix: NumberArray[]): NumberArray[] {
    const rows = matrix.length
    const cols = matrix[0].length
    let i = 0
    let j = 0
    let k: number
    let swap: NumberArray

    while (i < rows && j < cols) {
      k = i
      // Look for non-zero entries in col j at or below row i
      while (k < rows && matrix[k][j] === 0) {
        k++
      }
      // If an entry is found at row k
      if (k < rows) {
        // If k is not i, then swap row i with row k
        if (k !== i) {
          swap = matrix[i]
          matrix[i] = matrix[k]
          matrix[k] = swap
        }
        // If matrix[i][j] is != 1, divide row i by matrix[i][j]
        if (matrix[i][j] !== 1) {
          Polyfit.gaussJordanDivide(matrix, i, j, cols)
        }
        // Eliminate all other non-zero entries
        Polyfit.gaussJordanEliminate(matrix, i, j, rows, cols)
        i++
      }
      j++
    }

    return matrix
  }

  /**
   * Perform regression
   *
   * @param {number} x
   * @param {number[]|Float32Array[]|Float64Array[]} terms
   * @returns {number}
   */
  static regress(x: number, terms: NumberArray): number {
    let a = 0
    let exp = 0

    for (let i = 0, len = terms.length; i < len; i++) {
      a += terms[i] * Math.pow(x, exp++)
    }

    return a
  }

  /**
   * Compute correlation coefficient
   *
   * @param {number[]|Float32Array[]|Float64Array[]} terms
   * @returns {number}
   */
  public correlationCoefficient(terms: NumberArray): number {
    let r = 0
    const n = this.x.length
    let sx = 0
    let sx2 = 0
    let sy = 0
    let sy2 = 0
    let sxy = 0
    let x: number
    let y: number

    for (let i = 0; i < n; i++) {
      x = Polyfit.regress(this.x[i], terms)
      y = this.y[i]
      sx += x
      sy += y
      sxy += x * y
      sx2 += x * x
      sy2 += y * y
    }

    let div = Math.sqrt((sx2 - (sx * sx) / n) * (sy2 - (sy * sy) / n))

    if (div !== 0) {
      r = Math.pow((sxy - (sx * sy) / n) / div, 2)
    }

    return r
  }

  /**
   * Run standard error function
   *
   * @param {number[]|Float32Array[]|Float64Array[]} terms
   * @returns number
   */
  public standardError(terms: NumberArray): number {
    let r = 0
    const n = this.x.length

    if (n > 2) {
      let a = 0
      for (let i = 0; i < n; i++) {
        a += Math.pow(Polyfit.regress(this.x[i], terms) - this.y[i], 2)
      }
      r = Math.sqrt(a / (n - 2))
    }

    return r
  }

  /**
   * Compute coefficients for given data matrix
   *
   * @param {number} p
   * @returns {number[]|Float32Array|Float64Array}
   */
  public computeCoefficients(p: number): NumberArray {
    const n = this.x.length
    let r: number
    let c: number
    const rs = 2 * ++p - 1
    let i: number

    let m: NumberArray[] = []

    // Initialize array with 0 values
    if (this.FloatXArray) {
      // fast FloatXArray-Matrix init
      const bytesPerRow = (p + 1) * this.FloatXArray.BYTES_PER_ELEMENT
      let buffer = new ArrayBuffer(p * bytesPerRow)
      for (i = 0; i < p; i++) {
        m[i] = new this.FloatXArray(buffer, i * bytesPerRow, p + 1)
      }
    } else {
      let zeroRow: number[] = []
      for (i = 0; i <= p; i++) {
        zeroRow[i] = 0
      }
      m[0] = zeroRow
      for (i = 1; i < p; i++) {
        // copy zeroRow
        m[i] = [...zeroRow]
      }
    }

    let mpc = [n]

    for (i = 1; i < rs; i++) {
      mpc[i] = 0
    }

    for (i = 0; i < n; i++) {
      let x = this.x[i]
      let y = this.y[i]

      // Process precalculation array
      for (r = 1; r < rs; r++) {
        mpc[r] += Math.pow(x, r)
      }
      // Process RH column cells
      m[0][p] += y
      for (r = 1; r < p; r++) {
        m[r][p] += Math.pow(x, r) * y
      }
    }

    // Populate square matrix section
    for (r = 0; r < p; r++) {
      for (c = 0; c < p; c++) {
        m[r][c] = mpc[r + c]
      }
    }

    Polyfit.gaussJordanEchelonize(m)

    let terms =
      (this.FloatXArray && new this.FloatXArray(m.length)) || <NumberArray>[]

    for (i = m.length - 1; i >= 0; i--) {
      terms[i] = m[i][p]
    }

    return terms
  }

  /**
   * Using given degree of fitment, return a function that will calculate
   * the y for a given x
   *
   * @param {number} degree  > 0
   * @returns {Function}     f(x) =
   */
  public getPolynomial(degree: number): (x: number) => number {
    if (isNaN(degree) || degree < 0) {
      throw new Error('Degree must be a positive integer')
    }

    let terms = this.computeCoefficients(degree)
    let eqParts: string[] = []

    eqParts.push(terms[0].toPrecision())

    for (let i = 1, len = terms.length; i < len; i++) {
      eqParts.push(`${terms[i]} * Math.pow(x, ${i})`)
    }

    let expr = `return ${eqParts.join(' + ')};`

    /* jshint evil: true */
    return <(x: number) => number>new Function('x', expr)
    /* jshint evil: false */
  }

  /**
   * Convert the polynomial to a string expression, mostly useful for visual
   * debugging
   *
   * @param {number} degree
   * @returns {string}
   */
  public toExpression(degree: number): string {
    if (isNaN(degree) || degree < 0) {
      throw new Error('Degree must be a positive integer')
    }

    const terms = this.computeCoefficients(degree)
    let eqParts: string[] = []
    const len = terms.length

    eqParts.push(terms[0].toPrecision())

    for (let i = 1; i < len; i++) {
      eqParts.push(`${terms[i]}x^${i}`)
    }

    return eqParts.join(' + ')
  }
}
