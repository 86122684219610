import { gql } from 'apollo-angular'

export default gql`
  query scannersDetails($id: Int!) {
    survey_test_routine {
      id
      name
      survey_test_routine_questions_survey_test_questions {
        survey_test_question {
          id
          text
          section
        }
      }
    }
    scanner_by_pk(id: $id) {
      phantom_tests(limit: 1, order_by: { study: { scanDate: desc } }) {
        study {
          scanDate
        }
      }
      id
      facility {
        name
      }
      modality {
        id
      }
      facility_room {
        name
      }
      name
      scanner_manufacturer {
        name
      }
      scanner_model {
        name
      }
      institutionNameTag
      stationNameTag
      facilityId
      modalityId
      facilityRoomId
      scannerManufacturerId
      scannerModelId
      schedule
      scheduleGrace
      extra
      supportContacts
    }
  }
`
