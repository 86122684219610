<ng-container *ngIf="documents?.length > 0">
  <p>Test Documents</p>
  <div class="test-document-container">
    <app-document-viewer
      *ngFor="let doc of documents"
      [src]="src(doc.location)"
      viewer="microsoft"
    ></app-document-viewer>
  </div>
</ng-container>

<ng-container *ngIf="testImages?.length > 0">
  <p>Test Images</p>
  <app-image-viewer [images]="testImages"></app-image-viewer>
</ng-container>

<ng-container *ngIf="result.series">
  <ng-container *ngFor="let series of result.series; let i = index">
    <p>Series {{ i + 1 }}</p>
    <app-image-viewer [images]="seriesImages[i]"></app-image-viewer>
  </ng-container>
</ng-container>
