import { Component, OnInit } from '@angular/core'
import { Apollo } from 'apollo-angular'
import moment from 'moment-timezone'
import { IconService } from 'src/app/icon.service'
import { PageHeaderAction } from '../page-header/page-header.component'
import { defaultSchedule } from '../rrule-generator/rrule-generator.component'
import schedulesGql from './schedules.gql'
import schedules_relatedGql from './schedules_related.gql'
import { schedules, schedules_schedule } from './types/schedules'
import { schedules_related } from './types/schedules_related'

export interface Schedule extends schedules_schedule {
  scannersFriendly: ScannerFriendly[]
  tasksFriendly: TaskFriendly[]
  new?: boolean
}

export interface ScannerFriendly {
  name: string
  id: number
}
export interface TaskFriendly {
  name: string
  id: number
  __typename: string
}

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss'],
})
export class SchedulesComponent implements OnInit {
  title = 'Tasks Schedules'

  actions: PageHeaderAction[] = [{ text: 'Add Schedule' }]
  actionsHandler($event: string) {
    switch ($event) {
      case 'Add Schedule':
        this.addSchedule()
        break
      default:
        break
    }
  }

  onDelete($event: number) {
    console.log($event)
    this.schedules = this.schedules.filter((s) => s.id !== $event)
  }

  messages = {
    outstanding: 'This task has not been completed',
    unavailable: 'It is not yet time to do this task',
    completePast: 'This task was completed on time',
    complete: 'This task has been completed',
    missed: 'This task was missed',
  }

  schedules: Schedule[] = []

  allScanners: ScannerFriendly[]
  allTasks: TaskFriendly[]

  constructor(private icon: IconService, private apollo: Apollo) {}

  ngOnInit(): void {
    this.fetchSchedules()
    this.fetchRelated()
  }

  fetchSchedules() {
    this.apollo
      .query<schedules>({ query: schedulesGql })
      .subscribe({
        next: (result) => {
          if (result.error) {
            throw result.error
          }
          if (result.errors) {
            throw result.errors
          }
          if (result.data) {
            this.schedules = result.data.schedule.map((s) => ({
              ...s,
              scannersFriendly: s.schedule_scanners_scanners.map(
                (sc) => sc.scanner,
              ),
              tasksFriendly: [
                ...s.schedule_phantom_test_routines_phantom_test_routines.map(
                  (ptr) => ptr.phantom_test_routine,
                ),
                ...s.schedule_survey_test_routines_survey_test_routines.map(
                  (str) => str.survey_test_routine,
                ),
              ],
            }))
          }
        },
      })
  }

  fetchRelated() {
    this.apollo
      .query<schedules_related>({ query: schedules_relatedGql })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors
          }
          if (result.error) {
            throw result.error
          }
          this.allScanners = result.data.scanner

          // Adds prefix "Test: " and "Survey: " to the name before pushing to allTasks. ticket #201
          this.allTasks = [
            ...result.data.phantom_test_routine.map((ptr) => ({
              ...ptr,
              name: 'Test: ' + ptr.name,
            })),
            ...result.data.survey_test_routine.map((str) => ({
              ...str,
              name: 'Survey: ' + str.name,
            })),
          ]
        },
      })
  }

  addSchedule() {
    const newScheduleTemplate: Partial<Schedule> & {
      new: boolean
    } = {
      new: true,
      graceAmount: null,
      graceType: null,
      interval: defaultSchedule,
      priorToAmount: null,
      priorToType: null,
      reminderAmount: null,
      reminderType: null,
      tasks: [],
      title: '',
      schedule_phantom_test_routines_phantom_test_routines: [],
      schedule_scanners_scanners: [],
      schedule_survey_test_routines_survey_test_routines: [],
    }
    this.schedules = [].concat(newScheduleTemplate, this.schedules)
  }
}
