import { Component, OnInit, Input } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { DevError } from 'src/app/core/errors/errors'

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit {
  @Input() src: string
  @Input() viewer: 'google' | 'microsoft' | 'native' = 'native'
  safeurl: SafeResourceUrl

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    let src: string
    switch (this.viewer) {
      case 'google':
        src = `https://docs.google.com/gview?url=${this.src}&embedded=true`
        break
      case 'microsoft':
        src = `https://view.officeapps.live.com/op/embed.aspx?src=${this.src}`
        break
      case 'native':
        src = this.src
        break
      default:
        throw new DevError('invalid viewer')
    }
    this.safeurl = this.sanitizer.bypassSecurityTrustResourceUrl(src)
  }
}
