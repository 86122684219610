import { gql } from 'apollo-angular'

export default gql`
  mutation addRoom($facilityId: Int!, $roomName: String!) {
    insert_facility_room(
      objects: { facilityId: $facilityId, name: $roomName }
    ) {
      returning {
        facility {
          facility_rooms {
            name
            id
          }
        }
      }
    }
  }
`
