import { Component, OnInit } from '@angular/core'
import { CommService } from 'src/app/core/comm.service'

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  constructor(public comm: CommService) {}

  ngOnInit() {}
}
