import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { GALLERY_IMAGE } from 'ngx-image-gallery'
import { MessageError } from 'src/app/core/errors/errors'
import { FileService } from 'src/app/core/file.service'
import { NavService } from 'src/app/core/nav.service'
import { safeGet } from '../../util/util'
import { PageHeaderAction } from '../page-header/page-header.component'
import deleteMutation from './delete-studies-details.gql'
import downloadImagesGql from './downloadImages.gql'
import query from './studies-details.gql'
import { delete_study_by_pk } from './types/delete_study_by_pk'
import { downloadImgaesQuery } from './types/downloadImgaesQuery'
import {
  studiesDetails,
  studiesDetails_study_by_pk_scanner,
  studiesDetails_study_by_pk_study_images,
} from './types/studiesDetails'
import { UserRole } from 'src/types/graphql-global-types'
import { AuthService } from 'src/app/auth/auth.service'
import { FeaturesService } from 'src/app/features.service'

@Component({
  selector: 'app-studies-details',
  templateUrl: './studies-details.component.html',
  styleUrls: ['./studies-details.component.scss'],
  providers: [FeaturesService],
})
export class StudiesDetailsComponent implements OnInit {
  title = 'study details'

  actions: PageHeaderAction[] = [
    {
      text: 'Delete Study',
      class: 'danger',
      authorized: [UserRole.admin, UserRole.support],
    },
    {
      text: 'Download Images',
      class: 'success',
    },
    { text: 'Edit Study', authorized: [UserRole.admin, UserRole.support] },
  ]

  actionsHandler($event: string) {
    switch ($event) {
      case 'Delete Study':
        this.delete()
        break
      case 'Download Images':
        this.downloadImages()
        break
      case 'Edit Study':
        this.editView()
        break
      default:
        break
    }
  }

  @Input() studyId?: number

  @Output() updateEvent = new EventEmitter()

  queryResult: ApolloQueryResult<studiesDetails>

  safeGet = safeGet

  images: GALLERY_IMAGE[]

  loading: boolean

  editing: boolean

  constructor(
    private apollo: Apollo,
    private fileService: FileService,
    private route: ActivatedRoute,
    private nav: NavService,
    private auth: AuthService,
    public featuresService: FeaturesService,
  ) {}

  reset() {
    this.loading = false
    this.editing = false
  }

  src(image: studiesDetails_study_by_pk_study_images): string {
    return this.fileService.getHostedUrl(image.storedFileByRenderedfileid.key)
  }

  ngOnInit() {
    if (!this.studyId) {
      const { id } = this.route.snapshot.params
      if (typeof id === 'string') {
        this.studyId = parseInt(id, 10)
      } else {
        throw new Error(`Study Not Found`)
      }
    }
    this.reset()
    this.fetchstudiesDetails()
  }

  fetchstudiesDetails = () => {
    this.loading = true
    this.apollo
      .query<studiesDetails>({ query, variables: { id: this.studyId } })
      .subscribe({ next: this.onNext, error: this.onError })
  }

  onNext = (result: ApolloQueryResult<studiesDetails>) => {
    this.loading = false
    this.queryResult = result
    if (result.errors) {
      throw result.errors[0]
    }
    if (!this.study) {
      throw new MessageError('study not found')
    }
    this.images = result.data.study_by_pk.study_images.map(
      (i): GALLERY_IMAGE => ({
        url: this.src(i),
        title: i.label,
      }),
    )
  }

  onError = (err: unknown) => {
    this.loading = false
    this.reset()
    throw err
  }

  get study() {
    return this.queryResult?.data?.study_by_pk
  }

  delete() {
    if (
      window.confirm(
        'Delete Study: are you sure? This will delete all tests performed on this study.',
      )
    ) {
      this.apollo
        .mutate<delete_study_by_pk>({
          mutation: deleteMutation,
          variables: { id: this.studyId },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.updateEvent.emit('delete')
            this.nav.navtree.studies()
          },
        })
    }
  }

  editView() {
    this.editing = true
  }

  onSave($event) {
    this.editing = false
    this.updateEvent.emit($event)
    this.fetchstudiesDetails()
  }

  get scannerName() {
    return this.queryResult.data.study_by_pk.scanner.name
  }

  get studyDate() {
    return this.queryResult.data.study_by_pk.scanDate
  }

  get extra() {
    return this.queryResult.data.study_by_pk.extra
  }

  get scanner(): studiesDetails_study_by_pk_scanner {
    return this.study.scanner
  }

  get facility() {
    return this.scanner.facility
  }

  get room() {
    return this.scanner.facility_room
  }

  get facilityName() {
    return this.facility?.name
  }

  get dateReceived() {
    return this.queryResult.data.study_by_pk.dateCreated
  }

  get roomName() {
    return this.room.name
  }

  get modality() {
    return this.queryResult.data.study_by_pk.modalityId
  }

  get phantomTests() {
    return this.queryResult.data.study_by_pk.phantom_tests
  }

  downloadImages() {
    this.apollo
      .query<downloadImgaesQuery>({
        query: downloadImagesGql,
        variables: { studyId: this.study.id, userId: this.auth.user.id },
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.fileService.download(result.data.downloadStudyImages)
        },
      })
  }

  viewFacility() {
    this.nav.navtree.facilityDetails(this.facility?.id)
  }

  viewScanner() {
    this.nav.navtree.scannerDetails(this.scanner.id)
  }
}
