import { gql } from 'apollo-angular'

export default gql`
  query facility_get_related_entities {
    user(order_by: { firstName: asc }) {
      id
      firstName
      lastName
    }
  }
`
