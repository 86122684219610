import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
@Component({
  selector: 'app-duration-picker',
  templateUrl: './duration-picker.component.html',
  styleUrls: ['./duration-picker.component.scss'],
})
export class DurationPickerComponent implements OnInit {
  @Input() amount: number = 1
  @Output() amountChange = new EventEmitter<number>()
  onAmountChange() {
    this.amountChange.emit(this.amount)
  }
  @Input() type: string = 'days'
  @Output() typeChange = new EventEmitter<string>()
  onTypeChange() {
    this.typeChange.emit(this.type)
  }

  constructor() {}

  ngOnInit(): void {}
}
