<ng-container *ngIf="evaluatedNumbers && evaluatedNumbers.length > 0">
  <h3>Test Summary</h3>
  <!-- evaluated values -->
  <table class="table">
    <thead>
      <th class="title left">Measurement</th>
      <th class="title left" colspan="2">Min</th>
      <th class="title left">Actual</th>
      <th class="title left" colspan="2">Max</th>
      <th class="title left">Result</th>
    </thead>
    <tbody>
      <tr *ngFor="let num of evaluatedNumbers">
        <td class="left">{{ num.title | humanReadable }}</td>
        <td
          class="left"
          appCustomLimit
          [test]="test"
          [currentLimit]="num.low"
          limitKey="{{ num.limitKey }}.low"
          [attr.colspan]="num.problemLow === undefined ? 2 : 1"
        ></td>
        <td
          class="left"
          appCustomLimit
          [test]="test"
          [currentLimit]="num.problemLow"
          limitKey="{{ num.limitKey }}.problemLow"
          *ngIf="num.problemLow !== undefined"
        ></td>
        <td class="{{ num.result }} left">
          {{ num.actual | number: '1.0-1' }}
        </td>
        <td
          appCustomLimit
          [test]="test"
          [currentLimit]="num.problemHigh"
          limitKey="{{ num.limitKey }}.problemHigh"
          *ngIf="num.problemHigh !== undefined"
        ></td>
        <td
          class="left"
          appCustomLimit
          [test]="test"
          [currentLimit]="num.high"
          limitKey="{{ num.limitKey }}.high"
          [attr.colspan]="num.problemHigh === undefined ? 2 : 1"
        ></td>
        <td class="{{ num.result }} left">{{ num.result }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>
