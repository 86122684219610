import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'

import { cloneDeep } from 'lodash'
import { scanner_insert_input } from 'src/types/graphql-global-types'
import insertMutation from './workstations-insert.gql'
import { createNewScanners } from '../../../../../server/src/util/types/createNewScanners'

@Component({
  selector: 'app-workstations-create',
  templateUrl: './workstations-create.component.html',
  styleUrls: ['./workstations-create.component.scss'],
})
export class WorkstationsCreateComponent implements OnInit {
  newWorkstation: scanner_insert_input = {}

  @Output() saveEvent = new EventEmitter()

  objectKeys = Object.keys

  ngOnInit() {
    this.newWorkstation = {
      modalityId: 'WS',
      deviceType: 'workstation',
      extra: {},
    }
  }

  constructor(private apollo: Apollo) {}

  save() {
    //console.log(this.newWorkstation)
    this.apollo
      .mutate<createNewScanners>({
        mutation: insertMutation,
        variables: { workstation: this.newWorkstation },
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.newWorkstation = {}
          this.saveEvent.emit('create')
        },
      })
  }

  back() {
    this.newWorkstation = {}
    console.log('back')
    this.saveEvent.emit('back')
  }

  getModel() {
    return this.newWorkstation
  }

  setModel(key: string, val: string) {
    this.getModel()[key] = val
  }
}
