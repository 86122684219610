<div
  appSbox
  title="Technologist Testing Schedule"
  [(enable)]="enableSchedule"
  (save)="save()"
  [multiblock]="true"
>
  <div class="card-block">
    <app-rrule-generator
      [schedule]="this.scanner?.schedule"
      #RRuleGenerator
    ></app-rrule-generator>
  </div>
  <div class="card-block">
    <div class="clr-row">
      <div class="clr-col-sm-3">grace period</div>
      <div class="clr-col-sm-2">
        <clr-input-container>
          <input
            clrInput
            type="number"
            name="name"
            min="0"
            step="1"
            [(ngModel)]="unsavedGrace"
          />
        </clr-input-container>
      </div>
      <div class="clr-col-sm-1">day(s)</div>
    </div>
  </div>
</div>
