import { gql } from 'apollo-angular'

export default gql`
  query microsoftLogin(
    $accountIdentifier: String!
    $homeAccountIdentifier: String!
    $userName: String!
    $name: String!
    $environment: String!
  ) {
    microsoftLogin(
      data: {
        accountIdentifier: $accountIdentifier
        homeAccountIdentifier: $homeAccountIdentifier
        userName: $userName
        name: $name
        environment: $environment
      }
    ) {
      token
      permissions
      user {
        accountType
        username
        id
        role
        firstName
        lastName
        email
        facility {
          id
          name
        }
      }
    }
  }
`
