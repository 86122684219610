<ng-container *ngIf="true">
  <div id="side-card" class="card" [ngClass]="{ disabled: disabled }">
    <div class="card-header">
      {{ title }}
      <clr-icon
        class="expand-toggle"
        [ngStyle]="{ display: contentExpanded ? 'none' : 'initial' }"
        [ngClass]="{ disabled: disabled }"
        (click)="expand(true)"
        shape="caret right"
      ></clr-icon>
      <clr-icon
        [ngStyle]="{ display: !contentExpanded ? 'none' : 'initial' }"
        class="expand-toggle"
        (click)="expand(false)"
        shape="caret down"
      ></clr-icon>
    </div>
    <ng-container *ngIf="!disabled && contentExpanded">
      <div class="card-block">
        <div class="clr-row">
          <div class="clr-col-xl-6 body-item">Test</div>
          <div class="clr-col-xl-6 body-item">
            <ng-container
              [ngTemplateOutlet]="multiSectionSelect"
            ></ng-container>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-xl-6 body-item">Parameter</div>
          <div class="clr-col-xl-6 body-item">
            <ng-container [ngTemplateOutlet]="changeData"></ng-container>
          </div>
        </div>
      </div>
      <div class="card-block">
        <div class="clr-row">
          <div class="clr-col-6 body-item">
            Show trend lines
            <a (click)="modalOpen = true"><clr-icon shape="info"></clr-icon></a>
          </div>
          <div class="clr-col-6 body-item">
            <clr-checkbox-wrapper>
              <input
                [disabled]="disabled"
                type="checkbox"
                clrCheckbox
                name="showTrendLines"
                [(ngModel)]="showTrendLines"
                (ngModelChange)="emitValueChange()"
              />
            </clr-checkbox-wrapper>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-6 body-item">Show control limits</div>
          <div class="clr-col-6 body-item">
            <clr-checkbox-wrapper>
              <input
                [disabled]="disabled"
                type="checkbox"
                clrCheckbox
                name="showControlLimits"
                [(ngModel)]="showControlLimits"
                (ngModelChange)="emitValueChange()"
              />
            </clr-checkbox-wrapper>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-6 body-item">Highlight outliers</div>
          <div class="clr-col-6 body-item">
            <clr-checkbox-wrapper>
              <input
                [disabled]="disabled"
                type="checkbox"
                clrCheckbox
                name="showOutliers"
                [(ngModel)]="showOutliers"
                (ngModelChange)="emitValueChange()"
              />
            </clr-checkbox-wrapper>
          </div>
        </div>
        <div class="clr-row" *ngIf="showYAxis">
          <div class="clr-col-6 body-item">Same Y-axis</div>
          <div class="clr-col-6 body-item">
            <clr-checkbox-wrapper>
              <input
                [disabled]="disabled"
                type="checkbox"
                clrCheckbox
                name="sameYAxis"
                [(ngModel)]="sameYAxis"
                (ngModelChange)="emitValueChange()"
              />
            </clr-checkbox-wrapper>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #unavailableMessage>
  <div class="card-block">
    <div class="clr-row">
      <div class="clr-col-12 body-item">
        Unavailable when plotting multiple scanners
      </div>
    </div>
  </div>
</ng-template>

<ng-template #multiSectionSelect>
  <clr-dropdown>
    <div class="select-like" clrDropdownTrigger>
      {{
        selectedPhantomTestRoutine?.name || selectedSurveyTestRoutine?.name
      }}&nbsp;
      <clr-icon
        *ngIf="!selectedPhantomTestRoutine && !selectedSurveyTestRoutine"
        shape="caret down"
      ></clr-icon>
    </div>

    <clr-dropdown-menu *ngIf="!disabled" clrPosition="bottom-right">
      <label class="dropdown-header" aria-hidden="true">Image Tests</label>
      <ng-container *ngFor="let phantomTestRoutine of phantomTestRoutines">
        <div
          (click)="
            selectedPhantomTestRoutine = phantomTestRoutine;
            handlePhantomTestRoutineChange()
          "
          clrDropdownItem
        >
          {{ phantomTestRoutine.name }}
        </div>
      </ng-container>
      <label class="dropdown-header" aria-hidden="true">Forms</label>
      <ng-container *ngFor="let surveyTestRoutine of surveyTestRoutines">
        <div
          (click)="
            selectedSurveyTestRoutine = surveyTestRoutine;
            handleSurveyTestRoutineChange()
          "
          clrDropdownItem
        >
          {{ surveyTestRoutine.name }}
        </div>
      </ng-container>
    </clr-dropdown-menu>
  </clr-dropdown>
</ng-template>

<ng-template #changeData>
  <clr-dropdown
    [ngStyle]="{
      visibility:
        selectedPhantomTestRoutine || selectedSurveyTestRoutine
          ? 'visible'
          : 'hidden'
    }"
  >
    <div class="select-like" clrDropdownTrigger>
      {{ last(selectedTrendKey) | humanReadable }}&nbsp;
      <clr-icon *ngIf="!last(selectedTrendKey)" shape="caret down"></clr-icon>
    </div>

    <clr-dropdown-menu *ngIf="!disabled" clrPosition="bottom-{{ position }}">
      <label class="dropdown-header" aria-hidden="true"
        >Select a Parameter</label
      >

      <ng-container
        [ngTemplateOutlet]="recursiveDropdown"
        [ngTemplateOutletContext]="{ options: genericValues, prefix: [] }"
      ></ng-container>

      <ng-template #recursiveDropdown let-options="options" let-prefix="prefix">
        <ng-container *ngFor="let option of options">
          <!-- Base Case -->
          <div
            *ngIf="!option.options"
            (click)="
              selectedTrendKey = concat(prefix, option.key); setShowTrend()
            "
            clrDropdownItem
          >
            {{ option.key | humanReadable }}
          </div>
          <!-- Recursive Case -->
          <ng-container *ngIf="option.options">
            <clr-dropdown>
              <button clrDropdownTrigger>
                {{ option.key | humanReadable }}
              </button>
              <!-- This is a little magic to account for clarity's dropdown not zig-zagging like it says it should -->
              <clr-dropdown-menu
                clrPosition="{{
                  !(prefix.length % 2) || position === 'left'
                    ? flipPosition
                    : position
                }}-top"
              >
                <ng-container
                  [ngTemplateOutlet]="recursiveDropdown"
                  [ngTemplateOutletContext]="{
                    options: option.options,
                    prefix: concat(prefix, option.key)
                  }"
                ></ng-container>
              </clr-dropdown-menu>
            </clr-dropdown>
          </ng-container>
        </ng-container>
      </ng-template>
    </clr-dropdown-menu>
  </clr-dropdown>
</ng-template>

<clr-modal [(clrModalOpen)]="modalOpen">
  <h3 class="modal-title">Predictive Failure Analysis</h3>
  <div class="modal-body">
    <p>
      Trend lines are calculated by OLS simple linear regression. A 95%
      prediction interval (not shown on the graph) for the next
      {{ TIME_PERIOD_DAYS }} days is constructed. A trending problem is declared
      when the 95% prediction interval falls outside of control limits.
    </p>
    <p>
      Outliers (points highlighted in yellow) are not factored into the
      analysis.
    </p>
  </div>
</clr-modal>
