import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { cloneDeep } from 'lodash'
import { facility_insert_input } from 'src/types/graphql-global-types'
import {
  camel,
  safeGet,
  StringObjectLiteral,
  userFullname,
} from '../../util/util'
import { facilitiesDetails_facility_by_pk } from '../facilities-details/types/facilitiesDetails'
import editMutation from './facilities-edit.gql'
import insertMutation from './facilities-insert.gql'
import relatedEntityQuery from './facility_get_related_entities.gql'
import { edit_facility_by_pk } from './types/edit_facility_by_pk'
import { facility_get_related_entities } from './types/facility_get_related_entities'
import { insert_facility } from './types/insert_facility'

@Component({
  selector: 'app-facilities-edit',
  templateUrl: './facilities-edit.component.html',
  styleUrls: ['./facilities-edit.component.scss'],
})
export class FacilitiesEditComponent implements OnInit {
  createNew = false

  @Input() facility: facilitiesDetails_facility_by_pk

  newFacility: facility_insert_input = {}

  private _backup_facility: facilitiesDetails_facility_by_pk

  @Output() saveEvent = new EventEmitter()

  safeGet = safeGet

  objectKeys = Object.keys

  relatedEntityOptionsQueryResult: ApolloQueryResult<
    facility_get_related_entities
  >

  getUserFullname = userFullname

  constructor(private apollo: Apollo) {}

  fetchRelatedEntityOptions() {
    this.apollo
      .query<facility_get_related_entities>({ query: relatedEntityQuery })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.relatedEntityOptionsQueryResult = result
        },
      })
  }

  get relatedEntityOptions(): facility_get_related_entities {
    return this.relatedEntityOptionsQueryResult.data
  }

  ngOnInit() {
    // figure out if we're editing or creating a facility
    if (!this.facility) {
      this.createNew = true
    }
    if (!this.createNew) {
      this._backup_facility = cloneDeep(this.facility)
    }
    this.fetchRelatedEntityOptions()
  }

  save() {
    if (this.createNew) {
      this.apollo
        .mutate<insert_facility>({
          mutation: insertMutation,
          variables: { facility: this.newFacility },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.newFacility = {}
            this.saveEvent.emit('create')
          },
        })
    } else {
      this.apollo
        .mutate<edit_facility_by_pk>({
          mutation: editMutation,
          variables: {
            id: this.facility?.id,
            _set: this.stripInput(this.facility),
          },
        })
        .subscribe({
          next: (data) => {
            if (!data.errors) {
              this.saveEvent.emit('edit')
            }
          },
        })
    }
  }

  back() {
    if (!this.createNew) {
      this.facility = cloneDeep(this._backup_facility)
    } else {
      this.newFacility = {}
    }
    console.log('back')
    this.saveEvent.emit('back')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripInput = (obj: StringObjectLiteral<any>) =>
    Object.keys(obj).reduce((newObj, key) => {
      if (
        key !== 'id' &&
        key !== 'dateCreated' &&
        key !== 'dateUpdated' &&
        key !== '__typename'
      ) {
        if (
          typeof obj[key] === 'string' ||
          typeof obj[key] === 'number' ||
          (obj[key] === null && key.slice(-2) === 'Id')
        ) {
          newObj[camel(key)] = obj[key]
        }
      }
      return newObj
    }, {})

  getModel(): facilitiesDetails_facility_by_pk | facility_insert_input {
    return this.createNew ? this.newFacility : this.facility
  }

  setModel(key: string, val: string) {
    this.getModel()[key] = val
  }
}
