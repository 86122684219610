<div class="clr-row" appPageHeader [title]="title">
  <button
    [clrLoading]="exportButtonState"
    (click)="tryToOpenModal()"
    class="btn btn-outline"
  >
    Export Test Data
  </button>
</div>
<clr-datagrid
  class="datagrid-striped"
  #datagrid
  (clrDgRefresh)="refreshGrid($event, fetchItems)"
  [clrDgLoading]="loading"
>
  <clr-dg-placeholder>Nothing to Show</clr-dg-placeholder>

  <!-- Row Def -->
  <clr-dg-row *ngFor="let item of items">
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanner?.facility?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanner?.facility_room?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.study?.modality?.id
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanner?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.phantom_test_routine?.phantom | friendlyPhantomName
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.study?.scanDate | customDate
    }}</clr-dg-cell>
    <clr-dg-cell
      appClickToView
      (click)="view(item)"
      class="{{ item?.result }}"
      >{{ item?.result }}</clr-dg-cell
    >
    <clr-dg-cell appClickToView (click)="view(item)">{{
      getUserFullname(item?.user)
    }}</clr-dg-cell>
  </clr-dg-row>

  <!-- Facility Column -->
  <clr-dg-column [clrDgField]="properties.facility.sort">
    <ng-container>Facility</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.facility.filter]?.exact"
        [property]="properties.facility.filter"
        [options]="options.facilities"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Room Column -->
  <clr-dg-column [clrDgField]="properties.facility_room.sort">
    <ng-container>Room</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.facility_room.filter]?.exact"
        [property]="properties.facility_room.filter"
        [options]="options.rooms"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Modality Column -->
  <clr-dg-column [clrDgField]="properties.modality">
    <ng-container>Modality</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.modality]?.exact"
        [property]="properties.modality"
        [options]="options.modalities"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Scanner Column -->
  <clr-dg-column [clrDgField]="properties.scanner.sort">
    <ng-container>Scanner</ng-container>
    <clr-dg-filter>
      <app-select-filter
        (changes)="onScannerChange($event)"
        [exact]="filterState[properties.scanner.filter]?.exact"
        [property]="properties.scanner.filter"
        [options]="options.scanners"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Phantom Column -->
  <clr-dg-column [clrDgField]="properties.phantom">
    <ng-container>Phantom</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.phantom]?.exact"
        [property]="properties.phantom"
        [options]="options.phantoms"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Study Date Column -->
  <clr-dg-column [clrDgField]="properties.scanDate">
    <ng-container>Study Date</ng-container>
    <clr-dg-filter>
      <app-date-filter
        [property]="properties.scanDate"
        [earliest]="filterState[properties.scanDate]?.earliest"
        [latest]="filterState[properties.scanDate]?.latest"
      ></app-date-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Result Column -->
  <clr-dg-column [clrDgField]="properties.result">
    <ng-container>Result</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.result]?.exact"
        [property]="properties.result"
        [options]="options.results"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Tester Column -->
  <clr-dg-column [clrDgField]="properties.user.sort">
    <ng-container>Tester</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.user.filter]?.exact"
        [property]="properties.user.filter"
        [options]="options.users"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Footer -->
  <clr-dg-footer>
    {{ pagination.firstItem + 1 | number }} -
    {{ pagination.lastItem + 1 | number }} of {{ totalItems | number }} items
    <clr-dg-pagination
      #pagination
      [clrDgTotalItems]="totalItems"
    ></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

<app-scanner-calendar
  *ngIf="selectedScannerId"
  [scannerId]="selectedScannerId"
></app-scanner-calendar>

<clr-modal *ngIf="showExportModal" [(clrModalOpen)]="showExportModal">
  <h3 class="modal-title">Data Export</h3>
  <div class="modal-body">
    <p>
      This tool exports raw test data in
      <!-- CSV or  -->
      JSON format for the selected tests. Use the filters on the page to select
      which tests to export. Then, click export.
    </p>
    <p class="fail" *ngIf="!anyFilterSelected()">
      No filters are selected - you will export all data for all scanners
    </p>
    <p class="problem" *ngIf="anyFilterSelected() && !scannerFilterSelected()">
      No scanner is selected - you will export data for all scanners
    </p>
    <!-- <p>
      <clr-radio-wrapper>
        <input
          type="radio"
          clrRadio
          value="csv"
          name="options"
          [(ngModel)]="exportDataType"
        />
        <label>CSV</label>
      </clr-radio-wrapper>
      <clr-radio-wrapper>
        <input
          type="radio"
          clrRadio
          value="json"
          name="options"
          [(ngModel)]="exportDataType"
        />
        <label>JSON</label>
      </clr-radio-wrapper>
    </p> -->
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showExportModal = false"
    >
      Back
    </button>
    <button
      type="button"
      class="btn btn-{{
        !anyFilterSelected()
          ? 'danger'
          : !scannerFilterSelected()
          ? 'warning'
          : 'success'
      }}-outline"
      (click)="showExportModal = false; exportData()"
    >
      Export
    </button>
  </div>
</clr-modal>
