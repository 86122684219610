import { gql } from 'apollo-angular'

export default gql`
  query surveyTestsDetails($id: Int!) {
    survey_test_by_pk(id: $id) {
      answered_survey_test_questions {
        text
        result
        section
        answer
      }
      id
      scanner {
        id
        name
        facility_room {
          name
          id
        }
        facility {
          id
          name
        }
        modality {
          id
        }
      }
      dateCreated
      result
      user {
        firstName
        lastName
      }
      scannerId
      testerId
    }
  }
`
