import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { UserRole } from 'src/types/graphql-global-types'
import { AuthService } from 'src/app/auth/auth.service'

export interface PageHeaderAction {
  text: string
  authorized?: UserRole[] | UserRole
  class?: 'info' | 'danger' | 'warning' | 'success'
}

@Component({
  selector: '[appPageHeader]',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() title: string

  @Input() actions: PageHeaderAction[]

  @Output() clicks = new EventEmitter()

  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    if (this.actions) {
      this.actions = this.actions.filter(
        (a) => !a.authorized || this.auth.checkrole(a.authorized),
      )
      this.actions.forEach((a) => {
        a.class = a.class || 'info'
      })
    }
  }
}
