<div class="clr-row" appPageHeader [title]="title">
  <clr-dropdown>
    <button class="btn btn-outline-primary" clrDropdownTrigger>
      Perform a Survey
      <clr-icon shape="caret down"></clr-icon>
    </button>
    <clr-dropdown-menu *clrIfOpen>
      <label class="dropdown-header" aria-hidden="true">All Surveys</label>
      <div
        *ngFor="let routine of surveyTestRoutines"
        (click)="runRoutine(routine)"
        clrDropdownItem
      >
        {{ routine.name }}
      </div>
    </clr-dropdown-menu>
  </clr-dropdown>
</div>
<clr-datagrid
  class="datagrid-striped"
  #datagrid
  (clrDgRefresh)="refreshGrid($event, fetchItems)"
  [clrDgLoading]="loading"
>
  <clr-dg-placeholder>Nothing to Show</clr-dg-placeholder>

  <!-- Row Def -->
  <clr-dg-row *ngFor="let item of items">
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanner?.facility?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanner?.facility_room?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanner?.modality?.id
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanner?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.dateCreated | customDate
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.survey_test_routine?.name
    }}</clr-dg-cell>
    <clr-dg-cell
      appClickToView
      (click)="view(item)"
      class="{{ item?.result }}"
      >{{ item?.result }}</clr-dg-cell
    >
    <clr-dg-cell appClickToView (click)="view(item)">{{
      getUserFullname(item?.user)
    }}</clr-dg-cell>
  </clr-dg-row>

  <!-- Facility Column -->
  <clr-dg-column [clrDgField]="properties.facility.sort">
    <ng-container>Facility</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.facility.filter]?.exact"
        [property]="properties.facility.filter"
        [options]="options.facilities"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Room Column -->
  <clr-dg-column [clrDgField]="properties.facility_room.sort">
    <ng-container>Room</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.facility_room.filter]?.exact"
        [property]="properties.facility_room.filter"
        [options]="options.rooms"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Modality Column -->
  <clr-dg-column [clrDgField]="properties.modality">
    <ng-container>Modality</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.modality]?.exact"
        [property]="properties.modality"
        [options]="options.modalities"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Scanner Column -->
  <clr-dg-column [clrDgField]="properties.scanner.sort">
    <ng-container>Scanner</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.scanner.filter]?.exact"
        [property]="properties.scanner.filter"
        [options]="options.scanners"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Test Date Column -->
  <clr-dg-column [clrDgField]="properties.dateCreated">
    <ng-container>Test Date</ng-container>
    <clr-dg-filter>
      <app-date-filter
        [property]="properties.dateCreated"
        [earliest]="filterState[properties.dateCreated]?.earliest"
        [latest]="filterState[properties.dateCreated]?.latest"
      ></app-date-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Survey Column -->
  <clr-dg-column [clrDgField]="properties.survey.sort">
    <ng-container>Survey</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.survey.filter]?.exact"
        [property]="properties.survey.filter"
        [options]="options.surveys"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Result Column -->
  <clr-dg-column [clrDgField]="properties.result">
    <ng-container>Result</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.result]?.exact"
        [property]="properties.result"
        [options]="options.results"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Tester Column -->
  <clr-dg-column [clrDgField]="properties.user.sort">
    <ng-container>Tester</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.user.filter]?.exact"
        [property]="properties.user.filter"
        [options]="options.users"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Footer -->
  <clr-dg-footer>
    {{ pagination.firstItem + 1 | number }} -
    {{ pagination.lastItem + 1 | number }} of {{ totalItems | number }} items
    <clr-dg-pagination
      #pagination
      [clrDgTotalItems]="totalItems"
    ></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
