import { gql } from 'apollo-angular'

export default gql`
  query commentsDetails($id: Int!) {
    comment_by_pk(id: $id) {
      id
      content
      dateCreated
      user {
        firstName
        lastName
      }
      subject
      comment_files {
        stored_file {
          key
        }
      }
      comment_category {
        name
      }
      phantom_test {
        study {
          scanDate
        }
      }
      userId
    }
  }
`
