<div class="clr-row" appPageHeader [title]="title"></div>
<app-icon-legend></app-icon-legend>
<clr-datagrid
  class="datagrid-striped"
  #datagrid
  (clrDgRefresh)="refreshGrid($event, fetchItems)"
  [clrDgLoading]="loading"
>
  <clr-dg-placeholder>Nothing to Show</clr-dg-placeholder>

  <!-- Row Def -->
  <clr-dg-row *ngFor="let item of items">
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.facility?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.facility_room?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.modality?.id
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">
      <app-action-link
        (clicks)="actionsHandler($event, item)"
        [actions]="scannerLinkActions"
        [text]="item?.name"
      ></app-action-link>
    </clr-dg-cell>
    <clr-dg-cell class="dashboard-tests"
      ><app-dashboard-tests
        [scanner]="item"
        [daysToDisplay]="daysToDisplay"
        [skipDays]="skipDays"
      ></app-dashboard-tests
    ></clr-dg-cell>
    <clr-dg-cell
      appClickToView
      (click)="viewSurveyTest(mostRecentSurveyTestDate(item).id)"
      >{{
        (mostRecentSurveyTestDate(item).date | customDate) || 'none'
      }}</clr-dg-cell
    >
  </clr-dg-row>

  <!-- Facility Column -->
  <clr-dg-column [clrDgField]="properties.facility.sort">
    <ng-container>Facility</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.facility.filter]?.exact"
        [property]="properties.facility.filter"
        [options]="options.facilities"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Room Column -->
  <clr-dg-column [clrDgField]="properties.facility_room.sort">
    <ng-container>Room</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.facility_room.filter]?.exact"
        [property]="properties.facility_room.filter"
        [options]="options.rooms"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Modality Column -->
  <clr-dg-column [clrDgField]="properties.modality">
    <ng-container>Modality</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.modality]?.exact"
        [property]="properties.modality"
        [options]="options.modalities"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Scanner Column -->
  <clr-dg-column
    [clrFilterValue]="this.filterState[properties.scanner]?.exact"
    [clrDgField]="properties.scanner"
  >
    <ng-container>Scanner</ng-container>
  </clr-dg-column>

  <!-- Last 7 Days Column -->
  <clr-dg-column>
    <ng-container>Last 7 Days</ng-container>
  </clr-dg-column>

  <!-- Most Recent Survey Test Column -->
  <clr-dg-column>
    <ng-container>Most Recent Survey Test</ng-container>
  </clr-dg-column>

  <!-- Footer -->
  <clr-dg-footer>
    {{ pagination.firstItem + 1 | number }} -
    {{ pagination.lastItem + 1 | number }} of {{ totalItems | number }} items
    <clr-dg-pagination
      #pagination
      [clrDgTotalItems]="totalItems"
      [clrDgPageSize]="pageSize"
    ></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
