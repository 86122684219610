import { Component, OnInit, Input, ViewChild } from '@angular/core'
import {
  GALLERY_CONF,
  GALLERY_IMAGE,
  NgxImageGalleryComponent,
} from 'ngx-image-gallery'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent

  dev(): boolean {
    return environment.appUri.includes('ngrok')
  }

  // gallery configuration
  @Input() conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: true,
  }

  // gallery images
  @Input() images: GALLERY_IMAGE[]

  constructor() {}

  ngOnInit(): void {}

  openGallery(index = 0) {
    this.ngxImageGallery.open(index)
  }

  // close gallery
  closeGallery() {
    this.ngxImageGallery.close()
  }

  // set new active(visible) image in gallery
  newImage(index = 0) {
    this.ngxImageGallery.setActiveImage(index)
  }

  // next image in gallery
  nextImage(index = 0) {
    this.ngxImageGallery.next()
  }

  // prev image in gallery
  prevImage(index = 0) {
    this.ngxImageGallery.prev()
  }

  /** *********************************************** */

  // EVENTS
  // callback on gallery opened
  galleryOpened(index) {}

  // callback on gallery closed
  galleryClosed() {}

  // callback on gallery image clicked
  galleryImageClicked(index) {}

  // callback on gallery image changed
  galleryImageChanged(index) {}

  // callback on user clicked delete button
  deleteImage(index) {}
}
