<clr-tabs>
  <clr-tab>
    <button class="large-title" clrTabLink (click)="activeTab = 0">
      Single Scanner
    </button>
    <clr-tab-content [style.display]="activeTab !== 0 ? 'none' : 'initial'">
      <div class="tab-content">
        <app-trends [type]="'singleScanner'"></app-trends>
      </div>
    </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button class="large-title" clrTabLink (click)="activeTab = 1">
      Multiple Scanners
    </button>
    <clr-tab-content [style.display]="activeTab !== 1 ? 'none' : 'initial'">
      <div class="tab-content">
        <app-trends [type]="'multipleScanners'"></app-trends>
      </div>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>
