import {
  AfterViewInit,
  OnDestroy,
  Type,
  ViewChild,
  Directive,
} from '@angular/core'
import { ResolveEnd, Router } from '@angular/router'
import {
  ClrDatagrid,
  ClrDatagridPagination,
  ClrDatagridStateInterface,
} from '@clr/angular'
import * as dot from 'dot-object'
import { isEmpty } from 'lodash'
import * as moment from 'moment-timezone'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import {
  DatagridStateService,
  DatagridManualState,
} from '../core/datagrid-state.service'
import { DevError } from '../core/errors/errors'
import {
  FilterInterface,
  isClrDefaultStringFilter,
  isDateFilter,
  isSelectFilter,
  isExactNumberFilter,
  isNumberFilter,
} from '../filters/filters.util'
import { StringObjectLiteral } from './util'
import { TimezoneService } from '../timezone.service'

export interface QueryVariables {
  limit?: number
  offset?: number
  order_by?: object[]
  where?: object
}

@Directive()
export abstract class DatagridComponent implements AfterViewInit, OnDestroy {
  abstract title: string
  abstract properties: StringObjectLiteral
  variables: QueryVariables = {}
  // see inbox for example
  invariants: { where?: object }
  loading = true
  defaultState: DatagridManualState
  private providedState: DatagridManualState | undefined
  onPageLoad = true
  ignoreRefresh = false
  destroyed$ = new Subject<void>()
  @ViewChild('pagination') pagination: ClrDatagridPagination
  @ViewChild('datagrid') datagrid: ClrDatagrid
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterState: StringObjectLiteral<any> = {}
  get clrDgState(): ClrDatagridStateInterface<unknown> {
    if (!this.title) {
      return {}
    }
    if (this.providedState) {
      this.clrDgState = this.providedState
      this.providedState = undefined
    }
    const savedState = this.dgStateService.get(this.title)
    if (isEmpty(savedState) && !isEmpty(this.defaultState)) {
      this.clrDgState = this.defaultState
    }
    return this.dgStateService.get(this.title)
  }
  set clrDgState(state) {
    this.dgStateService.set(this.title, state)
  }

  constructor(
    protected dgStateService: DatagridStateService,
    protected router: Router,
    protected timezoneService: TimezoneService,
  ) {
    if (router.events) {
      router.events
        .pipe(
          takeUntil(this.destroyed$),
          filter((e) => e instanceof ResolveEnd),
        )
        .subscribe((e: ResolveEnd) => {
          this.ignoreRefresh = e.state.root.component !== DatagridComponent
        })
      const providedState = this.router.getCurrentNavigation()?.extras?.state
        ?.providedState
      if (providedState) {
        this.providedState = providedState
      }
    }
  }

  /** Angular Lifecycle Methods */

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadDatagridState()
    }, 0)
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  /** Class Methods */

  loadDatagridState() {
    this.ignoreRefresh = true
    // set the filters
    const filters = this.clrDgState.filters
    if (filters && filters.length > 0) {
      filters.forEach((f: FilterInterface) => {
        if (isDateFilter(f)) {
          this.filterState[f.property] = {
            earliest: f.earliest,
            latest: f.latest,
          }
        } else if (isSelectFilter(f)) {
          this.filterState[f.property] = { exact: f.exact }
        } else if (isClrDefaultStringFilter(f)) {
          this.filterState[f.property] = { value: f.value }
        } else if (isExactNumberFilter(f)) {
          this.filterState[f.property] = { exact: f.exact }
        } else if (isNumberFilter(f)) {
          this.filterState[f.property] = {
            min: f.min,
            max: f.max,
          }
        }
      })
    }
    // set the sort
    const sort = this.clrDgState.sort
    if (sort) {
      this.datagrid.columns.forEach((c) => {
        if (c.field === sort.by) {
          c.sort(sort.reverse)
        }
      })
    }
    // set the paginator
    this.pagination.pageSize = this.clrDgState.page?.size
    this.pagination.currentPage = this.clrDgState.page?.current
    setTimeout(() => {
      this.ignoreRefresh = false
    }, 0)
  }

  /**
   * This is a pretty big complicated method that could probably be broken up
   */
  refreshGrid(
    dgstate: ClrDatagridStateInterface<unknown>,
    fetchFn: () => void,
  ) {
    let state: ClrDatagridStateInterface<unknown>
    if (this.ignoreRefresh) {
      return
    }
    // if on page load, use the saved state. else, save the state
    if (this.onPageLoad) {
      this.onPageLoad = false
      state = this.clrDgState
    } else {
      state = dgstate
      this.clrDgState = state
    }
    this.variables = {}
    // limit + offset: which page to display
    if (state.page) {
      this.variables.limit = state.page.size
      this.variables.offset = state.page.from === -1 ? 0 : state.page.from
    }
    // disallow fetching entire table
    if (!this.variables.limit) {
      this.variables.limit = 10
    }
    // order_by: which column to sort by
    if (state.sort) {
      if (typeof state.sort.by !== 'string') {
        throw new DevError(
          'you are probably trying to use client-side sorting, which is not supported',
        )
      }
      const property = state.sort.by.replace('.aggregate.', '.')
      const orderBy = { [property]: state.sort.reverse ? 'desc' : 'asc' }
      dot.object(orderBy)
      this.variables.order_by = [orderBy]
    }
    // filters
    if (state.filters) {
      this.variables.where = {}
      state.filters.forEach((f: FilterInterface) => {
        if (isClrDefaultStringFilter(f)) {
          const property = f.property.replace('.aggregate.', '.')
          // set where clause in query
          this.variables.where[property] = { _ilike: `${f.value}%` }
        } else if (isSelectFilter(f)) {
          const { property } = f
          // set where clause in query
          this.variables.where[property] = { _eq: f.exact }
        } else if (isDateFilter(f)) {
          const { property } = f
          if (f.earliest || f.latest) {
            if (!this.variables.where[property]) {
              this.variables.where[property] = {}
            }
            if (f.earliest) {
              const startOfDayTz: string = moment(f.earliest)
                .tz(this.timezoneService.cachedTz)
                .startOf('day')
                .toISOString()
              // set where clause in query
              this.variables.where[property]._gte = startOfDayTz
            }
            if (f.latest) {
              const endofDayTz: string = moment(f.latest)
                .tz(this.timezoneService.cachedTz)
                .endOf('day')
                .toISOString()
              if (!this.variables.where[property]) {
                this.variables.where[property] = {}
              }
              // set where clause in query
              this.variables.where[property]._lte = endofDayTz
            }
          }
        } else if (isNumberFilter(f)) {
          const { property } = f
          if (typeof f.min === 'number' || typeof f.max === 'number') {
            if (!this.variables.where[property]) {
              this.variables.where[property] = {}
            }
            if (typeof f.min === 'number') {
              // set where clause in query
              this.variables.where[property]._gte = f.min
            }
            if (typeof f.max === 'number') {
              if (!this.variables.where[property]) {
                this.variables.where[property] = {}
              }
              // set where clause in query
              this.variables.where[property]._lte = f.max
            }
          }
        } else if (isExactNumberFilter(f)) {
          const { property } = f
          this.variables.where[property] = { _eq: f.exact }
        } else {
          console.warn('filter type not implemented')
          console.warn(f)
        }
      })
      dot.object(this.variables.where)
    }
    // capture invariants
    if (this.invariants) {
      const where = this.variables.where
      const andWhere = this.invariants.where
      if (!isEmpty(andWhere)) {
        if (!isEmpty(where)) {
          this.variables.where = {
            _and: [where, andWhere],
          }
        } else {
          this.variables.where = andWhere
        }
      }
    }
    // fetch
    fetchFn()
  }
}
