import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { DatagridStateService } from 'src/app/core/datagrid-state.service'
import { NavService } from 'src/app/core/nav.service'
import { DatagridComponent } from 'src/app/util/datagrid.class'
import { TimezoneService } from 'src/app/timezone.service'
import { KeyVal } from '../../util/util'
import { PageHeaderAction } from '../page-header/page-header.component'
import scanner_get_related_entitiesGql from '../scanners-edit/scanner_get_related_entities.gql'
import { scanner_get_related_entities } from '../scanners-edit/types/scanner_get_related_entities'
import query from '../scanners/scanners.gql'
import {
  scanners,
  scannersVariables,
  scanners_scanner,
} from '../scanners/types/scanners'

@Component({
  selector: 'app-scanners',
  templateUrl: './workstations.component.html',
  styleUrls: ['./workstations.component.scss'],
})
export class WorkstationsComponent extends DatagridComponent {
  title = 'workstations'
  creatingWorkstation = false
  actions: PageHeaderAction[] = [{ text: 'Create Workstation' }]

  invariants: scannersVariables = {
    where: {
      deviceType: { _eq: 'workstation' },
    },
  }

  static properties = {
    facility: { filter: 'facility.id', sort: 'facility.name' },
    facility_room: {
      filter: 'facility_room.id',
      sort: 'facility_room.name',
    },
    modality: 'modality.id',
    scanner: 'name',
    scanner_manufacturer: {
      filter: 'scanner_manufacturer.id',
      sort: 'scanner_manufacturer.name',
    },
    scanner_model: {
      filter: 'scanner_model.id',
      sort: 'scanner_model.name',
    },
  }
  properties = WorkstationsComponent.properties

  constructor(
    private apollo: Apollo,
    private nav: NavService,
    protected router: Router,
    protected dgStateService: DatagridStateService,
    protected timezoneService: TimezoneService,
  ) {
    super(dgStateService, router, timezoneService)

    // add default if necessary
    this.defaultState = {
      sort: {
        by: this.properties.scanner,
        reverse: false,
      },
    }
  }

  actionsHandler($event: string) {
    switch ($event) {
      case 'Create Workstation':
        this.creatingWorkstation = true
        break
      default:
        break
    }
  }

  items: scanners_scanner[]
  totalItems: number

  fetchItems = () => {
    this.loading = true
    this.apollo
      .query<scanners>({ query, variables: this.variables })
      .subscribe({
        next: (result: ApolloQueryResult<scanners>) => {
          this.loading = false
          if (result.errors) {
            throw result.errors[0]
          }
          this.items = result.data.scanner
          this.totalItems = result.data.scanner_aggregate.aggregate.count
        },
        error: this.onError,
      })
    this.apollo
      .query<scanner_get_related_entities>({
        query: scanner_get_related_entitiesGql,
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.assembleRelatedEntityOptions(result.data)
        },
        error: this.onError,
      })
  }

  onUpdate($event: string) {
    switch ($event) {
      case 'create':
      case 'back':
        this.creatingWorkstation = false
        this.fetchItems()
        break
      default:
        break
    }
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  options: {
    facilities: KeyVal[]
    rooms: KeyVal[]
    modalities: KeyVal[]
    scanner_manufacturers: KeyVal[]
    scanner_models: KeyVal[]
  } = {
    facilities: [],
    rooms: [],
    modalities: [],
    scanner_manufacturers: [],
    scanner_models: [],
  }

  assembleRelatedEntityOptions(data: scanner_get_related_entities) {
    this.options = {
      facilities: data.facility.map((f) => ({
        key: f.id,
        val: f.name,
      })),
      rooms: data.facility_room.map((r) => ({
        key: r.id,
        val: r.name,
      })),
      modalities: data.modality.map((m) => ({
        key: m.id,
        val: m.id,
      })),
      scanner_manufacturers: data.scanner_manufacturer.map((m) => ({
        key: m.id,
        val: m.name,
      })),
      scanner_models: data.scanner_model.map((m) => ({
        key: m.id,
        val: m.name,
      })),
    }
  }

  view(scanner: scanners_scanner) {
    this.nav.navtree.workstationDetails(scanner.id)
  }
}
