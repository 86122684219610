import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { debounce } from 'lodash'
import moment from 'moment-timezone'
import { CommService } from 'src/app/core/comm.service'
import { TimezoneService } from 'src/app/timezone.service'
import { TrendsService } from 'src/app/trends.service'
import { ScannerMultiSelectComponent } from '../scanner-multi-select/scanner-multi-select.component'
import { SideControlsComponent } from '../side-controls/side-controls.component'
import {
  CSVDemoData,
  generateChartConfig,
  generateDataOnly,
  trends_phantom_test_routine,
  scannersAndTrendKeySelected,
  TrendDisplayType,
  TrendsStateInterface,
  trends_survey_test_routine,
} from './helpers'
import { trendsScannerDetails_scanner } from './types/trendsScannerDetails'
import { trendsScanners_scanner } from './types/trendsScanners'

@Component({
  selector: 'app-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.scss'],
})
export class TrendsComponent implements OnInit {
  @Input() type: TrendDisplayType
  @ViewChild('scannerSelect') scannerSelect: ScannerMultiSelectComponent
  @ViewChild('sideControlLeft') sideControlLeft: SideControlsComponent
  @ViewChild('sideControlRight') sideControlRight: SideControlsComponent
  get state(): TrendsStateInterface {
    const selectedScanners = this.scannerSelect?.selected
    const left = {
      selectedRoutine: this.sideControlLeft?.selectedPhantomTestRoutine,
      selectedTrendKey: this.sideControlLeft?.selectedTrendKey,
      showTrendLines: this.sideControlLeft?.showTrendLines,
      showControlLimits: this.sideControlLeft?.showControlLimits,
      showOutliers: this.sideControlLeft?.showOutliers,
      sameYAxis: this.sideControlLeft?.sameYAxis,
      disabled: this.sideControlLeft?.disabled,
      selectedSurveyTestRoutine: this.sideControlLeft
        ?.selectedSurveyTestRoutine,
      selectedSurveyTestField: this.sideControlLeft?.selectedSurveyTestField,
    }
    const right = {
      selectedRoutine: this.sideControlRight?.selectedPhantomTestRoutine,
      selectedTrendKey: this.sideControlRight?.selectedTrendKey,
      showTrendLines: this.sideControlRight?.showTrendLines,
      showControlLimits: this.sideControlRight?.showControlLimits,
      showOutliers: this.sideControlRight?.showOutliers,
      sameYAxis: this.sideControlRight?.sameYAxis,
      disabled: this.sideControlRight?.disabled,
      selectedSurveyTestRoutine: this.sideControlRight
        ?.selectedSurveyTestRoutine,
      selectedSurveyTestField: this.sideControlRight?.selectedSurveyTestField,
    }
    return { selectedScanners, left, right }
  }

  get selectedScanner() {
    return this.state?.selectedScanners?.length > 0
  }

  get firstParamSelected() {
    return this.selectedScanner && this.state?.left?.selectedTrendKey
  }

  constructor(
    private comm: CommService,
    private trendsService: TrendsService,
    private timezone: TimezoneService,
  ) {}

  // retrieved data
  allScanners: trendsScanners_scanner[]
  allRoomNames: string[]
  scannersDetails: trendsScannerDetails_scanner[]
  routineIntersection: trends_phantom_test_routine[]
  routineUnion: trends_phantom_test_routine[]
  surveyTestRoutineUnion: trends_survey_test_routine[]
  surveyTestRoutineIntersection: trends_survey_test_routine[]
  csvDemoData: CSVDemoData

  // chart options
  _chartOptions: any
  get chartOptions(): any {
    return this._chartOptions
  }
  set chartOptions(val: any) {
    console.debug(JSON.stringify(val))
    this._chartOptions = val
  }

  // methods
  async ngOnInit() {
    await this.fetchScannersList()
  }
  async ngAfterViewInit(): Promise<void> {
    await this.doClientSideWork()
  }

  /** Impure */
  async fetchScannersList() {
    const { scanners, roomNames } = await this.trendsService.allScanners()
    this.allScanners = scanners
    this.allRoomNames = roomNames
  }

  /** Impure */
  async scannerSelectChange() {
    // pull new data, refresh client-side stuff
    const {
      scanners,
      routineIntersection,
      routineUnion,
      surveyRoutineIntersection,
      surveyRoutineUnion,
    } = await this.fetchScannerDetails()
    this.scannersDetails = scanners
    this.routineIntersection = routineIntersection
    this.routineUnion = routineUnion
    this.surveyTestRoutineIntersection = surveyRoutineIntersection
    this.surveyTestRoutineUnion = surveyRoutineUnion
    if (routineIntersection.length === 0 && scanners.length > 1) {
      this.comm.alert(
        'info',
        'These scanners do not have any phantoms in common',
      )
    }
    await this.doClientSideWork()
  }

  /** Triggered in HTML */
  async sideControlChange() {
    await this.doClientSideWork()
  }

  /** Triggered in HTML */
  onChartClick($event: any) {
    // check if this is a phantom test click
    const possibleTest = $event?.data?.[2]
    if (
      possibleTest &&
      typeof possibleTest?.id === 'number' &&
      possibleTest?.__typename === 'phantom_test'
    ) {
      window.open(`/home/phantomTests/${possibleTest.id}`, '_blank')
    }
  }

  /** Pure */
  private async fetchScannerDetails() {
    // load scanner list from multi-select
    const scannerIds = this.state.selectedScanners.map((s) => s.id)
    // fetch all phantom tests for each scanner
    return await this.trendsService.selectedScannerDetails(scannerIds)
  }

  private async doClientSideWork() {
    await this.debouncedDoClientSideWork()
    this.userHasSelectedScannersAndTrendKey = scannersAndTrendKeySelected(
      this.scannersDetails,
      this.state,
    )
  }

  private executeClientSideWork = async () => {
    if (!this.csvDemoData) {
      this.csvDemoData = await this.trendsService.getCsvDemoData()
    }
    this.chartOptions = (
      await generateChartConfig(
        this.scannersDetails,
        this.state,
        this.type,
        this.timezone.cachedTz,
        this.csvDemoData,
      )
    ).config
  }

  downloadData() {
    const data = generateDataOnly(
      this.scannersDetails,
      this.state,
      this.type,
      this.timezone.cachedTz,
    )
    const csv = []
    Object.keys(data).forEach((scannerName) => {
      const values = data[scannerName]
      // have to combine data for left and right
      // so, create arrays like this
      const indexedByDate: {
        [key: number]: [number | undefined, number | undefined]
      } = {}
      if (values.leftData) {
        values.leftData.forEach((leftValue) => {
          indexedByDate[leftValue[0]] = [leftValue[1], undefined]
        })
      }
      if (values.rightData) {
        values.rightData.forEach((rightValue) => {
          if (indexedByDate[rightValue[0]]) {
            indexedByDate[rightValue[0]][1] = rightValue[1]
          } else {
            indexedByDate[rightValue[0]] = [undefined, rightValue[1]]
          }
        })
      }
      // push the header row to the csv
      csv.push([
        scannerName,
        this.state.left?.selectedTrendKey?.join(' / '),
        this.state.right?.selectedTrendKey?.join(' / '),
      ])
      // push the data rows to the csv
      const sortedDates = Object.keys(indexedByDate).sort()
      sortedDates.forEach((date) => {
        const dateAsISOString = new Date(parseInt(date)).toISOString()
        csv.push([
          dateAsISOString,
          indexedByDate[date][0],
          indexedByDate[date][1],
        ])
      })
    })
    const csvContent = csv.map((row) => row.join(',')).join('\n')
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'trends.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    console.log(csv)
  }

  debouncedDoClientSideWork = debounce(this.executeClientSideWork, 50)

  userHasSelectedScannersAndTrendKey: boolean = false
}
