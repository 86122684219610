import { gql } from 'apollo-angular'

export default gql`
  query missedPhantomTestsDetails($id: Int!) {
    missed_phantom_test_by_pk(id: $id) {
      id
      scanner {
        id
        facility_room {
          name
        }
        name
        facility {
          id
          name
        }
      }
      scheduledDate
      comments_aggregate {
        aggregate {
          count
        }
      }
      scannerId
    }
  }
`
