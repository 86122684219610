import { gql } from 'apollo-angular'

export default gql`
  query login($username: String!, $password: String!) {
    login(data: { username: $username, password: $password }) {
      token
      permissions
      user {
        accountType
        username
        id
        role
        firstName
        lastName
        facility {
          id
          name
        }
      }
    }
  }
`
