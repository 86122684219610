import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { Apollo, gql } from 'apollo-angular'
import { DocumentNode } from 'graphql'
import { cloneDeep, Dictionary, flatten, groupBy, mean, meanBy } from 'lodash'
import moment from 'moment-timezone'
import { TimezoneService } from './timezone.service'
import { earliestTest, earliestTest_scanner } from './types/earliestTest'
import {
  numberOfStudiesMetric,
  numberOfStudiesMetricVariables,
  numberOfStudiesMetric_study,
} from './types/numberOfStudiesMetric'
import {
  numberOfTestsMetric,
  numberOfTestsMetricVariables,
  numberOfTestsMetric_phantom_test,
} from './types/numberOfTestsMetric'
import {
  proportionPassedTestsMetric,
  proportionPassedTestsMetricVariables,
  proportionPassedTestsMetric_phantom_test,
} from './types/proportionPassedTestsMetric'

type groupFn<ItemType> = (data: ItemType[]) => Dictionary<ItemType[]>
interface Group<ItemType> {
  label: string
  groupFn: groupFn<ItemType>
}
export interface Metric<
  QueryType = unknown,
  ItemType = unknown,
  VariablesType = unknown
> {
  type: 'absolute' | 'percent'
  shortTitle: string
  title: string
  query: DocumentNode
  variables: VariablesType
  groups: [Group<ItemType>, Group<ItemType>]
  dataFromResult: (result: ApolloQueryResult<QueryType>) => ItemType[]
  metricFn: (data: ItemType[]) => number
  dateFn: (item: ItemType) => moment.Moment
}

export interface MetricList {
  metric: Metric<unknown, unknown, unknown>
  data: MetricSummary
}

export function groupTwice<ItemType>(
  ungrouped: ItemType[],
  groups: [Group<ItemType>, Group<ItemType>],
): Dictionary<Dictionary<ItemType[]>> {
  const firstGroup = groupOnce(ungrouped, groups[0])
  const fullyGrouped: Dictionary<Dictionary<ItemType[]>> = Object.keys(
    firstGroup,
  )
    .map((key) => ({
      key,
      val: groupOnce(firstGroup[key], groups[1]),
    }))
    .reduce((acc, curr) => ({ ...acc, [curr.key]: curr.val }), {})
  return fullyGrouped
}
export function groupOnce<ItemType>(
  ungrouped: ItemType[],
  group: Group<ItemType>,
): Dictionary<ItemType[]> {
  const firstGroup = group.groupFn(ungrouped)
  return firstGroup
}

export interface YearDataByMonth {
  month: number
  label: string
  value: number
}

export interface AbsoluteValueOfMetric {
  thisMonth: number
  lastMonth: number
  monthAverageThisYear: number
}
export interface MetricSummaryWithoutBreakdown {
  absoluteValueOfMetric: AbsoluteValueOfMetric
  /**
   * expressed as a percent = 0.2 === +20%, -1.34 === -134%
   * calculated as 1 - ((curr - former) / former)
   */
  changeInMetric: {
    vsLastMonth: number
    vsMonthAverageThisYear: number
  }
  /** { month: 0, label: 'Jan', value: 1234 } */
  yearDataByMonth: YearDataByMonth[]
}

export interface Breakdown {
  group1: Dictionary<MetricSummaryWithoutBreakdown>
  group2: Dictionary<MetricSummaryWithoutBreakdown>
  both: Dictionary<Dictionary<MetricSummaryWithoutBreakdown>>
}

export interface MetricSummary extends MetricSummaryWithoutBreakdown {
  breakdown: Breakdown
}

export type ByMonthConfig = { month: number; label: string; value: number }[]

export interface SummaryConfig {
  thisMonth: number
  lastMonth: number
  yearAverage: number
}
export interface MatrixConfig {
  data: { group: string[]; thisMonth: number; lastMonth: number }[][]
}

@Injectable({
  providedIn: 'root',
})
export class MetricsService {
  metrics: Metric<unknown, unknown, unknown>[] = []
  constructor(private timezone: TimezoneService, private apollo: Apollo) {
    const numberOfTestsMetric: Metric<
      numberOfTestsMetric,
      numberOfTestsMetric_phantom_test,
      numberOfTestsMetricVariables
    > = {
      type: 'absolute',
      shortTitle: '# Tests',
      title: 'Number of Tests',
      query: gql`
        query numberOfTestsMetric($lastYear: timestamp!) {
          phantom_test(where: { study: { scanDate: { _gte: $lastYear } } }) {
            study {
              scanDate
              modalityId
            }
            scanner {
              facility {
                name
              }
            }
          }
        }
      `,
      variables: {
        // if it's halfway through April 2021, get everything since start of May 2020
        lastYear: moment
          .tz(timezone.cachedTz)
          .add(-1, 'year')
          .add(1, 'month')
          .startOf('month')
          .toISOString(),
      },
      groups: [
        {
          label: 'Modality',
          groupFn: (data: numberOfTestsMetric_phantom_test[]) =>
            groupBy(data, (t) => t.study.modalityId),
        },
        {
          label: 'Facility',
          groupFn: (data: numberOfTestsMetric_phantom_test[]) =>
            groupBy(data, (t) => t.scanner.facility?.name),
        },
      ],
      dataFromResult: (result) => result.data.phantom_test,
      metricFn: (data) => data.length,
      dateFn: (item) => moment(item.study.scanDate).tz(timezone.cachedTz),
    }

    const numberOfScannersDateFn: (
      item: earliestTest_scanner,
    ) => moment.Moment = (scanner) =>
      moment(scanner.phantom_tests[0].study.scanDate).tz(this.timezone.cachedTz)
    const numberOfScannersMetric: Metric<
      earliestTest,
      earliestTest_scanner,
      undefined
    > = {
      type: 'absolute',
      shortTitle: '# Scanners',
      title: 'Number of Scanners Being Tested',
      query: gql`
        query earliestTest {
          scanner {
            phantom_tests(limit: 1, order_by: { study: { scanDate: asc } }) {
              study {
                scanDate
              }
            }
            id
            modalityId
            facility {
              name
            }
          }
        }
      `,
      variables: undefined,
      groups: [
        {
          label: 'Modality',
          groupFn: (data) => groupBy(data, (t) => t.modalityId),
        },
        {
          label: 'Facility',
          groupFn: (data) => groupBy(data, (t) => t.facility?.name),
        },
      ],
      dataFromResult: (result) => {
        const scanners = result.data.scanner.filter(
          (s) => s.phantom_tests.length > 0,
        )
        const groupedByMonth = this.last12Months(
          scanners,
          numberOfScannersDateFn,
        )
        const sortedByMonth = this.sortByMonth(groupedByMonth)
        sortedByMonth.forEach((month, index, array) => {
          if (index !== array.length - 1) {
            // copy every scanner from the current month to the next to make it cumulative
            array[index + 1][1] = array[index + 1][1].concat(
              array[index][1].map((item) => {
                // advance the date by a month
                const cloned = cloneDeep(item)
                const date = moment(cloned.phantom_tests[0].study.scanDate).tz(
                  this.timezone.cachedTz,
                )
                cloned.phantom_tests[0].study.scanDate = date
                  .add(1, 'month')
                  .toISOString()
                return cloned
              }),
            )
          }
        })
        const scannersWithDuplicates = flatten(sortedByMonth.map((s) => s[1]))
        return scannersWithDuplicates
      },
      metricFn: (data) => data.length,
      dateFn: numberOfScannersDateFn,
    }

    const proportionPassedTestsMetric: Metric<
      proportionPassedTestsMetric,
      proportionPassedTestsMetric_phantom_test,
      proportionPassedTestsMetricVariables
    > = {
      type: 'percent',
      shortTitle: '% Passed Tests',
      title: 'Proportion of Passed Tests',
      query: gql`
        query proportionPassedTestsMetric($lastYear: timestamp!) {
          phantom_test(where: { study: { scanDate: { _gte: $lastYear } } }) {
            study {
              scanDate
              modalityId
            }
            scanner {
              facility {
                name
              }
            }
            result
          }
        }
      `,
      variables: {
        // if it's halfway through April 2021, get everything since start of May 2020
        lastYear: moment
          .tz(timezone.cachedTz)
          .add(-1, 'year')
          .add(1, 'month')
          .startOf('month')
          .toISOString(),
      },
      groups: [
        {
          label: 'Modality',
          groupFn: (data: proportionPassedTestsMetric_phantom_test[]) =>
            groupBy(data, (t) => t.study.modalityId),
        },
        {
          label: 'Facility',
          groupFn: (data: proportionPassedTestsMetric_phantom_test[]) =>
            groupBy(data, (t) => t.scanner.facility?.name),
        },
      ],
      dataFromResult: (result) => result.data.phantom_test,
      metricFn: (data) => {
        const passed = data.filter((item) => item.result === 'pass')
        const proportion = passed.length / data.length
        return proportion
      },
      dateFn: (item) => moment(item.study.scanDate).tz(timezone.cachedTz),
    }

    const numberOfStudiesMetric: Metric<
      numberOfStudiesMetric,
      numberOfStudiesMetric_study,
      numberOfStudiesMetricVariables
    > = {
      type: 'absolute',
      shortTitle: '# Studies',
      title: 'Number of Studies',
      query: gql`
        query numberOfStudiesMetric($lastYear: timestamp!) {
          study(where: { scanDate: { _gte: $lastYear } }) {
            scanDate
            modalityId
            scanner {
              facility {
                name
              }
            }
          }
        }
      `,
      variables: {
        // if it's halfway through April 2021, get everything since start of May 2020
        lastYear: moment
          .tz(timezone.cachedTz)
          .add(-1, 'year')
          .add(1, 'month')
          .startOf('month')
          .toISOString(),
      },
      groups: [
        {
          label: 'Modality',
          groupFn: (data: numberOfStudiesMetric_study[]) =>
            groupBy(data, (t) => t.modalityId),
        },
        {
          label: 'Facility',
          groupFn: (data: numberOfStudiesMetric_study[]) =>
            groupBy(data, (t) => t.scanner.facility?.name),
        },
      ],
      dataFromResult: (result) => result.data.study,
      metricFn: (data) => data.length,
      dateFn: (item) => moment(item.scanDate).tz(timezone.cachedTz),
    }

    this.metrics = [
      numberOfTestsMetric,
      proportionPassedTestsMetric,
      numberOfStudiesMetric,
      numberOfScannersMetric,
    ]
  }

  /**
   * picks the groups from the first breakdown,
   * and for each additional one, merge all the groups present in the first one together. *
   */
  public mergeBreakdowns(breakdowns: Breakdown[]): Breakdown {
    let result: Breakdown = {
      group1: {},
      group2: {},
      both: {},
    }

    const firstBreakdown = breakdowns[0]
    if (!firstBreakdown) throw new Error('must have at least one breakdown')

    function averageMSWB(
      items: MetricSummaryWithoutBreakdown[],
    ): MetricSummaryWithoutBreakdown {
      function getYearData(): YearDataByMonth[] {
        const allItemsData = items.map((item) => item.yearDataByMonth)
        const firstItemData = allItemsData[0]
        const averagedData = firstItemData.map((oneMonthInFirstItem) => {
          const oneMonthAverage = {
            label: oneMonthInFirstItem.label,
            month: oneMonthInFirstItem.month,
            value: meanBy(
              allItemsData.map((oneYearAllMonths) =>
                oneYearAllMonths.find(
                  (oneYearOneMonth) =>
                    oneYearOneMonth.month === oneMonthInFirstItem.month,
                ),
              ),
              (item) => item.value,
            ),
          }
          return oneMonthAverage
        })
        return averagedData
      }

      const averaged: MetricSummaryWithoutBreakdown = {
        absoluteValueOfMetric: {
          thisMonth: mean(
            items.map((item) => item.absoluteValueOfMetric.thisMonth),
          ),
          lastMonth: mean(
            items.map((item) => item.absoluteValueOfMetric.lastMonth),
          ),
          monthAverageThisYear: mean(
            items.map(
              (item) => item.absoluteValueOfMetric.monthAverageThisYear,
            ),
          ),
        },
        changeInMetric: {
          vsLastMonth: mean(
            items.map((item) => item.changeInMetric.vsLastMonth),
          ),
          vsMonthAverageThisYear: mean(
            items.map((item) => item.changeInMetric.vsMonthAverageThisYear),
          ),
        },
        yearDataByMonth: getYearData(),
      }

      return averaged
    }

    for (const group1Key in firstBreakdown.group1) {
      const values: MetricSummaryWithoutBreakdown[] = breakdowns
        .map((b) => b.group1[group1Key])
        .filter((item) => !!item)
      const average: MetricSummaryWithoutBreakdown = averageMSWB(values)
      result.group1[group1Key] = average
    }

    for (const group2Key in firstBreakdown.group2) {
      const values: MetricSummaryWithoutBreakdown[] = breakdowns
        .map((b) => b.group2[group2Key])
        .filter((item) => !!item)
      const average: MetricSummaryWithoutBreakdown = averageMSWB(values)
      result.group2[group2Key] = average
    }

    for (const group1Key in firstBreakdown.both) {
      for (const group2Key in firstBreakdown.both[group1Key]) {
        const values: MetricSummaryWithoutBreakdown[] = breakdowns
          .map((b) => b.both[group1Key]?.[group2Key])
          .filter((item) => !!item)
        const average: MetricSummaryWithoutBreakdown = averageMSWB(values)
        if (!result.both[group1Key]) result.both[group1Key] = {}
        result.both[group1Key][group2Key] = average
      }
    }

    return result
  }

  public async fetchFromApiGivenSpec<QueryType, ItemType, VariablesType>(
    metric: Metric<QueryType, ItemType, VariablesType>,
  ): Promise<ItemType[]> {
    return new Promise<ItemType[]>((resolve, reject) => {
      this.apollo
        .query<QueryType>({ query: metric.query, variables: metric.variables })
        .subscribe({
          error: reject,
          next: (result) => {
            const ungrouped = metric.dataFromResult(result)
            resolve(ungrouped)
          },
        })
    })
  }

  private calculateMetricSummaryWithoutBreakdownFromSpec<
    QueryType,
    ItemType,
    VariablesType
  >(
    metric: Metric<QueryType, ItemType, VariablesType>,
    items: ItemType[],
  ): MetricSummaryWithoutBreakdown {
    const yearDataByMonth = this.getBarConfig(metric, items)
    const summaryConfig = this.getMonthYearAverage(metric, items)
    const absoluteValueOfMetric = {
      thisMonth: summaryConfig.thisMonth,
      lastMonth: summaryConfig.lastMonth,
      monthAverageThisYear: summaryConfig.yearAverage,
    }
    const changeInMetric = {
      vsLastMonth:
        absoluteValueOfMetric.thisMonth / absoluteValueOfMetric.lastMonth - 1,
      vsMonthAverageThisYear:
        absoluteValueOfMetric.thisMonth /
          absoluteValueOfMetric.monthAverageThisYear -
        1,
    }
    return {
      absoluteValueOfMetric,
      changeInMetric,
      yearDataByMonth,
    }
  }

  public calculateMetricSummaryFromSpec<QueryType, ItemType, VariablesType>(
    metric: Metric<QueryType, ItemType, VariablesType>,
    items: ItemType[],
  ): MetricSummary {
    const summaryWithoutBreakdown = this.calculateMetricSummaryWithoutBreakdownFromSpec(
      metric,
      items,
    )
    const bothGroupsDict = groupTwice(items, metric.groups)
    const group1Dict = groupOnce(items, metric.groups[0])
    const group2Dict = groupOnce(items, metric.groups[1])
    const bothGroupsBreakdown: Dictionary<Dictionary<
      MetricSummaryWithoutBreakdown
    >> = {}
    for (const group1Key in bothGroupsDict) {
      bothGroupsBreakdown[group1Key] = {}
      for (const group2Key in bothGroupsDict[group1Key]) {
        const items = bothGroupsDict[group1Key][group2Key]
        bothGroupsBreakdown[group1Key][
          group2Key
        ] = this.calculateMetricSummaryWithoutBreakdownFromSpec(metric, items)
      }
    }
    const group1Breakdown: Dictionary<MetricSummaryWithoutBreakdown> = {}
    for (const group1Key in group1Dict) {
      const items = group1Dict[group1Key]
      group1Breakdown[
        group1Key
      ] = this.calculateMetricSummaryWithoutBreakdownFromSpec(metric, items)
    }
    const group2Breakdown: Dictionary<MetricSummaryWithoutBreakdown> = {}
    for (const group2Key in group2Dict) {
      const items = group2Dict[group2Key]
      group2Breakdown[
        group2Key
      ] = this.calculateMetricSummaryWithoutBreakdownFromSpec(metric, items)
    }
    const breakdown = {
      group1: group1Breakdown,
      group2: group2Breakdown,
      both: bothGroupsBreakdown,
    }
    return { ...summaryWithoutBreakdown, breakdown }
  }

  private last12Months<ItemType>(
    items: ItemType[],
    dateFn: (item: ItemType) => moment.Moment,
  ): Dictionary<ItemType[]> {
    const groupedByMonth = groupBy(items, (item) => dateFn(item).month())
    for (let i = 0; i < 12; i++) {
      groupedByMonth[i.toString()] = groupedByMonth[i.toString()] || []
    }
    return groupedByMonth
  }

  private sortByMonth<ItemType>(groupedByMonth: Dictionary<ItemType[]>) {
    const entries = Object.entries(groupedByMonth)
    // order by month => distance from this month
    const thisMonthNumber = moment.tz(this.timezone.cachedTz).month()
    const sortedByMonth = entries.sort((a, b) => {
      let aMonthNumber = parseInt(a[0]) - thisMonthNumber
      let bMonthNumber = parseInt(b[0]) - thisMonthNumber
      if (aMonthNumber <= 0) aMonthNumber += 12
      if (bMonthNumber <= 0) bMonthNumber += 12
      return aMonthNumber - bMonthNumber
    })
    return sortedByMonth
  }

  // tested
  public getBarConfig<QueryType, ItemType, VariablesType>(
    metric: Metric<QueryType, ItemType, VariablesType>,
    items: ItemType[],
  ): ByMonthConfig {
    const metricFn = metric.metricFn
    const groupedByMonth = this.last12Months(items, metric.dateFn)
    const entries = Object.entries(groupedByMonth)
    // order by month => distance from this month
    const thisMonthNumber = moment.tz(this.timezone.cachedTz).month()
    const sortedByMonth = this.sortByMonth(groupedByMonth)
    const config = sortedByMonth.map(([monthNumber, items]) => ({
      month: parseInt(monthNumber),
      label: moment().month(monthNumber).format('MMM'),
      value: metricFn(items),
    }))
    return config
  }

  /** outputs a decimal that will have to be displayed as a percent e.g. data: 0.3 => display as 30% */
  public getMonthYearAverage<QueryType, ItemType, VariablesType>(
    metric: Metric<QueryType, ItemType, VariablesType>,
    items: ItemType[],
  ): SummaryConfig {
    const groupedByMonth = this.last12Months(items, metric.dateFn)
    const thisMonthItems =
      groupedByMonth[moment.tz(this.timezone.cachedTz).month().toString()]
    const lastMonthItems =
      groupedByMonth[
        moment.tz(this.timezone.cachedTz).add(-1, 'months').month().toString()
      ]
    const thisMonthMetric = metric.metricFn(thisMonthItems)
    const lastMonthMetric = metric.metricFn(lastMonthItems)
    const yearAverage = mean(
      Object.values(groupedByMonth)
        .map((items) => metric.metricFn(items))
        .filter((num) => !isNaN(num) && Math.abs(num) !== Infinity),
    )
    return {
      thisMonth: thisMonthMetric,
      lastMonth: lastMonthMetric,
      yearAverage,
    }
  }

  public getMatrixConfig<QueryType, ItemType, VariablesType>(
    metric: Metric<QueryType, ItemType, VariablesType>,
    items: ItemType[],
  ): MatrixConfig {
    const grouped = groupTwice(items, metric.groups)
    const data = []
    for (const firstGroupName in grouped) {
      const firstGroupData = []
      const firstGroup = grouped[firstGroupName]
      for (const secondGroupName in firstGroup) {
        const items = firstGroup[secondGroupName]
        const summary = this.getMonthYearAverage(metric, items)
        firstGroupData.push({
          group: [firstGroupName, secondGroupName],
          thisMonth: summary.thisMonth,
          lastMonth: summary.lastMonth,
        })
      }
      data.push(firstGroupData)
    }
    return { data }
  }
}
