import { Component, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { ComponentStatus, StatusService } from 'src/app/status.service'

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  title = 'status'

  constructor(private statusService: StatusService) {}

  subscriptions: Subscription[] = []
  interval: NodeJS.Timeout

  apiStatus: ComponentStatus
  serverStatus: ComponentStatus
  gatewayStatus: ComponentStatus
  statusText(s: ComponentStatus) {
    return ComponentStatus[s]
  }

  ngOnInit(): void {
    this.poll()
    this.interval = setInterval(() => this.poll(), 3000)
  }

  poll() {
    this.subscriptions.forEach((s) => s.unsubscribe())
    this.subscriptions.push(
      ...[
        this.statusService.checkAPI().subscribe({
          next: (componentStatus) => (this.apiStatus = componentStatus),
          error: (err) => console.error(err),
        }),
        this.statusService.checkServer().subscribe({
          next: (componentStatus) => (this.serverStatus = componentStatus),
          error: (err) => console.error(err),
        }),
        this.statusService.checkGateway().subscribe({
          next: (componentStatus) => (this.gatewayStatus = componentStatus),
          error: (err) => console.error(err),
        }),
      ],
    )
  }

  ngOnDestroy(): void {
    clearInterval(this.interval)
    this.subscriptions.forEach((s) => s.unsubscribe())
  }
}
