import { gql } from 'apollo-angular'

export default gql`
  mutation insert_comment_category(
    $comment_category: comment_category_insert_input!
  ) {
    insert_comment_category(objects: [$comment_category]) {
      returning {
        id
      }
    }
  }
`
