import { Component, OnInit, Input } from '@angular/core'
import { NavService } from 'src/app/core/nav.service'
import { DevError } from 'src/app/core/errors/errors'

@Component({
  selector: 'app-record-subtitle',
  templateUrl: './record-subtitle.component.html',
  styleUrls: ['./record-subtitle.component.scss'],
})
export class RecordSubtitleComponent implements OnInit {
  @Input() scanner: { id: number; name: string }

  @Input() facility: { id: number; name: string }

  @Input() room: { id: number; name: string }

  facilityName: string

  scannerName: string

  roomName: string

  constructor(private nav: NavService) {}

  ngOnInit(): void {
    this.facilityName = this.facility?.name
    this.scannerName = this.scanner?.name
    this.roomName = this.room?.name
  }

  viewFacility() {
    this.nav.navtree.facilityDetails(this.facility?.id)
  }

  viewScanner() {
    this.nav.navtree.scannerDetails(this.scanner.id)
  }
}
