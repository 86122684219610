import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { DatagridStateService } from 'src/app/core/datagrid-state.service'
import { NavService } from 'src/app/core/nav.service'
import { DatagridComponent } from 'src/app/util/datagrid.class'
import * as moment from 'moment-timezone'
import { TimezoneService } from 'src/app/timezone.service'
import { KeyVal } from '../../util/util'
import report_get_related_entitiesGql from '../reports-edit/report_get_related_entities.gql'
import { report_get_related_entities } from '../reports-edit/types/report_get_related_entities'
import query from './reports.gql'
import { reports, reports_report } from './types/reports'
import { PageHeaderAction } from '../page-header/page-header.component'

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent extends DatagridComponent {
  title = 'reports'
  actions: PageHeaderAction[] = [{ text: 'Generate Report' }]
  generating: boolean = false
  modules: string[] = ['CT', 'MR', 'MG']

  static properties = {
    facility: {
      filter: 'survey_test.scanner.facility.id',
      sort: 'survey_test.scanner.facility.name',
    },
    facility_room: {
      filter: 'survey_test.scanner.facility_room.id',
      sort: 'survey_test.scanner.facility_room.name',
    },
    scanner: {
      filter: 'survey_test.scanner.id',
      sort: 'survey_test.scanner.name',
    },
    month: 'month',
    year: 'year',
    survey: {
      filter: 'survey_test.survey_test_routine.id',
      sort: 'survey_test.survey_test_routine.name',
    },
    dateCreated: 'dateCreated',
  }
  properties = ReportsComponent.properties

  constructor(
    private apollo: Apollo,
    private nav: NavService,
    protected router: Router,
    protected dgStateService: DatagridStateService,
    protected timezoneService: TimezoneService,
  ) {
    super(dgStateService, router, timezoneService)
    this.defaultState = {
      sort: {
        by: this.properties.dateCreated,
        reverse: true,
      },
    }
  }

  items: reports_report[]
  totalItems: number

  fetchItems = () => {
    this.loading = true
    this.apollo
      .query<reports>({ query, variables: this.variables })
      .subscribe({
        next: (result: ApolloQueryResult<reports>) => {
          this.loading = false
          if (result.errors) {
            throw result.errors[0]
          }
          this.items = result.data.report
          this.totalItems = result.data.report_aggregate.aggregate.count
        },
        error: this.onError,
      })
    this.apollo
      .query<report_get_related_entities>({
        query: report_get_related_entitiesGql,
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.assembleRelatedEntityOptions(result.data)
        },
        error: this.onError,
      })
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  options: {
    facilities: KeyVal[]
    rooms: KeyVal[]
    scanners: KeyVal[]
    surveys: KeyVal[]
    months: KeyVal[]
  } = {
    facilities: [],
    rooms: [],
    scanners: [],
    surveys: [],
    months: [],
  }

  assembleRelatedEntityOptions(data: report_get_related_entities) {
    this.options = {
      facilities: data.facility.map((f) => ({
        key: f.id,
        val: f.name,
      })),
      rooms: data.facility_room.map((r) => ({
        key: r.id,
        val: r.name,
      })),
      scanners: data.scanner.map((s) => ({
        key: s.id,
        val: s.name,
      })),
      surveys: data.survey_test_routine.map((s) => ({
        key: s.id,
        val: s.name,
      })),
      months: moment.months().map((m, i) => ({
        key: i,
        val: m,
      })),
    }
  }

  view(report: reports_report) {
    this.nav.navtree.reportDetails(report.id)
  }

  actionsHandler($event: string) {
    switch ($event) {
      case 'Generate Report':
        this.generating = true
        this.loading = false
        break
      case 'Back':
        this.generating = false
        this.loading = true
        break
      default:
        break
    }
  }
}
