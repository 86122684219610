import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component } from '@angular/core'
import { Router } from '@angular/router'

import * as moment from 'moment-timezone'

import { LinkAction } from 'src/app/core/action-link/action-link.component'
import {
  DatagridManualState,
  DatagridStateService,
} from 'src/app/core/datagrid-state.service'
import { NavService } from 'src/app/core/nav.service'
import { DatagridComponent } from 'src/app/util/datagrid.class'
import { FilterType } from 'src/app/filters/filters.util'
import { TimezoneService } from 'src/app/timezone.service'
import { KeyVal } from '../../util/util'
import scanner_get_related_entitiesGql from '../scanners-edit/scanner_get_related_entities.gql'
import { scanner_get_related_entities } from '../scanners-edit/types/scanner_get_related_entities'

import query from './dashboard.gql'
import {
  dashboard,
  dashboardVariables,
  dashboard_scanner,
} from './types/dashboard'
import { PhantomTestsComponent } from '../phantom-tests/phantom-tests.component'
import { AuthService } from 'src/app/auth/auth.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends DatagridComponent {
  title = 'dashboard'

  static properties = {
    facility: { filter: 'facility.id', sort: 'facility.name' },
    facility_room: {
      filter: 'facility_room.id',
      sort: 'facility_room.name',
    },
    modality: 'modality.id',
    scanner: 'name',
  }
  properties = DashboardComponent.properties

  daysToDisplay = 7

  skipDays = 0

  invariants: dashboardVariables = {
    startDate: moment().add(-this.daysToDisplay - this.skipDays, 'days'),
  }

  pageSize = 50

  scannerLinkActions: LinkAction[] = [
    {
      name: 'phantomTestsForScanner',
      shape: 'tasks',
      text: `Tests on this scanner`,
    },
    { name: 'scannerDetails', shape: 'cog', text: 'Scanner details' },
  ]

  actionsHandler($event: string, scanner: dashboard_scanner) {
    switch ($event) {
      case 'scannerDetails':
        this.nav.navtree.scannerDetails(scanner.id)
        break
      case 'phantomTestsForScanner': {
        this.viewPhantomTests(scanner)
        break
      }
      default:
        break
    }
  }

  constructor(
    private apollo: Apollo,
    private nav: NavService,
    protected router: Router,
    protected dgStateService: DatagridStateService,
    protected timezoneService: TimezoneService,
    private auth: AuthService,
  ) {
    super(dgStateService, router, timezoneService)

    // add default if necessary
    this.defaultState = {
      sort: {
        by: this.properties.scanner,
        reverse: false,
      },
      page: {
        size: this.pageSize,
      },

      filters: [],
    }
    const defaultFacility = this.auth.user?.facility?.id
    if (defaultFacility) {
      this.defaultState.filters.push({
        type: FilterType.select,
        property: this.properties.facility.filter,
        exact: defaultFacility,
      })
    }
  }

  items: dashboard_scanner[]
  totalItems: number

  fetchItems = () => {
    this.loading = true
    this.apollo
      .query<dashboard>({ query, variables: this.variables })
      .subscribe({
        next: (result: ApolloQueryResult<dashboard>) => {
          this.loading = false
          if (result.errors) {
            throw result.errors[0]
          }
          this.items = result.data.scanner
          this.totalItems = result.data.scanner_aggregate.aggregate.count
        },
        error: this.onError,
      })
    this.apollo
      .query<scanner_get_related_entities>({
        query: scanner_get_related_entitiesGql,
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.assembleRelatedEntityOptions(result.data)
        },
        error: this.onError,
      })
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  options: {
    facilities: KeyVal[]
    rooms: KeyVal[]
    modalities: KeyVal[]
  } = {
    facilities: [],
    rooms: [],
    modalities: [],
  }

  assembleRelatedEntityOptions(data: scanner_get_related_entities) {
    this.options = {
      facilities: data.facility.map((f) => ({
        key: f.id,
        val: f.name,
      })),
      rooms: data.facility_room.map((r) => ({
        key: r.id,
        val: r.name,
      })),
      modalities: data.modality.map((m) => ({
        key: m.id,
        val: m.id,
      })),
    }
  }

  mostRecentSurveyTestDate(
    scanner: dashboard_scanner,
  ): {
    date?: string
    id?: number
  } {
    try {
      return {
        date: scanner.survey_tests[0].dateCreated,
        id: scanner.survey_tests[0].id,
      }
    } catch (e) {
      return {}
    }
  }

  viewPhantomTests(scanner: dashboard_scanner) {
    const state: DatagridManualState = {
      filters: [
        {
          type: FilterType.select,
          property: PhantomTestsComponent.properties.scanner.filter,
          exact: scanner.id,
        },
      ],
    }
    this.nav.navtree.phantomTests(state)
  }

  viewSurveyTest(id?: number) {
    if (typeof id === 'number') {
      this.nav.navtree.surveyTestDetails(id)
    }
  }

  view(item: dashboard_scanner) {
    this.viewPhantomTests(item)
  }
}
