
    <clr-dg-filter [clrDgFilter]="registered" [(clrDgFilterOpen)]="open">
      <input
        class="datagrid-numeric-filter-input"
        #input_low
        type="number"
        name="low"
        [(ngModel)]="low"
        [placeholder]="commonStrings.keys.minValue"
        [attr.aria-label]="commonStrings.keys.minValue"
      />
      <span class="datagrid-filter-input-spacer"></span>
      <input
        class="datagrid-numeric-filter-input"
        #input_high
        type="number"
        name="high"
        [(ngModel)]="high"
        [placeholder]="commonStrings.keys.maxValue"
        [attr.aria-label]="commonStrings.keys.maxValue"
      />
    </clr-dg-filter>
  