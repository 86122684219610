import { Pipe, PipeTransform } from '@angular/core'
import { DatePipe } from '@angular/common'
import * as moment from 'moment-timezone'
import { TimezoneService } from '../timezone.service'

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private timezoneService: TimezoneService,
  ) {}

  static dateString = 'D MMM YYYY - HH:mm'
  static fileNameDateString = 'D MMM YYYY HHmm'

  transform(
    value: string | undefined,
    ...args: Array<string | undefined>
  ): string {
    if (!value) {
      return ''
    }
    try {
      return moment
        .parseZone(value)
        .tz(this.timezoneService.cachedTz)
        .format(args[0] || CustomDatePipe.dateString)
    } catch (e) {
      console.error(e)
      return ''
    }
  }
}
