import { gql } from 'apollo-angular'

export default gql`
  query settings(
    $distinct_on: [setting_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [setting_order_by!]
    $where: setting_bool_exp
  ) {
    setting(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      key
      value
      json
    }
    setting_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
