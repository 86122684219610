import { gql } from 'apollo-angular'

export default gql`
  mutation change_pw(
    $confirmPassword: String!
    $newPassword: String!
    $oldPassword: String!
    $userId: Int!
  ) {
    changePassword(
      data: {
        confirmPassword: $confirmPassword
        newPassword: $newPassword
        oldPassword: $oldPassword
        userId: $userId
      }
    )
  }
`
