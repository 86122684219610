
    <clr-dg-filter [clrDgFilter]="registered" [(clrDgFilterOpen)]="open">
      <input
        #input
        type="text"
        name="search"
        [(ngModel)]="value"
        class="clr-input"
        [attr.aria-label]="commonStrings.keys.filterItems"
      />
    </clr-dg-filter>
  