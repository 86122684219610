<app-reports-edit
  *ngIf="editing"
  [report]="report"
  (saveEvent)="onSave($event)"
></app-reports-edit>
<ng-container *ngIf="!editing">
  <div [clrLoading]="loading">
    <div *ngIf="report" class="">
      <!-- Header and actions -->
      <div
        class="clr-row"
        appPageHeader
        [title]="title"
        [actions]="actions"
        (clicks)="actionsHandler($event)"
      ></div>
      <!-- Buttons -->
      <!-- <div class="clr-row">
        <div class="clr-col-6">
          <h3>Report Details</h3>
        </div>
        <div id="actions" class="clr-col-6">
          <button class="btn btn-danger-outline" (click)="delete()">Delete Report</button>
          <button class="btn btn-outline" (click)="editView()">Edit Report</button>
        </div>
      </div> -->
      <app-record-subtitle
        [facility]="facility"
        [room]="room"
        [scanner]="scanner"
      ></app-record-subtitle>

      <!-- Main Body -->
      <div class="clr-row">
        <div class="col-md-4">
          <table *ngIf="report" class="table">
            <tbody>
              <tr>
                <td>Date Generated</td>
                <td>{{ date | customDate }}</td>
              </tr>
              <tr>
                <td>Survey</td>
                <td>{{ surveyTestRoutineName }}</td>
              </tr>
              <tr>
                <td>Month</td>
                <td>{{ month }}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>{{ year }}</td>
              </tr>
            </tbody>
          </table>
          <div class="downloadLink">
            <a [href]="getReportFile()" class="ref-link" download
              >Download Report</a
            >
          </div>
        </div>
        <div class="clr-col-md-8" *ngIf="report">
          <app-pdf-viewer [src]="getReportFile()"></app-pdf-viewer>
        </div>
      </div>
    </div>
  </div>
</ng-container>
