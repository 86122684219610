<app-generate-report
  *ngIf="generating"
  (saveEvent)="actionsHandler($event)"
></app-generate-report>

<ng-container *ngIf="!generating">
  <div
    class="clr-row"
    appPageHeader
    [title]="title"
    [actions]="actions"
    (clicks)="actionsHandler($event)"
  ></div>
  <clr-datagrid
    class="datagrid-striped"
    #datagrid
    (clrDgRefresh)="refreshGrid($event, fetchItems)"
    [clrDgLoading]="loading"
  >
    <clr-dg-placeholder>Nothing to Show</clr-dg-placeholder>

    <!-- Row Def -->
    <clr-dg-row *ngFor="let item of items">
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.survey_test?.scanner?.facility?.name
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.survey_test?.scanner?.facility_room?.name
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.survey_test?.scanner?.name
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.month | toMonthName
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.year
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.survey_test?.survey_test_routine?.name
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.dateCreated | customDate
      }}</clr-dg-cell>
    </clr-dg-row>

    <!-- Facility Column -->
    <clr-dg-column [clrDgField]="properties.facility.sort">
      <ng-container>Facility</ng-container>
      <clr-dg-filter>
        <app-select-filter
          [exact]="filterState[properties.facility.filter]?.exact"
          [property]="properties.facility.filter"
          [options]="options.facilities"
        ></app-select-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <!-- Room Column -->
    <clr-dg-column [clrDgField]="properties.facility_room.sort">
      <ng-container>Room</ng-container>
      <clr-dg-filter>
        <app-select-filter
          [exact]="filterState[properties.facility_room.filter]?.exact"
          [property]="properties.facility_room.filter"
          [options]="options.rooms"
        ></app-select-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <!-- Scanner Column -->
    <clr-dg-column [clrDgField]="properties.scanner.sort">
      <ng-container>Scanner</ng-container>
      <clr-dg-filter>
        <app-select-filter
          [exact]="filterState[properties.scanner.filter]?.exact"
          [property]="properties.scanner.filter"
          [options]="options.scanners"
        ></app-select-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <!-- Month Column -->
    <clr-dg-column [clrDgField]="properties.month">
      <ng-container>Month</ng-container>
      <clr-dg-filter>
        <app-select-filter
          [exact]="filterState[properties.month]?.exact"
          [property]="properties.month"
          [options]="options.months"
        ></app-select-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <!-- Year Column -->
    <clr-dg-column>
      <ng-container>Year</ng-container>
      <clr-dg-filter>
        <app-exact-number-filter
          [property]="properties.year"
          [exact]="filterState[properties.year]?.exact"
        ></app-exact-number-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <!-- Survey Column -->
    <clr-dg-column [clrDgField]="properties.survey.sort">
      <ng-container>Survey</ng-container>
      <clr-dg-filter>
        <app-select-filter
          [exact]="filterState[properties.survey.filter]?.exact"
          [property]="properties.survey.filter"
          [options]="options.surveys"
        ></app-select-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <!-- Date Generated Column -->
    <clr-dg-column [clrDgField]="properties.dateCreated">
      <ng-container>Date Generated</ng-container>
      <clr-dg-filter>
        <app-date-filter
          [property]="properties.dateCreated"
          [earliest]="filterState[properties.dateCreated]?.earliest"
          [latest]="filterState[properties.dateCreated]?.latest"
        ></app-date-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <!-- Footer -->
    <clr-dg-footer>
      {{ pagination.firstItem + 1 | number }} -
      {{ pagination.lastItem + 1 | number }} of {{ totalItems | number }} items
      <clr-dg-pagination
        #pagination
        [clrDgTotalItems]="totalItems"
      ></clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</ng-container>
