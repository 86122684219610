import {
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core'

import { colors } from '../../metrics.component'

@Component({
  selector: 'app-metric-cell',
  templateUrl: './metric-cell.component.html',
  styleUrls: ['./metric-cell.component.scss'],
})
export class MetricCellComponent implements OnInit {
  @Input() val: number
  highlight = false

  onMouseEnter($event: MouseEvent) {
    this.highlight = true
  }

  onMouseLeave($event: MouseEvent) {
    this.highlight = false
  }

  get background(): string | undefined {
    if (this.val < -0.5) return colors.fail
    if (this.val < 0) return colors.problem
    if (this.val > 0) return colors.pass
    return '#ffffff'
  }

  constructor() {}

  ngOnInit(): void {}
}
