/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum UserRole {
  admin = 'admin',
  physicist = 'physicist',
  support = 'support',
  tech = 'tech',
}

/**
 * unique or primary key constraints on table "answered_phantom_test_question"
 */
export enum answered_phantom_test_question_constraint {
  PK_b8e70631b5532a7f2f3f2388b7a = 'PK_b8e70631b5532a7f2f3f2388b7a',
}

/**
 * update columns of table "answered_phantom_test_question"
 */
export enum answered_phantom_test_question_update_column {
  answer = 'answer',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  name = 'name',
  recordId = 'recordId',
  result = 'result',
  text = 'text',
}

/**
 * unique or primary key constraints on table "answered_survey_test_question"
 */
export enum answered_survey_test_question_constraint {
  PK_e8989e327aac665d1bdcd17dca2 = 'PK_e8989e327aac665d1bdcd17dca2',
}

/**
 * update columns of table "answered_survey_test_question"
 */
export enum answered_survey_test_question_update_column {
  answer = 'answer',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  recordId = 'recordId',
  result = 'result',
  section = 'section',
  text = 'text',
}

/**
 * unique or primary key constraints on table "audit_log"
 */
export enum audit_log_constraint {
  PK_07fefa57f7f5ab8fc3f52b3ed0b = 'PK_07fefa57f7f5ab8fc3f52b3ed0b',
}

/**
 * update columns of table "audit_log"
 */
export enum audit_log_update_column {
  action = 'action',
  data = 'data',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  ip = 'ip',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "comment_category"
 */
export enum comment_category_constraint {
  PK_8909ebfe8f863a39a9b650a5c51 = 'PK_8909ebfe8f863a39a9b650a5c51',
}

/**
 * select columns of table "comment_category"
 */
export enum comment_category_select_column {
  color = 'color',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  name = 'name',
}

/**
 * update columns of table "comment_category"
 */
export enum comment_category_update_column {
  color = 'color',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  name = 'name',
}

/**
 * unique or primary key constraints on table "comment"
 */
export enum comment_constraint {
  PK_0b0e4bbc8415ec426f87f3a88e2 = 'PK_0b0e4bbc8415ec426f87f3a88e2',
}

/**
 * unique or primary key constraints on table "comment_file"
 */
export enum comment_file_constraint {
  PK_82d253ec0eee2847f7aed08cbf4 = 'PK_82d253ec0eee2847f7aed08cbf4',
  REL_a170b7a92a936c548f53cad5ca = 'REL_a170b7a92a936c548f53cad5ca',
}

/**
 * update columns of table "comment_file"
 */
export enum comment_file_update_column {
  commentId = 'commentId',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  fileId = 'fileId',
  id = 'id',
}

/**
 * select columns of table "comment"
 */
export enum comment_select_column {
  categoryId = 'categoryId',
  content = 'content',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  missedPhantomTestId = 'missedPhantomTestId',
  phantomTestId = 'phantomTestId',
  scannerId = 'scannerId',
  subject = 'subject',
  surveyTestId = 'surveyTestId',
  userId = 'userId',
}

/**
 * update columns of table "comment"
 */
export enum comment_update_column {
  categoryId = 'categoryId',
  content = 'content',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  missedPhantomTestId = 'missedPhantomTestId',
  phantomTestId = 'phantomTestId',
  scannerId = 'scannerId',
  subject = 'subject',
  surveyTestId = 'surveyTestId',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "facility"
 */
export enum facility_constraint {
  PK_07c6c82781d105a680b5c265be6 = 'PK_07c6c82781d105a680b5c265be6',
}

/**
 * unique or primary key constraints on table "facility_room"
 */
export enum facility_room_constraint {
  PK_d92667bcb980306fc4eb830cd07 = 'PK_d92667bcb980306fc4eb830cd07',
}

/**
 * update columns of table "facility_room"
 */
export enum facility_room_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  facilityId = 'facilityId',
  id = 'id',
  name = 'name',
}

/**
 * select columns of table "facility"
 */
export enum facility_select_column {
  address = 'address',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  facilityId = 'facilityId',
  id = 'id',
  name = 'name',
  phone = 'phone',
  supportContacts = 'supportContacts',
}

/**
 * update columns of table "facility"
 */
export enum facility_update_column {
  address = 'address',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  facilityId = 'facilityId',
  id = 'id',
  name = 'name',
  phone = 'phone',
  supportContacts = 'supportContacts',
}

/**
 * unique or primary key constraints on table "missed_phantom_test"
 */
export enum missed_phantom_test_constraint {
  PK_9628d5010978b2ab5de9ec1ec92 = 'PK_9628d5010978b2ab5de9ec1ec92',
}

/**
 * select columns of table "missed_phantom_test"
 */
export enum missed_phantom_test_select_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  result = 'result',
  scannerId = 'scannerId',
  scheduledDate = 'scheduledDate',
}

/**
 * update columns of table "missed_phantom_test"
 */
export enum missed_phantom_test_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  result = 'result',
  scannerId = 'scannerId',
  scheduledDate = 'scheduledDate',
}

/**
 * unique or primary key constraints on table "modality"
 */
export enum modality_constraint {
  PK_ba5430e7e9e6035b0d13a5cc564 = 'PK_ba5430e7e9e6035b0d13a5cc564',
}

/**
 * update columns of table "modality"
 */
export enum modality_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
}

/**
 * column ordering options
 */
export enum order_by {
  asc = 'asc',
  asc_nulls_first = 'asc_nulls_first',
  asc_nulls_last = 'asc_nulls_last',
  desc = 'desc',
  desc_nulls_first = 'desc_nulls_first',
  desc_nulls_last = 'desc_nulls_last',
}

/**
 * unique or primary key constraints on table "phantom_test"
 */
export enum phantom_test_constraint {
  PK_044aa10b60421454a671fcb32e1 = 'PK_044aa10b60421454a671fcb32e1',
}

/**
 * unique or primary key constraints on table "phantom_test_image"
 */
export enum phantom_test_image_constraint {
  PK_b002379e1d8cbcb77425ded0fa2 = 'PK_b002379e1d8cbcb77425ded0fa2',
  REL_f31813ea52d4adc148e4ac21f6 = 'REL_f31813ea52d4adc148e4ac21f6',
}

/**
 * update columns of table "phantom_test_image"
 */
export enum phantom_test_image_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  fileId = 'fileId',
  id = 'id',
  label = 'label',
  phantomTestId = 'phantomTestId',
}

/**
 * unique or primary key constraints on table "phantom_test_question"
 */
export enum phantom_test_question_constraint {
  PK_dd3e884a7fc7795729068ba253e = 'PK_dd3e884a7fc7795729068ba253e',
}

/**
 * update columns of table "phantom_test_question"
 */
export enum phantom_test_question_update_column {
  assignProblemInsteadOfFail = 'assignProblemInsteadOfFail',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  defaultAnswer = 'defaultAnswer',
  exact = 'exact',
  expected = 'expected',
  id = 'id',
  max = 'max',
  min = 'min',
  name = 'name',
  options = 'options',
  order = 'order',
  text = 'text',
  type = 'type',
}

/**
 * unique or primary key constraints on table "phantom_test_routine"
 */
export enum phantom_test_routine_constraint {
  PK_c11cf38b495d244ab37757b1a02 = 'PK_c11cf38b495d244ab37757b1a02',
}

/**
 * unique or primary key constraints on table "phantom_test_routine_questions_phantom_test_question"
 */
export enum phantom_test_routine_questions_phantom_test_question_constraint {
  PK_ebe8a109f9791efa5094b1f364d = 'PK_ebe8a109f9791efa5094b1f364d',
}

/**
 * update columns of table "phantom_test_routine_questions_phantom_test_question"
 */
export enum phantom_test_routine_questions_phantom_test_question_update_column {
  phantomTestQuestionId = 'phantomTestQuestionId',
  phantomTestRoutineId = 'phantomTestRoutineId',
}

/**
 * unique or primary key constraints on table "phantom_test_routine_schedules_schedule"
 */
export enum phantom_test_routine_schedules_schedule_constraint {
  PK_ad9c6b477c191f8780363a48ad9 = 'PK_ad9c6b477c191f8780363a48ad9',
}

/**
 * update columns of table "phantom_test_routine_schedules_schedule"
 */
export enum phantom_test_routine_schedules_schedule_update_column {
  phantomTestRoutineId = 'phantomTestRoutineId',
  scheduleId = 'scheduleId',
}

/**
 * update columns of table "phantom_test_routine"
 */
export enum phantom_test_routine_update_column {
  customValues = 'customValues',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  method = 'method',
  modalityId = 'modalityId',
  name = 'name',
  phantom = 'phantom',
}

/**
 * select columns of table "phantom_test"
 */
export enum phantom_test_select_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  evaluationItems = 'evaluationItems',
  id = 'id',
  rawOutput = 'rawOutput',
  result = 'result',
  routineId = 'routineId',
  scannerId = 'scannerId',
  status = 'status',
  studyId = 'studyId',
  testerId = 'testerId',
}

/**
 * update columns of table "phantom_test"
 */
export enum phantom_test_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  evaluationItems = 'evaluationItems',
  id = 'id',
  rawOutput = 'rawOutput',
  result = 'result',
  routineId = 'routineId',
  scannerId = 'scannerId',
  status = 'status',
  studyId = 'studyId',
  testerId = 'testerId',
}

/**
 * select columns of table "problem"
 */
export enum problem_select_column {
  commentsCount = 'commentsCount',
  facilityName = 'facilityName',
  facilityRoomName = 'facilityRoomName',
  id = 'id',
  modalityName = 'modalityName',
  result = 'result',
  scannerId = 'scannerId',
  scannerName = 'scannerName',
  tableName = 'tableName',
  testDate = 'testDate',
}

/**
 * unique or primary key constraints on table "report"
 */
export enum report_constraint {
  PK_99e4d0bea58cba73c57f935a546 = 'PK_99e4d0bea58cba73c57f935a546',
  REL_745a0f7c76266d259bcc4ec74e = 'REL_745a0f7c76266d259bcc4ec74e',
}

/**
 * select columns of table "report"
 */
export enum report_select_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  fileId = 'fileId',
  id = 'id',
  month = 'month',
  status = 'status',
  surveyTestId = 'surveyTestId',
  year = 'year',
}

/**
 * update columns of table "report"
 */
export enum report_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  fileId = 'fileId',
  id = 'id',
  month = 'month',
  status = 'status',
  surveyTestId = 'surveyTestId',
  year = 'year',
}

/**
 * unique or primary key constraints on table "scanner"
 */
export enum scanner_constraint {
  PK_5d2dd540d01665a24b2c136bd0e = 'PK_5d2dd540d01665a24b2c136bd0e',
}

/**
 * unique or primary key constraints on table "scanner_manufacturer"
 */
export enum scanner_manufacturer_constraint {
  IDX_2fe9cf2587681c6495c6294bf9 = 'IDX_2fe9cf2587681c6495c6294bf9',
  PK_4b04b39c0a489917eaeb50a8691 = 'PK_4b04b39c0a489917eaeb50a8691',
  UQ_2fe9cf2587681c6495c6294bf93 = 'UQ_2fe9cf2587681c6495c6294bf93',
}

/**
 * update columns of table "scanner_manufacturer"
 */
export enum scanner_manufacturer_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  name = 'name',
}

/**
 * unique or primary key constraints on table "scanner_model"
 */
export enum scanner_model_constraint {
  IDX_1b753f0128defb01be9b0a5b83 = 'IDX_1b753f0128defb01be9b0a5b83',
  PK_11ad528cd1bf0da1d075b2cd941 = 'PK_11ad528cd1bf0da1d075b2cd941',
  UQ_1b753f0128defb01be9b0a5b83f = 'UQ_1b753f0128defb01be9b0a5b83f',
}

/**
 * update columns of table "scanner_model"
 */
export enum scanner_model_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  name = 'name',
}

/**
 * unique or primary key constraints on table "scanner_phantom_test_routines_phantom_test_routine"
 */
export enum scanner_phantom_test_routines_phantom_test_routine_constraint {
  PK_a550622063a7e32d66010c2f39a = 'PK_a550622063a7e32d66010c2f39a',
}

/**
 * update columns of table "scanner_phantom_test_routines_phantom_test_routine"
 */
export enum scanner_phantom_test_routines_phantom_test_routine_update_column {
  phantomTestRoutineId = 'phantomTestRoutineId',
  scannerId = 'scannerId',
}

/**
 * unique or primary key constraints on table "scanner_schedules_schedule"
 */
export enum scanner_schedules_schedule_constraint {
  PK_6eaab9769ff3f95856da2e3ceb0 = 'PK_6eaab9769ff3f95856da2e3ceb0',
}

/**
 * update columns of table "scanner_schedules_schedule"
 */
export enum scanner_schedules_schedule_update_column {
  scannerId = 'scannerId',
  scheduleId = 'scheduleId',
}

/**
 * select columns of table "scanner"
 */
export enum scanner_select_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  deviceType = 'deviceType',
  extra = 'extra',
  facilityId = 'facilityId',
  facilityRoomId = 'facilityRoomId',
  id = 'id',
  institutionNameTag = 'institutionNameTag',
  modalityId = 'modalityId',
  name = 'name',
  scannerManufacturerId = 'scannerManufacturerId',
  scannerModelId = 'scannerModelId',
  schedule = 'schedule',
  scheduleGrace = 'scheduleGrace',
  stationNameTag = 'stationNameTag',
  supportContacts = 'supportContacts',
}

/**
 * update columns of table "scanner"
 */
export enum scanner_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  deviceType = 'deviceType',
  extra = 'extra',
  facilityId = 'facilityId',
  facilityRoomId = 'facilityRoomId',
  id = 'id',
  institutionNameTag = 'institutionNameTag',
  modalityId = 'modalityId',
  name = 'name',
  scannerManufacturerId = 'scannerManufacturerId',
  scannerModelId = 'scannerModelId',
  schedule = 'schedule',
  scheduleGrace = 'scheduleGrace',
  stationNameTag = 'stationNameTag',
  supportContacts = 'supportContacts',
}

/**
 * unique or primary key constraints on table "schedule"
 */
export enum schedule_constraint {
  PK_1c05e42aec7371641193e180046 = 'PK_1c05e42aec7371641193e180046',
}

/**
 * unique or primary key constraints on table "schedule_phantom_test_routines_phantom_test_routine"
 */
export enum schedule_phantom_test_routines_phantom_test_routine_constraint {
  PK_df9a4125befdf4a2e255df13675 = 'PK_df9a4125befdf4a2e255df13675',
}

/**
 * update columns of table "schedule_phantom_test_routines_phantom_test_routine"
 */
export enum schedule_phantom_test_routines_phantom_test_routine_update_column {
  phantomTestRoutineId = 'phantomTestRoutineId',
  scheduleId = 'scheduleId',
}

/**
 * unique or primary key constraints on table "schedule_scanners_scanner"
 */
export enum schedule_scanners_scanner_constraint {
  PK_63421df39b775e7432951f9bd70 = 'PK_63421df39b775e7432951f9bd70',
}

/**
 * update columns of table "schedule_scanners_scanner"
 */
export enum schedule_scanners_scanner_update_column {
  scannerId = 'scannerId',
  scheduleId = 'scheduleId',
}

/**
 * unique or primary key constraints on table "schedule_survey_test_routines_survey_test_routine"
 */
export enum schedule_survey_test_routines_survey_test_routine_constraint {
  PK_f6c8f333273079ed85540745956 = 'PK_f6c8f333273079ed85540745956',
}

/**
 * update columns of table "schedule_survey_test_routines_survey_test_routine"
 */
export enum schedule_survey_test_routines_survey_test_routine_update_column {
  scheduleId = 'scheduleId',
  surveyTestRoutineId = 'surveyTestRoutineId',
}

/**
 * update columns of table "schedule"
 */
export enum schedule_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  graceAmount = 'graceAmount',
  graceType = 'graceType',
  id = 'id',
  interval = 'interval',
  priorToAmount = 'priorToAmount',
  priorToType = 'priorToType',
  reminderAmount = 'reminderAmount',
  reminderType = 'reminderType',
  tasks = 'tasks',
  title = 'title',
}

/**
 * select columns of table "setting"
 */
export enum setting_select_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  json = 'json',
  key = 'key',
  value = 'value',
}

/**
 * unique or primary key constraints on table "stored_file"
 */
export enum stored_file_constraint {
  PK_e140967af84027b493a3ff04fbb = 'PK_e140967af84027b493a3ff04fbb',
}

/**
 * update columns of table "stored_file"
 */
export enum stored_file_update_column {
  common = 'common',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  key = 'key',
}

/**
 * unique or primary key constraints on table "study"
 */
export enum study_constraint {
  PK_ae14dbea0172b8521edb2ce4597 = 'PK_ae14dbea0172b8521edb2ce4597',
}

/**
 * unique or primary key constraints on table "study_image"
 */
export enum study_image_constraint {
  PK_8957be18c454b7b412335e3df48 = 'PK_8957be18c454b7b412335e3df48',
  REL_63e0a9ecde8cca7340ccd2f574 = 'REL_63e0a9ecde8cca7340ccd2f574',
  REL_9242e102f8cd1c895257cddc73 = 'REL_9242e102f8cd1c895257cddc73',
}

/**
 * update columns of table "study_image"
 */
export enum study_image_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  dcmFileId = 'dcmFileId',
  extra = 'extra',
  id = 'id',
  imageUID = 'imageUID',
  label = 'label',
  renderedFileId = 'renderedFileId',
  scanDate = 'scanDate',
  studyId = 'studyId',
}

/**
 * select columns of table "study"
 */
export enum study_select_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  extra = 'extra',
  filmedBy = 'filmedBy',
  id = 'id',
  modalityId = 'modalityId',
  scanDate = 'scanDate',
  scannerId = 'scannerId',
  uid = 'uid',
}

/**
 * update columns of table "study"
 */
export enum study_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  extra = 'extra',
  filmedBy = 'filmedBy',
  id = 'id',
  modalityId = 'modalityId',
  scanDate = 'scanDate',
  scannerId = 'scannerId',
  uid = 'uid',
}

/**
 * unique or primary key constraints on table "survey_test"
 */
export enum survey_test_constraint {
  PK_bfcd1b6dc3df4d6895f56a92c77 = 'PK_bfcd1b6dc3df4d6895f56a92c77',
}

/**
 * unique or primary key constraints on table "survey_test_question"
 */
export enum survey_test_question_constraint {
  PK_8b7a644b398de774391c5747946 = 'PK_8b7a644b398de774391c5747946',
}

/**
 * update columns of table "survey_test_question"
 */
export enum survey_test_question_update_column {
  assignProblemInsteadOfFail = 'assignProblemInsteadOfFail',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  exact = 'exact',
  expected = 'expected',
  id = 'id',
  max = 'max',
  min = 'min',
  options = 'options',
  order = 'order',
  section = 'section',
  text = 'text',
  type = 'type',
}

/**
 * unique or primary key constraints on table "survey_test_routine"
 */
export enum survey_test_routine_constraint {
  PK_3ded2ae421405040cfd01113e74 = 'PK_3ded2ae421405040cfd01113e74',
}

/**
 * unique or primary key constraints on table "survey_test_routine_questions_survey_test_question"
 */
export enum survey_test_routine_questions_survey_test_question_constraint {
  PK_1b991d74a883027e8909b09d534 = 'PK_1b991d74a883027e8909b09d534',
}

/**
 * update columns of table "survey_test_routine_questions_survey_test_question"
 */
export enum survey_test_routine_questions_survey_test_question_update_column {
  surveyTestQuestionId = 'surveyTestQuestionId',
  surveyTestRoutineId = 'surveyTestRoutineId',
}

/**
 * unique or primary key constraints on table "survey_test_routine_schedules_schedule"
 */
export enum survey_test_routine_schedules_schedule_constraint {
  PK_d1d6e09648ba98572e90d560b1f = 'PK_d1d6e09648ba98572e90d560b1f',
}

/**
 * update columns of table "survey_test_routine_schedules_schedule"
 */
export enum survey_test_routine_schedules_schedule_update_column {
  scheduleId = 'scheduleId',
  surveyTestRoutineId = 'surveyTestRoutineId',
}

/**
 * update columns of table "survey_test_routine"
 */
export enum survey_test_routine_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  modalityId = 'modalityId',
  name = 'name',
}

/**
 * select columns of table "survey_test"
 */
export enum survey_test_select_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  result = 'result',
  routineId = 'routineId',
  scannerId = 'scannerId',
  testerId = 'testerId',
}

/**
 * update columns of table "survey_test"
 */
export enum survey_test_update_column {
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  id = 'id',
  result = 'result',
  routineId = 'routineId',
  scannerId = 'scannerId',
  testerId = 'testerId',
}

/**
 * unique or primary key constraints on table "user"
 */
export enum user_constraint {
  PK_cace4a159ff9f2512dd42373760 = 'PK_cace4a159ff9f2512dd42373760',
}

/**
 * select columns of table "user"
 */
export enum user_select_column {
  accountType = 'accountType',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  email = 'email',
  externalId = 'externalId',
  facilityId = 'facilityId',
  firstName = 'firstName',
  id = 'id',
  lastName = 'lastName',
  password = 'password',
  phone = 'phone',
  restrictedIP = 'restrictedIP',
  role = 'role',
  status = 'status',
  username = 'username',
}

/**
 * update columns of table "user"
 */
export enum user_update_column {
  accountType = 'accountType',
  dateCreated = 'dateCreated',
  dateUpdated = 'dateUpdated',
  email = 'email',
  externalId = 'externalId',
  facilityId = 'facilityId',
  firstName = 'firstName',
  id = 'id',
  lastName = 'lastName',
  password = 'password',
  phone = 'phone',
  restrictedIP = 'restrictedIP',
  role = 'role',
  status = 'status',
  username = 'username',
}

/**
 * expression to compare columns of type Boolean. All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null
  _gt?: boolean | null
  _gte?: boolean | null
  _in?: boolean[] | null
  _is_null?: boolean | null
  _lt?: boolean | null
  _lte?: boolean | null
  _neq?: boolean | null
  _nin?: boolean[] | null
}

export interface GenerateReportInput {
  clientTz: string
  month: any
  periodLength: number
  scannerId: number
  selectedRoutine: string
  surveyTestId: number
}

/**
 * expression to compare columns of type Int. All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null
  _gt?: number | null
  _gte?: number | null
  _in?: number[] | null
  _is_null?: boolean | null
  _lt?: number | null
  _lte?: number | null
  _neq?: number | null
  _nin?: number[] | null
}

export interface RegisterInput {
  email?: string | null
  facilityId?: number | null
  firstName: string
  lastName: string
  phone?: string | null
  restrictedIP?: boolean | null
  role: UserRole
  username: string
}

/**
 * expression to compare columns of type String. All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null
  _gt?: string | null
  _gte?: string | null
  _ilike?: string | null
  _in?: string[] | null
  _is_null?: boolean | null
  _like?: string | null
  _lt?: string | null
  _lte?: string | null
  _neq?: string | null
  _nilike?: string | null
  _nin?: string[] | null
  _nlike?: string | null
  _nsimilar?: string | null
  _similar?: string | null
}

/**
 * order by aggregate values of table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_aggregate_order_by {
  avg?: answered_phantom_test_question_avg_order_by | null
  count?: order_by | null
  max?: answered_phantom_test_question_max_order_by | null
  min?: answered_phantom_test_question_min_order_by | null
  stddev?: answered_phantom_test_question_stddev_order_by | null
  stddev_pop?: answered_phantom_test_question_stddev_pop_order_by | null
  stddev_samp?: answered_phantom_test_question_stddev_samp_order_by | null
  sum?: answered_phantom_test_question_sum_order_by | null
  var_pop?: answered_phantom_test_question_var_pop_order_by | null
  var_samp?: answered_phantom_test_question_var_samp_order_by | null
  variance?: answered_phantom_test_question_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_arr_rel_insert_input {
  data: answered_phantom_test_question_insert_input[]
  on_conflict?: answered_phantom_test_question_on_conflict | null
}

/**
 * order by avg() on columns of table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_avg_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "answered_phantom_test_question". All fields are combined with a logical 'AND'.
 */
export interface answered_phantom_test_question_bool_exp {
  _and?: (answered_phantom_test_question_bool_exp | null)[] | null
  _not?: answered_phantom_test_question_bool_exp | null
  _or?: (answered_phantom_test_question_bool_exp | null)[] | null
  answer?: String_comparison_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: Int_comparison_exp | null
  name?: String_comparison_exp | null
  phantom_test?: phantom_test_bool_exp | null
  recordId?: Int_comparison_exp | null
  result?: String_comparison_exp | null
  text?: String_comparison_exp | null
}

/**
 * input type for inserting data into table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_insert_input {
  answer?: string | null
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  name?: string | null
  phantom_test?: phantom_test_obj_rel_insert_input | null
  recordId?: number | null
  result?: string | null
  text?: string | null
}

/**
 * order by max() on columns of table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_max_order_by {
  answer?: order_by | null
  id?: order_by | null
  name?: order_by | null
  recordId?: order_by | null
  result?: order_by | null
  text?: order_by | null
}

/**
 * order by min() on columns of table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_min_order_by {
  answer?: order_by | null
  id?: order_by | null
  name?: order_by | null
  recordId?: order_by | null
  result?: order_by | null
  text?: order_by | null
}

/**
 * on conflict condition type for table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_on_conflict {
  constraint: answered_phantom_test_question_constraint
  update_columns: answered_phantom_test_question_update_column[]
  where?: answered_phantom_test_question_bool_exp | null
}

/**
 * order by stddev() on columns of table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_stddev_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_stddev_pop_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_stddev_samp_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by sum() on columns of table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_sum_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by var_pop() on columns of table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_var_pop_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by var_samp() on columns of table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_var_samp_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by variance() on columns of table "answered_phantom_test_question"
 */
export interface answered_phantom_test_question_variance_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by aggregate values of table "answered_survey_test_question"
 */
export interface answered_survey_test_question_aggregate_order_by {
  avg?: answered_survey_test_question_avg_order_by | null
  count?: order_by | null
  max?: answered_survey_test_question_max_order_by | null
  min?: answered_survey_test_question_min_order_by | null
  stddev?: answered_survey_test_question_stddev_order_by | null
  stddev_pop?: answered_survey_test_question_stddev_pop_order_by | null
  stddev_samp?: answered_survey_test_question_stddev_samp_order_by | null
  sum?: answered_survey_test_question_sum_order_by | null
  var_pop?: answered_survey_test_question_var_pop_order_by | null
  var_samp?: answered_survey_test_question_var_samp_order_by | null
  variance?: answered_survey_test_question_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "answered_survey_test_question"
 */
export interface answered_survey_test_question_arr_rel_insert_input {
  data: answered_survey_test_question_insert_input[]
  on_conflict?: answered_survey_test_question_on_conflict | null
}

/**
 * order by avg() on columns of table "answered_survey_test_question"
 */
export interface answered_survey_test_question_avg_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "answered_survey_test_question". All fields are combined with a logical 'AND'.
 */
export interface answered_survey_test_question_bool_exp {
  _and?: (answered_survey_test_question_bool_exp | null)[] | null
  _not?: answered_survey_test_question_bool_exp | null
  _or?: (answered_survey_test_question_bool_exp | null)[] | null
  answer?: String_comparison_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: Int_comparison_exp | null
  recordId?: Int_comparison_exp | null
  result?: String_comparison_exp | null
  section?: String_comparison_exp | null
  survey_test?: survey_test_bool_exp | null
  text?: String_comparison_exp | null
}

/**
 * input type for inserting data into table "answered_survey_test_question"
 */
export interface answered_survey_test_question_insert_input {
  answer?: string | null
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  recordId?: number | null
  result?: string | null
  section?: string | null
  survey_test?: survey_test_obj_rel_insert_input | null
  text?: string | null
}

/**
 * order by max() on columns of table "answered_survey_test_question"
 */
export interface answered_survey_test_question_max_order_by {
  answer?: order_by | null
  id?: order_by | null
  recordId?: order_by | null
  result?: order_by | null
  section?: order_by | null
  text?: order_by | null
}

/**
 * order by min() on columns of table "answered_survey_test_question"
 */
export interface answered_survey_test_question_min_order_by {
  answer?: order_by | null
  id?: order_by | null
  recordId?: order_by | null
  result?: order_by | null
  section?: order_by | null
  text?: order_by | null
}

/**
 * on conflict condition type for table "answered_survey_test_question"
 */
export interface answered_survey_test_question_on_conflict {
  constraint: answered_survey_test_question_constraint
  update_columns: answered_survey_test_question_update_column[]
  where?: answered_survey_test_question_bool_exp | null
}

/**
 * order by stddev() on columns of table "answered_survey_test_question"
 */
export interface answered_survey_test_question_stddev_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "answered_survey_test_question"
 */
export interface answered_survey_test_question_stddev_pop_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "answered_survey_test_question"
 */
export interface answered_survey_test_question_stddev_samp_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by sum() on columns of table "answered_survey_test_question"
 */
export interface answered_survey_test_question_sum_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by var_pop() on columns of table "answered_survey_test_question"
 */
export interface answered_survey_test_question_var_pop_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by var_samp() on columns of table "answered_survey_test_question"
 */
export interface answered_survey_test_question_var_samp_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by variance() on columns of table "answered_survey_test_question"
 */
export interface answered_survey_test_question_variance_order_by {
  id?: order_by | null
  recordId?: order_by | null
}

/**
 * order by aggregate values of table "audit_log"
 */
export interface audit_log_aggregate_order_by {
  avg?: audit_log_avg_order_by | null
  count?: order_by | null
  max?: audit_log_max_order_by | null
  min?: audit_log_min_order_by | null
  stddev?: audit_log_stddev_order_by | null
  stddev_pop?: audit_log_stddev_pop_order_by | null
  stddev_samp?: audit_log_stddev_samp_order_by | null
  sum?: audit_log_sum_order_by | null
  var_pop?: audit_log_var_pop_order_by | null
  var_samp?: audit_log_var_samp_order_by | null
  variance?: audit_log_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "audit_log"
 */
export interface audit_log_arr_rel_insert_input {
  data: audit_log_insert_input[]
  on_conflict?: audit_log_on_conflict | null
}

/**
 * order by avg() on columns of table "audit_log"
 */
export interface audit_log_avg_order_by {
  id?: order_by | null
  userId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "audit_log". All fields are combined with a logical 'AND'.
 */
export interface audit_log_bool_exp {
  _and?: (audit_log_bool_exp | null)[] | null
  _not?: audit_log_bool_exp | null
  _or?: (audit_log_bool_exp | null)[] | null
  action?: String_comparison_exp | null
  data?: jsonb_comparison_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: Int_comparison_exp | null
  ip?: String_comparison_exp | null
  user?: user_bool_exp | null
  userId?: Int_comparison_exp | null
}

/**
 * input type for inserting data into table "audit_log"
 */
export interface audit_log_insert_input {
  action?: string | null
  data?: any | null
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  ip?: string | null
  user?: user_obj_rel_insert_input | null
  userId?: number | null
}

/**
 * order by max() on columns of table "audit_log"
 */
export interface audit_log_max_order_by {
  action?: order_by | null
  id?: order_by | null
  ip?: order_by | null
  userId?: order_by | null
}

/**
 * order by min() on columns of table "audit_log"
 */
export interface audit_log_min_order_by {
  action?: order_by | null
  id?: order_by | null
  ip?: order_by | null
  userId?: order_by | null
}

/**
 * on conflict condition type for table "audit_log"
 */
export interface audit_log_on_conflict {
  constraint: audit_log_constraint
  update_columns: audit_log_update_column[]
  where?: audit_log_bool_exp | null
}

/**
 * order by stddev() on columns of table "audit_log"
 */
export interface audit_log_stddev_order_by {
  id?: order_by | null
  userId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "audit_log"
 */
export interface audit_log_stddev_pop_order_by {
  id?: order_by | null
  userId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "audit_log"
 */
export interface audit_log_stddev_samp_order_by {
  id?: order_by | null
  userId?: order_by | null
}

/**
 * order by sum() on columns of table "audit_log"
 */
export interface audit_log_sum_order_by {
  id?: order_by | null
  userId?: order_by | null
}

/**
 * order by var_pop() on columns of table "audit_log"
 */
export interface audit_log_var_pop_order_by {
  id?: order_by | null
  userId?: order_by | null
}

/**
 * order by var_samp() on columns of table "audit_log"
 */
export interface audit_log_var_samp_order_by {
  id?: order_by | null
  userId?: order_by | null
}

/**
 * order by variance() on columns of table "audit_log"
 */
export interface audit_log_variance_order_by {
  id?: order_by | null
  userId?: order_by | null
}

/**
 * expression to compare columns of type bigint. All fields are combined with logical 'AND'.
 */
export interface bigint_comparison_exp {
  _eq?: any | null
  _gt?: any | null
  _gte?: any | null
  _in?: any[] | null
  _is_null?: boolean | null
  _lt?: any | null
  _lte?: any | null
  _neq?: any | null
  _nin?: any[] | null
}

/**
 * order by aggregate values of table "comment"
 */
export interface comment_aggregate_order_by {
  avg?: comment_avg_order_by | null
  count?: order_by | null
  max?: comment_max_order_by | null
  min?: comment_min_order_by | null
  stddev?: comment_stddev_order_by | null
  stddev_pop?: comment_stddev_pop_order_by | null
  stddev_samp?: comment_stddev_samp_order_by | null
  sum?: comment_sum_order_by | null
  var_pop?: comment_var_pop_order_by | null
  var_samp?: comment_var_samp_order_by | null
  variance?: comment_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "comment"
 */
export interface comment_arr_rel_insert_input {
  data: comment_insert_input[]
  on_conflict?: comment_on_conflict | null
}

/**
 * order by avg() on columns of table "comment"
 */
export interface comment_avg_order_by {
  categoryId?: order_by | null
  id?: order_by | null
  missedPhantomTestId?: order_by | null
  phantomTestId?: order_by | null
  scannerId?: order_by | null
  surveyTestId?: order_by | null
  userId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "comment". All fields are combined with a logical 'AND'.
 */
export interface comment_bool_exp {
  _and?: (comment_bool_exp | null)[] | null
  _not?: comment_bool_exp | null
  _or?: (comment_bool_exp | null)[] | null
  categoryId?: Int_comparison_exp | null
  comment_category?: comment_category_bool_exp | null
  comment_files?: comment_file_bool_exp | null
  content?: String_comparison_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: Int_comparison_exp | null
  missedPhantomTestId?: Int_comparison_exp | null
  missed_phantom_test?: missed_phantom_test_bool_exp | null
  phantomTestId?: Int_comparison_exp | null
  phantom_test?: phantom_test_bool_exp | null
  scanner?: scanner_bool_exp | null
  scannerId?: Int_comparison_exp | null
  subject?: String_comparison_exp | null
  surveyTestId?: Int_comparison_exp | null
  survey_test?: survey_test_bool_exp | null
  user?: user_bool_exp | null
  userId?: Int_comparison_exp | null
}

/**
 * Boolean expression to filter rows from the table "comment_category". All fields are combined with a logical 'AND'.
 */
export interface comment_category_bool_exp {
  _and?: (comment_category_bool_exp | null)[] | null
  _not?: comment_category_bool_exp | null
  _or?: (comment_category_bool_exp | null)[] | null
  color?: String_comparison_exp | null
  comments?: comment_bool_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: Int_comparison_exp | null
  name?: String_comparison_exp | null
}

/**
 * input type for inserting data into table "comment_category"
 */
export interface comment_category_insert_input {
  color?: string | null
  comments?: comment_arr_rel_insert_input | null
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  name?: string | null
}

/**
 * input type for inserting object relation for remote table "comment_category"
 */
export interface comment_category_obj_rel_insert_input {
  data: comment_category_insert_input
  on_conflict?: comment_category_on_conflict | null
}

/**
 * on conflict condition type for table "comment_category"
 */
export interface comment_category_on_conflict {
  constraint: comment_category_constraint
  update_columns: comment_category_update_column[]
  where?: comment_category_bool_exp | null
}

/**
 * ordering options when selecting data from "comment_category"
 */
export interface comment_category_order_by {
  color?: order_by | null
  comments_aggregate?: comment_aggregate_order_by | null
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  id?: order_by | null
  name?: order_by | null
}

/**
 * input type for updating data in table "comment_category"
 */
export interface comment_category_set_input {
  color?: string | null
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  name?: string | null
}

/**
 * order by aggregate values of table "comment_file"
 */
export interface comment_file_aggregate_order_by {
  avg?: comment_file_avg_order_by | null
  count?: order_by | null
  max?: comment_file_max_order_by | null
  min?: comment_file_min_order_by | null
  stddev?: comment_file_stddev_order_by | null
  stddev_pop?: comment_file_stddev_pop_order_by | null
  stddev_samp?: comment_file_stddev_samp_order_by | null
  sum?: comment_file_sum_order_by | null
  var_pop?: comment_file_var_pop_order_by | null
  var_samp?: comment_file_var_samp_order_by | null
  variance?: comment_file_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "comment_file"
 */
export interface comment_file_arr_rel_insert_input {
  data: comment_file_insert_input[]
  on_conflict?: comment_file_on_conflict | null
}

/**
 * order by avg() on columns of table "comment_file"
 */
export interface comment_file_avg_order_by {
  commentId?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "comment_file". All fields are combined with a logical 'AND'.
 */
export interface comment_file_bool_exp {
  _and?: (comment_file_bool_exp | null)[] | null
  _not?: comment_file_bool_exp | null
  _or?: (comment_file_bool_exp | null)[] | null
  comment?: comment_bool_exp | null
  commentId?: Int_comparison_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  fileId?: Int_comparison_exp | null
  id?: Int_comparison_exp | null
  stored_file?: stored_file_bool_exp | null
}

/**
 * input type for inserting data into table "comment_file"
 */
export interface comment_file_insert_input {
  comment?: comment_obj_rel_insert_input | null
  commentId?: number | null
  dateCreated?: any | null
  dateUpdated?: any | null
  fileId?: number | null
  id?: number | null
  stored_file?: stored_file_obj_rel_insert_input | null
}

/**
 * order by max() on columns of table "comment_file"
 */
export interface comment_file_max_order_by {
  commentId?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
}

/**
 * order by min() on columns of table "comment_file"
 */
export interface comment_file_min_order_by {
  commentId?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
}

/**
 * input type for inserting object relation for remote table "comment_file"
 */
export interface comment_file_obj_rel_insert_input {
  data: comment_file_insert_input
  on_conflict?: comment_file_on_conflict | null
}

/**
 * on conflict condition type for table "comment_file"
 */
export interface comment_file_on_conflict {
  constraint: comment_file_constraint
  update_columns: comment_file_update_column[]
  where?: comment_file_bool_exp | null
}

/**
 * ordering options when selecting data from "comment_file"
 */
export interface comment_file_order_by {
  comment?: comment_order_by | null
  commentId?: order_by | null
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
  stored_file?: stored_file_order_by | null
}

/**
 * order by stddev() on columns of table "comment_file"
 */
export interface comment_file_stddev_order_by {
  commentId?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "comment_file"
 */
export interface comment_file_stddev_pop_order_by {
  commentId?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "comment_file"
 */
export interface comment_file_stddev_samp_order_by {
  commentId?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
}

/**
 * order by sum() on columns of table "comment_file"
 */
export interface comment_file_sum_order_by {
  commentId?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
}

/**
 * order by var_pop() on columns of table "comment_file"
 */
export interface comment_file_var_pop_order_by {
  commentId?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
}

/**
 * order by var_samp() on columns of table "comment_file"
 */
export interface comment_file_var_samp_order_by {
  commentId?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
}

/**
 * order by variance() on columns of table "comment_file"
 */
export interface comment_file_variance_order_by {
  commentId?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
}

/**
 * input type for inserting data into table "comment"
 */
export interface comment_insert_input {
  categoryId?: number | null
  comment_category?: comment_category_obj_rel_insert_input | null
  comment_files?: comment_file_arr_rel_insert_input | null
  content?: string | null
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  missedPhantomTestId?: number | null
  missed_phantom_test?: missed_phantom_test_obj_rel_insert_input | null
  phantomTestId?: number | null
  phantom_test?: phantom_test_obj_rel_insert_input | null
  scanner?: scanner_obj_rel_insert_input | null
  scannerId?: number | null
  subject?: string | null
  surveyTestId?: number | null
  survey_test?: survey_test_obj_rel_insert_input | null
  user?: user_obj_rel_insert_input | null
  userId?: number | null
}

/**
 * order by max() on columns of table "comment"
 */
export interface comment_max_order_by {
  categoryId?: order_by | null
  content?: order_by | null
  id?: order_by | null
  missedPhantomTestId?: order_by | null
  phantomTestId?: order_by | null
  scannerId?: order_by | null
  subject?: order_by | null
  surveyTestId?: order_by | null
  userId?: order_by | null
}

/**
 * order by min() on columns of table "comment"
 */
export interface comment_min_order_by {
  categoryId?: order_by | null
  content?: order_by | null
  id?: order_by | null
  missedPhantomTestId?: order_by | null
  phantomTestId?: order_by | null
  scannerId?: order_by | null
  subject?: order_by | null
  surveyTestId?: order_by | null
  userId?: order_by | null
}

/**
 * input type for inserting object relation for remote table "comment"
 */
export interface comment_obj_rel_insert_input {
  data: comment_insert_input
  on_conflict?: comment_on_conflict | null
}

/**
 * on conflict condition type for table "comment"
 */
export interface comment_on_conflict {
  constraint: comment_constraint
  update_columns: comment_update_column[]
  where?: comment_bool_exp | null
}

/**
 * ordering options when selecting data from "comment"
 */
export interface comment_order_by {
  categoryId?: order_by | null
  comment_category?: comment_category_order_by | null
  comment_files_aggregate?: comment_file_aggregate_order_by | null
  content?: order_by | null
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  id?: order_by | null
  missedPhantomTestId?: order_by | null
  missed_phantom_test?: missed_phantom_test_order_by | null
  phantomTestId?: order_by | null
  phantom_test?: phantom_test_order_by | null
  scanner?: scanner_order_by | null
  scannerId?: order_by | null
  subject?: order_by | null
  surveyTestId?: order_by | null
  survey_test?: survey_test_order_by | null
  user?: user_order_by | null
  userId?: order_by | null
}

/**
 * input type for updating data in table "comment"
 */
export interface comment_set_input {
  categoryId?: number | null
  content?: string | null
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  missedPhantomTestId?: number | null
  phantomTestId?: number | null
  scannerId?: number | null
  subject?: string | null
  surveyTestId?: number | null
  userId?: number | null
}

/**
 * order by stddev() on columns of table "comment"
 */
export interface comment_stddev_order_by {
  categoryId?: order_by | null
  id?: order_by | null
  missedPhantomTestId?: order_by | null
  phantomTestId?: order_by | null
  scannerId?: order_by | null
  surveyTestId?: order_by | null
  userId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "comment"
 */
export interface comment_stddev_pop_order_by {
  categoryId?: order_by | null
  id?: order_by | null
  missedPhantomTestId?: order_by | null
  phantomTestId?: order_by | null
  scannerId?: order_by | null
  surveyTestId?: order_by | null
  userId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "comment"
 */
export interface comment_stddev_samp_order_by {
  categoryId?: order_by | null
  id?: order_by | null
  missedPhantomTestId?: order_by | null
  phantomTestId?: order_by | null
  scannerId?: order_by | null
  surveyTestId?: order_by | null
  userId?: order_by | null
}

/**
 * order by sum() on columns of table "comment"
 */
export interface comment_sum_order_by {
  categoryId?: order_by | null
  id?: order_by | null
  missedPhantomTestId?: order_by | null
  phantomTestId?: order_by | null
  scannerId?: order_by | null
  surveyTestId?: order_by | null
  userId?: order_by | null
}

/**
 * order by var_pop() on columns of table "comment"
 */
export interface comment_var_pop_order_by {
  categoryId?: order_by | null
  id?: order_by | null
  missedPhantomTestId?: order_by | null
  phantomTestId?: order_by | null
  scannerId?: order_by | null
  surveyTestId?: order_by | null
  userId?: order_by | null
}

/**
 * order by var_samp() on columns of table "comment"
 */
export interface comment_var_samp_order_by {
  categoryId?: order_by | null
  id?: order_by | null
  missedPhantomTestId?: order_by | null
  phantomTestId?: order_by | null
  scannerId?: order_by | null
  surveyTestId?: order_by | null
  userId?: order_by | null
}

/**
 * order by variance() on columns of table "comment"
 */
export interface comment_variance_order_by {
  categoryId?: order_by | null
  id?: order_by | null
  missedPhantomTestId?: order_by | null
  phantomTestId?: order_by | null
  scannerId?: order_by | null
  surveyTestId?: order_by | null
  userId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "facility". All fields are combined with a logical 'AND'.
 */
export interface facility_bool_exp {
  _and?: (facility_bool_exp | null)[] | null
  _not?: facility_bool_exp | null
  _or?: (facility_bool_exp | null)[] | null
  address?: String_comparison_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  facilityId?: String_comparison_exp | null
  facility_rooms?: facility_room_bool_exp | null
  id?: Int_comparison_exp | null
  name?: String_comparison_exp | null
  phone?: String_comparison_exp | null
  scanners?: scanner_bool_exp | null
  supportContacts?: jsonb_comparison_exp | null
  users?: user_bool_exp | null
}

/**
 * input type for inserting data into table "facility"
 */
export interface facility_insert_input {
  address?: string | null
  dateCreated?: any | null
  dateUpdated?: any | null
  facilityId?: string | null
  facility_rooms?: facility_room_arr_rel_insert_input | null
  id?: number | null
  name?: string | null
  phone?: string | null
  scanners?: scanner_arr_rel_insert_input | null
  supportContacts?: any | null
  users?: user_arr_rel_insert_input | null
}

/**
 * input type for inserting object relation for remote table "facility"
 */
export interface facility_obj_rel_insert_input {
  data: facility_insert_input
  on_conflict?: facility_on_conflict | null
}

/**
 * on conflict condition type for table "facility"
 */
export interface facility_on_conflict {
  constraint: facility_constraint
  update_columns: facility_update_column[]
  where?: facility_bool_exp | null
}

/**
 * ordering options when selecting data from "facility"
 */
export interface facility_order_by {
  address?: order_by | null
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  facilityId?: order_by | null
  facility_rooms_aggregate?: facility_room_aggregate_order_by | null
  id?: order_by | null
  name?: order_by | null
  phone?: order_by | null
  scanners_aggregate?: scanner_aggregate_order_by | null
  supportContacts?: order_by | null
  users_aggregate?: user_aggregate_order_by | null
}

/**
 * order by aggregate values of table "facility_room"
 */
export interface facility_room_aggregate_order_by {
  avg?: facility_room_avg_order_by | null
  count?: order_by | null
  max?: facility_room_max_order_by | null
  min?: facility_room_min_order_by | null
  stddev?: facility_room_stddev_order_by | null
  stddev_pop?: facility_room_stddev_pop_order_by | null
  stddev_samp?: facility_room_stddev_samp_order_by | null
  sum?: facility_room_sum_order_by | null
  var_pop?: facility_room_var_pop_order_by | null
  var_samp?: facility_room_var_samp_order_by | null
  variance?: facility_room_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "facility_room"
 */
export interface facility_room_arr_rel_insert_input {
  data: facility_room_insert_input[]
  on_conflict?: facility_room_on_conflict | null
}

/**
 * order by avg() on columns of table "facility_room"
 */
export interface facility_room_avg_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "facility_room". All fields are combined with a logical 'AND'.
 */
export interface facility_room_bool_exp {
  _and?: (facility_room_bool_exp | null)[] | null
  _not?: facility_room_bool_exp | null
  _or?: (facility_room_bool_exp | null)[] | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  facility?: facility_bool_exp | null
  facilityId?: Int_comparison_exp | null
  id?: Int_comparison_exp | null
  name?: String_comparison_exp | null
  scanners?: scanner_bool_exp | null
}

/**
 * input type for inserting data into table "facility_room"
 */
export interface facility_room_insert_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  facility?: facility_obj_rel_insert_input | null
  facilityId?: number | null
  id?: number | null
  name?: string | null
  scanners?: scanner_arr_rel_insert_input | null
}

/**
 * order by max() on columns of table "facility_room"
 */
export interface facility_room_max_order_by {
  facilityId?: order_by | null
  id?: order_by | null
  name?: order_by | null
}

/**
 * order by min() on columns of table "facility_room"
 */
export interface facility_room_min_order_by {
  facilityId?: order_by | null
  id?: order_by | null
  name?: order_by | null
}

/**
 * input type for inserting object relation for remote table "facility_room"
 */
export interface facility_room_obj_rel_insert_input {
  data: facility_room_insert_input
  on_conflict?: facility_room_on_conflict | null
}

/**
 * on conflict condition type for table "facility_room"
 */
export interface facility_room_on_conflict {
  constraint: facility_room_constraint
  update_columns: facility_room_update_column[]
  where?: facility_room_bool_exp | null
}

/**
 * ordering options when selecting data from "facility_room"
 */
export interface facility_room_order_by {
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  facility?: facility_order_by | null
  facilityId?: order_by | null
  id?: order_by | null
  name?: order_by | null
  scanners_aggregate?: scanner_aggregate_order_by | null
}

/**
 * order by stddev() on columns of table "facility_room"
 */
export interface facility_room_stddev_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "facility_room"
 */
export interface facility_room_stddev_pop_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "facility_room"
 */
export interface facility_room_stddev_samp_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * order by sum() on columns of table "facility_room"
 */
export interface facility_room_sum_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * order by var_pop() on columns of table "facility_room"
 */
export interface facility_room_var_pop_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * order by var_samp() on columns of table "facility_room"
 */
export interface facility_room_var_samp_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * order by variance() on columns of table "facility_room"
 */
export interface facility_room_variance_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * input type for updating data in table "facility"
 */
export interface facility_set_input {
  address?: string | null
  dateCreated?: any | null
  dateUpdated?: any | null
  facilityId?: string | null
  id?: number | null
  name?: string | null
  phone?: string | null
  supportContacts?: any | null
}

/**
 * expression to compare columns of type jsonb. All fields are combined with logical 'AND'.
 */
export interface jsonb_comparison_exp {
  _contained_in?: any | null
  _contains?: any | null
  _eq?: any | null
  _gt?: any | null
  _gte?: any | null
  _has_key?: string | null
  _has_keys_all?: string[] | null
  _has_keys_any?: string[] | null
  _in?: any[] | null
  _is_null?: boolean | null
  _lt?: any | null
  _lte?: any | null
  _neq?: any | null
  _nin?: any[] | null
}

/**
 * order by aggregate values of table "missed_phantom_test"
 */
export interface missed_phantom_test_aggregate_order_by {
  avg?: missed_phantom_test_avg_order_by | null
  count?: order_by | null
  max?: missed_phantom_test_max_order_by | null
  min?: missed_phantom_test_min_order_by | null
  stddev?: missed_phantom_test_stddev_order_by | null
  stddev_pop?: missed_phantom_test_stddev_pop_order_by | null
  stddev_samp?: missed_phantom_test_stddev_samp_order_by | null
  sum?: missed_phantom_test_sum_order_by | null
  var_pop?: missed_phantom_test_var_pop_order_by | null
  var_samp?: missed_phantom_test_var_samp_order_by | null
  variance?: missed_phantom_test_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "missed_phantom_test"
 */
export interface missed_phantom_test_arr_rel_insert_input {
  data: missed_phantom_test_insert_input[]
  on_conflict?: missed_phantom_test_on_conflict | null
}

/**
 * order by avg() on columns of table "missed_phantom_test"
 */
export interface missed_phantom_test_avg_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "missed_phantom_test". All fields are combined with a logical 'AND'.
 */
export interface missed_phantom_test_bool_exp {
  _and?: (missed_phantom_test_bool_exp | null)[] | null
  _not?: missed_phantom_test_bool_exp | null
  _or?: (missed_phantom_test_bool_exp | null)[] | null
  comments?: comment_bool_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: Int_comparison_exp | null
  result?: String_comparison_exp | null
  scanner?: scanner_bool_exp | null
  scannerId?: Int_comparison_exp | null
  scheduledDate?: timestamp_comparison_exp | null
}

/**
 * input type for inserting data into table "missed_phantom_test"
 */
export interface missed_phantom_test_insert_input {
  comments?: comment_arr_rel_insert_input | null
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  result?: string | null
  scanner?: scanner_obj_rel_insert_input | null
  scannerId?: number | null
  scheduledDate?: any | null
}

/**
 * order by max() on columns of table "missed_phantom_test"
 */
export interface missed_phantom_test_max_order_by {
  id?: order_by | null
  result?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by min() on columns of table "missed_phantom_test"
 */
export interface missed_phantom_test_min_order_by {
  id?: order_by | null
  result?: order_by | null
  scannerId?: order_by | null
}

/**
 * input type for inserting object relation for remote table "missed_phantom_test"
 */
export interface missed_phantom_test_obj_rel_insert_input {
  data: missed_phantom_test_insert_input
  on_conflict?: missed_phantom_test_on_conflict | null
}

/**
 * on conflict condition type for table "missed_phantom_test"
 */
export interface missed_phantom_test_on_conflict {
  constraint: missed_phantom_test_constraint
  update_columns: missed_phantom_test_update_column[]
  where?: missed_phantom_test_bool_exp | null
}

/**
 * ordering options when selecting data from "missed_phantom_test"
 */
export interface missed_phantom_test_order_by {
  comments_aggregate?: comment_aggregate_order_by | null
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  id?: order_by | null
  result?: order_by | null
  scanner?: scanner_order_by | null
  scannerId?: order_by | null
  scheduledDate?: order_by | null
}

/**
 * input type for updating data in table "missed_phantom_test"
 */
export interface missed_phantom_test_set_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  result?: string | null
  scannerId?: number | null
  scheduledDate?: any | null
}

/**
 * order by stddev() on columns of table "missed_phantom_test"
 */
export interface missed_phantom_test_stddev_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "missed_phantom_test"
 */
export interface missed_phantom_test_stddev_pop_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "missed_phantom_test"
 */
export interface missed_phantom_test_stddev_samp_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by sum() on columns of table "missed_phantom_test"
 */
export interface missed_phantom_test_sum_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by var_pop() on columns of table "missed_phantom_test"
 */
export interface missed_phantom_test_var_pop_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by var_samp() on columns of table "missed_phantom_test"
 */
export interface missed_phantom_test_var_samp_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by variance() on columns of table "missed_phantom_test"
 */
export interface missed_phantom_test_variance_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "modality". All fields are combined with a logical 'AND'.
 */
export interface modality_bool_exp {
  _and?: (modality_bool_exp | null)[] | null
  _not?: modality_bool_exp | null
  _or?: (modality_bool_exp | null)[] | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: String_comparison_exp | null
  phantom_test_routines?: phantom_test_routine_bool_exp | null
  scanners?: scanner_bool_exp | null
  studies?: study_bool_exp | null
  survey_test_routines?: survey_test_routine_bool_exp | null
}

/**
 * input type for inserting data into table "modality"
 */
export interface modality_insert_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: string | null
  phantom_test_routines?: phantom_test_routine_arr_rel_insert_input | null
  scanners?: scanner_arr_rel_insert_input | null
  studies?: study_arr_rel_insert_input | null
  survey_test_routines?: survey_test_routine_arr_rel_insert_input | null
}

/**
 * input type for inserting object relation for remote table "modality"
 */
export interface modality_obj_rel_insert_input {
  data: modality_insert_input
  on_conflict?: modality_on_conflict | null
}

/**
 * on conflict condition type for table "modality"
 */
export interface modality_on_conflict {
  constraint: modality_constraint
  update_columns: modality_update_column[]
  where?: modality_bool_exp | null
}

/**
 * ordering options when selecting data from "modality"
 */
export interface modality_order_by {
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  id?: order_by | null
  phantom_test_routines_aggregate?: phantom_test_routine_aggregate_order_by | null
  scanners_aggregate?: scanner_aggregate_order_by | null
  studies_aggregate?: study_aggregate_order_by | null
  survey_test_routines_aggregate?: survey_test_routine_aggregate_order_by | null
}

/**
 * order by aggregate values of table "phantom_test"
 */
export interface phantom_test_aggregate_order_by {
  avg?: phantom_test_avg_order_by | null
  count?: order_by | null
  max?: phantom_test_max_order_by | null
  min?: phantom_test_min_order_by | null
  stddev?: phantom_test_stddev_order_by | null
  stddev_pop?: phantom_test_stddev_pop_order_by | null
  stddev_samp?: phantom_test_stddev_samp_order_by | null
  sum?: phantom_test_sum_order_by | null
  var_pop?: phantom_test_var_pop_order_by | null
  var_samp?: phantom_test_var_samp_order_by | null
  variance?: phantom_test_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "phantom_test"
 */
export interface phantom_test_arr_rel_insert_input {
  data: phantom_test_insert_input[]
  on_conflict?: phantom_test_on_conflict | null
}

/**
 * order by avg() on columns of table "phantom_test"
 */
export interface phantom_test_avg_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  studyId?: order_by | null
  testerId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "phantom_test". All fields are combined with a logical 'AND'.
 */
export interface phantom_test_bool_exp {
  _and?: (phantom_test_bool_exp | null)[] | null
  _not?: phantom_test_bool_exp | null
  _or?: (phantom_test_bool_exp | null)[] | null
  answered_phantom_test_questions?: answered_phantom_test_question_bool_exp | null
  comments?: comment_bool_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  evaluationItems?: jsonb_comparison_exp | null
  id?: Int_comparison_exp | null
  phantom_test_images?: phantom_test_image_bool_exp | null
  phantom_test_routine?: phantom_test_routine_bool_exp | null
  rawOutput?: jsonb_comparison_exp | null
  result?: String_comparison_exp | null
  routineId?: Int_comparison_exp | null
  scanner?: scanner_bool_exp | null
  scannerId?: Int_comparison_exp | null
  status?: String_comparison_exp | null
  study?: study_bool_exp | null
  studyId?: Int_comparison_exp | null
  testerId?: Int_comparison_exp | null
  user?: user_bool_exp | null
}

/**
 * order by aggregate values of table "phantom_test_image"
 */
export interface phantom_test_image_aggregate_order_by {
  avg?: phantom_test_image_avg_order_by | null
  count?: order_by | null
  max?: phantom_test_image_max_order_by | null
  min?: phantom_test_image_min_order_by | null
  stddev?: phantom_test_image_stddev_order_by | null
  stddev_pop?: phantom_test_image_stddev_pop_order_by | null
  stddev_samp?: phantom_test_image_stddev_samp_order_by | null
  sum?: phantom_test_image_sum_order_by | null
  var_pop?: phantom_test_image_var_pop_order_by | null
  var_samp?: phantom_test_image_var_samp_order_by | null
  variance?: phantom_test_image_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "phantom_test_image"
 */
export interface phantom_test_image_arr_rel_insert_input {
  data: phantom_test_image_insert_input[]
  on_conflict?: phantom_test_image_on_conflict | null
}

/**
 * order by avg() on columns of table "phantom_test_image"
 */
export interface phantom_test_image_avg_order_by {
  fileId?: order_by | null
  id?: order_by | null
  phantomTestId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "phantom_test_image". All fields are combined with a logical 'AND'.
 */
export interface phantom_test_image_bool_exp {
  _and?: (phantom_test_image_bool_exp | null)[] | null
  _not?: phantom_test_image_bool_exp | null
  _or?: (phantom_test_image_bool_exp | null)[] | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  fileId?: Int_comparison_exp | null
  id?: Int_comparison_exp | null
  label?: String_comparison_exp | null
  phantomTestId?: Int_comparison_exp | null
  phantom_test?: phantom_test_bool_exp | null
  stored_file?: stored_file_bool_exp | null
}

/**
 * input type for inserting data into table "phantom_test_image"
 */
export interface phantom_test_image_insert_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  fileId?: number | null
  id?: number | null
  label?: string | null
  phantomTestId?: number | null
  phantom_test?: phantom_test_obj_rel_insert_input | null
  stored_file?: stored_file_obj_rel_insert_input | null
}

/**
 * order by max() on columns of table "phantom_test_image"
 */
export interface phantom_test_image_max_order_by {
  fileId?: order_by | null
  id?: order_by | null
  label?: order_by | null
  phantomTestId?: order_by | null
}

/**
 * order by min() on columns of table "phantom_test_image"
 */
export interface phantom_test_image_min_order_by {
  fileId?: order_by | null
  id?: order_by | null
  label?: order_by | null
  phantomTestId?: order_by | null
}

/**
 * input type for inserting object relation for remote table "phantom_test_image"
 */
export interface phantom_test_image_obj_rel_insert_input {
  data: phantom_test_image_insert_input
  on_conflict?: phantom_test_image_on_conflict | null
}

/**
 * on conflict condition type for table "phantom_test_image"
 */
export interface phantom_test_image_on_conflict {
  constraint: phantom_test_image_constraint
  update_columns: phantom_test_image_update_column[]
  where?: phantom_test_image_bool_exp | null
}

/**
 * ordering options when selecting data from "phantom_test_image"
 */
export interface phantom_test_image_order_by {
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
  label?: order_by | null
  phantomTestId?: order_by | null
  phantom_test?: phantom_test_order_by | null
  stored_file?: stored_file_order_by | null
}

/**
 * order by stddev() on columns of table "phantom_test_image"
 */
export interface phantom_test_image_stddev_order_by {
  fileId?: order_by | null
  id?: order_by | null
  phantomTestId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "phantom_test_image"
 */
export interface phantom_test_image_stddev_pop_order_by {
  fileId?: order_by | null
  id?: order_by | null
  phantomTestId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "phantom_test_image"
 */
export interface phantom_test_image_stddev_samp_order_by {
  fileId?: order_by | null
  id?: order_by | null
  phantomTestId?: order_by | null
}

/**
 * order by sum() on columns of table "phantom_test_image"
 */
export interface phantom_test_image_sum_order_by {
  fileId?: order_by | null
  id?: order_by | null
  phantomTestId?: order_by | null
}

/**
 * order by var_pop() on columns of table "phantom_test_image"
 */
export interface phantom_test_image_var_pop_order_by {
  fileId?: order_by | null
  id?: order_by | null
  phantomTestId?: order_by | null
}

/**
 * order by var_samp() on columns of table "phantom_test_image"
 */
export interface phantom_test_image_var_samp_order_by {
  fileId?: order_by | null
  id?: order_by | null
  phantomTestId?: order_by | null
}

/**
 * order by variance() on columns of table "phantom_test_image"
 */
export interface phantom_test_image_variance_order_by {
  fileId?: order_by | null
  id?: order_by | null
  phantomTestId?: order_by | null
}

/**
 * input type for inserting data into table "phantom_test"
 */
export interface phantom_test_insert_input {
  answered_phantom_test_questions?: answered_phantom_test_question_arr_rel_insert_input | null
  comments?: comment_arr_rel_insert_input | null
  dateCreated?: any | null
  dateUpdated?: any | null
  evaluationItems?: any | null
  id?: number | null
  phantom_test_images?: phantom_test_image_arr_rel_insert_input | null
  phantom_test_routine?: phantom_test_routine_obj_rel_insert_input | null
  rawOutput?: any | null
  result?: string | null
  routineId?: number | null
  scanner?: scanner_obj_rel_insert_input | null
  scannerId?: number | null
  status?: string | null
  study?: study_obj_rel_insert_input | null
  studyId?: number | null
  testerId?: number | null
  user?: user_obj_rel_insert_input | null
}

/**
 * order by max() on columns of table "phantom_test"
 */
export interface phantom_test_max_order_by {
  id?: order_by | null
  result?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  status?: order_by | null
  studyId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by min() on columns of table "phantom_test"
 */
export interface phantom_test_min_order_by {
  id?: order_by | null
  result?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  status?: order_by | null
  studyId?: order_by | null
  testerId?: order_by | null
}

/**
 * input type for inserting object relation for remote table "phantom_test"
 */
export interface phantom_test_obj_rel_insert_input {
  data: phantom_test_insert_input
  on_conflict?: phantom_test_on_conflict | null
}

/**
 * on conflict condition type for table "phantom_test"
 */
export interface phantom_test_on_conflict {
  constraint: phantom_test_constraint
  update_columns: phantom_test_update_column[]
  where?: phantom_test_bool_exp | null
}

/**
 * ordering options when selecting data from "phantom_test"
 */
export interface phantom_test_order_by {
  answered_phantom_test_questions_aggregate?: answered_phantom_test_question_aggregate_order_by | null
  comments_aggregate?: comment_aggregate_order_by | null
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  evaluationItems?: order_by | null
  id?: order_by | null
  phantom_test_images_aggregate?: phantom_test_image_aggregate_order_by | null
  phantom_test_routine?: phantom_test_routine_order_by | null
  rawOutput?: order_by | null
  result?: order_by | null
  routineId?: order_by | null
  scanner?: scanner_order_by | null
  scannerId?: order_by | null
  status?: order_by | null
  study?: study_order_by | null
  studyId?: order_by | null
  testerId?: order_by | null
  user?: user_order_by | null
}

/**
 * Boolean expression to filter rows from the table "phantom_test_question". All fields are combined with a logical 'AND'.
 */
export interface phantom_test_question_bool_exp {
  _and?: (phantom_test_question_bool_exp | null)[] | null
  _not?: phantom_test_question_bool_exp | null
  _or?: (phantom_test_question_bool_exp | null)[] | null
  assignProblemInsteadOfFail?: Boolean_comparison_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  defaultAnswer?: String_comparison_exp | null
  exact?: Int_comparison_exp | null
  expected?: String_comparison_exp | null
  id?: Int_comparison_exp | null
  max?: Int_comparison_exp | null
  min?: Int_comparison_exp | null
  name?: String_comparison_exp | null
  options?: jsonb_comparison_exp | null
  order?: Int_comparison_exp | null
  phantom_test_routine_questions_phantom_test_questions?: phantom_test_routine_questions_phantom_test_question_bool_exp | null
  text?: String_comparison_exp | null
  type?: String_comparison_exp | null
}

/**
 * input type for inserting data into table "phantom_test_question"
 */
export interface phantom_test_question_insert_input {
  assignProblemInsteadOfFail?: boolean | null
  dateCreated?: any | null
  dateUpdated?: any | null
  defaultAnswer?: string | null
  exact?: number | null
  expected?: string | null
  id?: number | null
  max?: number | null
  min?: number | null
  name?: string | null
  options?: any | null
  order?: number | null
  phantom_test_routine_questions_phantom_test_questions?: phantom_test_routine_questions_phantom_test_question_arr_rel_insert_input | null
  text?: string | null
  type?: string | null
}

/**
 * input type for inserting object relation for remote table "phantom_test_question"
 */
export interface phantom_test_question_obj_rel_insert_input {
  data: phantom_test_question_insert_input
  on_conflict?: phantom_test_question_on_conflict | null
}

/**
 * on conflict condition type for table "phantom_test_question"
 */
export interface phantom_test_question_on_conflict {
  constraint: phantom_test_question_constraint
  update_columns: phantom_test_question_update_column[]
  where?: phantom_test_question_bool_exp | null
}

/**
 * order by aggregate values of table "phantom_test_routine"
 */
export interface phantom_test_routine_aggregate_order_by {
  avg?: phantom_test_routine_avg_order_by | null
  count?: order_by | null
  max?: phantom_test_routine_max_order_by | null
  min?: phantom_test_routine_min_order_by | null
  stddev?: phantom_test_routine_stddev_order_by | null
  stddev_pop?: phantom_test_routine_stddev_pop_order_by | null
  stddev_samp?: phantom_test_routine_stddev_samp_order_by | null
  sum?: phantom_test_routine_sum_order_by | null
  var_pop?: phantom_test_routine_var_pop_order_by | null
  var_samp?: phantom_test_routine_var_samp_order_by | null
  variance?: phantom_test_routine_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "phantom_test_routine"
 */
export interface phantom_test_routine_arr_rel_insert_input {
  data: phantom_test_routine_insert_input[]
  on_conflict?: phantom_test_routine_on_conflict | null
}

/**
 * order by avg() on columns of table "phantom_test_routine"
 */
export interface phantom_test_routine_avg_order_by {
  id?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "phantom_test_routine". All fields are combined with a logical 'AND'.
 */
export interface phantom_test_routine_bool_exp {
  _and?: (phantom_test_routine_bool_exp | null)[] | null
  _not?: phantom_test_routine_bool_exp | null
  _or?: (phantom_test_routine_bool_exp | null)[] | null
  customValues?: jsonb_comparison_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: Int_comparison_exp | null
  method?: String_comparison_exp | null
  modality?: modality_bool_exp | null
  modalityId?: String_comparison_exp | null
  name?: String_comparison_exp | null
  phantom?: String_comparison_exp | null
  phantom_test_routine_questions_phantom_test_questions?: phantom_test_routine_questions_phantom_test_question_bool_exp | null
  phantom_test_routine_schedules_schedules?: phantom_test_routine_schedules_schedule_bool_exp | null
  phantom_tests?: phantom_test_bool_exp | null
  scanner_phantom_test_routines_phantom_test_routines?: scanner_phantom_test_routines_phantom_test_routine_bool_exp | null
  schedule_phantom_test_routines_phantom_test_routines?: schedule_phantom_test_routines_phantom_test_routine_bool_exp | null
}

/**
 * input type for inserting data into table "phantom_test_routine"
 */
export interface phantom_test_routine_insert_input {
  customValues?: any | null
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  method?: string | null
  modality?: modality_obj_rel_insert_input | null
  modalityId?: string | null
  name?: string | null
  phantom?: string | null
  phantom_test_routine_questions_phantom_test_questions?: phantom_test_routine_questions_phantom_test_question_arr_rel_insert_input | null
  phantom_test_routine_schedules_schedules?: phantom_test_routine_schedules_schedule_arr_rel_insert_input | null
  phantom_tests?: phantom_test_arr_rel_insert_input | null
  scanner_phantom_test_routines_phantom_test_routines?: scanner_phantom_test_routines_phantom_test_routine_arr_rel_insert_input | null
  schedule_phantom_test_routines_phantom_test_routines?: schedule_phantom_test_routines_phantom_test_routine_arr_rel_insert_input | null
}

/**
 * order by max() on columns of table "phantom_test_routine"
 */
export interface phantom_test_routine_max_order_by {
  id?: order_by | null
  method?: order_by | null
  modalityId?: order_by | null
  name?: order_by | null
  phantom?: order_by | null
}

/**
 * order by min() on columns of table "phantom_test_routine"
 */
export interface phantom_test_routine_min_order_by {
  id?: order_by | null
  method?: order_by | null
  modalityId?: order_by | null
  name?: order_by | null
  phantom?: order_by | null
}

/**
 * input type for inserting object relation for remote table "phantom_test_routine"
 */
export interface phantom_test_routine_obj_rel_insert_input {
  data: phantom_test_routine_insert_input
  on_conflict?: phantom_test_routine_on_conflict | null
}

/**
 * on conflict condition type for table "phantom_test_routine"
 */
export interface phantom_test_routine_on_conflict {
  constraint: phantom_test_routine_constraint
  update_columns: phantom_test_routine_update_column[]
  where?: phantom_test_routine_bool_exp | null
}

/**
 * ordering options when selecting data from "phantom_test_routine"
 */
export interface phantom_test_routine_order_by {
  customValues?: order_by | null
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  id?: order_by | null
  method?: order_by | null
  modality?: modality_order_by | null
  modalityId?: order_by | null
  name?: order_by | null
  phantom?: order_by | null
  phantom_test_routine_questions_phantom_test_questions_aggregate?: phantom_test_routine_questions_phantom_test_question_aggregate_order_by | null
  phantom_test_routine_schedules_schedules_aggregate?: phantom_test_routine_schedules_schedule_aggregate_order_by | null
  phantom_tests_aggregate?: phantom_test_aggregate_order_by | null
  scanner_phantom_test_routines_phantom_test_routines_aggregate?: scanner_phantom_test_routines_phantom_test_routine_aggregate_order_by | null
  schedule_phantom_test_routines_phantom_test_routines_aggregate?: schedule_phantom_test_routines_phantom_test_routine_aggregate_order_by | null
}

/**
 * order by aggregate values of table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_aggregate_order_by {
  avg?: phantom_test_routine_questions_phantom_test_question_avg_order_by | null
  count?: order_by | null
  max?: phantom_test_routine_questions_phantom_test_question_max_order_by | null
  min?: phantom_test_routine_questions_phantom_test_question_min_order_by | null
  stddev?: phantom_test_routine_questions_phantom_test_question_stddev_order_by | null
  stddev_pop?: phantom_test_routine_questions_phantom_test_question_stddev_pop_order_by | null
  stddev_samp?: phantom_test_routine_questions_phantom_test_question_stddev_samp_order_by | null
  sum?: phantom_test_routine_questions_phantom_test_question_sum_order_by | null
  var_pop?: phantom_test_routine_questions_phantom_test_question_var_pop_order_by | null
  var_samp?: phantom_test_routine_questions_phantom_test_question_var_samp_order_by | null
  variance?: phantom_test_routine_questions_phantom_test_question_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_arr_rel_insert_input {
  data: phantom_test_routine_questions_phantom_test_question_insert_input[]
  on_conflict?: phantom_test_routine_questions_phantom_test_question_on_conflict | null
}

/**
 * order by avg() on columns of table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_avg_order_by {
  phantomTestQuestionId?: order_by | null
  phantomTestRoutineId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "phantom_test_routine_questions_phantom_test_question". All fields are combined with a logical 'AND'.
 */
export interface phantom_test_routine_questions_phantom_test_question_bool_exp {
  _and?:
    | (phantom_test_routine_questions_phantom_test_question_bool_exp | null)[]
    | null
  _not?: phantom_test_routine_questions_phantom_test_question_bool_exp | null
  _or?:
    | (phantom_test_routine_questions_phantom_test_question_bool_exp | null)[]
    | null
  phantomTestQuestionId?: Int_comparison_exp | null
  phantomTestRoutineId?: Int_comparison_exp | null
  phantom_test_question?: phantom_test_question_bool_exp | null
  phantom_test_routine?: phantom_test_routine_bool_exp | null
}

/**
 * input type for inserting data into table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_insert_input {
  phantomTestQuestionId?: number | null
  phantomTestRoutineId?: number | null
  phantom_test_question?: phantom_test_question_obj_rel_insert_input | null
  phantom_test_routine?: phantom_test_routine_obj_rel_insert_input | null
}

/**
 * order by max() on columns of table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_max_order_by {
  phantomTestQuestionId?: order_by | null
  phantomTestRoutineId?: order_by | null
}

/**
 * order by min() on columns of table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_min_order_by {
  phantomTestQuestionId?: order_by | null
  phantomTestRoutineId?: order_by | null
}

/**
 * on conflict condition type for table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_on_conflict {
  constraint: phantom_test_routine_questions_phantom_test_question_constraint
  update_columns: phantom_test_routine_questions_phantom_test_question_update_column[]
  where?: phantom_test_routine_questions_phantom_test_question_bool_exp | null
}

/**
 * order by stddev() on columns of table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_stddev_order_by {
  phantomTestQuestionId?: order_by | null
  phantomTestRoutineId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_stddev_pop_order_by {
  phantomTestQuestionId?: order_by | null
  phantomTestRoutineId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_stddev_samp_order_by {
  phantomTestQuestionId?: order_by | null
  phantomTestRoutineId?: order_by | null
}

/**
 * order by sum() on columns of table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_sum_order_by {
  phantomTestQuestionId?: order_by | null
  phantomTestRoutineId?: order_by | null
}

/**
 * order by var_pop() on columns of table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_var_pop_order_by {
  phantomTestQuestionId?: order_by | null
  phantomTestRoutineId?: order_by | null
}

/**
 * order by var_samp() on columns of table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_var_samp_order_by {
  phantomTestQuestionId?: order_by | null
  phantomTestRoutineId?: order_by | null
}

/**
 * order by variance() on columns of table "phantom_test_routine_questions_phantom_test_question"
 */
export interface phantom_test_routine_questions_phantom_test_question_variance_order_by {
  phantomTestQuestionId?: order_by | null
  phantomTestRoutineId?: order_by | null
}

/**
 * order by aggregate values of table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_aggregate_order_by {
  avg?: phantom_test_routine_schedules_schedule_avg_order_by | null
  count?: order_by | null
  max?: phantom_test_routine_schedules_schedule_max_order_by | null
  min?: phantom_test_routine_schedules_schedule_min_order_by | null
  stddev?: phantom_test_routine_schedules_schedule_stddev_order_by | null
  stddev_pop?: phantom_test_routine_schedules_schedule_stddev_pop_order_by | null
  stddev_samp?: phantom_test_routine_schedules_schedule_stddev_samp_order_by | null
  sum?: phantom_test_routine_schedules_schedule_sum_order_by | null
  var_pop?: phantom_test_routine_schedules_schedule_var_pop_order_by | null
  var_samp?: phantom_test_routine_schedules_schedule_var_samp_order_by | null
  variance?: phantom_test_routine_schedules_schedule_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_arr_rel_insert_input {
  data: phantom_test_routine_schedules_schedule_insert_input[]
  on_conflict?: phantom_test_routine_schedules_schedule_on_conflict | null
}

/**
 * order by avg() on columns of table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_avg_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "phantom_test_routine_schedules_schedule". All fields are combined with a logical 'AND'.
 */
export interface phantom_test_routine_schedules_schedule_bool_exp {
  _and?: (phantom_test_routine_schedules_schedule_bool_exp | null)[] | null
  _not?: phantom_test_routine_schedules_schedule_bool_exp | null
  _or?: (phantom_test_routine_schedules_schedule_bool_exp | null)[] | null
  phantomTestRoutineId?: Int_comparison_exp | null
  phantom_test_routine?: phantom_test_routine_bool_exp | null
  schedule?: schedule_bool_exp | null
  scheduleId?: Int_comparison_exp | null
}

/**
 * input type for inserting data into table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_insert_input {
  phantomTestRoutineId?: number | null
  phantom_test_routine?: phantom_test_routine_obj_rel_insert_input | null
  schedule?: schedule_obj_rel_insert_input | null
  scheduleId?: number | null
}

/**
 * order by max() on columns of table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_max_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by min() on columns of table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_min_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * on conflict condition type for table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_on_conflict {
  constraint: phantom_test_routine_schedules_schedule_constraint
  update_columns: phantom_test_routine_schedules_schedule_update_column[]
  where?: phantom_test_routine_schedules_schedule_bool_exp | null
}

/**
 * order by stddev() on columns of table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_stddev_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_stddev_pop_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_stddev_samp_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by sum() on columns of table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_sum_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by var_pop() on columns of table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_var_pop_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by var_samp() on columns of table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_var_samp_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by variance() on columns of table "phantom_test_routine_schedules_schedule"
 */
export interface phantom_test_routine_schedules_schedule_variance_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by stddev() on columns of table "phantom_test_routine"
 */
export interface phantom_test_routine_stddev_order_by {
  id?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "phantom_test_routine"
 */
export interface phantom_test_routine_stddev_pop_order_by {
  id?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "phantom_test_routine"
 */
export interface phantom_test_routine_stddev_samp_order_by {
  id?: order_by | null
}

/**
 * order by sum() on columns of table "phantom_test_routine"
 */
export interface phantom_test_routine_sum_order_by {
  id?: order_by | null
}

/**
 * order by var_pop() on columns of table "phantom_test_routine"
 */
export interface phantom_test_routine_var_pop_order_by {
  id?: order_by | null
}

/**
 * order by var_samp() on columns of table "phantom_test_routine"
 */
export interface phantom_test_routine_var_samp_order_by {
  id?: order_by | null
}

/**
 * order by variance() on columns of table "phantom_test_routine"
 */
export interface phantom_test_routine_variance_order_by {
  id?: order_by | null
}

/**
 * input type for updating data in table "phantom_test"
 */
export interface phantom_test_set_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  evaluationItems?: any | null
  id?: number | null
  rawOutput?: any | null
  result?: string | null
  routineId?: number | null
  scannerId?: number | null
  status?: string | null
  studyId?: number | null
  testerId?: number | null
}

/**
 * order by stddev() on columns of table "phantom_test"
 */
export interface phantom_test_stddev_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  studyId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "phantom_test"
 */
export interface phantom_test_stddev_pop_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  studyId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "phantom_test"
 */
export interface phantom_test_stddev_samp_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  studyId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by sum() on columns of table "phantom_test"
 */
export interface phantom_test_sum_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  studyId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by var_pop() on columns of table "phantom_test"
 */
export interface phantom_test_var_pop_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  studyId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by var_samp() on columns of table "phantom_test"
 */
export interface phantom_test_var_samp_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  studyId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by variance() on columns of table "phantom_test"
 */
export interface phantom_test_variance_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  studyId?: order_by | null
  testerId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "problem". All fields are combined with a logical 'AND'.
 */
export interface problem_bool_exp {
  _and?: (problem_bool_exp | null)[] | null
  _not?: problem_bool_exp | null
  _or?: (problem_bool_exp | null)[] | null
  commentsCount?: bigint_comparison_exp | null
  facilityName?: String_comparison_exp | null
  facilityRoomName?: String_comparison_exp | null
  id?: Int_comparison_exp | null
  modalityName?: String_comparison_exp | null
  result?: String_comparison_exp | null
  scannerId?: Int_comparison_exp | null
  scannerName?: String_comparison_exp | null
  tableName?: String_comparison_exp | null
  testDate?: timestamp_comparison_exp | null
}

/**
 * ordering options when selecting data from "problem"
 */
export interface problem_order_by {
  commentsCount?: order_by | null
  facilityName?: order_by | null
  facilityRoomName?: order_by | null
  id?: order_by | null
  modalityName?: order_by | null
  result?: order_by | null
  scannerId?: order_by | null
  scannerName?: order_by | null
  tableName?: order_by | null
  testDate?: order_by | null
}

/**
 * order by aggregate values of table "report"
 */
export interface report_aggregate_order_by {
  avg?: report_avg_order_by | null
  count?: order_by | null
  max?: report_max_order_by | null
  min?: report_min_order_by | null
  stddev?: report_stddev_order_by | null
  stddev_pop?: report_stddev_pop_order_by | null
  stddev_samp?: report_stddev_samp_order_by | null
  sum?: report_sum_order_by | null
  var_pop?: report_var_pop_order_by | null
  var_samp?: report_var_samp_order_by | null
  variance?: report_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "report"
 */
export interface report_arr_rel_insert_input {
  data: report_insert_input[]
  on_conflict?: report_on_conflict | null
}

/**
 * order by avg() on columns of table "report"
 */
export interface report_avg_order_by {
  fileId?: order_by | null
  id?: order_by | null
  month?: order_by | null
  surveyTestId?: order_by | null
  year?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "report". All fields are combined with a logical 'AND'.
 */
export interface report_bool_exp {
  _and?: (report_bool_exp | null)[] | null
  _not?: report_bool_exp | null
  _or?: (report_bool_exp | null)[] | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  fileId?: Int_comparison_exp | null
  id?: Int_comparison_exp | null
  month?: Int_comparison_exp | null
  status?: String_comparison_exp | null
  stored_file?: stored_file_bool_exp | null
  surveyTestId?: Int_comparison_exp | null
  survey_test?: survey_test_bool_exp | null
  year?: Int_comparison_exp | null
}

/**
 * input type for inserting data into table "report"
 */
export interface report_insert_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  fileId?: number | null
  id?: number | null
  month?: number | null
  status?: string | null
  stored_file?: stored_file_obj_rel_insert_input | null
  surveyTestId?: number | null
  survey_test?: survey_test_obj_rel_insert_input | null
  year?: number | null
}

/**
 * order by max() on columns of table "report"
 */
export interface report_max_order_by {
  fileId?: order_by | null
  id?: order_by | null
  month?: order_by | null
  status?: order_by | null
  surveyTestId?: order_by | null
  year?: order_by | null
}

/**
 * order by min() on columns of table "report"
 */
export interface report_min_order_by {
  fileId?: order_by | null
  id?: order_by | null
  month?: order_by | null
  status?: order_by | null
  surveyTestId?: order_by | null
  year?: order_by | null
}

/**
 * input type for inserting object relation for remote table "report"
 */
export interface report_obj_rel_insert_input {
  data: report_insert_input
  on_conflict?: report_on_conflict | null
}

/**
 * on conflict condition type for table "report"
 */
export interface report_on_conflict {
  constraint: report_constraint
  update_columns: report_update_column[]
  where?: report_bool_exp | null
}

/**
 * ordering options when selecting data from "report"
 */
export interface report_order_by {
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  fileId?: order_by | null
  id?: order_by | null
  month?: order_by | null
  status?: order_by | null
  stored_file?: stored_file_order_by | null
  surveyTestId?: order_by | null
  survey_test?: survey_test_order_by | null
  year?: order_by | null
}

/**
 * input type for updating data in table "report"
 */
export interface report_set_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  fileId?: number | null
  id?: number | null
  month?: number | null
  status?: string | null
  surveyTestId?: number | null
  year?: number | null
}

/**
 * order by stddev() on columns of table "report"
 */
export interface report_stddev_order_by {
  fileId?: order_by | null
  id?: order_by | null
  month?: order_by | null
  surveyTestId?: order_by | null
  year?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "report"
 */
export interface report_stddev_pop_order_by {
  fileId?: order_by | null
  id?: order_by | null
  month?: order_by | null
  surveyTestId?: order_by | null
  year?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "report"
 */
export interface report_stddev_samp_order_by {
  fileId?: order_by | null
  id?: order_by | null
  month?: order_by | null
  surveyTestId?: order_by | null
  year?: order_by | null
}

/**
 * order by sum() on columns of table "report"
 */
export interface report_sum_order_by {
  fileId?: order_by | null
  id?: order_by | null
  month?: order_by | null
  surveyTestId?: order_by | null
  year?: order_by | null
}

/**
 * order by var_pop() on columns of table "report"
 */
export interface report_var_pop_order_by {
  fileId?: order_by | null
  id?: order_by | null
  month?: order_by | null
  surveyTestId?: order_by | null
  year?: order_by | null
}

/**
 * order by var_samp() on columns of table "report"
 */
export interface report_var_samp_order_by {
  fileId?: order_by | null
  id?: order_by | null
  month?: order_by | null
  surveyTestId?: order_by | null
  year?: order_by | null
}

/**
 * order by variance() on columns of table "report"
 */
export interface report_variance_order_by {
  fileId?: order_by | null
  id?: order_by | null
  month?: order_by | null
  surveyTestId?: order_by | null
  year?: order_by | null
}

/**
 * order by aggregate values of table "scanner"
 */
export interface scanner_aggregate_order_by {
  avg?: scanner_avg_order_by | null
  count?: order_by | null
  max?: scanner_max_order_by | null
  min?: scanner_min_order_by | null
  stddev?: scanner_stddev_order_by | null
  stddev_pop?: scanner_stddev_pop_order_by | null
  stddev_samp?: scanner_stddev_samp_order_by | null
  sum?: scanner_sum_order_by | null
  var_pop?: scanner_var_pop_order_by | null
  var_samp?: scanner_var_samp_order_by | null
  variance?: scanner_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "scanner"
 */
export interface scanner_arr_rel_insert_input {
  data: scanner_insert_input[]
  on_conflict?: scanner_on_conflict | null
}

/**
 * order by avg() on columns of table "scanner"
 */
export interface scanner_avg_order_by {
  facilityId?: order_by | null
  facilityRoomId?: order_by | null
  id?: order_by | null
  scannerManufacturerId?: order_by | null
  scannerModelId?: order_by | null
  scheduleGrace?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "scanner". All fields are combined with a logical 'AND'.
 */
export interface scanner_bool_exp {
  _and?: (scanner_bool_exp | null)[] | null
  _not?: scanner_bool_exp | null
  _or?: (scanner_bool_exp | null)[] | null
  comments?: comment_bool_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  deviceType?: String_comparison_exp | null
  extra?: jsonb_comparison_exp | null
  facility?: facility_bool_exp | null
  facilityId?: Int_comparison_exp | null
  facilityRoomId?: Int_comparison_exp | null
  facility_room?: facility_room_bool_exp | null
  id?: Int_comparison_exp | null
  institutionNameTag?: String_comparison_exp | null
  missed_phantom_tests?: missed_phantom_test_bool_exp | null
  modality?: modality_bool_exp | null
  modalityId?: String_comparison_exp | null
  name?: String_comparison_exp | null
  phantom_tests?: phantom_test_bool_exp | null
  scannerManufacturerId?: Int_comparison_exp | null
  scannerModelId?: Int_comparison_exp | null
  scanner_manufacturer?: scanner_manufacturer_bool_exp | null
  scanner_model?: scanner_model_bool_exp | null
  scanner_phantom_test_routines_phantom_test_routines?: scanner_phantom_test_routines_phantom_test_routine_bool_exp | null
  scanner_schedules_schedules?: scanner_schedules_schedule_bool_exp | null
  schedule?: String_comparison_exp | null
  scheduleGrace?: Int_comparison_exp | null
  schedule_scanners_scanners?: schedule_scanners_scanner_bool_exp | null
  stationNameTag?: String_comparison_exp | null
  studies?: study_bool_exp | null
  supportContacts?: jsonb_comparison_exp | null
  survey_tests?: survey_test_bool_exp | null
}

/**
 * input type for inserting data into table "scanner"
 */
export interface scanner_insert_input {
  comments?: comment_arr_rel_insert_input | null
  dateCreated?: any | null
  dateUpdated?: any | null
  deviceType?: string | null
  extra?: any | null
  facility?: facility_obj_rel_insert_input | null
  facilityId?: number | null
  facilityRoomId?: number | null
  facility_room?: facility_room_obj_rel_insert_input | null
  id?: number | null
  institutionNameTag?: string | null
  missed_phantom_tests?: missed_phantom_test_arr_rel_insert_input | null
  modality?: modality_obj_rel_insert_input | null
  modalityId?: string | null
  name?: string | null
  phantom_tests?: phantom_test_arr_rel_insert_input | null
  scannerManufacturerId?: number | null
  scannerModelId?: number | null
  scanner_manufacturer?: scanner_manufacturer_obj_rel_insert_input | null
  scanner_model?: scanner_model_obj_rel_insert_input | null
  scanner_phantom_test_routines_phantom_test_routines?: scanner_phantom_test_routines_phantom_test_routine_arr_rel_insert_input | null
  scanner_schedules_schedules?: scanner_schedules_schedule_arr_rel_insert_input | null
  schedule?: string | null
  scheduleGrace?: number | null
  schedule_scanners_scanners?: schedule_scanners_scanner_arr_rel_insert_input | null
  stationNameTag?: string | null
  studies?: study_arr_rel_insert_input | null
  supportContacts?: any | null
  survey_tests?: survey_test_arr_rel_insert_input | null
}

/**
 * Boolean expression to filter rows from the table "scanner_manufacturer". All fields are combined with a logical 'AND'.
 */
export interface scanner_manufacturer_bool_exp {
  _and?: (scanner_manufacturer_bool_exp | null)[] | null
  _not?: scanner_manufacturer_bool_exp | null
  _or?: (scanner_manufacturer_bool_exp | null)[] | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: Int_comparison_exp | null
  name?: String_comparison_exp | null
  scanners?: scanner_bool_exp | null
}

/**
 * input type for inserting data into table "scanner_manufacturer"
 */
export interface scanner_manufacturer_insert_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  name?: string | null
  scanners?: scanner_arr_rel_insert_input | null
}

/**
 * input type for inserting object relation for remote table "scanner_manufacturer"
 */
export interface scanner_manufacturer_obj_rel_insert_input {
  data: scanner_manufacturer_insert_input
  on_conflict?: scanner_manufacturer_on_conflict | null
}

/**
 * on conflict condition type for table "scanner_manufacturer"
 */
export interface scanner_manufacturer_on_conflict {
  constraint: scanner_manufacturer_constraint
  update_columns: scanner_manufacturer_update_column[]
  where?: scanner_manufacturer_bool_exp | null
}

/**
 * ordering options when selecting data from "scanner_manufacturer"
 */
export interface scanner_manufacturer_order_by {
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  id?: order_by | null
  name?: order_by | null
  scanners_aggregate?: scanner_aggregate_order_by | null
}

/**
 * order by max() on columns of table "scanner"
 */
export interface scanner_max_order_by {
  deviceType?: order_by | null
  facilityId?: order_by | null
  facilityRoomId?: order_by | null
  id?: order_by | null
  institutionNameTag?: order_by | null
  modalityId?: order_by | null
  name?: order_by | null
  scannerManufacturerId?: order_by | null
  scannerModelId?: order_by | null
  schedule?: order_by | null
  scheduleGrace?: order_by | null
  stationNameTag?: order_by | null
}

/**
 * order by min() on columns of table "scanner"
 */
export interface scanner_min_order_by {
  deviceType?: order_by | null
  facilityId?: order_by | null
  facilityRoomId?: order_by | null
  id?: order_by | null
  institutionNameTag?: order_by | null
  modalityId?: order_by | null
  name?: order_by | null
  scannerManufacturerId?: order_by | null
  scannerModelId?: order_by | null
  schedule?: order_by | null
  scheduleGrace?: order_by | null
  stationNameTag?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "scanner_model". All fields are combined with a logical 'AND'.
 */
export interface scanner_model_bool_exp {
  _and?: (scanner_model_bool_exp | null)[] | null
  _not?: scanner_model_bool_exp | null
  _or?: (scanner_model_bool_exp | null)[] | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: Int_comparison_exp | null
  name?: String_comparison_exp | null
  scanners?: scanner_bool_exp | null
}

/**
 * input type for inserting data into table "scanner_model"
 */
export interface scanner_model_insert_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  name?: string | null
  scanners?: scanner_arr_rel_insert_input | null
}

/**
 * input type for inserting object relation for remote table "scanner_model"
 */
export interface scanner_model_obj_rel_insert_input {
  data: scanner_model_insert_input
  on_conflict?: scanner_model_on_conflict | null
}

/**
 * on conflict condition type for table "scanner_model"
 */
export interface scanner_model_on_conflict {
  constraint: scanner_model_constraint
  update_columns: scanner_model_update_column[]
  where?: scanner_model_bool_exp | null
}

/**
 * ordering options when selecting data from "scanner_model"
 */
export interface scanner_model_order_by {
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  id?: order_by | null
  name?: order_by | null
  scanners_aggregate?: scanner_aggregate_order_by | null
}

/**
 * input type for inserting object relation for remote table "scanner"
 */
export interface scanner_obj_rel_insert_input {
  data: scanner_insert_input
  on_conflict?: scanner_on_conflict | null
}

/**
 * on conflict condition type for table "scanner"
 */
export interface scanner_on_conflict {
  constraint: scanner_constraint
  update_columns: scanner_update_column[]
  where?: scanner_bool_exp | null
}

/**
 * ordering options when selecting data from "scanner"
 */
export interface scanner_order_by {
  comments_aggregate?: comment_aggregate_order_by | null
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  deviceType?: order_by | null
  extra?: order_by | null
  facility?: facility_order_by | null
  facilityId?: order_by | null
  facilityRoomId?: order_by | null
  facility_room?: facility_room_order_by | null
  id?: order_by | null
  institutionNameTag?: order_by | null
  missed_phantom_tests_aggregate?: missed_phantom_test_aggregate_order_by | null
  modality?: modality_order_by | null
  modalityId?: order_by | null
  name?: order_by | null
  phantom_tests_aggregate?: phantom_test_aggregate_order_by | null
  scannerManufacturerId?: order_by | null
  scannerModelId?: order_by | null
  scanner_manufacturer?: scanner_manufacturer_order_by | null
  scanner_model?: scanner_model_order_by | null
  scanner_phantom_test_routines_phantom_test_routines_aggregate?: scanner_phantom_test_routines_phantom_test_routine_aggregate_order_by | null
  scanner_schedules_schedules_aggregate?: scanner_schedules_schedule_aggregate_order_by | null
  schedule?: order_by | null
  scheduleGrace?: order_by | null
  schedule_scanners_scanners_aggregate?: schedule_scanners_scanner_aggregate_order_by | null
  stationNameTag?: order_by | null
  studies_aggregate?: study_aggregate_order_by | null
  supportContacts?: order_by | null
  survey_tests_aggregate?: survey_test_aggregate_order_by | null
}

/**
 * order by aggregate values of table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_aggregate_order_by {
  avg?: scanner_phantom_test_routines_phantom_test_routine_avg_order_by | null
  count?: order_by | null
  max?: scanner_phantom_test_routines_phantom_test_routine_max_order_by | null
  min?: scanner_phantom_test_routines_phantom_test_routine_min_order_by | null
  stddev?: scanner_phantom_test_routines_phantom_test_routine_stddev_order_by | null
  stddev_pop?: scanner_phantom_test_routines_phantom_test_routine_stddev_pop_order_by | null
  stddev_samp?: scanner_phantom_test_routines_phantom_test_routine_stddev_samp_order_by | null
  sum?: scanner_phantom_test_routines_phantom_test_routine_sum_order_by | null
  var_pop?: scanner_phantom_test_routines_phantom_test_routine_var_pop_order_by | null
  var_samp?: scanner_phantom_test_routines_phantom_test_routine_var_samp_order_by | null
  variance?: scanner_phantom_test_routines_phantom_test_routine_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_arr_rel_insert_input {
  data: scanner_phantom_test_routines_phantom_test_routine_insert_input[]
  on_conflict?: scanner_phantom_test_routines_phantom_test_routine_on_conflict | null
}

/**
 * order by avg() on columns of table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_avg_order_by {
  phantomTestRoutineId?: order_by | null
  scannerId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "scanner_phantom_test_routines_phantom_test_routine". All fields are combined with a logical 'AND'.
 */
export interface scanner_phantom_test_routines_phantom_test_routine_bool_exp {
  _and?:
    | (scanner_phantom_test_routines_phantom_test_routine_bool_exp | null)[]
    | null
  _not?: scanner_phantom_test_routines_phantom_test_routine_bool_exp | null
  _or?:
    | (scanner_phantom_test_routines_phantom_test_routine_bool_exp | null)[]
    | null
  phantomTestRoutineId?: Int_comparison_exp | null
  phantom_test_routine?: phantom_test_routine_bool_exp | null
  scanner?: scanner_bool_exp | null
  scannerId?: Int_comparison_exp | null
}

/**
 * input type for inserting data into table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_insert_input {
  phantomTestRoutineId?: number | null
  phantom_test_routine?: phantom_test_routine_obj_rel_insert_input | null
  scanner?: scanner_obj_rel_insert_input | null
  scannerId?: number | null
}

/**
 * order by max() on columns of table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_max_order_by {
  phantomTestRoutineId?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by min() on columns of table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_min_order_by {
  phantomTestRoutineId?: order_by | null
  scannerId?: order_by | null
}

/**
 * on conflict condition type for table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_on_conflict {
  constraint: scanner_phantom_test_routines_phantom_test_routine_constraint
  update_columns: scanner_phantom_test_routines_phantom_test_routine_update_column[]
  where?: scanner_phantom_test_routines_phantom_test_routine_bool_exp | null
}

/**
 * order by stddev() on columns of table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_stddev_order_by {
  phantomTestRoutineId?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_stddev_pop_order_by {
  phantomTestRoutineId?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_stddev_samp_order_by {
  phantomTestRoutineId?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by sum() on columns of table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_sum_order_by {
  phantomTestRoutineId?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by var_pop() on columns of table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_var_pop_order_by {
  phantomTestRoutineId?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by var_samp() on columns of table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_var_samp_order_by {
  phantomTestRoutineId?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by variance() on columns of table "scanner_phantom_test_routines_phantom_test_routine"
 */
export interface scanner_phantom_test_routines_phantom_test_routine_variance_order_by {
  phantomTestRoutineId?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by aggregate values of table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_aggregate_order_by {
  avg?: scanner_schedules_schedule_avg_order_by | null
  count?: order_by | null
  max?: scanner_schedules_schedule_max_order_by | null
  min?: scanner_schedules_schedule_min_order_by | null
  stddev?: scanner_schedules_schedule_stddev_order_by | null
  stddev_pop?: scanner_schedules_schedule_stddev_pop_order_by | null
  stddev_samp?: scanner_schedules_schedule_stddev_samp_order_by | null
  sum?: scanner_schedules_schedule_sum_order_by | null
  var_pop?: scanner_schedules_schedule_var_pop_order_by | null
  var_samp?: scanner_schedules_schedule_var_samp_order_by | null
  variance?: scanner_schedules_schedule_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_arr_rel_insert_input {
  data: scanner_schedules_schedule_insert_input[]
  on_conflict?: scanner_schedules_schedule_on_conflict | null
}

/**
 * order by avg() on columns of table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_avg_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "scanner_schedules_schedule". All fields are combined with a logical 'AND'.
 */
export interface scanner_schedules_schedule_bool_exp {
  _and?: (scanner_schedules_schedule_bool_exp | null)[] | null
  _not?: scanner_schedules_schedule_bool_exp | null
  _or?: (scanner_schedules_schedule_bool_exp | null)[] | null
  scanner?: scanner_bool_exp | null
  scannerId?: Int_comparison_exp | null
  schedule?: schedule_bool_exp | null
  scheduleId?: Int_comparison_exp | null
}

/**
 * input type for inserting data into table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_insert_input {
  scanner?: scanner_obj_rel_insert_input | null
  scannerId?: number | null
  schedule?: schedule_obj_rel_insert_input | null
  scheduleId?: number | null
}

/**
 * order by max() on columns of table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_max_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by min() on columns of table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_min_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * on conflict condition type for table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_on_conflict {
  constraint: scanner_schedules_schedule_constraint
  update_columns: scanner_schedules_schedule_update_column[]
  where?: scanner_schedules_schedule_bool_exp | null
}

/**
 * order by stddev() on columns of table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_stddev_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_stddev_pop_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_stddev_samp_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by sum() on columns of table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_sum_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by var_pop() on columns of table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_var_pop_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by var_samp() on columns of table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_var_samp_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by variance() on columns of table "scanner_schedules_schedule"
 */
export interface scanner_schedules_schedule_variance_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * input type for updating data in table "scanner"
 */
export interface scanner_set_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  deviceType?: string | null
  extra?: any | null
  facilityId?: number | null
  facilityRoomId?: number | null
  id?: number | null
  institutionNameTag?: string | null
  modalityId?: string | null
  name?: string | null
  scannerManufacturerId?: number | null
  scannerModelId?: number | null
  schedule?: string | null
  scheduleGrace?: number | null
  stationNameTag?: string | null
  supportContacts?: any | null
}

/**
 * order by stddev() on columns of table "scanner"
 */
export interface scanner_stddev_order_by {
  facilityId?: order_by | null
  facilityRoomId?: order_by | null
  id?: order_by | null
  scannerManufacturerId?: order_by | null
  scannerModelId?: order_by | null
  scheduleGrace?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "scanner"
 */
export interface scanner_stddev_pop_order_by {
  facilityId?: order_by | null
  facilityRoomId?: order_by | null
  id?: order_by | null
  scannerManufacturerId?: order_by | null
  scannerModelId?: order_by | null
  scheduleGrace?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "scanner"
 */
export interface scanner_stddev_samp_order_by {
  facilityId?: order_by | null
  facilityRoomId?: order_by | null
  id?: order_by | null
  scannerManufacturerId?: order_by | null
  scannerModelId?: order_by | null
  scheduleGrace?: order_by | null
}

/**
 * order by sum() on columns of table "scanner"
 */
export interface scanner_sum_order_by {
  facilityId?: order_by | null
  facilityRoomId?: order_by | null
  id?: order_by | null
  scannerManufacturerId?: order_by | null
  scannerModelId?: order_by | null
  scheduleGrace?: order_by | null
}

/**
 * order by var_pop() on columns of table "scanner"
 */
export interface scanner_var_pop_order_by {
  facilityId?: order_by | null
  facilityRoomId?: order_by | null
  id?: order_by | null
  scannerManufacturerId?: order_by | null
  scannerModelId?: order_by | null
  scheduleGrace?: order_by | null
}

/**
 * order by var_samp() on columns of table "scanner"
 */
export interface scanner_var_samp_order_by {
  facilityId?: order_by | null
  facilityRoomId?: order_by | null
  id?: order_by | null
  scannerManufacturerId?: order_by | null
  scannerModelId?: order_by | null
  scheduleGrace?: order_by | null
}

/**
 * order by variance() on columns of table "scanner"
 */
export interface scanner_variance_order_by {
  facilityId?: order_by | null
  facilityRoomId?: order_by | null
  id?: order_by | null
  scannerManufacturerId?: order_by | null
  scannerModelId?: order_by | null
  scheduleGrace?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "schedule". All fields are combined with a logical 'AND'.
 */
export interface schedule_bool_exp {
  _and?: (schedule_bool_exp | null)[] | null
  _not?: schedule_bool_exp | null
  _or?: (schedule_bool_exp | null)[] | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  graceAmount?: Int_comparison_exp | null
  graceType?: String_comparison_exp | null
  id?: Int_comparison_exp | null
  interval?: String_comparison_exp | null
  phantom_test_routine_schedules_schedules?: phantom_test_routine_schedules_schedule_bool_exp | null
  priorToAmount?: Int_comparison_exp | null
  priorToType?: String_comparison_exp | null
  reminderAmount?: Int_comparison_exp | null
  reminderType?: String_comparison_exp | null
  scanner_schedules_schedules?: scanner_schedules_schedule_bool_exp | null
  schedule_phantom_test_routines_phantom_test_routines?: schedule_phantom_test_routines_phantom_test_routine_bool_exp | null
  schedule_scanners_scanners?: schedule_scanners_scanner_bool_exp | null
  schedule_survey_test_routines_survey_test_routines?: schedule_survey_test_routines_survey_test_routine_bool_exp | null
  survey_test_routine_schedules_schedules?: survey_test_routine_schedules_schedule_bool_exp | null
  tasks?: jsonb_comparison_exp | null
  title?: String_comparison_exp | null
}

/**
 * input type for inserting data into table "schedule"
 */
export interface schedule_insert_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  graceAmount?: number | null
  graceType?: string | null
  id?: number | null
  interval?: string | null
  phantom_test_routine_schedules_schedules?: phantom_test_routine_schedules_schedule_arr_rel_insert_input | null
  priorToAmount?: number | null
  priorToType?: string | null
  reminderAmount?: number | null
  reminderType?: string | null
  scanner_schedules_schedules?: scanner_schedules_schedule_arr_rel_insert_input | null
  schedule_phantom_test_routines_phantom_test_routines?: schedule_phantom_test_routines_phantom_test_routine_arr_rel_insert_input | null
  schedule_scanners_scanners?: schedule_scanners_scanner_arr_rel_insert_input | null
  schedule_survey_test_routines_survey_test_routines?: schedule_survey_test_routines_survey_test_routine_arr_rel_insert_input | null
  survey_test_routine_schedules_schedules?: survey_test_routine_schedules_schedule_arr_rel_insert_input | null
  tasks?: any | null
  title?: string | null
}

/**
 * input type for inserting object relation for remote table "schedule"
 */
export interface schedule_obj_rel_insert_input {
  data: schedule_insert_input
  on_conflict?: schedule_on_conflict | null
}

/**
 * on conflict condition type for table "schedule"
 */
export interface schedule_on_conflict {
  constraint: schedule_constraint
  update_columns: schedule_update_column[]
  where?: schedule_bool_exp | null
}

/**
 * order by aggregate values of table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_aggregate_order_by {
  avg?: schedule_phantom_test_routines_phantom_test_routine_avg_order_by | null
  count?: order_by | null
  max?: schedule_phantom_test_routines_phantom_test_routine_max_order_by | null
  min?: schedule_phantom_test_routines_phantom_test_routine_min_order_by | null
  stddev?: schedule_phantom_test_routines_phantom_test_routine_stddev_order_by | null
  stddev_pop?: schedule_phantom_test_routines_phantom_test_routine_stddev_pop_order_by | null
  stddev_samp?: schedule_phantom_test_routines_phantom_test_routine_stddev_samp_order_by | null
  sum?: schedule_phantom_test_routines_phantom_test_routine_sum_order_by | null
  var_pop?: schedule_phantom_test_routines_phantom_test_routine_var_pop_order_by | null
  var_samp?: schedule_phantom_test_routines_phantom_test_routine_var_samp_order_by | null
  variance?: schedule_phantom_test_routines_phantom_test_routine_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_arr_rel_insert_input {
  data: schedule_phantom_test_routines_phantom_test_routine_insert_input[]
  on_conflict?: schedule_phantom_test_routines_phantom_test_routine_on_conflict | null
}

/**
 * order by avg() on columns of table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_avg_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "schedule_phantom_test_routines_phantom_test_routine". All fields are combined with a logical 'AND'.
 */
export interface schedule_phantom_test_routines_phantom_test_routine_bool_exp {
  _and?:
    | (schedule_phantom_test_routines_phantom_test_routine_bool_exp | null)[]
    | null
  _not?: schedule_phantom_test_routines_phantom_test_routine_bool_exp | null
  _or?:
    | (schedule_phantom_test_routines_phantom_test_routine_bool_exp | null)[]
    | null
  phantomTestRoutineId?: Int_comparison_exp | null
  phantom_test_routine?: phantom_test_routine_bool_exp | null
  schedule?: schedule_bool_exp | null
  scheduleId?: Int_comparison_exp | null
}

/**
 * input type for inserting data into table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_insert_input {
  phantomTestRoutineId?: number | null
  phantom_test_routine?: phantom_test_routine_obj_rel_insert_input | null
  schedule?: schedule_obj_rel_insert_input | null
  scheduleId?: number | null
}

/**
 * order by max() on columns of table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_max_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by min() on columns of table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_min_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * on conflict condition type for table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_on_conflict {
  constraint: schedule_phantom_test_routines_phantom_test_routine_constraint
  update_columns: schedule_phantom_test_routines_phantom_test_routine_update_column[]
  where?: schedule_phantom_test_routines_phantom_test_routine_bool_exp | null
}

/**
 * order by stddev() on columns of table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_stddev_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_stddev_pop_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_stddev_samp_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by sum() on columns of table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_sum_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by var_pop() on columns of table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_var_pop_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by var_samp() on columns of table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_var_samp_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by variance() on columns of table "schedule_phantom_test_routines_phantom_test_routine"
 */
export interface schedule_phantom_test_routines_phantom_test_routine_variance_order_by {
  phantomTestRoutineId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by aggregate values of table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_aggregate_order_by {
  avg?: schedule_scanners_scanner_avg_order_by | null
  count?: order_by | null
  max?: schedule_scanners_scanner_max_order_by | null
  min?: schedule_scanners_scanner_min_order_by | null
  stddev?: schedule_scanners_scanner_stddev_order_by | null
  stddev_pop?: schedule_scanners_scanner_stddev_pop_order_by | null
  stddev_samp?: schedule_scanners_scanner_stddev_samp_order_by | null
  sum?: schedule_scanners_scanner_sum_order_by | null
  var_pop?: schedule_scanners_scanner_var_pop_order_by | null
  var_samp?: schedule_scanners_scanner_var_samp_order_by | null
  variance?: schedule_scanners_scanner_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_arr_rel_insert_input {
  data: schedule_scanners_scanner_insert_input[]
  on_conflict?: schedule_scanners_scanner_on_conflict | null
}

/**
 * order by avg() on columns of table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_avg_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "schedule_scanners_scanner". All fields are combined with a logical 'AND'.
 */
export interface schedule_scanners_scanner_bool_exp {
  _and?: (schedule_scanners_scanner_bool_exp | null)[] | null
  _not?: schedule_scanners_scanner_bool_exp | null
  _or?: (schedule_scanners_scanner_bool_exp | null)[] | null
  scanner?: scanner_bool_exp | null
  scannerId?: Int_comparison_exp | null
  schedule?: schedule_bool_exp | null
  scheduleId?: Int_comparison_exp | null
}

/**
 * input type for inserting data into table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_insert_input {
  scanner?: scanner_obj_rel_insert_input | null
  scannerId?: number | null
  schedule?: schedule_obj_rel_insert_input | null
  scheduleId?: number | null
}

/**
 * order by max() on columns of table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_max_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by min() on columns of table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_min_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * on conflict condition type for table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_on_conflict {
  constraint: schedule_scanners_scanner_constraint
  update_columns: schedule_scanners_scanner_update_column[]
  where?: schedule_scanners_scanner_bool_exp | null
}

/**
 * order by stddev() on columns of table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_stddev_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_stddev_pop_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_stddev_samp_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by sum() on columns of table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_sum_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by var_pop() on columns of table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_var_pop_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by var_samp() on columns of table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_var_samp_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * order by variance() on columns of table "schedule_scanners_scanner"
 */
export interface schedule_scanners_scanner_variance_order_by {
  scannerId?: order_by | null
  scheduleId?: order_by | null
}

/**
 * input type for updating data in table "schedule"
 */
export interface schedule_set_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  graceAmount?: number | null
  graceType?: string | null
  id?: number | null
  interval?: string | null
  priorToAmount?: number | null
  priorToType?: string | null
  reminderAmount?: number | null
  reminderType?: string | null
  tasks?: any | null
  title?: string | null
}

/**
 * order by aggregate values of table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_aggregate_order_by {
  avg?: schedule_survey_test_routines_survey_test_routine_avg_order_by | null
  count?: order_by | null
  max?: schedule_survey_test_routines_survey_test_routine_max_order_by | null
  min?: schedule_survey_test_routines_survey_test_routine_min_order_by | null
  stddev?: schedule_survey_test_routines_survey_test_routine_stddev_order_by | null
  stddev_pop?: schedule_survey_test_routines_survey_test_routine_stddev_pop_order_by | null
  stddev_samp?: schedule_survey_test_routines_survey_test_routine_stddev_samp_order_by | null
  sum?: schedule_survey_test_routines_survey_test_routine_sum_order_by | null
  var_pop?: schedule_survey_test_routines_survey_test_routine_var_pop_order_by | null
  var_samp?: schedule_survey_test_routines_survey_test_routine_var_samp_order_by | null
  variance?: schedule_survey_test_routines_survey_test_routine_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_arr_rel_insert_input {
  data: schedule_survey_test_routines_survey_test_routine_insert_input[]
  on_conflict?: schedule_survey_test_routines_survey_test_routine_on_conflict | null
}

/**
 * order by avg() on columns of table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_avg_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "schedule_survey_test_routines_survey_test_routine". All fields are combined with a logical 'AND'.
 */
export interface schedule_survey_test_routines_survey_test_routine_bool_exp {
  _and?:
    | (schedule_survey_test_routines_survey_test_routine_bool_exp | null)[]
    | null
  _not?: schedule_survey_test_routines_survey_test_routine_bool_exp | null
  _or?:
    | (schedule_survey_test_routines_survey_test_routine_bool_exp | null)[]
    | null
  schedule?: schedule_bool_exp | null
  scheduleId?: Int_comparison_exp | null
  surveyTestRoutineId?: Int_comparison_exp | null
  survey_test_routine?: survey_test_routine_bool_exp | null
}

/**
 * input type for inserting data into table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_insert_input {
  schedule?: schedule_obj_rel_insert_input | null
  scheduleId?: number | null
  surveyTestRoutineId?: number | null
  survey_test_routine?: survey_test_routine_obj_rel_insert_input | null
}

/**
 * order by max() on columns of table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_max_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by min() on columns of table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_min_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * on conflict condition type for table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_on_conflict {
  constraint: schedule_survey_test_routines_survey_test_routine_constraint
  update_columns: schedule_survey_test_routines_survey_test_routine_update_column[]
  where?: schedule_survey_test_routines_survey_test_routine_bool_exp | null
}

/**
 * order by stddev() on columns of table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_stddev_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_stddev_pop_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_stddev_samp_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by sum() on columns of table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_sum_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by var_pop() on columns of table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_var_pop_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by var_samp() on columns of table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_var_samp_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by variance() on columns of table "schedule_survey_test_routines_survey_test_routine"
 */
export interface schedule_survey_test_routines_survey_test_routine_variance_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "setting". All fields are combined with a logical 'AND'.
 */
export interface setting_bool_exp {
  _and?: (setting_bool_exp | null)[] | null
  _not?: setting_bool_exp | null
  _or?: (setting_bool_exp | null)[] | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  json?: jsonb_comparison_exp | null
  key?: String_comparison_exp | null
  value?: String_comparison_exp | null
}

/**
 * ordering options when selecting data from "setting"
 */
export interface setting_order_by {
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  json?: order_by | null
  key?: order_by | null
  value?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "stored_file". All fields are combined with a logical 'AND'.
 */
export interface stored_file_bool_exp {
  _and?: (stored_file_bool_exp | null)[] | null
  _not?: stored_file_bool_exp | null
  _or?: (stored_file_bool_exp | null)[] | null
  comment_file?: comment_file_bool_exp | null
  common?: Boolean_comparison_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: Int_comparison_exp | null
  key?: String_comparison_exp | null
  phantom_test_image?: phantom_test_image_bool_exp | null
  report?: report_bool_exp | null
  studyImageById?: study_image_bool_exp | null
  study_image?: study_image_bool_exp | null
}

/**
 * input type for inserting data into table "stored_file"
 */
export interface stored_file_insert_input {
  comment_file?: comment_file_obj_rel_insert_input | null
  common?: boolean | null
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  key?: string | null
  phantom_test_image?: phantom_test_image_obj_rel_insert_input | null
  report?: report_obj_rel_insert_input | null
  studyImageById?: study_image_obj_rel_insert_input | null
  study_image?: study_image_obj_rel_insert_input | null
}

/**
 * input type for inserting object relation for remote table "stored_file"
 */
export interface stored_file_obj_rel_insert_input {
  data: stored_file_insert_input
  on_conflict?: stored_file_on_conflict | null
}

/**
 * on conflict condition type for table "stored_file"
 */
export interface stored_file_on_conflict {
  constraint: stored_file_constraint
  update_columns: stored_file_update_column[]
  where?: stored_file_bool_exp | null
}

/**
 * ordering options when selecting data from "stored_file"
 */
export interface stored_file_order_by {
  comment_file?: comment_file_order_by | null
  common?: order_by | null
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  id?: order_by | null
  key?: order_by | null
  phantom_test_image?: phantom_test_image_order_by | null
  report?: report_order_by | null
  studyImageById?: study_image_order_by | null
  study_image?: study_image_order_by | null
}

/**
 * order by aggregate values of table "study"
 */
export interface study_aggregate_order_by {
  avg?: study_avg_order_by | null
  count?: order_by | null
  max?: study_max_order_by | null
  min?: study_min_order_by | null
  stddev?: study_stddev_order_by | null
  stddev_pop?: study_stddev_pop_order_by | null
  stddev_samp?: study_stddev_samp_order_by | null
  sum?: study_sum_order_by | null
  var_pop?: study_var_pop_order_by | null
  var_samp?: study_var_samp_order_by | null
  variance?: study_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "study"
 */
export interface study_arr_rel_insert_input {
  data: study_insert_input[]
  on_conflict?: study_on_conflict | null
}

/**
 * order by avg() on columns of table "study"
 */
export interface study_avg_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "study". All fields are combined with a logical 'AND'.
 */
export interface study_bool_exp {
  _and?: (study_bool_exp | null)[] | null
  _not?: study_bool_exp | null
  _or?: (study_bool_exp | null)[] | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  extra?: jsonb_comparison_exp | null
  filmedBy?: String_comparison_exp | null
  id?: Int_comparison_exp | null
  modality?: modality_bool_exp | null
  modalityId?: String_comparison_exp | null
  phantom_tests?: phantom_test_bool_exp | null
  scanDate?: timestamp_comparison_exp | null
  scanner?: scanner_bool_exp | null
  scannerId?: Int_comparison_exp | null
  study_images?: study_image_bool_exp | null
  uid?: String_comparison_exp | null
}

/**
 * order by aggregate values of table "study_image"
 */
export interface study_image_aggregate_order_by {
  avg?: study_image_avg_order_by | null
  count?: order_by | null
  max?: study_image_max_order_by | null
  min?: study_image_min_order_by | null
  stddev?: study_image_stddev_order_by | null
  stddev_pop?: study_image_stddev_pop_order_by | null
  stddev_samp?: study_image_stddev_samp_order_by | null
  sum?: study_image_sum_order_by | null
  var_pop?: study_image_var_pop_order_by | null
  var_samp?: study_image_var_samp_order_by | null
  variance?: study_image_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "study_image"
 */
export interface study_image_arr_rel_insert_input {
  data: study_image_insert_input[]
  on_conflict?: study_image_on_conflict | null
}

/**
 * order by avg() on columns of table "study_image"
 */
export interface study_image_avg_order_by {
  dcmFileId?: order_by | null
  id?: order_by | null
  renderedFileId?: order_by | null
  studyId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "study_image". All fields are combined with a logical 'AND'.
 */
export interface study_image_bool_exp {
  _and?: (study_image_bool_exp | null)[] | null
  _not?: study_image_bool_exp | null
  _or?: (study_image_bool_exp | null)[] | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  dcmFileId?: Int_comparison_exp | null
  extra?: jsonb_comparison_exp | null
  id?: Int_comparison_exp | null
  imageUID?: String_comparison_exp | null
  label?: String_comparison_exp | null
  renderedFileId?: Int_comparison_exp | null
  scanDate?: timestamp_comparison_exp | null
  storedFileByDcmfileid?: stored_file_bool_exp | null
  storedFileByRenderedfileid?: stored_file_bool_exp | null
  study?: study_bool_exp | null
  studyId?: Int_comparison_exp | null
}

/**
 * input type for inserting data into table "study_image"
 */
export interface study_image_insert_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  dcmFileId?: number | null
  extra?: any | null
  id?: number | null
  imageUID?: string | null
  label?: string | null
  renderedFileId?: number | null
  scanDate?: any | null
  storedFileByDcmfileid?: stored_file_obj_rel_insert_input | null
  storedFileByRenderedfileid?: stored_file_obj_rel_insert_input | null
  study?: study_obj_rel_insert_input | null
  studyId?: number | null
}

/**
 * order by max() on columns of table "study_image"
 */
export interface study_image_max_order_by {
  dcmFileId?: order_by | null
  id?: order_by | null
  imageUID?: order_by | null
  label?: order_by | null
  renderedFileId?: order_by | null
  studyId?: order_by | null
}

/**
 * order by min() on columns of table "study_image"
 */
export interface study_image_min_order_by {
  dcmFileId?: order_by | null
  id?: order_by | null
  imageUID?: order_by | null
  label?: order_by | null
  renderedFileId?: order_by | null
  studyId?: order_by | null
}

/**
 * input type for inserting object relation for remote table "study_image"
 */
export interface study_image_obj_rel_insert_input {
  data: study_image_insert_input
  on_conflict?: study_image_on_conflict | null
}

/**
 * on conflict condition type for table "study_image"
 */
export interface study_image_on_conflict {
  constraint: study_image_constraint
  update_columns: study_image_update_column[]
  where?: study_image_bool_exp | null
}

/**
 * ordering options when selecting data from "study_image"
 */
export interface study_image_order_by {
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  dcmFileId?: order_by | null
  extra?: order_by | null
  id?: order_by | null
  imageUID?: order_by | null
  label?: order_by | null
  renderedFileId?: order_by | null
  scanDate?: order_by | null
  storedFileByDcmfileid?: stored_file_order_by | null
  storedFileByRenderedfileid?: stored_file_order_by | null
  study?: study_order_by | null
  studyId?: order_by | null
}

/**
 * order by stddev() on columns of table "study_image"
 */
export interface study_image_stddev_order_by {
  dcmFileId?: order_by | null
  id?: order_by | null
  renderedFileId?: order_by | null
  studyId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "study_image"
 */
export interface study_image_stddev_pop_order_by {
  dcmFileId?: order_by | null
  id?: order_by | null
  renderedFileId?: order_by | null
  studyId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "study_image"
 */
export interface study_image_stddev_samp_order_by {
  dcmFileId?: order_by | null
  id?: order_by | null
  renderedFileId?: order_by | null
  studyId?: order_by | null
}

/**
 * order by sum() on columns of table "study_image"
 */
export interface study_image_sum_order_by {
  dcmFileId?: order_by | null
  id?: order_by | null
  renderedFileId?: order_by | null
  studyId?: order_by | null
}

/**
 * order by var_pop() on columns of table "study_image"
 */
export interface study_image_var_pop_order_by {
  dcmFileId?: order_by | null
  id?: order_by | null
  renderedFileId?: order_by | null
  studyId?: order_by | null
}

/**
 * order by var_samp() on columns of table "study_image"
 */
export interface study_image_var_samp_order_by {
  dcmFileId?: order_by | null
  id?: order_by | null
  renderedFileId?: order_by | null
  studyId?: order_by | null
}

/**
 * order by variance() on columns of table "study_image"
 */
export interface study_image_variance_order_by {
  dcmFileId?: order_by | null
  id?: order_by | null
  renderedFileId?: order_by | null
  studyId?: order_by | null
}

/**
 * input type for inserting data into table "study"
 */
export interface study_insert_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  extra?: any | null
  filmedBy?: string | null
  id?: number | null
  modality?: modality_obj_rel_insert_input | null
  modalityId?: string | null
  phantom_tests?: phantom_test_arr_rel_insert_input | null
  scanDate?: any | null
  scanner?: scanner_obj_rel_insert_input | null
  scannerId?: number | null
  study_images?: study_image_arr_rel_insert_input | null
  uid?: string | null
}

/**
 * order by max() on columns of table "study"
 */
export interface study_max_order_by {
  filmedBy?: order_by | null
  id?: order_by | null
  modalityId?: order_by | null
  scannerId?: order_by | null
  uid?: order_by | null
}

/**
 * order by min() on columns of table "study"
 */
export interface study_min_order_by {
  filmedBy?: order_by | null
  id?: order_by | null
  modalityId?: order_by | null
  scannerId?: order_by | null
  uid?: order_by | null
}

/**
 * input type for inserting object relation for remote table "study"
 */
export interface study_obj_rel_insert_input {
  data: study_insert_input
  on_conflict?: study_on_conflict | null
}

/**
 * on conflict condition type for table "study"
 */
export interface study_on_conflict {
  constraint: study_constraint
  update_columns: study_update_column[]
  where?: study_bool_exp | null
}

/**
 * ordering options when selecting data from "study"
 */
export interface study_order_by {
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  extra?: order_by | null
  filmedBy?: order_by | null
  id?: order_by | null
  modality?: modality_order_by | null
  modalityId?: order_by | null
  phantom_tests_aggregate?: phantom_test_aggregate_order_by | null
  scanDate?: order_by | null
  scanner?: scanner_order_by | null
  scannerId?: order_by | null
  study_images_aggregate?: study_image_aggregate_order_by | null
  uid?: order_by | null
}

/**
 * input type for updating data in table "study"
 */
export interface study_set_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  extra?: any | null
  filmedBy?: string | null
  id?: number | null
  modalityId?: string | null
  scanDate?: any | null
  scannerId?: number | null
  uid?: string | null
}

/**
 * order by stddev() on columns of table "study"
 */
export interface study_stddev_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "study"
 */
export interface study_stddev_pop_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "study"
 */
export interface study_stddev_samp_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by sum() on columns of table "study"
 */
export interface study_sum_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by var_pop() on columns of table "study"
 */
export interface study_var_pop_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by var_samp() on columns of table "study"
 */
export interface study_var_samp_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by variance() on columns of table "study"
 */
export interface study_variance_order_by {
  id?: order_by | null
  scannerId?: order_by | null
}

/**
 * order by aggregate values of table "survey_test"
 */
export interface survey_test_aggregate_order_by {
  avg?: survey_test_avg_order_by | null
  count?: order_by | null
  max?: survey_test_max_order_by | null
  min?: survey_test_min_order_by | null
  stddev?: survey_test_stddev_order_by | null
  stddev_pop?: survey_test_stddev_pop_order_by | null
  stddev_samp?: survey_test_stddev_samp_order_by | null
  sum?: survey_test_sum_order_by | null
  var_pop?: survey_test_var_pop_order_by | null
  var_samp?: survey_test_var_samp_order_by | null
  variance?: survey_test_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "survey_test"
 */
export interface survey_test_arr_rel_insert_input {
  data: survey_test_insert_input[]
  on_conflict?: survey_test_on_conflict | null
}

/**
 * order by avg() on columns of table "survey_test"
 */
export interface survey_test_avg_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  testerId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "survey_test". All fields are combined with a logical 'AND'.
 */
export interface survey_test_bool_exp {
  _and?: (survey_test_bool_exp | null)[] | null
  _not?: survey_test_bool_exp | null
  _or?: (survey_test_bool_exp | null)[] | null
  answered_survey_test_questions?: answered_survey_test_question_bool_exp | null
  comments?: comment_bool_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: Int_comparison_exp | null
  reports?: report_bool_exp | null
  result?: String_comparison_exp | null
  routineId?: Int_comparison_exp | null
  scanner?: scanner_bool_exp | null
  scannerId?: Int_comparison_exp | null
  survey_test_routine?: survey_test_routine_bool_exp | null
  testerId?: Int_comparison_exp | null
  user?: user_bool_exp | null
}

/**
 * input type for inserting data into table "survey_test"
 */
export interface survey_test_insert_input {
  answered_survey_test_questions?: answered_survey_test_question_arr_rel_insert_input | null
  comments?: comment_arr_rel_insert_input | null
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  reports?: report_arr_rel_insert_input | null
  result?: string | null
  routineId?: number | null
  scanner?: scanner_obj_rel_insert_input | null
  scannerId?: number | null
  survey_test_routine?: survey_test_routine_obj_rel_insert_input | null
  testerId?: number | null
  user?: user_obj_rel_insert_input | null
}

/**
 * order by max() on columns of table "survey_test"
 */
export interface survey_test_max_order_by {
  id?: order_by | null
  result?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by min() on columns of table "survey_test"
 */
export interface survey_test_min_order_by {
  id?: order_by | null
  result?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  testerId?: order_by | null
}

/**
 * input type for inserting object relation for remote table "survey_test"
 */
export interface survey_test_obj_rel_insert_input {
  data: survey_test_insert_input
  on_conflict?: survey_test_on_conflict | null
}

/**
 * on conflict condition type for table "survey_test"
 */
export interface survey_test_on_conflict {
  constraint: survey_test_constraint
  update_columns: survey_test_update_column[]
  where?: survey_test_bool_exp | null
}

/**
 * ordering options when selecting data from "survey_test"
 */
export interface survey_test_order_by {
  answered_survey_test_questions_aggregate?: answered_survey_test_question_aggregate_order_by | null
  comments_aggregate?: comment_aggregate_order_by | null
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  id?: order_by | null
  reports_aggregate?: report_aggregate_order_by | null
  result?: order_by | null
  routineId?: order_by | null
  scanner?: scanner_order_by | null
  scannerId?: order_by | null
  survey_test_routine?: survey_test_routine_order_by | null
  testerId?: order_by | null
  user?: user_order_by | null
}

/**
 * Boolean expression to filter rows from the table "survey_test_question". All fields are combined with a logical 'AND'.
 */
export interface survey_test_question_bool_exp {
  _and?: (survey_test_question_bool_exp | null)[] | null
  _not?: survey_test_question_bool_exp | null
  _or?: (survey_test_question_bool_exp | null)[] | null
  assignProblemInsteadOfFail?: Boolean_comparison_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  exact?: Int_comparison_exp | null
  expected?: String_comparison_exp | null
  id?: Int_comparison_exp | null
  max?: Int_comparison_exp | null
  min?: Int_comparison_exp | null
  options?: jsonb_comparison_exp | null
  order?: Int_comparison_exp | null
  section?: String_comparison_exp | null
  survey_test_routine_questions_survey_test_questions?: survey_test_routine_questions_survey_test_question_bool_exp | null
  text?: String_comparison_exp | null
  type?: String_comparison_exp | null
}

/**
 * input type for inserting data into table "survey_test_question"
 */
export interface survey_test_question_insert_input {
  assignProblemInsteadOfFail?: boolean | null
  dateCreated?: any | null
  dateUpdated?: any | null
  exact?: number | null
  expected?: string | null
  id?: number | null
  max?: number | null
  min?: number | null
  options?: any | null
  order?: number | null
  section?: string | null
  survey_test_routine_questions_survey_test_questions?: survey_test_routine_questions_survey_test_question_arr_rel_insert_input | null
  text?: string | null
  type?: string | null
}

/**
 * input type for inserting object relation for remote table "survey_test_question"
 */
export interface survey_test_question_obj_rel_insert_input {
  data: survey_test_question_insert_input
  on_conflict?: survey_test_question_on_conflict | null
}

/**
 * on conflict condition type for table "survey_test_question"
 */
export interface survey_test_question_on_conflict {
  constraint: survey_test_question_constraint
  update_columns: survey_test_question_update_column[]
  where?: survey_test_question_bool_exp | null
}

/**
 * order by aggregate values of table "survey_test_routine"
 */
export interface survey_test_routine_aggregate_order_by {
  avg?: survey_test_routine_avg_order_by | null
  count?: order_by | null
  max?: survey_test_routine_max_order_by | null
  min?: survey_test_routine_min_order_by | null
  stddev?: survey_test_routine_stddev_order_by | null
  stddev_pop?: survey_test_routine_stddev_pop_order_by | null
  stddev_samp?: survey_test_routine_stddev_samp_order_by | null
  sum?: survey_test_routine_sum_order_by | null
  var_pop?: survey_test_routine_var_pop_order_by | null
  var_samp?: survey_test_routine_var_samp_order_by | null
  variance?: survey_test_routine_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "survey_test_routine"
 */
export interface survey_test_routine_arr_rel_insert_input {
  data: survey_test_routine_insert_input[]
  on_conflict?: survey_test_routine_on_conflict | null
}

/**
 * order by avg() on columns of table "survey_test_routine"
 */
export interface survey_test_routine_avg_order_by {
  id?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "survey_test_routine". All fields are combined with a logical 'AND'.
 */
export interface survey_test_routine_bool_exp {
  _and?: (survey_test_routine_bool_exp | null)[] | null
  _not?: survey_test_routine_bool_exp | null
  _or?: (survey_test_routine_bool_exp | null)[] | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  id?: Int_comparison_exp | null
  modality?: modality_bool_exp | null
  modalityId?: String_comparison_exp | null
  name?: String_comparison_exp | null
  schedule_survey_test_routines_survey_test_routines?: schedule_survey_test_routines_survey_test_routine_bool_exp | null
  survey_test_routine_questions_survey_test_questions?: survey_test_routine_questions_survey_test_question_bool_exp | null
  survey_test_routine_schedules_schedules?: survey_test_routine_schedules_schedule_bool_exp | null
  survey_tests?: survey_test_bool_exp | null
}

/**
 * input type for inserting data into table "survey_test_routine"
 */
export interface survey_test_routine_insert_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  modality?: modality_obj_rel_insert_input | null
  modalityId?: string | null
  name?: string | null
  schedule_survey_test_routines_survey_test_routines?: schedule_survey_test_routines_survey_test_routine_arr_rel_insert_input | null
  survey_test_routine_questions_survey_test_questions?: survey_test_routine_questions_survey_test_question_arr_rel_insert_input | null
  survey_test_routine_schedules_schedules?: survey_test_routine_schedules_schedule_arr_rel_insert_input | null
  survey_tests?: survey_test_arr_rel_insert_input | null
}

/**
 * order by max() on columns of table "survey_test_routine"
 */
export interface survey_test_routine_max_order_by {
  id?: order_by | null
  modalityId?: order_by | null
  name?: order_by | null
}

/**
 * order by min() on columns of table "survey_test_routine"
 */
export interface survey_test_routine_min_order_by {
  id?: order_by | null
  modalityId?: order_by | null
  name?: order_by | null
}

/**
 * input type for inserting object relation for remote table "survey_test_routine"
 */
export interface survey_test_routine_obj_rel_insert_input {
  data: survey_test_routine_insert_input
  on_conflict?: survey_test_routine_on_conflict | null
}

/**
 * on conflict condition type for table "survey_test_routine"
 */
export interface survey_test_routine_on_conflict {
  constraint: survey_test_routine_constraint
  update_columns: survey_test_routine_update_column[]
  where?: survey_test_routine_bool_exp | null
}

/**
 * ordering options when selecting data from "survey_test_routine"
 */
export interface survey_test_routine_order_by {
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  id?: order_by | null
  modality?: modality_order_by | null
  modalityId?: order_by | null
  name?: order_by | null
  schedule_survey_test_routines_survey_test_routines_aggregate?: schedule_survey_test_routines_survey_test_routine_aggregate_order_by | null
  survey_test_routine_questions_survey_test_questions_aggregate?: survey_test_routine_questions_survey_test_question_aggregate_order_by | null
  survey_test_routine_schedules_schedules_aggregate?: survey_test_routine_schedules_schedule_aggregate_order_by | null
  survey_tests_aggregate?: survey_test_aggregate_order_by | null
}

/**
 * order by aggregate values of table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_aggregate_order_by {
  avg?: survey_test_routine_questions_survey_test_question_avg_order_by | null
  count?: order_by | null
  max?: survey_test_routine_questions_survey_test_question_max_order_by | null
  min?: survey_test_routine_questions_survey_test_question_min_order_by | null
  stddev?: survey_test_routine_questions_survey_test_question_stddev_order_by | null
  stddev_pop?: survey_test_routine_questions_survey_test_question_stddev_pop_order_by | null
  stddev_samp?: survey_test_routine_questions_survey_test_question_stddev_samp_order_by | null
  sum?: survey_test_routine_questions_survey_test_question_sum_order_by | null
  var_pop?: survey_test_routine_questions_survey_test_question_var_pop_order_by | null
  var_samp?: survey_test_routine_questions_survey_test_question_var_samp_order_by | null
  variance?: survey_test_routine_questions_survey_test_question_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_arr_rel_insert_input {
  data: survey_test_routine_questions_survey_test_question_insert_input[]
  on_conflict?: survey_test_routine_questions_survey_test_question_on_conflict | null
}

/**
 * order by avg() on columns of table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_avg_order_by {
  surveyTestQuestionId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "survey_test_routine_questions_survey_test_question". All fields are combined with a logical 'AND'.
 */
export interface survey_test_routine_questions_survey_test_question_bool_exp {
  _and?:
    | (survey_test_routine_questions_survey_test_question_bool_exp | null)[]
    | null
  _not?: survey_test_routine_questions_survey_test_question_bool_exp | null
  _or?:
    | (survey_test_routine_questions_survey_test_question_bool_exp | null)[]
    | null
  surveyTestQuestionId?: Int_comparison_exp | null
  surveyTestRoutineId?: Int_comparison_exp | null
  survey_test_question?: survey_test_question_bool_exp | null
  survey_test_routine?: survey_test_routine_bool_exp | null
}

/**
 * input type for inserting data into table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_insert_input {
  surveyTestQuestionId?: number | null
  surveyTestRoutineId?: number | null
  survey_test_question?: survey_test_question_obj_rel_insert_input | null
  survey_test_routine?: survey_test_routine_obj_rel_insert_input | null
}

/**
 * order by max() on columns of table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_max_order_by {
  surveyTestQuestionId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by min() on columns of table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_min_order_by {
  surveyTestQuestionId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * on conflict condition type for table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_on_conflict {
  constraint: survey_test_routine_questions_survey_test_question_constraint
  update_columns: survey_test_routine_questions_survey_test_question_update_column[]
  where?: survey_test_routine_questions_survey_test_question_bool_exp | null
}

/**
 * order by stddev() on columns of table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_stddev_order_by {
  surveyTestQuestionId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_stddev_pop_order_by {
  surveyTestQuestionId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_stddev_samp_order_by {
  surveyTestQuestionId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by sum() on columns of table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_sum_order_by {
  surveyTestQuestionId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by var_pop() on columns of table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_var_pop_order_by {
  surveyTestQuestionId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by var_samp() on columns of table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_var_samp_order_by {
  surveyTestQuestionId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by variance() on columns of table "survey_test_routine_questions_survey_test_question"
 */
export interface survey_test_routine_questions_survey_test_question_variance_order_by {
  surveyTestQuestionId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by aggregate values of table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_aggregate_order_by {
  avg?: survey_test_routine_schedules_schedule_avg_order_by | null
  count?: order_by | null
  max?: survey_test_routine_schedules_schedule_max_order_by | null
  min?: survey_test_routine_schedules_schedule_min_order_by | null
  stddev?: survey_test_routine_schedules_schedule_stddev_order_by | null
  stddev_pop?: survey_test_routine_schedules_schedule_stddev_pop_order_by | null
  stddev_samp?: survey_test_routine_schedules_schedule_stddev_samp_order_by | null
  sum?: survey_test_routine_schedules_schedule_sum_order_by | null
  var_pop?: survey_test_routine_schedules_schedule_var_pop_order_by | null
  var_samp?: survey_test_routine_schedules_schedule_var_samp_order_by | null
  variance?: survey_test_routine_schedules_schedule_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_arr_rel_insert_input {
  data: survey_test_routine_schedules_schedule_insert_input[]
  on_conflict?: survey_test_routine_schedules_schedule_on_conflict | null
}

/**
 * order by avg() on columns of table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_avg_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "survey_test_routine_schedules_schedule". All fields are combined with a logical 'AND'.
 */
export interface survey_test_routine_schedules_schedule_bool_exp {
  _and?: (survey_test_routine_schedules_schedule_bool_exp | null)[] | null
  _not?: survey_test_routine_schedules_schedule_bool_exp | null
  _or?: (survey_test_routine_schedules_schedule_bool_exp | null)[] | null
  schedule?: schedule_bool_exp | null
  scheduleId?: Int_comparison_exp | null
  surveyTestRoutineId?: Int_comparison_exp | null
  survey_test_routine?: survey_test_routine_bool_exp | null
}

/**
 * input type for inserting data into table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_insert_input {
  schedule?: schedule_obj_rel_insert_input | null
  scheduleId?: number | null
  surveyTestRoutineId?: number | null
  survey_test_routine?: survey_test_routine_obj_rel_insert_input | null
}

/**
 * order by max() on columns of table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_max_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by min() on columns of table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_min_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * on conflict condition type for table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_on_conflict {
  constraint: survey_test_routine_schedules_schedule_constraint
  update_columns: survey_test_routine_schedules_schedule_update_column[]
  where?: survey_test_routine_schedules_schedule_bool_exp | null
}

/**
 * order by stddev() on columns of table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_stddev_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_stddev_pop_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_stddev_samp_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by sum() on columns of table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_sum_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by var_pop() on columns of table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_var_pop_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by var_samp() on columns of table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_var_samp_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by variance() on columns of table "survey_test_routine_schedules_schedule"
 */
export interface survey_test_routine_schedules_schedule_variance_order_by {
  scheduleId?: order_by | null
  surveyTestRoutineId?: order_by | null
}

/**
 * order by stddev() on columns of table "survey_test_routine"
 */
export interface survey_test_routine_stddev_order_by {
  id?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "survey_test_routine"
 */
export interface survey_test_routine_stddev_pop_order_by {
  id?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "survey_test_routine"
 */
export interface survey_test_routine_stddev_samp_order_by {
  id?: order_by | null
}

/**
 * order by sum() on columns of table "survey_test_routine"
 */
export interface survey_test_routine_sum_order_by {
  id?: order_by | null
}

/**
 * order by var_pop() on columns of table "survey_test_routine"
 */
export interface survey_test_routine_var_pop_order_by {
  id?: order_by | null
}

/**
 * order by var_samp() on columns of table "survey_test_routine"
 */
export interface survey_test_routine_var_samp_order_by {
  id?: order_by | null
}

/**
 * order by variance() on columns of table "survey_test_routine"
 */
export interface survey_test_routine_variance_order_by {
  id?: order_by | null
}

/**
 * input type for updating data in table "survey_test"
 */
export interface survey_test_set_input {
  dateCreated?: any | null
  dateUpdated?: any | null
  id?: number | null
  result?: string | null
  routineId?: number | null
  scannerId?: number | null
  testerId?: number | null
}

/**
 * order by stddev() on columns of table "survey_test"
 */
export interface survey_test_stddev_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "survey_test"
 */
export interface survey_test_stddev_pop_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "survey_test"
 */
export interface survey_test_stddev_samp_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by sum() on columns of table "survey_test"
 */
export interface survey_test_sum_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by var_pop() on columns of table "survey_test"
 */
export interface survey_test_var_pop_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by var_samp() on columns of table "survey_test"
 */
export interface survey_test_var_samp_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  testerId?: order_by | null
}

/**
 * order by variance() on columns of table "survey_test"
 */
export interface survey_test_variance_order_by {
  id?: order_by | null
  routineId?: order_by | null
  scannerId?: order_by | null
  testerId?: order_by | null
}

/**
 * expression to compare columns of type timestamp. All fields are combined with logical 'AND'.
 */
export interface timestamp_comparison_exp {
  _eq?: any | null
  _gt?: any | null
  _gte?: any | null
  _in?: any[] | null
  _is_null?: boolean | null
  _lt?: any | null
  _lte?: any | null
  _neq?: any | null
  _nin?: any[] | null
}

/**
 * order by aggregate values of table "user"
 */
export interface user_aggregate_order_by {
  avg?: user_avg_order_by | null
  count?: order_by | null
  max?: user_max_order_by | null
  min?: user_min_order_by | null
  stddev?: user_stddev_order_by | null
  stddev_pop?: user_stddev_pop_order_by | null
  stddev_samp?: user_stddev_samp_order_by | null
  sum?: user_sum_order_by | null
  var_pop?: user_var_pop_order_by | null
  var_samp?: user_var_samp_order_by | null
  variance?: user_variance_order_by | null
}

/**
 * input type for inserting array relation for remote table "user"
 */
export interface user_arr_rel_insert_input {
  data: user_insert_input[]
  on_conflict?: user_on_conflict | null
}

/**
 * order by avg() on columns of table "user"
 */
export interface user_avg_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'.
 */
export interface user_bool_exp {
  _and?: (user_bool_exp | null)[] | null
  _not?: user_bool_exp | null
  _or?: (user_bool_exp | null)[] | null
  accountType?: String_comparison_exp | null
  audit_logs?: audit_log_bool_exp | null
  comments?: comment_bool_exp | null
  dateCreated?: timestamp_comparison_exp | null
  dateUpdated?: timestamp_comparison_exp | null
  email?: String_comparison_exp | null
  externalId?: String_comparison_exp | null
  facility?: facility_bool_exp | null
  facilityId?: Int_comparison_exp | null
  firstName?: String_comparison_exp | null
  id?: Int_comparison_exp | null
  lastName?: String_comparison_exp | null
  password?: String_comparison_exp | null
  phantom_tests?: phantom_test_bool_exp | null
  phone?: String_comparison_exp | null
  restrictedIP?: Boolean_comparison_exp | null
  role?: String_comparison_exp | null
  status?: String_comparison_exp | null
  survey_tests?: survey_test_bool_exp | null
  username?: String_comparison_exp | null
}

/**
 * input type for inserting data into table "user"
 */
export interface user_insert_input {
  accountType?: string | null
  audit_logs?: audit_log_arr_rel_insert_input | null
  comments?: comment_arr_rel_insert_input | null
  dateCreated?: any | null
  dateUpdated?: any | null
  email?: string | null
  externalId?: string | null
  facility?: facility_obj_rel_insert_input | null
  facilityId?: number | null
  firstName?: string | null
  id?: number | null
  lastName?: string | null
  password?: string | null
  phantom_tests?: phantom_test_arr_rel_insert_input | null
  phone?: string | null
  restrictedIP?: boolean | null
  role?: string | null
  status?: string | null
  survey_tests?: survey_test_arr_rel_insert_input | null
  username?: string | null
}

/**
 * order by max() on columns of table "user"
 */
export interface user_max_order_by {
  accountType?: order_by | null
  email?: order_by | null
  externalId?: order_by | null
  facilityId?: order_by | null
  firstName?: order_by | null
  id?: order_by | null
  lastName?: order_by | null
  password?: order_by | null
  phone?: order_by | null
  role?: order_by | null
  status?: order_by | null
  username?: order_by | null
}

/**
 * order by min() on columns of table "user"
 */
export interface user_min_order_by {
  accountType?: order_by | null
  email?: order_by | null
  externalId?: order_by | null
  facilityId?: order_by | null
  firstName?: order_by | null
  id?: order_by | null
  lastName?: order_by | null
  password?: order_by | null
  phone?: order_by | null
  role?: order_by | null
  status?: order_by | null
  username?: order_by | null
}

/**
 * input type for inserting object relation for remote table "user"
 */
export interface user_obj_rel_insert_input {
  data: user_insert_input
  on_conflict?: user_on_conflict | null
}

/**
 * on conflict condition type for table "user"
 */
export interface user_on_conflict {
  constraint: user_constraint
  update_columns: user_update_column[]
  where?: user_bool_exp | null
}

/**
 * ordering options when selecting data from "user"
 */
export interface user_order_by {
  accountType?: order_by | null
  audit_logs_aggregate?: audit_log_aggregate_order_by | null
  comments_aggregate?: comment_aggregate_order_by | null
  dateCreated?: order_by | null
  dateUpdated?: order_by | null
  email?: order_by | null
  externalId?: order_by | null
  facility?: facility_order_by | null
  facilityId?: order_by | null
  firstName?: order_by | null
  id?: order_by | null
  lastName?: order_by | null
  password?: order_by | null
  phantom_tests_aggregate?: phantom_test_aggregate_order_by | null
  phone?: order_by | null
  restrictedIP?: order_by | null
  role?: order_by | null
  status?: order_by | null
  survey_tests_aggregate?: survey_test_aggregate_order_by | null
  username?: order_by | null
}

/**
 * input type for updating data in table "user"
 */
export interface user_set_input {
  accountType?: string | null
  dateCreated?: any | null
  dateUpdated?: any | null
  email?: string | null
  externalId?: string | null
  facilityId?: number | null
  firstName?: string | null
  id?: number | null
  lastName?: string | null
  password?: string | null
  phone?: string | null
  restrictedIP?: boolean | null
  role?: string | null
  status?: string | null
  username?: string | null
}

/**
 * order by stddev() on columns of table "user"
 */
export interface user_stddev_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * order by stddev_pop() on columns of table "user"
 */
export interface user_stddev_pop_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * order by stddev_samp() on columns of table "user"
 */
export interface user_stddev_samp_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * order by sum() on columns of table "user"
 */
export interface user_sum_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * order by var_pop() on columns of table "user"
 */
export interface user_var_pop_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * order by var_samp() on columns of table "user"
 */
export interface user_var_samp_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

/**
 * order by variance() on columns of table "user"
 */
export interface user_variance_order_by {
  facilityId?: order_by | null
  id?: order_by | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
