import { Component, Input, OnInit } from '@angular/core'
import { ClrDatagridFilter, ClrDatagridFilterInterface } from '@clr/angular'
import * as moment from 'moment-timezone'
import { Subject } from 'rxjs'
import { DevError } from 'src/app/core/errors/errors'
import { TimezoneService } from 'src/app/timezone.service'
import { FilterType, DateFilterInterface } from '../filters.util'

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent
  implements OnInit, ClrDatagridFilterInterface<Date>, DateFilterInterface {
  type: FilterType.date = FilterType.date
  changes = new Subject<Date>()
  @Input() earliest: Date
  @Input() latest: Date
  @Input() property: string

  constructor(
    private filterContainer: ClrDatagridFilter,
    private timezoneService: TimezoneService,
  ) {
    filterContainer.setFilter(this)
  }

  isActive(): boolean {
    return !!this.earliest || !!this.latest
  }

  accepts(date: Date, whichDate?: 'earliest' | 'latest'): boolean {
    if (whichDate === 'earliest') {
      this.earliest = date
    } else if (whichDate === 'latest') {
      this.latest = date
    } else {
      throw new DevError('date was not earliest or latest')
    }
    this.changes.next(date)
    return true
  }

  ngOnInit() {}

  preset(range: 'today' | 'yesterday' | 'month' | 'year' | 'reset') {
    switch (range) {
      case 'today':
        this.accepts(
          moment().tz(this.timezoneService.cachedTz).startOf('day').toDate(),
          'earliest',
        )
        this.accepts(
          moment().tz(this.timezoneService.cachedTz).endOf('day').toDate(),
          'latest',
        )
        break
      case 'yesterday':
        this.accepts(
          moment()
            .add(-1, 'days')
            .tz(this.timezoneService.cachedTz)
            .startOf('day')
            .toDate(),
          'earliest',
        )
        this.accepts(
          moment()
            .add(-1, 'days')
            .tz(this.timezoneService.cachedTz)
            .endOf('day')
            .toDate(),
          'latest',
        )
        break
      case 'month':
        this.accepts(
          moment().tz(this.timezoneService.cachedTz).startOf('month').toDate(),
          'earliest',
        )
        this.accepts(
          moment().tz(this.timezoneService.cachedTz).endOf('month').toDate(),
          'latest',
        )
        break
      case 'year':
        this.accepts(
          moment().tz(this.timezoneService.cachedTz).startOf('year').toDate(),
          'earliest',
        )
        this.accepts(
          moment().tz(this.timezoneService.cachedTz).endOf('year').toDate(),
          'latest',
        )
        break
      case 'reset':
        this.accepts(null, 'earliest')
        this.accepts(null, 'latest')
        break
      default:
        throw new DevError(`${range} not allowable range`)
    }
  }
}
