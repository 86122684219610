import { gql } from 'apollo-angular'

export default gql`
  query comment_get_related_entities {
    user(order_by: { firstName: asc }) {
      id
      firstName
      lastName
    }
    comment_category(order_by: { name: asc }) {
      id
      name
      color
    }
  }
`
