import { gql } from 'apollo-angular'

export default gql`
  mutation testStudy($studyId: Int!, $userId: Int!, $routineId: Int!) {
    testStudy(
      data: {
        studyId: $studyId
        userId: $userId
        phantomTestRoutineId: $routineId
      }
    ) {
      id
    }
  }
`
