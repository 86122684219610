import { Configuration } from 'msal'

const msalConfig: Configuration = {
  auth: {
    clientId: '13782617-105d-49e0-bc5f-78e974d1d1dd',
    authority:
      'https://login.microsoftonline.com/036ca763-f2f1-4c90-af26-693cd5ffd322',
    redirectUri: `https://${window.location.hostname}/`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

export const environment = {
  production: true,
  appUri: '', // appuri is relative to host in production
  // hasuraUri is calculated in graphql.module.ts in production
  hasuraUri: null,
  hostedFilesPath: '/files',
  bugsnagApiKey: '4e515d7c03a56958e54235d600e26fcb',
  msalConfig,
}
