<div class="">
  <form clrForm>
    <clr-input-container>
      <label>Name</label>
      <input
        clrInput
        name="Name"
        [ngModel]="getModel().name"
        (ngModelChange)="setModel('name', $event)"
      />
    </clr-input-container>

    <clr-input-container>
      <label>Address</label>
      <input
        clrInput
        name="Address"
        [ngModel]="getModel().address"
        (ngModelChange)="setModel('address', $event)"
      />
    </clr-input-container>

    <clr-input-container>
      <label>Phone</label>
      <input
        clrInput
        name="Phone"
        [ngModel]="getModel().phone"
        (ngModelChange)="setModel('phone', $event)"
      />
    </clr-input-container>

    <clr-input-container>
      <label>Facility ID</label>
      <input
        clrInput
        name="FacilityId"
        [ngModel]="getModel().facilityId"
        (ngModelChange)="setModel('facilityId', $event)"
      />
    </clr-input-container>
  </form>

  <button (click)="save()" class="btn btn-success-outline">Save</button>
  <button (click)="back()" class="btn btn-outline">Back</button>
</div>
