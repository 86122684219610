<div class="main-container">
  <header class="header-2">
    <div class="branding">
      <a class="nav-link" (click)="home()">
        <img class="logo" src="../../assets/aqs-logo-white.svg" alt="" />
        <span class="title"
          >Advanced Quality Systems
          <a (click)="modalOpen = true" class="version">v{{ version }}</a></span
        >
      </a>
    </div>
    <div class="header-actions">
      <clr-dropdown>
        <button
          class="nav-text"
          clrDropdownTrigger
          aria-label="toggle user settings menu"
        >
          {{ username }}
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <a [routerLink]="['./userSettings']" clrDropdownItem>User Settings</a>
          <a (click)="logout()" clrDropdownItem>Log out</a>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </header>
  <!-- <nav class="subnav">

    </nav> -->
  <div class="content-container">
    <div class="content-area">
      <router-outlet></router-outlet>
    </div>
    <!-- wish I could put this in a separate component, but clarity css messes that up -->
    <clr-vertical-nav
      id="sidebar"
      [clrVerticalNavCollapsible]="true"
      [(clrVerticalNavCollapsed)]="collapsed"
      (clrVerticalNavCollapsedChange)="notifySidebarChange()"
    >
      <div class="divider"></div>
      <a
        clrVerticalNavLink
        routerLink="./inbox"
        class="nav-link"
        routerLinkActive="active"
        title="Inbox"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="inbox"
          class="sidebar-icon"
        ></clr-icon>
        <span>Inbox</span>
      </a>
      <a
        clrVerticalNavLink
        routerLink="./dashboard"
        class="nav-link"
        routerLinkActive="active"
        title="Dashboard"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="dashboard"
          class="sidebar-icon"
        ></clr-icon>
        <span>Dashboard</span>
      </a>
      <a
        clrVerticalNavLink
        routerLink="./metrics"
        class="nav-link"
        routerLinkActive="active"
        title="Metrics"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="bar-chart"
          class="sidebar-icon"
        ></clr-icon>
        <span>Metrics</span>
      </a>
      <div class="divider"></div>
      <a
        clrVerticalNavLink
        routerLink="./phantomTests"
        class="nav-link"
        routerLinkActive="active"
        title="Tests"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="tasks"
          class="sidebar-icon"
        ></clr-icon>
        <span>Tests</span>
      </a>
      <a
        clrVerticalNavLink
        routerLink="./problems"
        class="nav-link"
        routerLinkActive="active"
        title="Problems"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="warning-standard"
          class="sidebar-icon"
        ></clr-icon>
        <span>Problems</span>
      </a>
      <a
        clrVerticalNavLink
        routerLink="./surveyTests"
        class="nav-link"
        routerLinkActive="active"
        title="Surveys"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="form"
          class="sidebar-icon"
        ></clr-icon>
        <span>Surveys</span>
      </a>
      <a
        clrVerticalNavLink
        routerLink="./reports"
        class="nav-link"
        routerLinkActive="active"
        title="Reports"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="list"
          class="sidebar-icon"
        ></clr-icon>
        <span>Reports</span>
      </a>
      <a
        clrVerticalNavLink
        routerLink="./studies"
        class="nav-link"
        routerLinkActive="active"
        title="Studies"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="image"
          class="sidebar-icon"
        ></clr-icon>
        <span>Studies</span>
      </a>
      <a
        clrVerticalNavLink
        routerLink="./trends"
        class="nav-link"
        routerLinkActive="active"
        title="Trends"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="line-chart"
          class="sidebar-icon"
        ></clr-icon>
        <span>Trends</span>
      </a>
      <div class="divider"></div>
      <a
        clrVerticalNavLink
        routerLink="./scanners"
        class="nav-link"
        routerLinkActive="active"
        title="Scanners"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="target"
          class="sidebar-icon"
        ></clr-icon>
        <span>Scanners</span>
      </a>
      <a
        clrVerticalNavLink
        routerLink="./workstations"
        class="nav-link"
        routerLinkActive="active"
        title="Workstations"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="display"
          class="sidebar-icon"
        ></clr-icon>
        <span>Workstations</span>
      </a>
      <a
        clrVerticalNavLink
        routerLink="./facilities"
        class="nav-link"
        routerLinkActive="active"
        title="Facilities"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="building"
          class="sidebar-icon"
        ></clr-icon>
        <span>Facilities</span>
      </a>
      <a
        clrVerticalNavLink
        routerLink="./schedules"
        class="nav-link"
        routerLinkActive="active"
        title="Schedules"
      >
        <clr-icon
          clrVerticalNavIcon
          shape="calendar"
          class="sidebar-icon"
        ></clr-icon>
        <span>Schedules</span>
      </a>
      <ng-container *ngIf="auth.checkrole(adminRoles)">
        <div class="divider"></div>
        <a
          clrVerticalNavLink
          routerLink="./users"
          class="nav-link"
          routerLinkActive="active"
          title="Users"
        >
          <clr-icon
            clrVerticalNavIcon
            shape="users"
            class="sidebar-icon"
          ></clr-icon>
          <span>Users</span>
        </a>
        <a
          clrVerticalNavLink
          routerLink="./settings"
          class="nav-link"
          routerLinkActive="active"
          title="Settings"
        >
          <clr-icon
            clrVerticalNavIcon
            shape="cog"
            class="sidebar-icon"
          ></clr-icon>
          <span>Settings</span>
        </a>
        <!-- disabling the status UI until we test it. Will be re-enabled later -->
        <!-- <div class="divider"></div>
        <a clrVerticalNavLink routerLink="./status" class="nav-link" routerLinkActive="active" title="Status">
          <clr-icon clrVerticalNavIcon shape="cloud-traffic" class="sidebar-icon"></clr-icon>
          <span>Status</span>
        </a> -->
      </ng-container>
    </clr-vertical-nav>
  </div>
</div>

<clr-modal [(clrModalOpen)]="modalOpen">
  <h3 class="modal-title">Version Number</h3>
  <div class="modal-body">
    <p>
      Advanced Quality Systems is constantly adding new features and
      improvements. You will often see this version number increase. When you
      do, it means a new feature or fix is available to you.
    </p>
    <br />
    <em>What do these numbers mean?</em>
    <p>
      We follow
      <a href="https://semver.org/" target="_blank">Semantic Versioning</a> - a
      widely used standard for versioning where each number has real meaning.
    </p>
    <p>
      You are running version <strong>{{ version }}</strong>
    </p>
    <div>
      <strong>{{ versionExpanded[0] }}</strong> - major version: entire
      application restructures
    </div>
    <div>
      <strong>{{ versionExpanded[1] }}</strong> - minor version: new features &
      improvements
    </div>
    <div>
      <strong>{{ versionExpanded[2] }}</strong> - patch version: bug fixes,
      maintenance
    </div>
    <p>
      This means that you are running the
      <strong>{{ versionExpanded[0] }}{{ suffix(versionExpanded[0]) }}</strong>
      major version of AQS, we have added
      <strong>{{ versionExpanded[1] }}</strong> new feature{{
        versionExpanded[1] === '1' ? '' : 's'
      }}
      since releasing this major version, and we have performed
      <strong>{{ versionExpanded[2] }}</strong> fix{{
        versionExpanded[2] === '1' ? '' : 'es'
      }}
      or maintenance improvement{{ versionExpanded[2] === '1' ? '' : 's' }}
      since releasing the last new feature.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="modalOpen = false">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="modalOpen = false">
      Ok
    </button>
  </div>
</clr-modal>
