import { Component } from '@angular/core'
import { CommService } from './core/comm.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  ie: boolean
  edge: boolean
  constructor(private comm: CommService) {
    if ((window.document as any).documentMode) {
      this.ie = true
    }
    if (/ Edge\//gm.test(navigator.userAgent)) {
      this.edge = true
    }
  }
}
