import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { PageHeaderAction } from '../page-header/page-header.component'

import * as moment from 'moment-timezone'
import { ReportService } from 'src/app/core/report.service'
import { TimezoneService } from 'src/app/timezone.service'
import { TrendsService } from 'src/app/trends.service'
import { trendsScanners_scanner } from '../trends/types/trendsScanners'
import { getLatestSurveyIdGql } from './generate-report.gql'

import { cloneDeep } from 'lodash'

@Component({
  selector: 'app-generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.scss'],
})
export class GenerateReportComponent implements OnInit {
  title = 'Generate Report'
  actions: PageHeaderAction[] = [{ text: 'Back' }]
  disableOptions: boolean = true
  disableGenerateButton: boolean = true // true = button disabled

  // report type to months config
  reportTypeToMonths = {
    'CT - ACR Monthly QC': 1,
    'MR - ACR Monthly QC': 1,
    'MG - Monthly QC': 1,
    'MG - Annual QC': 12,
    'WS - CAR Monthly QC': 1,
  }

  selectedDevice
  selectedModality: string = '---'
  selectedPeriod: moment.Moment
  selectedReportType: string

  devices: trendsScanners_scanner[]
  periodLengthInMonths: number
  allRoomNames: string[]
  // devices: string[]
  periods: moment.Moment[]
  periodStart
  periodEnd

  reportTypes: string[] = ['---']

  @Output() saveEvent = new EventEmitter()

  objectKeys = Object.keys

  constructor(
    private apollo: Apollo,
    private timezoneService: TimezoneService,
    private reportService: ReportService,
    private trendsService: TrendsService,
  ) {}

  ngOnInit() {
    this.fetchScanners()
    this.periods = this.generateMonths()
  }

  async fetchScanners() {
    const { scanners, roomNames } = await this.trendsService.allScanners()
    this.devices = scanners
    this.allRoomNames = roomNames
  }

  generateMonths(): moment.Moment[] {
    let result: moment.Moment[] = []
    for (let i = 0; i < 14; i++) {
      const thisTestDateStartOfMonth = moment()
        .tz(this.timezoneService.cachedTz)
        .add(-i, 'month')
        .startOf('month')
      result.push(thisTestDateStartOfMonth)
    }
    return result
  }

  handleDeviceChange() {
    this.selectedModality = this.selectedDevice.modality.id

    // Populate reportType based on modality
    this.handleReportTypeChoices()

    // Pick first choice by default
    this.selectedReportType = this.reportTypes[0]

    // Enable option selection
    if (this.selectedDevice) this.disableOptions = false
  }

  handleReportTypeChoices() {
    switch (this.selectedModality) {
      case 'CT':
        this.reportTypes = ['CT - ACR Monthly QC']
        break
      case 'MR':
        this.reportTypes = ['MR - ACR Monthly QC']
        break
      case 'MG':
        this.reportTypes = ['MG - Monthly QC', 'MG - Annual QC']
        break
      case 'WS':
        this.reportTypes = ['WS - CAR Monthly QC']
        break
      default:
        break
    }
    this.selectedReportType = this.reportTypes[0]
    this.handleModalityChange()
  }

  handleModalityChange() {
    this.handleReportNumberOfMonths()

    if (this.selectedPeriod) this.calculatePeriod()

    // Enable generate Button
    if (this.selectedDevice && this.selectedPeriod && this.selectedReportType)
      this.disableGenerateButton = false
  }

  handleReportNumberOfMonths() {
    this.periodLengthInMonths = this.reportTypeToMonths[this.selectedReportType]
  }

  calculatePeriod() {
    const periodLength = this.periodLengthInMonths - 1
    this.periodStart = this.selectedPeriod
      .clone()
      .startOf('M')
      .add(-periodLength, 'M')
      .format('MMM DD, YYYY')

    const today = moment()
    const monthEnd = this.selectedPeriod.clone().endOf('M')
    if (today < monthEnd) {
      this.periodEnd = today.format('MMM DD, YYYY')
    } else {
      this.periodEnd = monthEnd.format('MMM DD, YYYY')
    }
  }

  async generate() {
    const month = this.selectedPeriod.toDate()
    const selectedRoutine: string = this.selectedReportType
    const scannerId: number = this.selectedDevice.id
    let surveyTestId: number = 0
    try {
      surveyTestId = await this.getLatestSurveyId(scannerId)
    } catch {
      console.log('No latest surveyId available')
      surveyTestId = 0
    }

    const periodLength: number = this.periodLengthInMonths
    this.reportService.generateReport({
      scannerId,
      surveyTestId,
      month,
      selectedRoutine,
      periodLength,
    })
  }

  getLatestSurveyId(scannerId) {
    return new Promise<number>((resolve, reject) => {
      const variables = { scannerId: scannerId }
      const query = getLatestSurveyIdGql
      this.apollo
        .query<any>({ query, variables })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              reject(result.errors[0])
            }
            if (!result.data.survey_test[0]) reject('no survey found')
            resolve(result.data.survey_test[0].id)
          },
          error: (e) => reject(e),
        })
    })
  }

  back() {
    this.saveEvent.emit('Back')
  }

  actionsHandler($event: string) {
    switch ($event) {
      case 'Back':
        this.back()
        break
      default:
        break
    }
  }
}
