import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-trends-tabs',
  templateUrl: './trends-tabs.component.html',
  styleUrls: ['./trends-tabs.component.scss'],
})
export class TrendsTabsComponent implements OnInit {
  activeTab: number = 0

  constructor() {}

  ngOnInit(): void {}
}
