import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { UserRole } from 'common/aqsTypes'
import { NavService } from './core/nav.service'
import { AuthService } from './auth/auth.service'

@Injectable({
  providedIn: 'root',
})
export class HomepageGuard implements CanActivate {
  constructor(private nav: NavService, private auth: AuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const url = state.url.toString()

    if (url === '/home') {
      if (this.auth.user.role === UserRole.tech) {
        this.nav.navtree.inbox()
      } else {
        this.nav.navtree.dashboard()
      }
    }
    return true
  }
}
