import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, OnInit, ViewChild } from '@angular/core'

import { cloneDeep } from 'lodash'
import * as moment from 'moment-timezone'
import { CommService } from 'src/app/core/comm.service'
import { order_by } from 'src/types/graphql-global-types'
import { TimezoneService } from 'src/app/timezone.service'
import { getUserTz, safeGet, StringObjectLiteral } from '../../util/util'

import query from './settings.gql'
import { settings, settingsVariables } from './types/settings'
import { updateSetting, updateSettingVariables } from './types/updateSetting'
import mutation from './updateSetting.gql'
import insertSettingGql from './insertSetting.gql'
import { insertSetting, insertSettingVariables } from './types/insertSetting'
import { TagInputComponent } from 'ngx-chips'
import { FeatureList } from 'src/app/features.service'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  title = 'settings'
  @ViewChild('taginput') taginput: TagInputComponent
  @ViewChild('ssoTaginput') ssoTaginput: TagInputComponent
  @ViewChild('supportContactTaginput') supportContactTaginput: TagInputComponent

  getUserTz = getUserTz

  private queryResult: ApolloQueryResult<settings>

  readonly defaultVariables: settingsVariables = {
    where: {},
    limit: null,
    offset: 0,
    order_by: [{ key: order_by.asc }],
  }

  variables: settingsVariables = cloneDeep(this.defaultVariables)

  safeGet = safeGet

  timezones: string[] = moment.tz.names()

  settings: StringObjectLiteral & { features: FeatureList } = {
    timezone: '',
    ipWhitelist: '',
    ssoDomains: '',
    supportContact: '',
    scannerNamingConvention: 'InstitutionNameStationName',
    features: {},
  }

  showIPModal: boolean

  constructor(
    private apollo: Apollo,
    private comm: CommService,
    private timezoneService: TimezoneService,
  ) {}

  ngOnInit() {
    this.fetchItems()
  }

  getTz() {
    return this.timezoneService.cachedTz
  }

  fetchItems = () => {
    this.apollo
      .query<settings>({ query, variables: this.variables })
      .subscribe({ next: this.onNext, error: this.onError })
  }

  onNext = (result: ApolloQueryResult<settings>) => {
    this.queryResult = result
    if (result.errors) {
      throw result.errors[0]
    }
    this.queryResult.data.setting.forEach((s) => {
      if (s.key === 'supportContact') {
        this.settings[s.key] = s.json
      } else {
        this.settings[s.key] = s.value || s.json
      }
    })
  }

  onError = (err: unknown) => {
    throw err
  }

  save(key: string, title: string) {
    let value = null
    let json = null
    if (typeof this.settings[key] === 'string') {
      value = this.settings[key]
    } else {
      json = this.settings[key]
    }
    const subscriber = {
      next: (
        result:
          | ApolloQueryResult<updateSetting>
          | ApolloQueryResult<insertSetting>,
      ) => {},
    }
    const variables: updateSettingVariables & insertSettingVariables = {
      key,
      value,
      json,
    }
    const handleResult = (
      result: ApolloQueryResult<insertSetting | updateSetting>,
    ) => {
      if (result.errors) {
        throw result.errors[0]
      }
      this.comm.alert('success', `${title} saved`)
      if (key === 'timezone') {
        this.timezoneService.setTz(value)
      }
    }
    if (this.settingAlreadyExistsInDB(key)) {
      this.apollo
        .mutate<updateSetting>({ mutation, variables })
        .subscribe({
          next: (result: ApolloQueryResult<updateSetting>) =>
            handleResult(result),
        })
    } else {
      this.apollo
        .mutate<insertSetting>({
          mutation: insertSettingGql,
          variables: { key, value, json },
        })
        .subscribe({
          next: (result: ApolloQueryResult<insertSetting>) =>
            handleResult(result),
        })
    }
  }

  settingAlreadyExistsInDB(key: string): boolean {
    return this.queryResult.data.setting
      .map((s) => s.key === key)
      .includes(true)
  }

  saveIPWhitelist($event: string) {
    const currentInputText = this.taginput.inputText
    if (currentInputText) {
      if (Array.isArray(this.settings['ipWhitelist'])) {
        this.settings['ipWhitelist'].push(currentInputText)
        this.taginput.setInputValue('')
      }
    }
    this.save('ipWhitelist', $event)
  }
  saveSSO($event: string) {
    const currentSSOInputText = this.ssoTaginput.inputText
    if (currentSSOInputText) {
      if (Array.isArray(this.settings['ssoDomains'])) {
        this.settings['ssoDomains'].push(currentSSOInputText)
        this.ssoTaginput.setInputValue('')
      }
    }
    this.save('ssoDomains', $event)
  }

  saveSupportContact($event: string) {
    const currentSupportContactInputText = this.supportContactTaginput.inputText
    if (currentSupportContactInputText) {
      if (Array.isArray(this.settings['supportContact'])) {
        this.settings['supportContact'].push(currentSupportContactInputText)
        this.supportContactTaginput.setInputValue('')
      }
    }
    this.save('supportContact', $event)
  }
}
