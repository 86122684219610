import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { LoginComponent } from './login.component'
import { CoreModule } from '../core/core.module'
import { LoginRoutingModule } from './login-routing.module'

@NgModule({
  declarations: [LoginComponent],
  imports: [CoreModule, LoginRoutingModule, FormsModule],
})
export class LoginModule {}
