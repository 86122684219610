import { Injectable } from '@angular/core'
import { Apollo, gql } from 'apollo-angular'
import { featuresSetting } from './types/featuresSetting'

export interface FeatureList {
  dicomViewer?: boolean
}

@Injectable()
export class FeaturesService {
  constructor(private apollo: Apollo) {
    this.fetchFeatures()
  }

  features: FeatureList = {}

  fetchFeatures() {
    this.apollo
      .query<featuresSetting>({
        query: gql`
          query featuresSetting {
            setting_by_pk(key: "features") {
              json
            }
          }
        `,
      })
      .subscribe({
        next: (result) => {
          const setting = result.data?.setting_by_pk
          if (setting) {
            this.features = setting.json
          }
        },
      })
  }
}
