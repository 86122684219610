import { Component, Input, OnInit } from '@angular/core'
import { AbsoluteValueOfMetric } from 'src/app/metrics.service'

@Component({
  selector: 'app-by-metric-summary',
  templateUrl: './by-metric-summary.component.html',
  styleUrls: ['./by-metric-summary.component.scss'],
})
export class ByMetricSummaryComponent implements OnInit {
  @Input() config: AbsoluteValueOfMetric
  @Input() display: 'percent' | 'absolute' = 'absolute'

  constructor() {}

  ngOnInit(): void {}
}
