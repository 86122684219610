import { gql } from 'apollo-angular'

export default gql`
  mutation updateSetting($key: String!, $value: String, $json: jsonb) {
    update_setting(
      where: { key: { _eq: $key } }
      _set: { value: $value, json: $json }
    ) {
      affected_rows
      returning {
        key
        value
      }
    }
  }
`
