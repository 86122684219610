import { Apollo } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { ApolloLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { environment } from 'src/environments/environment'

import { AuthService } from '../auth/auth.service'

function getHasuraUri() {
  const captureClientNameFromHostRegex = /^(.*)\.myaqs\.com/m
  const { hostname } = window.location
  const clientName =
    captureClientNameFromHostRegex.exec(hostname)?.[1] || 'dev2'
  return `https://${clientName}-hasura.myaqs.com/v1/graphql`
}

const hasuraEndpoint = environment.hasuraUri || getHasuraUri()

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class HasuraModule {
  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private auth: AuthService,
  ) {
    const hasuraHttpLink = httpLink.create({ uri: hasuraEndpoint })
    const hasuraLink = ApolloLink.from([hasuraHttpLink])
    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = auth.token
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      }
    })
    apollo.createDefault({
      link: authLink.concat(hasuraLink),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    })
  }
}
