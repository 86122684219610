import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
} from '@angular/core'
import {
  ClrDatagridFilter,
  ClrDatagridFilterInterface,
  ClrInput,
} from '@clr/angular'
import { Subject, Subscription } from 'rxjs'
import { KeyVal } from 'src/app/util/util'
import { SelectFilterInterface, FilterType } from '../filters.util'
import { ComboboxComponent } from '../combobox/combobox.component'

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
})
export class SelectFilterComponent
  implements
    OnInit,
    ClrDatagridFilterInterface<string | number>,
    SelectFilterInterface {
  type: FilterType.select = FilterType.select
  @Output() changes = new Subject<string | number | undefined>()
  // if provided and exact exists as a key in options, the filter will begin active
  @Input() exact: string | number
  @Input() property: string
  /**
   * Usage examples:
   * [{
   *    key: scanner.id, <-- used to select from the DB
   *    val: scanner.name, <-- shown to the user in the filter
   * }]
   *
   * You can use strings or numbers here
   * [{
   *    key: user.username, <-- used to select from the DB
   *    val: `${user.firstName} ${user.lastName}`, <-- shown to the user in the filter
   * }]
   *
   * Or use the same key and value
   * [{
   *    key: modality.id, <-- used to select from the DB
   *    val: modality.id, <-- shown to the user in the filter
   * }]
   */
  @Input() options: KeyVal<string | number, string>[] = []

  subs: Subscription[] = []
  constructor(private filterContainer: ClrDatagridFilter) {
    filterContainer.setFilter(this)
    this.subs.push(
      filterContainer.openChange.subscribe((open) => this.onOpenChange(open)),
    )
  }

  isActive(): boolean {
    return !!this.exact
  }

  accepts(key: string | number | undefined): boolean {
    switch (typeof key) {
      case 'string':
      case 'number':
        this.exact = key
        break
      case 'undefined':
        this.exact = key
        break
      default:
        break
    }
    this.changes.next(key)
    return true
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.subs.forEach((s) => s.unsubscribe())
  }

  @ViewChild('combobox') combobox: ComboboxComponent
  onOpenChange(open: boolean) {
    if (open) {
      setTimeout(() => {
        this.combobox.focus()
      }, 0)
    }
  }
}
