import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'
import { comments_comment } from '../types/comments'

@Component({
  selector: 'app-view-test',
  templateUrl: './view-test.component.html',
  styleUrls: ['./view-test.component.scss'],
})
export class ViewTestComponent implements OnInit {
  @Input() comment: comments_comment

  detailsUri: string

  testDate: string

  testId: number

  testResult: string

  constructor(private router: Router) {}

  ngOnInit() {
    console.log(this.comment)
    const { phantom_test } = this.comment
    const { survey_test } = this.comment
    const { missed_phantom_test } = this.comment

    if (phantom_test) {
      this.detailsUri = '/home/phantomTests'
      this.testDate = phantom_test.study.scanDate
      this.testId = phantom_test.id
      this.testResult = phantom_test.result
    } else if (survey_test) {
      this.detailsUri = '/home/surveyTests'
      this.testDate = survey_test.dateCreated
      this.testId = survey_test.id
      this.testResult = survey_test.result
    } else if (missed_phantom_test) {
      this.detailsUri = '/home/missedPhantomTests'
      this.testDate = missed_phantom_test.scheduledDate
      this.testId = missed_phantom_test.id
      this.testResult = missed_phantom_test.result
    }
  }

  viewTest() {
    this.router.navigate([this.detailsUri, this.testId])
  }
}
