import { gql } from 'apollo-angular'

export default gql`
  mutation setCustomValue($data: jsonb, $routineId: Int!) {
    update_phantom_test_routine(
      where: { id: { _eq: $routineId } }
      _append: { customValues: $data }
    ) {
      returning {
        customValues
      }
    }
  }
`
