import { NgModule, ErrorHandler } from '@angular/core'
import { CommonModule, DecimalPipe } from '@angular/common'
import { ClarityModule } from '@clr/angular'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { GraphQLModule } from './graphql/graphql.module'
import { GlobalErrorHandler } from './errors/errorHandler'
import { ProgressBarComponent } from './progress-bar/progress-bar.component'
import { HttpInterceptorService } from './http-interceptor.service'
import { AlertComponent } from './alert/alert.component'
import { HumanReadablePipe } from './human-readable.pipe'
import { StripStringPipe } from './strip-string.pipe'
import { RemoveFalsyPipe } from './remove-falsy.pipe'
import { NanOrInfinityPipe } from './nan-or-infinity.pipe'

import { RobustNumberPipe } from './robust-number.pipe'
import { DragDropDirective } from './drag-drop.directive'
import { UploadFileComponent } from './upload-file/upload-file.component'
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component'
import { SafeUrlPipe } from './safe-url.pipe'
import { ToMonthNamePipe } from './to-month-name.pipe'
import { FriendlyPhantomNamePipe } from './friendly-phantom-name.pipe'
import { ActionLinkComponent } from './action-link/action-link.component'
import { ClickToViewDirective } from './click-to-view.directive'
import { UserFullNamePipe } from './user-full-name.pipe'
import { ClockComponent } from './clock/clock.component'

@NgModule({
  declarations: [
    ProgressBarComponent,
    AlertComponent,
    HumanReadablePipe,
    StripStringPipe,
    RemoveFalsyPipe,
    NanOrInfinityPipe,
    RobustNumberPipe,
    DragDropDirective,
    UploadFileComponent,
    PdfViewerComponent,
    SafeUrlPipe,
    ToMonthNamePipe,
    FriendlyPhantomNamePipe,
    ActionLinkComponent,
    ClickToViewDirective,
    UserFullNamePipe,
    ClockComponent,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    GraphQLModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
  ],
  exports: [
    FriendlyPhantomNamePipe,
    CommonModule,
    ClarityModule,
    GraphQLModule,
    ProgressBarComponent,
    AlertComponent,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HumanReadablePipe,
    StripStringPipe,
    RemoveFalsyPipe,
    NanOrInfinityPipe,
    DecimalPipe,
    RobustNumberPipe,
    UploadFileComponent,
    PdfViewerComponent,
    ToMonthNamePipe,
    ActionLinkComponent,
    ClickToViewDirective,
    UserFullNamePipe,
    ClockComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    DecimalPipe,
    SafeUrlPipe,
    FriendlyPhantomNamePipe,
  ],
})
export class CoreModule {}
