import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { DevError } from '../errors/errors'

const MAX_ACTIONS = 5

export interface LinkAction {
  name: string
  shape: string
  text?: string
}

@Component({
  selector: 'app-action-link',
  templateUrl: './action-link.component.html',
  styleUrls: ['./action-link.component.scss'],
})
export class ActionLinkComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    if (this.actions.length > MAX_ACTIONS) {
      throw new DevError(
        `action-link component may not specify more than ${MAX_ACTIONS} actions`,
      )
    }
  }

  @Input() text = 'hello world'

  @Input() actions: LinkAction[] = []

  @Output() clicks = new EventEmitter<string>()

  emitClick(action: LinkAction) {
    this.clicks.emit(action.name)
    console.log(action.name)
  }

  emitDefaultClick() {
    if (this.actions.length > 0) {
      this.emitClick(this.actions[0])
    }
  }

  get defaultTitle() {
    return this.actions?.[0]?.text || ''
  }
}
