import { Component, OnInit, Input } from '@angular/core'
import { environment } from 'src/environments/environment'
import { join } from 'path'
import { phantomTestDetails_phantom_test_by_pk } from '../../../types/phantomTestDetails'
@Component({
  selector: 'app-test-reports',
  templateUrl: './test-reports.component.html',
  styleUrls: ['./test-reports.component.scss'],
})
export class TestReportsComponent implements OnInit {
  @Input() test: phantomTestDetails_phantom_test_by_pk

  constructor() {}

  ngOnInit() {}

  get originalReportKey(): string {
    return this.test.rawOutput.report_summaryFile
  }

  get reportFile(): string {
    if (this.test && this.originalReportKey) {
      const { appUri } = environment
      const { hostedFilesPath } = environment
      // Don't use this - join turns http:// into http:/
      // const url: string = join(appUri, hostedFilesPath, key);
      const url = `${appUri}${join(hostedFilesPath, this.originalReportKey)}`
      return url
    }
    return ''
  }
}
