import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { MessageError } from 'src/app/core/errors/errors'
import { NavService } from 'src/app/core/nav.service'
import { safeGet } from '../../util/util'
import { PageHeaderAction } from '../page-header/page-header.component'
import deleteMutation from './delete-missed-phantom-tests-details.gql'
import query from './missed-phantom-tests-details.gql'
import { delete_missed_phantom_test_by_pk } from './types/delete_missed_phantom_test_by_pk'
import { missedPhantomTestsDetails } from './types/missedPhantomTestsDetails'

@Component({
  selector: 'app-missed-phantom-tests-details',
  templateUrl: './missed-phantom-tests-details.component.html',
  styleUrls: ['./missed-phantom-tests-details.component.scss'],
})
export class MissedPhantomTestsDetailsComponent implements OnInit {
  @Input() missed_phantom_testId: number

  loading = false

  editing = false

  private queryResult: ApolloQueryResult<missedPhantomTestsDetails>

  safeGet = safeGet

  @Output() updateEvent = new EventEmitter()

  title = 'Missed Test Details'

  actions: PageHeaderAction[] = [
    { text: 'Delete Test', class: 'danger' },
    { text: 'Edit Test' },
  ]

  actionsHandler($event: string) {
    switch ($event) {
      case 'Edit Test':
        this.editView()
        break
      case 'Delete Test':
        this.delete()
        break
      default:
        break
    }
  }

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private nav: NavService,
  ) {}

  ngOnInit() {
    if (!this.missed_phantom_testId) {
      this.missed_phantom_testId = this.route.snapshot.params.id
    }
    this.fetchmissedPhantomTestsDetails()
  }

  fetchmissedPhantomTestsDetails = () => {
    this.loading = true
    this.apollo
      .query<missedPhantomTestsDetails>({
        query,
        variables: { id: this.missed_phantom_testId },
      })
      .subscribe({ next: this.onNext, error: this.onError })
  }

  onNext = (result: ApolloQueryResult<missedPhantomTestsDetails>) => {
    this.loading = false
    this.queryResult = result
    if (result.errors) {
      throw result.errors[0]
    }
    if (!this.missedPhantomTest) {
      throw new MessageError('missed test record not found')
    }
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  get missedPhantomTest() {
    return this.queryResult?.data?.missed_phantom_test_by_pk
  }

  delete() {
    this.apollo
      .mutate<delete_missed_phantom_test_by_pk>({
        mutation: deleteMutation,
        variables: { id: this.missed_phantom_testId },
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.updateEvent.emit('delete')
          this.nav.navtree.missedPhantomTests()
        },
        error: (err) => console.log(err),
      })
  }

  editView() {
    this.editing = true
  }

  onSave($event) {
    this.editing = false
    this.updateEvent.emit($event)
    this.fetchmissedPhantomTestsDetails()
  }

  get scannerName() {
    return this.missedPhantomTest.scanner.name
  }

  get scheduledDate() {
    return this.missedPhantomTest.scheduledDate
  }

  get scanner() {
    return this.missedPhantomTest.scanner
  }

  get facility() {
    return this.scanner.facility
  }

  get room() {
    return this.scanner.facility_room
  }

  get facilityName() {
    return this.facility?.name
  }

  get roomName() {
    return this.room.name
  }

  get testResult() {
    return 'missed'
  }

  get scannerId() {
    return this.missedPhantomTest.scannerId
  }

  viewScanner() {
    this.nav.navtree.scannerDetails(this.scanner.id)
  }
}
