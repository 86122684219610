<div appSbox title="Support Contacts" (save)="save()">
  These emails will be notified when test failures or other problems occur.
  <tag-input
    #supportContactTaginput
    [(ngModel)]="supportContacts"
    [modelAsStrings]="true"
    [theme]="'clr-theme'"
    [placeholder]="'+ add another'"
    [secondaryPlaceholder]="'Enter an email'"
  ></tag-input>
</div>
