import { gql } from 'apollo-angular'

export default gql`
  mutation delete_study_by_pk($id: Int!) {
    delete_study(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`
