import { Component, OnInit } from '@angular/core'
import { mean } from 'lodash'
import { Breakdown, MetricList, MetricsService } from 'src/app/metrics.service'

export const colors = {
  pass: '#61b715',
  fail: '#f55047',
  info: '#4aaed9',
  problem: '#fdd008',
  tableBackground: '#212121',
}

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
})
export class MetricsComponent implements OnInit {
  title = 'metrics'
  constructor(private metricsService: MetricsService) {}
  metricsList: MetricList[]
  overallBreakdown: Breakdown

  summary: {
    overallVsLastMonth: number
    overallVsLastYear: number
    items: {
      shortName: string
      name: string
      abs: number
      changeVsLastMonth: number
      changeVsLastYear: number
      type: 'absolute' | 'percent'
    }[]
  }

  async ngOnInit() {
    const metrics = this.metricsService.metrics

    const dbItems = await Promise.all(
      metrics.map(async (m) => ({
        metric: m,
        apiData: await this.metricsService.fetchFromApiGivenSpec(m),
      })),
    )

    const metricsList = dbItems.map(({ metric, apiData }) => ({
      metric,
      data: this.metricsService.calculateMetricSummaryFromSpec(metric, apiData),
    }))

    const breakdowns = metricsList.map((ml) => ml.data.breakdown)
    this.overallBreakdown = this.metricsService.mergeBreakdowns(breakdowns)

    const summaryItems = metricsList.map((listItem) => ({
      shortName: listItem.metric.shortTitle,
      name: listItem.metric.title,
      abs: listItem.data.absoluteValueOfMetric.thisMonth,
      changeVsLastMonth: listItem.data.changeInMetric.vsLastMonth,
      changeVsLastYear: listItem.data.changeInMetric.vsMonthAverageThisYear,
      type: listItem.metric.type,
    }))
    this.summary = {
      overallVsLastMonth: mean(
        summaryItems.map((item) => item.changeVsLastMonth),
      ),
      overallVsLastYear: mean(
        summaryItems.map((item) => item.changeVsLastYear),
      ),
      items: summaryItems,
    }

    // this modifications under here
    this.metricsList = metricsList
  }
}
