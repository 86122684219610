import { gql } from 'apollo-angular'

export default gql`
  mutation delete_user_by_pk($id: Int!) {
    delete_user(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`
