import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { TagInputComponent } from 'ngx-chips'
import { CommService } from 'src/app/core/comm.service'
import { scannersDetails_scanner_by_pk } from '../scanners-details/types/scannersDetails'
import {
  updateScannerSupportContact,
  updateScannerSupportContactVariables,
} from './types/updateScannerSupportContact'
import updateScannerSupportContactGql from './updateScannerSupportContact.gql'

@Component({
  selector: 'app-scanner-support-contacts',
  templateUrl: './scanner-support-contacts.component.html',
  styleUrls: ['./scanner-support-contacts.component.scss'],
})
export class ScannerSupportContactsComponent implements OnInit {
  @Input() scanner: scannersDetails_scanner_by_pk
  @ViewChild('supportContactTaginput') supportContactTaginput: TagInputComponent
  supportContacts: string[]

  constructor(private apollo: Apollo, private comm: CommService) {}

  ngOnInit(): void {
    this.supportContacts = this.scanner.supportContacts
  }

  save() {
    const currentInputText = this.supportContactTaginput.inputText
    if (currentInputText) {
      if (Array.isArray(this.supportContacts)) {
        this.supportContacts.push(currentInputText)
        this.supportContactTaginput.setInputValue('')
      }
    }
    const variables: updateScannerSupportContactVariables = {
      scannerId: this.scanner.id,
      contacts: this.supportContacts,
    }
    const mutation = updateScannerSupportContactGql
    this.apollo
      .mutate<updateScannerSupportContact>({ mutation, variables })
      .subscribe({
        next: (result) => {
          this.comm.alert('success', `Support Contacts saved`)
          this.scanner.supportContacts =
            result.data.update_scanner.returning[0].supportContacts
        },
      })
  }
}
