<ng-container *ngIf="survey">
  <!-- Survey Test Routine Name -->
  <h3>{{ surveyName }}</h3>

  <!-- Select Scanner Prompt -->

  <clr-select-container>
    <label>Select a Scanner</label>
    <select clrSelect [(ngModel)]="selectedScanner" name="scanner">
      <option *ngFor="let scanner of scanners" [ngValue]="scanner.id">{{
        scanner.facility_room?.name || scanner.name
      }}</option>
    </select>
  </clr-select-container>

  <!-- Survey Questions -->
  <ng-container *ngIf="selectedScanner">
    <!-- Render each section -->

    <!-- Legend -->
    <div class="card dark-card">
      <div class="card-header">
        Legend
      </div>
      <div class="card-block">
        <clr-radio-container [ngStyle]="{ 'margin-top': '0px' }">
          <clr-radio-wrapper *ngFor="let result of results">
            <input class="{{ result }}" type="radio" clrRadio disabled />
            <label class="{{ result }}" title="{{ result }}"
              >{{ result }}&nbsp;&nbsp;</label
            >
          </clr-radio-wrapper>
        </clr-radio-container>
      </div>
    </div>
    <form clrForm>
      <ng-container *ngFor="let section of sections | keyvalue">
        <div class="card">
          <!-- No title for the questions without a section -->
          <div *ngIf="section.key !== ''" class="card-header">
            {{ section.key }}
          </div>

          <!-- Section body -->
          <div class="card-block">
            <div class="clr-row">
              <ng-container *ngFor="let question of section.value">
                <div class="clr-col-6">
                  <ng-container [ngSwitch]="question.type">
                    <!-- SurveyTestTextQuestion -->
                    <clr-input-container
                      *ngSwitchCase="'SurveyTestTextQuestion'"
                    >
                      <label class="clr-col-md-6">{{ question.text }}</label>
                      <input
                        class="clr-col-md-6"
                        required
                        clrInput
                        type="text"
                        [(ngModel)]="question.answer"
                        name="{{ question.id }}"
                      />
                      <clr-control-error
                        >Please input a value</clr-control-error
                      >
                    </clr-input-container>

                    <!-- SurveyTestNumberQuestion -->
                    <clr-input-container
                      *ngSwitchCase="'SurveyTestNumberQuestion'"
                    >
                      <label class="clr-col-md-6">{{ question.text }}</label>
                      <input
                        class="clr-col-md-6"
                        required
                        clrInput
                        type="number"
                        [(ngModel)]="question.answer"
                        name="{{ question.id }}"
                      />
                      <clr-control-error
                        >Please input a value</clr-control-error
                      >
                    </clr-input-container>

                    <!-- SurveyTestSelectQuestion -->
                    <clr-select-container
                      *ngSwitchCase="'SurveyTestSelectQuestion'"
                    >
                      <label class="clr-col-md-6">{{ question.text }}</label>
                      <select
                        class="clr-col-md-6"
                        required
                        clrSelect
                        [(ngModel)]="question.answer"
                        name="{{ question.id }}"
                      >
                        <option
                          *ngFor="let option of question.options"
                          value="{{ option.id }}"
                          >{{ option.name }}
                        </option>
                      </select>
                      <clr-control-error
                        >Please select an option</clr-control-error
                      >
                    </clr-select-container>

                    <!-- SurveyTestYesNoQuestion -->
                    <clr-radio-container
                      *ngSwitchCase="'SurveyTestYesNoQuestion'"
                    >
                      <label class="clr-col-md-6">{{ question.text }}</label>
                      <clr-radio-wrapper>
                        <label>Yes</label>
                        <input
                          class="clr-col-md-6"
                          required
                          type="radio"
                          clrRadio
                          [(ngModel)]="question.answer"
                          value="yes"
                          name="{{ question.id }}"
                        />
                      </clr-radio-wrapper>
                      <clr-radio-wrapper>
                        <label>No</label>
                        <input
                          class="clr-col-md-6"
                          required
                          type="radio"
                          clrRadio
                          [(ngModel)]="question.answer"
                          value="no"
                          name="{{ question.id }}"
                        />
                      </clr-radio-wrapper>
                      <clr-control-error
                        >Please select an option</clr-control-error
                      >
                    </clr-radio-container>

                    <!-- SurveyTestPassFailQuestion -->
                    <ng-container *ngSwitchCase="'SurveyTestPassFailQuestion'">
                      <!-- Yes this is a little hacky - the layout fails for a label inside the radio container -->
                      <div class="clr-row">
                        <!-- Label -->
                        <div class="clr-col-6">
                          <clr-input-container>
                            <label class="clr-col-12">{{
                              question.text
                            }}</label>
                          </clr-input-container>
                        </div>

                        <!-- Radio Buttons -->
                        <div class="clr-col-6">
                          <clr-radio-container>
                            <clr-radio-wrapper *ngFor="let result of results">
                              <input
                                class="{{ result }}"
                                required
                                type="radio"
                                clrRadio
                                [(ngModel)]="question.answer"
                                value="{{ result }}"
                                name="{{ question.id }}"
                              />
                              <label class="{{ result }}" title="{{ result }}"
                                >&nbsp;</label
                              >
                            </clr-radio-wrapper>
                            <clr-control-error
                              >Please select an option</clr-control-error
                            >
                          </clr-radio-container>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </form>

    <!-- Submit -->
    <button
      [clrLoading]="submitButtonState"
      (click)="submitQuestions()"
      class="btn btn-success-outline"
    >
      Submit
    </button>
  </ng-container>
</ng-container>
