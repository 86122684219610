<app-comment-categories-edit
  (saveEvent)="actionsHandler($event)"
  *ngIf="creatingCategory"
></app-comment-categories-edit>
<ng-container *ngIf="!creatingCategory">
  <div
    class="clr-row"
    appPageHeader
    [title]="title"
    [actions]="actions"
    (clicks)="actionsHandler($event)"
  ></div>
  <clr-datagrid
    class="datagrid-striped"
    #datagrid
    (clrDgRefresh)="refreshGrid($event, fetchItems)"
    [clrDgLoading]="loading"
  >
    <clr-dg-placeholder>Nothing to Show</clr-dg-placeholder>

    <clr-dg-row *ngFor="let item of items">
      <clr-dg-cell>{{ item.name }}</clr-dg-cell>
      <clr-dg-cell [ngStyle]="{ color: colors[item.color] }">{{
        item.color
      }}</clr-dg-cell>
      <app-comment-categories-details
        (updateEvent)="onUpdate($event)"
        *clrIfExpanded
        [comment_categoryId]="item.id"
        ngProjectAs="clr-dg-row-detail"
      ></app-comment-categories-details>
    </clr-dg-row>

    <clr-dg-column
      [clrDgField]="properties.name"
      [clrFilterValue]="filterState[properties.name]?.value"
      >Name</clr-dg-column
    >
    <clr-dg-column
      [clrDgField]="properties.color"
      [clrFilterValue]="filterState[properties.color]?.value"
      >Color</clr-dg-column
    >

    <clr-dg-footer>
      {{ pagination.firstItem + 1 | number }} -
      {{ pagination.lastItem + 1 | number }} of {{ totalItems | number }} items
      <clr-dg-pagination
        #pagination
        [clrDgTotalItems]="totalItems"
      ></clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</ng-container>
