import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'

import { cloneDeep } from 'lodash'
import { AuthService } from 'src/app/auth/auth.service'
import { CommService } from 'src/app/core/comm.service'
import { FileService } from 'src/app/core/file.service'
import { UploadFileComponent } from 'src/app/core/upload-file/upload-file.component'
import { colors } from 'src/config/colors'
import { comment_insert_input } from 'src/types/graphql-global-types'
import { camel, safeGet, StringObjectLiteral } from '../../util/util'
import { commentsDetails_comment_by_pk } from '../comments-details/types/commentsDetails'
import editMutation from './comments-edit.gql'
import insertMutation from './comments-insert.gql'
import relatedEntityQuery from './comment_get_related_entities.gql'
import { comment_get_related_entities } from './types/comment_get_related_entities'
import { edit_comment_by_pk } from './types/edit_comment_by_pk'
import { insert_comment } from './types/insert_comment'

@Component({
  selector: 'app-comments-edit',
  templateUrl: './comments-edit.component.html',
  styleUrls: ['./comments-edit.component.scss'],
})
export class CommentsEditComponent implements OnInit {
  @ViewChild(UploadFileComponent) filesComponent: UploadFileComponent

  @Input() scannerId: number

  @Input() phantomTestId?: number

  @Input() surveyTestId?: number

  @Input() missedPhantomTestId?: number

  @Input() comment: commentsDetails_comment_by_pk

  categoryId: number

  createNew = false

  newComment: comment_insert_input = {}

  private _backup_comment: commentsDetails_comment_by_pk

  @Output() saveEvent = new EventEmitter()

  safeGet = safeGet

  objectKeys = Object.keys

  relatedEntityOptionsQueryResult: ApolloQueryResult<
    comment_get_related_entities
  >

  colors = colors

  file: File

  constructor(
    private apollo: Apollo,
    private auth: AuthService,
    private router: Router,
    private fileService: FileService,
    private comm: CommService,
  ) {}

  fetchRelatedEntityOptions() {
    this.apollo
      .query<comment_get_related_entities>({ query: relatedEntityQuery })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.relatedEntityOptionsQueryResult = result
        },
      })
  }

  get relatedEntityOptions(): comment_get_related_entities {
    return this.relatedEntityOptionsQueryResult.data
  }

  ngOnInit() {
    // figure out if we're editing or creating a comment
    if (!this.comment) {
      this.createNew = true
    }
    if (!this.createNew) {
      this._backup_comment = cloneDeep(this.comment)
    }
    this.fetchRelatedEntityOptions()
  }

  save(): void {
    if (this.createNew) {
      if (!this.newComment.subject) {
        this.comm.alert('danger', 'comment subject is required')
        return
      }
      this.newComment.scannerId = this.scannerId
      this.newComment.phantomTestId = this.phantomTestId
      this.newComment.surveyTestId = this.surveyTestId
      this.newComment.missedPhantomTestId = this.missedPhantomTestId
      this.newComment.userId = this.auth.user.id

      this.apollo
        .mutate<insert_comment>({
          mutation: insertMutation,
          variables: { comment: this.newComment },
        })
        .subscribe({
          next: async (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            try {
              await this.uploadFiles(result.data.insert_comment.returning[0].id)
            } catch (err) {
              this.comm.alert('danger', 'file upload failed')
            }
            this.newComment = {}
            this.saveEvent.emit('create')
          },
        })
    } else {
      this.comment.userId = this.auth.user.id
      this.apollo
        .mutate<edit_comment_by_pk>({
          mutation: editMutation,
          variables: {
            id: this.comment.id,
            _set: this.stripInput(this.comment),
          },
        })
        .subscribe({
          next: async (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            try {
              await this.uploadFiles(result.data.update_comment.returning[0].id)
            } catch (err) {
              this.comm.alert('danger', 'file upload failed')
            }
            this.saveEvent.emit('edit')
          },
        })
    }
  }

  back() {
    this.comment = cloneDeep(this._backup_comment)
    this.saveEvent.emit('back')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripInput = (obj: StringObjectLiteral<any>) =>
    Object.keys(obj).reduce((newObj, key) => {
      if (
        key !== 'id' &&
        key !== 'dateCreated' &&
        key !== 'dateUpdated' &&
        key !== '__typename'
      ) {
        if (
          typeof obj[key] === 'string' ||
          typeof obj[key] === 'number' ||
          (obj[key] === null && key.slice(-2) === 'Id')
        ) {
          newObj[camel(key)] = obj[key]
        }
      }
      return newObj
    }, {})

  getModel(): commentsDetails_comment_by_pk | comment_insert_input {
    return this.createNew ? this.newComment : this.comment
  }

  setModel(key: string, val: string) {
    this.getModel()[key] = val
  }

  manageCategories() {
    this.router.navigate(['/home/commentCategories'])
  }

  async uploadFiles(commentId: number) {
    console.log(this.filesComponent.files)
    return new Promise((resolve, reject) => {
      this.fileService
        .uploadFileToAQS(
          { uploadType: 'commentAttachment', commentId: commentId.toString() },
          this.filesComponent.files,
        )
        .subscribe({
          next: (result) => {
            resolve(result)
          },
          error: (err) => {
            reject(err)
          },
        })
    })
  }
}
