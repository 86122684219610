import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CoreModule } from '../core/core.module'
import { LoginComponent } from './login.component'
import { AlreadyLoggedInGuard } from '../already-logged-in.guard'

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AlreadyLoggedInGuard],
  },
]

@NgModule({
  declarations: [],
  imports: [CoreModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
