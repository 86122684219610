import { Injectable, EventEmitter } from '@angular/core'
import { hashCode } from 'src/app/util/util'

const ALERT_TIMEOUT = 5000

export type AlertType = 'success' | 'warning' | 'danger' | 'info'

export interface Alert {
  id: string
  type: AlertType
  text: string
  viewLink: string
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  alerts: Alert[] = []

  alertRemoved = new EventEmitter()

  constructor() {}

  alert(type: AlertType, text: string, duration?: number, viewLink?: string) {
    const id = hashCode(`${type}${text}`).toString()
    if (!this.alerts.find((a) => a.id === id)) {
      // don't add duplicate alerts
      this.alerts.push({
        id,
        type,
        text,
        viewLink: viewLink || '',
      })
      if (duration !== -1) {
        setTimeout(() => {
          this.alerts.shift()
          this.alertRemoved.emit(null)
        }, duration || ALERT_TIMEOUT)
      }
    }
  }

  removeAlert(id: string) {
    console.log('removing alert', id, this.alerts)
    this.alerts = this.alerts.filter((a) => a.id !== id)
    this.alertRemoved.emit(null)
  }
}
