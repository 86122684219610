import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { cloneDeep } from 'lodash'
import { camel, safeGet, StringObjectLiteral } from '../../util/util'
import { missedPhantomTestsDetails_missed_phantom_test_by_pk } from '../missed-phantom-tests-details/types/missedPhantomTestsDetails'
import editMutation from './missed-phantom-tests-edit.gql'
import relatedEntityQuery from './missed_phantom_test_get_related_entities.gql'
import { edit_missed_phantom_test_by_pk } from './types/edit_missed_phantom_test_by_pk'
import { missed_phantom_test_get_related_entities } from './types/missed_phantom_test_get_related_entities'

@Component({
  selector: 'app-missed-phantom-tests-edit',
  templateUrl: './missed-phantom-tests-edit.component.html',
  styleUrls: ['./missed-phantom-tests-edit.component.scss'],
})
export class MissedPhantomTestsEditComponent implements OnInit {
  @Input()
  missed_phantom_test: missedPhantomTestsDetails_missed_phantom_test_by_pk

  private _backup_missed_phantom_test: missedPhantomTestsDetails_missed_phantom_test_by_pk

  @Output() saveEvent = new EventEmitter()

  safeGet = safeGet

  objectKeys = Object.keys

  relatedEntityOptionsQueryResult: ApolloQueryResult<
    missed_phantom_test_get_related_entities
  >

  constructor(private apollo: Apollo) {}

  fetchRelatedEntityOptions() {
    this.apollo
      .query<missed_phantom_test_get_related_entities>({
        query: relatedEntityQuery,
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.relatedEntityOptionsQueryResult = result
        },
      })
  }

  get relatedEntityOptions(): missed_phantom_test_get_related_entities {
    return this.relatedEntityOptionsQueryResult.data
  }

  ngOnInit() {
    this._backup_missed_phantom_test = cloneDeep(this.missed_phantom_test)
    console.log(this.missed_phantom_test)
    this.fetchRelatedEntityOptions()
  }

  save() {
    this.apollo
      .mutate<edit_missed_phantom_test_by_pk>({
        mutation: editMutation,
        variables: {
          id: this.missed_phantom_test.id,
          _set: this.stripInput(this.missed_phantom_test),
        },
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.saveEvent.emit('edit')
        },
      })
  }

  back() {
    this.missed_phantom_test = cloneDeep(this._backup_missed_phantom_test)
    this.saveEvent.emit('back')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripInput = (obj: StringObjectLiteral<any>) =>
    Object.keys(obj).reduce((newObj, key) => {
      if (
        key !== 'id' &&
        key !== 'dateCreated' &&
        key !== 'dateUpdated' &&
        key !== '__typename'
      ) {
        if (
          typeof obj[key] === 'string' ||
          typeof obj[key] === 'number' ||
          (obj[key] === null && key.slice(-2) === 'Id')
        ) {
          newObj[camel(key)] = obj[key]
        }
      }
      return newObj
    }, {})
}
