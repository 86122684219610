import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { MessageError } from 'src/app/core/errors/errors'
import { NavService } from 'src/app/core/nav.service'
import { safeGet } from '../../util/util'
import addRoomGql from './add-room.gql'
import deleteMutation from './delete-facilities-details.gql'
import deleteRoomMutation from './delete-facilities-room.gql'
import query from './facilities-details.gql'
import { addRoom } from './types/addRoom'
import { delete_facility_by_pk } from './types/delete_facility_by_pk'
import {
  facilitiesDetails,
  facilitiesDetails_facility_by_pk_facility_rooms,
} from './types/facilitiesDetails'
import { deleteFacilityRoom } from './types/deleteFacilityRoom'

@Component({
  selector: 'app-facilities-details',
  templateUrl: './facilities-details.component.html',
  styleUrls: ['./facilities-details.component.scss'],
})
export class FacilitiesDetailsComponent implements OnInit {
  @Input() facilityId: number

  loading = false

  editing = false

  private queryResult: ApolloQueryResult<facilitiesDetails>

  safeGet = safeGet

  @Output() updateEvent = new EventEmitter()

  roomInput = ''

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private nav: NavService,
  ) {}

  ngOnInit() {
    if (!this.facilityId) {
      this.facilityId = this.route.snapshot.params.id
    }
    this.fetchfacilitiesDetails()
  }

  fetchfacilitiesDetails = () => {
    this.loading = true
    this.apollo
      .query<facilitiesDetails>({ query, variables: { id: this.facilityId } })
      .subscribe({ next: this.onNext, error: this.onError })
  }

  onNext = (result: ApolloQueryResult<facilitiesDetails>) => {
    this.loading = false
    this.queryResult = result
    if (result.errors) {
      throw result.errors[0]
    }
    if (!this.facility) {
      throw new MessageError('facility not found')
    }
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  get facility() {
    return this.queryResult?.data?.facility_by_pk
  }

  delete() {
    if (
      window.confirm(
        'Delete Facility: are you sure? This will NOT delete any scanner or test data.',
      )
    ) {
      this.apollo
        .mutate<delete_facility_by_pk>({
          mutation: deleteMutation,
          variables: { id: this.facilityId },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.nav.navtree.facilities()
          },
        })
    }
  }

  deleteRoom(room: facilitiesDetails_facility_by_pk_facility_rooms) {
    if (
      window.confirm(
        'Delete Room: are you sure? This will NOT delete any scanner or test data.',
      )
    ) {
      this.apollo
        .mutate<deleteFacilityRoom>({
          mutation: deleteRoomMutation,
          variables: { id: room.id },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.facility.facility_rooms = this.rooms.filter(
              (r) => r.id !== room.id,
            )
          },
        })
    }
  }

  editView() {
    this.editing = true
  }

  onSave($event: string) {
    this.editing = false
    this.updateEvent.emit($event)
  }

  get rooms() {
    return this.facility?.facility_rooms
  }

  onRoomSave() {
    // mutate facility rooms, return room, concat
    if (!this.roomInput) {
      return
    }
    this.apollo
      .mutate<addRoom>({
        mutation: addRoomGql,
        variables: {
          facilityId: this.facility?.id,
          roomName: this.roomInput,
        },
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.facility.facility_rooms =
            result.data.insert_facility_room.returning[0].facility?.facility_rooms
          this.roomInput = ''
        },
      })
  }

  onRoomCancel() {
    this.roomInput = ''
  }
}
