import { Injectable } from '@angular/core'
import { ClrDatagridStateInterface } from '@clr/angular'
import { AuthService } from '../auth/auth.service'
import { StringObjectLiteral } from '../util/util'
import { FilterInterface } from '../filters/filters.util'

export interface DatagridManualState extends ClrDatagridStateInterface {
  filters?: FilterInterface[]
}

@Injectable({
  providedIn: 'root',
})
export class DatagridStateService {
  private state: StringObjectLiteral<ClrDatagridStateInterface<unknown>> = {}

  get(path: string): ClrDatagridStateInterface<unknown> {
    if (!this.state[path]) {
      this.state[path] = {}
    }
    return this.state[path]
  }
  set(path: string, value: ClrDatagridStateInterface<unknown>) {
    this.state[path] = value
  }
  clear() {
    this.state = {}
  }
}
