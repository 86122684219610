<div
  class="clr-row"
  appPageHeader
  [title]="title"
  [actions]="actions"
  (clicks)="actionsHandler($event)"
></div>
<div class="">
  <form clrForm>
    <clr-select-container id="select">
      <label>Device</label>
      <select
        clrSelect
        [(ngModel)]="selectedDevice"
        (ngModelChange)="handleDeviceChange()"
        name="device"
      >
        <option *ngFor="let device of devices" [ngValue]="device">
          {{ device.name }}
        </option>
      </select>
    </clr-select-container>
    <clr-control-container>
      <label>Modality</label>
      <label>{{ selectedModality }}</label>
    </clr-control-container>
    <clr-select-container id="select">
      <label>Report Type</label>
      <select
        clrSelect
        [disabled]="disableOptions"
        [(ngModel)]="selectedReportType"
        (ngModelChange)="handleModalityChange()"
        name="periodType"
      >
        <option *ngFor="let reportType of reportTypes" [ngValue]="reportType">
          {{ reportType }}
        </option>
      </select>
    </clr-select-container>
    <clr-control-container>
      <label>Number of Months</label>
      <label>{{ periodLengthInMonths }}</label>
    </clr-control-container>
    <clr-select-container id="select">
      <label>Period End Date</label>
      <select
        clrSelect
        [disabled]="disableOptions"
        [(ngModel)]="selectedPeriod"
        (ngModelChange)="handleModalityChange()"
        name="period"
      >
        <option *ngFor="let period of periods" [ngValue]="period">
          {{ period.format('MMM-YYYY') }}
        </option>
      </select>
    </clr-select-container>
    <clr-control-container>
      <label>Period from/to</label>
      <label
        ><span *ngIf="periodStart"
          >{{ periodStart }} to {{ periodEnd }}</span
        ></label
      >
    </clr-control-container>
    <button
      class="btn btn-success-outline generateButton"
      [ngClass]="{ disabled: disableGenerateButton }"
      (click)="generate()"
    >
      Generate
    </button>
  </form>
  <!-- 
  <button (click)="save()" class="btn btn-success-outline">Save</button>
  <button (click)="back()" class="btn btn-outline">Back</button> -->
</div>
