import { Input, OnInit, Component, ViewChild } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { TagInputComponent } from 'ngx-chips'
import { CommService } from 'src/app/core/comm.service'
import { facilitiesDetails_facility_by_pk } from '../facilities-details/types/facilitiesDetails'
import {
  updateFacilitySupportContact,
  updateFacilitySupportContactVariables,
} from './types/updateFacilitySupportContact'
import updateFacilitySupportContactGql from './updateFacilitySupportContact.gql'

@Component({
  selector: 'app-facility-support-contacts',
  templateUrl: './facility-support-contacts.component.html',
  styleUrls: ['./facility-support-contacts.component.scss'],
})
export class FacilitySupportContactsComponent implements OnInit {
  @Input() facility: facilitiesDetails_facility_by_pk
  @ViewChild('supportContactTaginput') supportContactTaginput: TagInputComponent
  supportContacts: string[] = []

  constructor(private apollo: Apollo, private comm: CommService) {}

  ngOnInit(): void {
    this.supportContacts = this.facility.supportContacts
  }

  save() {
    const currentInputText = this.supportContactTaginput.inputText
    if (currentInputText) {
      if (Array.isArray(this.supportContacts)) {
        this.supportContacts.push(currentInputText)
        this.supportContactTaginput.setInputValue('')
      }
    }
    const variables: updateFacilitySupportContactVariables = {
      facilityId: this.facility.id,
      contacts: this.supportContacts,
    }
    const mutation = updateFacilitySupportContactGql
    this.apollo
      .mutate<updateFacilitySupportContact>({ mutation, variables })
      .subscribe({
        next: (result) => {
          this.comm.alert('success', `Support Contacts saved`)
          this.facility.supportContacts =
            result.data.update_facility.returning[0].supportContacts
        },
      })
  }
}
