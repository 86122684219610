<div class="clr-row" appPageHeader title="{{ title }}: {{ totalItems }}">
  <clr-toggle-container>
    <clr-toggle-wrapper>
      <input
        type="checkbox"
        clrToggle
        name="comments"
        [ngModel]="withoutComments"
        (ngModelChange)="setWithoutComments($event)"
      />
      <label>Only problems without comments</label>
    </clr-toggle-wrapper>
  </clr-toggle-container>
</div>
<clr-datagrid
  class="datagrid-striped"
  #datagrid
  (clrDgRefresh)="refreshGrid($event, fetchItems)"
  [clrDgLoading]="loading"
>
  <clr-dg-placeholder>Nothing to Show</clr-dg-placeholder>

  <!-- Row Def -->
  <clr-dg-row *ngFor="let item of items">
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item.facilityName
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item.facilityRoomName
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item.modalityName
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item.scannerName
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item.testDate | customDate
    }}</clr-dg-cell>
    <clr-dg-cell
      appClickToView
      (click)="view(item)"
      class="{{ item.result }}"
      >{{ item.result }}</clr-dg-cell
    >
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item.commentsCount
    }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-column [clrDgField]="properties.facilityName">
    <ng-container>Facility</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.facilityName]?.exact"
        [property]="properties.facilityName"
        [options]="options.facilities"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="properties.facilityRoomName">
    <ng-container>Room</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.facilityRoomName]?.exact"
        [property]="properties.facilityRoomName"
        [options]="options.rooms"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="properties.modalityName">
    <ng-container>Modality</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.modalityName]?.exact"
        [property]="properties.modalityName"
        [options]="options.modalities"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="properties.scannerName">
    <ng-container> Scanner</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.scannerName]?.exact"
        [property]="properties.scannerName"
        [options]="options.scanners"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="properties.testDate">
    <ng-container>Date</ng-container>
    <clr-dg-filter>
      <app-date-filter
        [property]="properties.testDate"
        [earliest]="filterState[properties.testDate]?.earliest"
        [latest]="filterState[properties.testDate]?.latest"
      ></app-date-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="properties.result">
    <ng-container>Result</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.result]?.exact"
        [property]="properties.result"
        [options]="options.results"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="properties.commentsCount">
    <ng-container>Comments</ng-container>
    <clr-dg-filter>
      <app-number-filter
        #numberFilter
        [property]="properties.commentsCount"
        [min]="filterState[properties.commentsCount]?.min"
        [max]="filterState[properties.commentsCount]?.max"
      ></app-number-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Footer -->
  <clr-dg-footer>
    {{ pagination.firstItem + 1 | number }} -
    {{ pagination.lastItem + 1 | number }} of {{ totalItems | number }} items
    <clr-dg-pagination
      #pagination
      [clrDgTotalItems]="totalItems"
    ></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
