import { gql } from 'apollo-angular'

export default gql`
  query user_get_related_entities {
    facility(order_by: { name: asc }) {
      id
      name
    }
  }
`
