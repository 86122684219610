<div
  class="clr-row"
  appPageHeader
  [title]="title"
  [actions]="actions"
  (clicks)="actionsHandler($event)"
></div>

<div class="clr-row">
  <ng-container *ngFor="let schedule of schedules">
    <div class="clr-col-xl-6">
      <app-single-schedule
        [schedule]="schedule"
        [allScanners]="allScanners"
        [allTasks]="allTasks"
        [new]="schedule.new"
        (deleteEvent)="onDelete($event)"
      ></app-single-schedule>
    </div>
  </ng-container>
</div>
