<div
  class="upload-file-container"
  (click)="fileInput.click()"
  appDragDrop
  (onDragover)="onDragOver()"
  (onDragleave)="onDragLeave()"
  (onFileDropped)="uploadFile($event)"
  [ngClass]="{ dragover: dragover }"
>
  Drag files or click to upload
  <input
    hidden
    multiple
    type="file"
    #fileInput
    (change)="uploadFile($event.target.files)"
  />
</div>
<div class="files-list">
  <div class="file" *ngFor="let file of files; let i = index">
    <span>{{ file.name }}</span>
    <a class="delete-button" (click)="deleteAttachment(i)">
      <clr-icon class="is-error" shape="times"></clr-icon>
    </a>
  </div>
</div>
