import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { trendsScanners_scanner } from '../trends/types/trendsScanners'

type scanner = trendsScanners_scanner

@Component({
  selector: 'app-scanner-multi-select',
  templateUrl: './scanner-multi-select.component.html',
  styleUrls: ['./scanner-multi-select.component.scss'],
})
export class ScannerMultiSelectComponent implements OnInit {
  @Input() scanners: scanner[]
  @Input() roomNames: string[]
  @Output() valueChange: EventEmitter<scanner[]> = new EventEmitter<scanner[]>()
  @Input() maxItems: number
  @Input() title: string
  private _selected: scanner[] = []
  get selected() {
    return this._selected
  }
  set selected(scanners: scanner[]) {
    this._selected = scanners
    this.valueChange.emit(this._selected)
    console.log(this.selected)
  }
  contentExpanded = false
  constructor(private elementRef: ElementRef) {}

  get scannersWithRooms() {
    const rooms = this.scanners
      .filter((s) => !!s.facility_room?.name)
      .map((s) => ({ ...s, name: s.facility_room.name }))
    return [...this.scanners, ...rooms]
  }

  ngOnInit(): void {}

  @HostListener('document:click', ['$event'])
  clickout($event) {
    if (
      !this.elementRef.nativeElement.contains($event.target) &&
      !this.isDropdownItem($event.target)
    ) {
      this.contentExpanded = false
    }
  }

  private isDropdownItem(target) {
    return (
      target?.classList.contains('ng2-menu-item') ||
      target?.parentElement?.classList.contains('ng2-menu-item')
    )
  }
}
