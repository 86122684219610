import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { FileService } from 'src/app/core/file.service'
import { safeGet } from '../../util/util'
import query from './comments-details.gql'
import deleteMutation from './delete-comments-details.gql'
import { commentsDetails } from './types/commentsDetails'
import { delete_comment_by_pk } from './types/delete_comment_by_pk'
import { AuthService } from 'src/app/auth/auth.service'
import { UserRole } from 'src/types/graphql-global-types'

@Component({
  selector: 'app-comments-details',
  templateUrl: './comments-details.component.html',
  styleUrls: ['./comments-details.component.scss'],
})
export class CommentsDetailsComponent implements OnInit {
  @Input() commentId: number

  loading = false

  editing = false

  queryResult: ApolloQueryResult<commentsDetails>

  safeGet = safeGet

  @Output() updateEvent = new EventEmitter()

  constructor(
    private apollo: Apollo,
    private fileService: FileService,
    private auth: AuthService,
  ) {}

  ngOnInit() {
    this.fetchcommentsDetails()
  }

  fetchcommentsDetails = () => {
    this.loading = true
    this.apollo
      .query<commentsDetails>({ query, variables: { id: this.commentId } })
      .subscribe({ next: this.onNext, error: this.onError })
  }

  onNext = (result: ApolloQueryResult<commentsDetails>) => {
    this.loading = false
    this.queryResult = result
    if (result.errors) {
      throw result.errors[0]
    }
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  get comment() {
    return this.queryResult.data.comment_by_pk
  }

  delete() {
    this.apollo
      .mutate<delete_comment_by_pk>({
        mutation: deleteMutation,
        variables: { id: this.commentId },
      })
      .subscribe({
        next: (result) => {
          this.updateEvent.emit('delete')
          if (result.errors) {
            throw result.errors[0]
          }
        },
        error: (err) => {
          throw err
        },
      })
  }

  editView() {
    this.editing = true
  }

  onSave($event: unknown) {
    this.editing = false
    this.updateEvent.emit($event)
  }

  get content() {
    return this.comment.content
  }

  get fileKeys(): string[] {
    return this.comment.comment_files.map((f) => f.stored_file.key)
  }

  src(key: string) {
    return this.fileService.getHostedUrl(key)
  }

  canDelete(): boolean {
    return (
      this.comment.userId === this.auth.user.id ||
      this.auth.checkrole([UserRole.admin, UserRole.support])
    )
  }
}
