import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core'
import {
  trends_phantom_test_routine,
  TIME_PERIOD_DAYS,
  trends_survey_test_routine,
} from 'src/app/home/trends/helpers'
import { clearEmpties, filterLeaves, NestedObject } from 'src/app/util/util'
import { TestResult } from 'common/testing/ge_big'
import { merge } from 'lodash'

interface RecursiveDropdownInput {
  key: string
  options?: RecursiveDropdownInput[]
}

@Component({
  selector: 'app-side-controls',
  templateUrl: './side-controls.component.html',
  styleUrls: ['./side-controls.component.scss'],
})
export class SideControlsComponent {
  // inputs
  @Input() title: string
  @Input() position: 'right' | 'left' = 'left'
  get flipPosition() {
    return this.position === 'left' ? 'right' : 'left'
  }
  /** union of all routines for selected scanners */
  @Input() phantomTestRoutines: trends_phantom_test_routine[]
  /** union of all survey test routines for selected scanners */
  @Input() surveyTestRoutines: trends_survey_test_routine[]
  ngOnChanges() {
    this.handlePhantomTestRoutineChange()
  }
  @Input() disabled: boolean
  @Input() showYAxis: boolean = true
  // outputs
  @Output() valueChange = new EventEmitter<void>()

  @HostListener('document:click', ['$event'])
  clickout($event) {
    if (!this.elementRef.nativeElement.contains($event.target)) {
      this.contentExpanded = false
    }
  }

  constructor(private elementRef: ElementRef) {}

  // accessed in trends component
  selectedPhantomTestRoutineId: number
  get selectedPhantomTestRoutine(): trends_phantom_test_routine {
    return this.phantomTestRoutines?.find(
      (r) => r.id === this.selectedPhantomTestRoutineId,
    )
  }
  set selectedPhantomTestRoutine(r: trends_phantom_test_routine | undefined) {
    this.selectedPhantomTestRoutineId = r?.id
  }
  selectedSurveyTestRoutineId: number
  get selectedSurveyTestRoutine(): trends_survey_test_routine {
    return this.surveyTestRoutines?.find(
      (r) => r.id === this.selectedSurveyTestRoutineId,
    )
  }
  set selectedSurveyTestRoutine(r: trends_survey_test_routine | undefined) {
    this.selectedSurveyTestRoutineId = r?.id
  }

  get allQuestionsOnSelectedSurveyTestRoutine() {
    return (
      this.selectedSurveyTestRoutine
        ?.survey_test_routine_questions_survey_test_questions ?? []
    )
  }

  selectedTrendKey: string[]
  selectedSurveyTestField: string
  @Input() defaultTrendKey: string[]
  showTrendLines = true
  showControlLimits = true
  showOutliers = true
  sameYAxis = false

  // other
  modalOpen = false
  contentExpanded = false
  expand(val: boolean) {
    if (!this.disabled) {
      this.contentExpanded = val
    }
  }
  TIME_PERIOD_DAYS = TIME_PERIOD_DAYS

  emitValueChange() {
    this.valueChange.emit()
  }

  setShowTrend() {
    this.emitValueChange()
  }

  setShowSurveyTest() {
    console.log(
      `question was selected. showing data from survey test ${this.selectedSurveyTestRoutine.name} for question ${this.selectedSurveyTestField}`,
    )
    this.emitValueChange()
  }

  join(arr: string[]): string {
    return arr?.join('/')
  }

  last<T>(arr: T[]): T | undefined {
    return arr ? arr[arr.length - 1] : undefined
  }

  concat(arr: string[], item: string): string[] {
    return [...arr, item]
  }

  /** generic values for selection */
  genericValues: RecursiveDropdownInput[]
  handlePhantomTestRoutineChange() {
    if (this.selectedSurveyTestRoutine) {
      this.selectedSurveyTestRoutine = undefined
    }
    if (
      this.phantomTestRoutines &&
      !this.selectedPhantomTestRoutine &&
      this.defaultTrendKey
    ) {
      // grab all the possible routines sorted by number of tests
      const routinesOrdered = this.phantomTestRoutines.sort(
        (a, b) =>
          b.phantom_tests_aggregate.aggregate.count -
          a.phantom_tests_aggregate.aggregate.count,
      )
      // set selected routine to be the first routine where the first test has default trend key in generic values
      for (const routine of routinesOrdered) {
        try {
          const genericValues = this.assembleGenericValuesForPhantomTest(
            routine,
          )
          if (
            this.trendKeyInGenericValues(this.defaultTrendKey, genericValues)
          ) {
            this.selectedPhantomTestRoutine = routine
            break
          }
        } catch (e) {
          continue
        }
      }
      if (this.selectedPhantomTestRoutine) {
        this.selectedTrendKey = this.defaultTrendKey
      }
    }
    const routine = this.selectedPhantomTestRoutine
    if (routine) {
      this.genericValues = this.assembleGenericValuesForPhantomTest(routine)
      if (
        !this.trendKeyInGenericValues(this.selectedTrendKey, this.genericValues)
      ) {
        this.selectedTrendKey = undefined
      }
    }
    this.emitValueChange()
  }

  handleSurveyTestRoutineChange() {
    if (this.selectedPhantomTestRoutine) {
      this.selectedPhantomTestRoutine = undefined
    }
    // assemble survey test questions into genericValues
    this.genericValues = this.assembleGenericValuesForSurveyTest(
      this.selectedSurveyTestRoutine,
    )
    this.selectedSurveyTestField = this.genericValues[0]?.key
    this.selectedTrendKey = [this.selectedSurveyTestField]
    this.emitValueChange()
  }

  /** Pure */
  private trendKeyInGenericValues(
    selectedTrendKey: string[],
    genericValues: RecursiveDropdownInput[],
  ): boolean {
    function searchGenericValues(
      key: string[],
      values: RecursiveDropdownInput,
    ): boolean {
      if (!key?.[0]) return false
      if (key[0] === values.key) {
        if (key.length === 1) return true
        if (!values.options) return false
        return values.options
          .map((val) => searchGenericValues(key.slice(1), val))
          .includes(true)
      }
      return false
    }
    return genericValues
      .map((val) => searchGenericValues(selectedTrendKey, val))
      .includes(true)
  }

  private assembleGenericValuesForSurveyTest(
    routine: trends_survey_test_routine,
  ): RecursiveDropdownInput[] {
    return routine.survey_test_routine_questions_survey_test_questions
      .filter(
        (question) =>
          question.survey_test_question.type === 'SurveyTestNumberQuestion',
      )
      .map((question) => ({
        key: question.survey_test_question.text,
      }))
  }

  /** Pure */
  private assembleGenericValuesForPhantomTest(
    routine: trends_phantom_test_routine,
  ): RecursiveDropdownInput[] {
    const phantomTest = routine.phantom_tests?.[0]
    if (!phantomTest) {
      throw new Error('no phantom test on this routine')
    }
    const seriesGenericData = (phantomTest.rawOutput as TestResult).series
      ?.filter((series) => !!series)
      .map((series) => series.generic_data)
      .filter((generic_data) => !!generic_data)
    const merged: NestedObject = merge({}, ...seriesGenericData)
    // remove leaf nodes that aren't numbers
    filterLeaves(merged, (leaf: unknown) => typeof leaf === 'number')
    // remove empty objects
    clearEmpties(merged)

    const getGenericValuesFromObject = (
      obj: NestedObject,
    ): RecursiveDropdownInput[] =>
      Object.keys(obj)
        .map((key) => {
          const value = obj[key]
          if (typeof value === 'number' || typeof value === 'object') {
            return {
              key,
              options:
                typeof value === 'object' && !Array.isArray(value)
                  ? getGenericValuesFromObject(value)
                  : undefined,
            }
          } else {
            return undefined
          }
        })
        .filter((option) => typeof option !== 'undefined')

    const genericValues: RecursiveDropdownInput[] = getGenericValuesFromObject(
      merged,
    )
    return genericValues
  }
}
