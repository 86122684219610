import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { cloneDeep } from 'lodash'
import { camel, safeGet, StringObjectLiteral } from '../../util/util'
import { scannersDetails_scanner_by_pk } from '../scanners-details/types/scannersDetails'
import editMutation from './scanners-edit.gql'
import relatedEntityQuery from './scanner_get_related_entities.gql'
import { edit_scanner_by_pk } from './types/edit_scanner_by_pk'
import {
  scanner_get_related_entities,
  scanner_get_related_entities_facility_room,
} from './types/scanner_get_related_entities'

@Component({
  selector: 'app-scanners-edit',
  templateUrl: './scanners-edit.component.html',
  styleUrls: ['./scanners-edit.component.scss'],
})
export class ScannersEditComponent implements OnInit {
  lastCalibrationDate: string //clrDate is entirely string-based, no Date obj is used.
  today: string //for max date in last calibration date. Format is yyyy-mm-dd

  @Input() scanner: scannersDetails_scanner_by_pk

  private _backup_scanner: scannersDetails_scanner_by_pk

  @Output() saveEvent = new EventEmitter()

  safeGet = safeGet

  objectKeys = Object.keys

  relatedEntityOptionsQueryResult: ApolloQueryResult<
    scanner_get_related_entities
  >

  constructor(private apollo: Apollo) {}

  filteredFacilityRoomOptions: scanner_get_related_entities_facility_room[]

  initToday() {
    this.today = new Date().toISOString().slice(0, 10)
  }

  initLastCalibrationDate() {
    if (this.scanner.extra['Last Calibration Date']) {
      this.lastCalibrationDate = this.scanner.extra['Last Calibration Date']
    }
    // Otherwise leave as undefined
  }

  fetchRelatedEntityOptions() {
    this.apollo
      .query<scanner_get_related_entities>({ query: relatedEntityQuery })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.relatedEntityOptionsQueryResult = result
          this.onFacilityIdChange()
        },
      })
  }

  onFacilityIdChange() {
    if (this.scanner.facilityId) {
      this.filteredFacilityRoomOptions = this.relatedEntityOptions.facility_room.filter(
        (r) => r.facilityId === this.scanner.facilityId,
      )
    }
  }

  get relatedEntityOptions(): scanner_get_related_entities {
    return this.relatedEntityOptionsQueryResult.data
  }

  ngOnInit() {
    this._backup_scanner = cloneDeep(this.scanner)
    this.fetchRelatedEntityOptions()
    this.initToday()
    this.initLastCalibrationDate()
  }

  save() {
    if (this.lastCalibrationDate) {
      this.scanner.extra['Last Calibration Date'] = this.lastCalibrationDate
    }
    this.apollo
      .mutate<edit_scanner_by_pk>({
        mutation: editMutation,
        variables: {
          id: this.scanner.id,
          _set: this.stripInput(this.scanner),
        },
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.saveEvent.emit('edit')
        },
      })
  }

  back() {
    this.scanner = cloneDeep(this._backup_scanner)
    this.saveEvent.emit('back')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripInput = (obj: StringObjectLiteral<any>) =>
    Object.keys(obj).reduce((newObj, key) => {
      if (
        key !== 'id' &&
        key !== 'dateCreated' &&
        key !== 'dateUpdated' &&
        key !== '__typename'
      ) {
        if (
          typeof obj[key] === 'string' ||
          typeof obj[key] === 'number' ||
          (obj[key] === null && key.slice(-2) === 'Id')
        ) {
          newObj[camel(key)] = obj[key]
        }
        // insert extra for 'last calibration date' requirement
        if (key === 'extra') newObj[key] = obj[key]
      }
      return newObj
    }, {})
}
