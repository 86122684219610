import { Injectable } from '@angular/core'
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { CommService } from './comm.service'

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private comm: CommService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    this.comm.startTask()
    console.log(req)
    return next.handle(req).pipe(
      tap(
        (res) => {
          if (res.type !== 0) {
            console.log(res)
          }
        },
        (err) => {
          this.comm.finishTask()
        }, // global error handler takes care of logging errors
        () => {
          this.comm.finishTask()
        },
      ),
    )
  }
}
