import { EventEmitter, Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  sidebarChange: EventEmitter<boolean> = new EventEmitter()

  constructor() {}
}
