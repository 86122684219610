<app-comments-edit
  *ngIf="editing"
  [comment]="comment"
  (saveEvent)="onSave($event)"
></app-comments-edit>
<ng-container *ngIf="!editing">
  <div [clrLoading]="loading">
    <div *ngIf="queryResult" class="">
      <!-- Buttons -->
      <div class="clr-row">
        <div class="clr-col-8">
          <p>{{ content }}</p>
        </div>
        <div id="actions" class="clr-col-4">
          <button
            *ngIf="canDelete()"
            class="btn btn-danger-outline"
            (click)="delete()"
          >
            Delete Comment
          </button>
          <button class="btn btn-outline" (click)="editView()">
            Edit Comment
          </button>
        </div>
      </div>
      <!-- Files -->
      <div class="clr-row">
        <div class="clr-col-12" *ngFor="let file of fileKeys">
          <a
            title="{{ file }}"
            class="btn btn-link"
            target="_blank"
            href="{{ src(file) }}"
            >{{ file }}</a
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>
