import { sortBy } from 'lodash'
import { Component, Input, OnInit } from '@angular/core'
import * as moment from 'moment-timezone'
import { join } from 'path'
import { CommService } from 'src/app/core/comm.service'
import { NavService } from 'src/app/core/nav.service'
import { PhantomTestResult } from 'src/app/util/util'
import { TimezoneService } from 'src/app/timezone.service'
import { safeGet } from '../../util/util'
import {
  dashboard_scanner,
  dashboard_scanner_studies,
  dashboard_scanner_studies_phantom_tests,
  dashboard_scanner_survey_tests,
  dashboard_scanner_missed_phantom_tests,
} from '../dashboard/types/dashboard'
import { Result } from 'common/aqsTypes'
import { DevError } from 'src/app/core/errors/errors'

type Entity =
  | dashboard_scanner_missed_phantom_tests
  | dashboard_scanner_studies
  | dashboard_scanner_studies_phantom_tests
  | dashboard_scanner_survey_tests

interface DisplayItem {
  iconSrc: string
  timestamp: Date
  entity: Entity
}

@Component({
  selector: 'app-dashboard-tests',
  templateUrl: './dashboard-tests.component.html',
  styleUrls: ['./dashboard-tests.component.scss'],
})
export class DashboardTestsComponent implements OnInit {
  @Input() scanner: dashboard_scanner

  /**
   * days[0] is the earliest day
   * days[length-1] is the latest day (i.e. today)
   * days[][0] is the latest item within a day and is displayed first
   * days[][length-1] is the earliest item within a day
   */
  days: DisplayItem[][] = []

  groupedStudies: dashboard_scanner_studies[] = []

  @Input() daysToDisplay = 7

  @Input() skipDays = 0

  safeGet = safeGet

  readonly iconSrcPath = 'assets/icons'

  constructor(
    private timezoneService: TimezoneService,
    private nav: NavService,
  ) {}

  ngOnInit() {
    this.initDays()
    this.collectItems()
  }

  initDays() {
    for (let i = 0; i < this.daysToDisplay; i++) {
      this.days[i] = []
    }
  }

  collectItems() {
    const tz = this.timezoneService.cachedTz
    const endOfDay = moment.tz(tz).endOf('day')
    this.scanner.studies.forEach((s) => {
      const scanDateTz = moment(s.scanDate).tz(tz)
      const index = endOfDay.diff(scanDateTz, 'days')
      if (index >= this.daysToDisplay || index < 0) return
      // if the study has no tests, push the study, else, push all the tests
      if (s.phantom_tests.length < 1) {
        // this is which day to push the item
        this.days[index].push(this.studyToDisplayItem(s))
      } else {
        s.phantom_tests.forEach((pt) => {
          this.days[index].push(this.phantomTestToDisplayItem(pt, s.scanDate))
        })
      }
    })
    this.scanner.survey_tests.forEach((st) => {
      const scheduledDateTz = moment(st.dateCreated).tz(tz)
      const index = endOfDay.diff(scheduledDateTz, 'days')
      if (index >= this.daysToDisplay || index < 0) return
      // if the study has no tests, push the study, else, push all the tests
      this.days[index].push(this.surveyTestToDisplayItem(st))
    })
    this.scanner.missed_phantom_tests.forEach((mt) => {
      const scheduledDateTz = moment(mt.scheduledDate).tz(tz)
      const index = endOfDay.diff(scheduledDateTz, 'days')
      if (index >= this.daysToDisplay || index < 0) return
      // if the study has no tests, push the study, else, push all the tests
      this.days[index].push(this.missedTestToDisplayItem(mt))
    })

    // sort this.days
    this.days.reverse()
    this.days.forEach((day, index) => {
      this.days[index] = this.customSort(day)
    })
  }
  customSort(day: DisplayItem[]): DisplayItem[] {
    return day.sort((a, b) => {
      if (a.entity.__typename === 'missed_phantom_test') return -1
      if (b.entity.__typename === 'missed_phantom_test') return 1
      if (a.timestamp > b.timestamp) return 1
      if (a.timestamp < b.timestamp) return -1
      if (a.timestamp === b.timestamp) return 0
      return 0
    })
  }

  missedTestToDisplayItem(
    mt: dashboard_scanner_missed_phantom_tests,
  ): DisplayItem {
    return {
      entity: mt,
      iconSrc: this.getIconForTest(mt),
      timestamp: mt.scheduledDate,
    }
  }

  phantomTestToDisplayItem(
    pt: dashboard_scanner_studies_phantom_tests,
    scanDate: Date,
  ): DisplayItem {
    return {
      entity: pt,
      iconSrc: this.getIconForTest(pt),
      timestamp: scanDate,
    }
  }

  surveyTestToDisplayItem(st: dashboard_scanner_survey_tests): DisplayItem {
    return {
      entity: st,
      iconSrc: this.getIconForTest(st),
      timestamp: st.dateCreated,
    }
  }

  studyToDisplayItem(s: dashboard_scanner_studies): DisplayItem {
    return {
      entity: s,
      iconSrc: this.getIconForStudy(s),
      timestamp: s.scanDate,
    }
  }

  getDate(item: DisplayItem): Date {
    return item.timestamp
  }

  getIcon(item: DisplayItem): string {
    return item.iconSrc
  }

  viewItem(item: DisplayItem) {
    switch (item.entity.__typename) {
      case 'missed_phantom_test':
        this.nav.navtree.missedPhantomTestDetails(item.entity.id)
        break
      case 'phantom_test':
        this.nav.navtree.phantomTestDetails(item.entity.id)
        break
      case 'study':
        this.nav.navtree.studyDetails(item.entity.id)
        break
      case 'survey_test':
        this.nav.navtree.surveyTestDetails(item.entity.id)
        break
      default:
        console.error(item)
        throw new DevError('Dashboard display item is not a supported entity')
    }
  }

  getBlankIcon(): string {
    return join(this.iconSrcPath, 'grey/grey-blank-small.png')
  }

  getIconForTest(
    test:
      | dashboard_scanner_studies_phantom_tests
      | dashboard_scanner_missed_phantom_tests
      | dashboard_scanner_survey_tests,
  ): string {
    let srcPath = this.iconSrcPath
    switch (test.result) {
      case Result.pass:
        // display the pass icon
        return join(srcPath, 'green/green-blank-small.png')
      case Result.problem:
        // display the problem icon
        srcPath = join(srcPath, 'orange/orange')
        break
      case Result.fail:
        // display the fail icon
        srcPath = join(srcPath, 'red/red')
        break
      case Result.missed:
        // display the missed icon
        srcPath = join(srcPath, 'purple/purple')
        break
      default:
        return join(srcPath, 'grey/study-small.png')
    }
    if (test.comments_aggregate.aggregate.count === 0) {
      // if no comments and a non-pass result, show an exclamation mark
      srcPath += '-exclamation'
    } else {
      // if there have been comments, show a check mark
      srcPath += '-tick'
    }
    srcPath += '-small.png'
    return srcPath
  }

  getIconForStudy(study: dashboard_scanner_studies | null): string {
    let srcPath = this.iconSrcPath
    // decide which icon we should display
    if (!study) {
      // return blank and grey
      return join(srcPath, 'grey/grey-blank-small.png')
    }
    const mostRecentPhantomTest = study.phantom_tests[0]
    if (!mostRecentPhantomTest) {
      return join(srcPath, 'grey/study-small.png')
    }
    return this.getIconForTest(mostRecentPhantomTest)
  }
}
