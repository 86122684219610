<app-workstations-edit
  *ngIf="editing"
  [scanner]="scanner"
  (saveEvent)="onSave($event)"
></app-workstations-edit>
<ng-container *ngIf="!editing">
  <div [clrLoading]="loading">
    <div *ngIf="scanner">
      <!-- Heading and Actions -->
      <section id="heading">
        <div class="clr-row">
          <!-- Heading -->
          <div class="clr-col-6">
            <h2 id="heading">Workstation Details: {{ scannerName }}</h2>
          </div>

          <!-- Actions -->
          <div class="clr-col-6" id="actions">
            <button
              *ngIf="auth.checkrole(editScannerRoles)"
              class="btn btn-danger-outline"
              (click)="delete()"
            >
              Delete Workstation
            </button>
            <button
              *ngIf="auth.checkrole(editScannerRoles)"
              class="btn btn-outline"
              (click)="editView()"
            >
              Edit Workstation
            </button>
            <button class="btn btn-outline" (click)="showExportTestsModal()">
              Export Tests
            </button>
          </div>
        </div>
      </section>

      <!-- Info -->
      <section id="info">
        <div class="clr-row">
          <!-- Mandatory Info -->
          <div class="clr-col-12 clr-col-md-6">
            <table class="table">
              <tbody>
                <tr>
                  <td class="left">Room</td>
                  <td class="left">{{ roomName }}</td>
                </tr>
                <tr>
                  <td class="left">Manufacturer</td>
                  <td class="left">{{ manufacturerName }}</td>
                </tr>
                <tr>
                  <td class="left">Model</td>
                  <td class="left">{{ modelName }}</td>
                </tr>

                <tr>
                  <td class="left">Facility</td>
                  <td class="left">{{ facilityName }}</td>
                </tr>
                <tr>
                  <td class="left">Most Recent Phantom Test</td>
                  <td class="left">
                    {{ mostRecentPhantomTestDate | customDate }}
                  </td>
                </tr>
                <tr>
                  <td class="left">Modality</td>
                  <td class="left">{{ modalityName }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Extra Info -->
          <div class="clr-col-12 clr-col-md-6">
            <table class="table">
              <tbody>
                <tr *ngFor="let item of extra | keyvalue">
                  <td class="left">
                    {{ item.key }}
                    <span *ngIf="item.key === 'Last Calibration Date'"
                      >({{ daysSinceLastCalibration }} days since last)</span
                    >
                  </td>
                  <td class="left">{{ item.value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section>
        <div class="clr-row">
          <!-- Support Contact -->
          <div class="clr-col-6">
            <app-scanner-support-contacts
              [scanner]="scanner"
            ></app-scanner-support-contacts>
          </div>
          <!-- Daily Testing Schedule -->
          <div class="clr-col-6">
            <app-scanner-rrule-generator
              [scanner]="scanner"
            ></app-scanner-rrule-generator>
            <!-- <app-shitty-scanner-schedule
              [scanner]="scanner"
            ></app-shitty-scanner-schedule> -->
          </div>
        </div>
      </section>

      <!-- Perform a Survey -->
      <section id="perform-survey">
        <div class="clr-row">
          <div class="clr-col-12">
            <clr-dropdown>
              <button class="btn btn-outline-primary" clrDropdownTrigger>
                Perform a Survey
                <clr-icon shape="caret down"></clr-icon>
              </button>
              <clr-dropdown-menu *clrIfOpen>
                <label class="dropdown-header" aria-hidden="true"
                  >All Surveys</label
                >
                <div
                  *ngFor="let routine of surveyTestRoutines"
                  (click)="runRoutine(routine)"
                  clrDropdownItem
                >
                  {{ routine.name }}
                </div>
              </clr-dropdown-menu>
            </clr-dropdown>
          </div>
        </div>
      </section>

      <!-- Calendar -->
      <section id="calendar">
        <div class="clr-row">
          <div class="clr-col-12">
            <app-scanner-calendar
              [scannerId]="scannerId"
            ></app-scanner-calendar>
          </div>
        </div>
      </section>

      <!-- Comments -->
      <section id="comments">
        <div class="clr-row">
          <div class="clr-col-md-12">
            <app-comments [scannerId]="scannerId"></app-comments>
          </div>
        </div>
      </section>
    </div>
  </div>
</ng-container>

<clr-modal [(clrModalOpen)]="showExportModal">
  <h3 class="modal-title">Test Data Export</h3>
  <div class="modal-body">
    <span *ngIf="!exportLoading; else exportLoadingTemplate">
      <button class="btn btn-link" (click)="presetExportTests('month')">
        This Month
      </button>
      <button class="btn btn-link" (click)="presetExportTests('lastMonth')">
        Last Month
      </button>
      <button class="btn btn-link" (click)="presetExportTests('year')">
        This Year
      </button>
      <button class="btn btn-link" (click)="presetExportTests('all')">
        All Time
      </button>
      <button
        class="btn btn-link"
        (click)="showExportModal = false; navigateToExportTests()"
      >
        Custom...
      </button>
    </span>
  </div>
</clr-modal>

<ng-template #exportLoadingTemplate>
  <span>
    <clr-spinner [clrInline]="true"></clr-spinner>
    <span>&nbsp;Downloading...</span>
  </span>
</ng-template>
