<div class="container">
  <table>
    <tbody>
      <tr>
        <td colspan="2">
          <!-- Blank -->
        </td>
        <td>
          <!-- group 1 labels -->
          <table class="table label-table fixed-table">
            <tbody>
              <tr>
                <td *ngFor="let label of group1Labels">{{ label }}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <!-- Blank -->
        </td>
        <td>
          <!-- group 1 totals -->
          <table class="table fixed-table">
            <tbody>
              <tr>
                <app-metric-cell
                  *ngFor="let total of group1Totals"
                  [val]="total"
                ></app-metric-cell>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <!-- group 2 labels -->
          <table class="table label-table">
            <tbody>
              <tr *ngFor="let label of group2Labels">
                <td *ngIf="label !== 'undefined'">{{ label }}</td>
                <td *ngIf="label === 'undefined'"><em>none</em></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td>
          <!-- group 2 totals -->
          <table class="table">
            <tbody>
              <tr *ngFor="let total of group2Totals">
                <app-metric-cell [val]="total"></app-metric-cell>
              </tr>
            </tbody>
          </table>
        </td>
        <td>
          <!-- matrix -->
          <table class="table fixed-table">
            <tbody>
              <tr *ngFor="let row of matrix">
                <app-metric-cell
                  *ngFor="let col of row"
                  [val]="col"
                ></app-metric-cell>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>
