<div class="clr-row" appPageHeader [title]="title"></div>
<clr-datagrid
  class="datagrid-striped"
  #datagrid
  (clrDgRefresh)="refreshGrid($event, fetchStudies)"
  [clrDgLoading]="loading"
>
  <clr-dg-placeholder>Nothing to Show</clr-dg-placeholder>

  <!-- Row Def -->
  <clr-dg-row *ngFor="let item of items">
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanDate | customDate
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.dateCreated | customDate
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanner?.facility?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanner?.facility_room?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.modality?.id
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanner?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.study_images_aggregate.aggregate.count
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.phantom_tests_aggregate.aggregate.count
    }}</clr-dg-cell>
  </clr-dg-row>

  <!-- Scan Date Column -->
  <clr-dg-column [clrDgField]="properties.scanDate">
    <ng-container>Scan Date</ng-container>
    <clr-dg-filter>
      <app-date-filter
        [property]="properties.scanDate"
        [earliest]="filterState[properties.scanDate]?.earliest"
        [latest]="filterState[properties.scanDate]?.latest"
      ></app-date-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Received Date Column -->
  <clr-dg-column [clrDgField]="properties.receivedDate">
    <ng-container>Received Date</ng-container>
    <clr-dg-filter>
      <app-date-filter
        [property]="properties.receivedDate"
        [earliest]="filterState[properties.receivedDate]?.earliest"
        [latest]="filterState[properties.receivedDate]?.latest"
      ></app-date-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Facility Column -->
  <clr-dg-column [clrDgField]="properties.facility.sort">
    <ng-container>Facility</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.facility.filter]?.exact"
        [property]="properties.facility.filter"
        [options]="options.facilities"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Room Column -->
  <clr-dg-column [clrDgField]="properties.facility_room.sort">
    <ng-container>Room</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.facility_room.filter]?.exact"
        [property]="properties.facility_room.filter"
        [options]="options.rooms"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Modality Column -->
  <clr-dg-column [clrDgField]="properties.modality">
    <ng-container>Modality</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.modality]?.exact"
        [property]="'modality.id'"
        [options]="options.modalities"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Scanner Column -->
  <clr-dg-column [clrDgField]="properties.scanner.sort">
    <ng-container>Scanner</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.scanner.filter]?.exact"
        [property]="properties.scanner.filter"
        [options]="options.scanners"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Images Column -->
  <clr-dg-column>
    <ng-container>Images</ng-container>
  </clr-dg-column>

  <!-- Images Column -->
  <clr-dg-column>
    <ng-container>Tests</ng-container>
  </clr-dg-column>

  <!-- Footer -->
  <clr-dg-footer>
    {{ pagination.firstItem + 1 | number }} -
    {{ pagination.lastItem + 1 | number }} of {{ totalItems | number }} items
    <clr-dg-pagination
      #pagination
      [clrDgTotalItems]="totalItems"
    ></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
