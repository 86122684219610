<clr-datagrid class="datagrid-striped" id="recent-tests">
  <clr-dg-placeholder>This study has no tests</clr-dg-placeholder>

  <clr-dg-row *ngFor="let test of tests">
    <clr-dg-cell appClickToView (click)="view(test)">{{
      test.dateCreated | customDate
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(test)">{{
      tester(test)
    }}</clr-dg-cell>
    <clr-dg-cell
      appClickToView
      (click)="view(test)"
      class="{{ test.result }}"
      >{{ test.result }}</clr-dg-cell
    >
  </clr-dg-row>

  <clr-dg-column>Previous Tests</clr-dg-column>
  <clr-dg-column>Tester</clr-dg-column>
  <clr-dg-column>Result</clr-dg-column>
</clr-datagrid>
