export enum Phantom {
  siemens = "siemens",
  toshiba = "toshiba",
  catphan = "catphan",
  catphanXrct = "catphanXrct",
  ct_acr = "ct_acr",
  mr_acr = "mr_acr",
  ge_small = "ge_small",
  ge_big = "ge_big",
  mg = "mg",
  canon = "canon",
  cr = "cr",
  ct_ge_optima_540 = "ct_ge_optima_540",
}

export enum TestMethod {
  pythonLocal = "pythonLocal",
  python = "python",
  imageJ = "imageJ",
}

export enum Result {
  pass = "pass",
  fail = "fail",
  problem = "problem",
  missed = "missed",
  na = "na",
}

export enum UserStatus {
  active = "active",
  deactivated = "deactivated",
}

export enum UserRole {
  tech = "tech",
  admin = "admin",
  physicist = "physicist",
  support = "support",
}

export enum PhantomTestStatus {
  pending = "pending",
  saved = "saved",
}

export enum ReportStatus {
  pending = "pending",
  saved = "saved",
}

export enum Permission {
  modifyUsers = "modifyUsers",
  modifyFacilities = "modifyFacilities",
  modifyScanners = "modifyScanners",
  performTests = "performTests",
  commentOnTests = "commentOnTests",
  commentOnScanners = "commentOnScanners",
  modifyStudies = "modifyStudies",
  removeTestComments = "removeTestComments",
  removeScannerComments = "removeScannerComments",
  modifySettings = "modifySettings",
  modifyMonthlyTests = "modifyMonthlyTests",
  modifyReports = "modifyReports",
}

export interface PhantomTestAnswers {
  [questionId: number]: string | number;
}

export interface TestDisplay {
  title: string;
  display: TableDisplay[]; // each display item corresponds to a separate table
  evaluatedNumbers: NumEval[];
  children: TestDisplay[];
  file?: string; // if there's a supporting file for this display
  result?: Result;
}

export interface TableDisplay {
  head?: string[];
  body?: (string | number | boolean | undefined)[][];
  collapsed?: boolean;
  title?: string;
  bodyWithDecimalConfig?: (
    | string
    | number
    | boolean
    | undefined
    | {
      val: number;
      decimalPlaces?: number;
    }
  )[][];
}

export interface NumEval {
  title: string;
  high?: number;
  problemHigh?: number;
  low?: number;
  problemLow?: number;
  actual: number | undefined;
  result: Result;
  limitKey: string;
  decimalPlaces?: number;
}

export interface LowHigh {
  low?: number;
  high?: number;
  problemLow?: number;
  problemHigh?: number;
}
