import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService } from 'src/app/auth/auth.service'
import { DatagridStateService } from 'src/app/core/datagrid-state.service'
import { NavService } from 'src/app/core/nav.service'
import { FilterType } from 'src/app/filters/filters.util'
import { TimezoneService } from 'src/app/timezone.service'
import { DatagridComponent } from 'src/app/util/datagrid.class'
import { KeyVal } from '../../util/util'
import study_get_related_entitiesGql from '../studies-edit/study_get_related_entities.gql'
import { study_get_related_entities } from '../studies-edit/types/study_get_related_entities'
import query from './inbox.gql'
import { inbox, inboxVariables, inbox_study } from './types/inbox'

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent extends DatagridComponent {
  title = 'inbox'

  invariants: inboxVariables = {
    where: {
      _not: { phantom_tests: { id: { _gte: 0 } } }, // studies without tests
    },
  }

  static properties = {
    scanDate: 'scanDate',
    facility: { filter: 'scanner.facility.id', sort: 'scanner.facility.name' },
    facility_room: {
      filter: 'scanner.facility_room.id',
      sort: 'scanner.facility_room.name',
    },
    modality: 'modality.id',
    scanner: { filter: 'scanner.id', sort: 'scanner.name' },
  }
  properties = InboxComponent.properties

  constructor(
    private apollo: Apollo,
    private auth: AuthService,
    private nav: NavService,
    protected router: Router,
    protected dgStateService: DatagridStateService,
    protected timezoneService: TimezoneService,
  ) {
    super(dgStateService, router, timezoneService)

    // add default if necessary
    this.defaultState = {
      sort: {
        by: this.properties.scanDate,
        reverse: true,
      },
      filters: [],
    }
    const defaultFacility = this.auth.user?.facility?.id
    if (defaultFacility) {
      this.defaultState.filters.push({
        type: FilterType.select,
        property: this.properties.facility.filter,
        exact: defaultFacility,
      })
    }
  }

  items: inbox_study[]
  totalItems: number

  fetchInbox = () => {
    this.loading = true
    this.apollo
      .query<inbox>({ query, variables: this.variables })
      .subscribe({
        next: (result: ApolloQueryResult<inbox>) => {
          this.loading = false
          if (result.errors) {
            throw result.errors[0]
          }
          this.items = result.data.study
          this.totalItems = result.data.study_aggregate.aggregate.count
        },
        error: this.onError,
      })
    this.apollo
      .query<study_get_related_entities>({
        query: study_get_related_entitiesGql,
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.assembleRelatedEntityOptions(result.data)
        },
        error: this.onError,
      })
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  options: {
    facilities: KeyVal[]
    rooms: KeyVal[]
    modalities: KeyVal[]
    scanners: KeyVal[]
  } = {
    facilities: [],
    rooms: [],
    modalities: [],
    scanners: [],
  }

  assembleRelatedEntityOptions(data: study_get_related_entities) {
    this.options = {
      facilities: data.facility.map((f) => ({
        key: f.id,
        val: f.name,
      })),
      rooms: data.facility_room.map((r) => ({
        key: r.id,
        val: r.name,
      })),
      modalities: data.modality.map((m) => ({
        key: m.id,
        val: m.id,
      })),
      scanners: data.scanner.map((s) => ({
        key: s.id,
        val: s.name,
      })),
    }
  }

  view(study: inbox_study) {
    this.nav.navtree.studyDetails(study.id)
  }
}
