<div class="card">
  <div class="card-header">
    {{ title }}
    <clr-toggle-container id="enable" *ngIf="enableIsProvided()">
      <clr-toggle-wrapper>
        <input
          type="checkbox"
          clrToggle
          [(ngModel)]="enable"
          (ngModelChange)="onEnableChange()"
        />
        <label>Enable</label>
      </clr-toggle-wrapper>
    </clr-toggle-container>
  </div>
  <ng-container *ngIf="!(enableIsProvided() && !enable)">
    <ng-container *ngIf="!multiblock">
      <div class="card-block">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="multiblock">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </ng-container>
  </ng-container>
  <div class="card-footer">
    <button (click)="onSave()" class="btn btn-sm btn-link">Save</button>
  </div>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
