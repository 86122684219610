import { Component, OnInit, Input } from '@angular/core'
import * as moment from 'moment-timezone'

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
})
export class ClockComponent implements OnInit {
  @Input() tz = 'utc'
  @Input() format = 'HH:mm:ss Z'
  date: moment.Moment
  interval: number
  constructor() {}

  ngOnInit(): void {
    this.refreshDate()
    this.interval = window.setInterval(() => {
      this.refreshDate()
    }, 1000)
  }

  refreshDate() {
    this.date = moment.tz(this.tz)
  }

  ngOnDestroy(): void {
    window.clearInterval(this.interval)
  }
}
