import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: '[appSbox]',
  templateUrl: './sbox.component.html',
  styleUrls: ['./sbox.component.scss'],
})
export class SboxComponent implements OnInit {
  @Input() title: string
  /**
   * if true or false, show an enable/disable toggle in the header with initial state as provided
   * if undefined, don't show
   */
  @Input() enable: boolean | undefined
  @Input() multiblock: boolean | undefined
  @Output() enableChange: EventEmitter<boolean> = new EventEmitter()
  onEnableChange() {
    this.enableChange.emit(this.enable)
  }

  @Output() save = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  enableIsProvided() {
    return typeof this.enable === 'boolean'
  }

  onSave() {
    this.save.emit(this.title)
  }
}
