import { Apollo } from 'apollo-angular'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { cloneDeep } from 'lodash'
import { camel, safeGet, StringObjectLiteral } from '../../util/util'
import { reportsDetails_report_by_pk } from '../reports-details/types/reportsDetails'
import editMutation from './reports-edit.gql'
import { edit_report_by_pk } from './types/edit_report_by_pk'

@Component({
  selector: 'app-reports-edit',
  templateUrl: './reports-edit.component.html',
  styleUrls: ['./reports-edit.component.scss'],
})
export class ReportsEditComponent implements OnInit {
  @Input() report: reportsDetails_report_by_pk

  private _backup_report: reportsDetails_report_by_pk

  @Output() saveEvent = new EventEmitter()

  safeGet = safeGet

  objectKeys = Object.keys

  constructor(private apollo: Apollo) {}

  ngOnInit() {
    this._backup_report = cloneDeep(this.report)
  }

  save() {
    this.apollo
      .mutate<edit_report_by_pk>({
        mutation: editMutation,
        variables: {
          id: this.report.id,
          _set: this.stripInput(this.report),
        },
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.saveEvent.emit('edit')
        },
      })
  }

  back() {
    this.report = cloneDeep(this._backup_report)
    this.saveEvent.emit('back')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripInput = (obj: StringObjectLiteral<any>) =>
    Object.keys(obj).reduce((newObj, key) => {
      if (
        key !== 'id' &&
        key !== 'dateCreated' &&
        key !== 'dateUpdated' &&
        key !== '__typename'
      ) {
        if (
          typeof obj[key] === 'string' ||
          typeof obj[key] === 'number' ||
          (obj[key] === null && key.slice(-2) === 'Id')
        ) {
          newObj[camel(key)] = obj[key]
        }
      }
      return newObj
    }, {})
}
