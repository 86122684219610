import { gql } from 'apollo-angular'

export default gql`
  mutation insert_comment($comment: comment_insert_input!) {
    insert_comment(objects: [$comment]) {
      returning {
        id
      }
    }
  }
`
