import { Pipe, PipeTransform } from '@angular/core'

export interface UserFragment {
  firstName: string
  lastName: string
}

@Pipe({
  name: 'userFullName',
})
export class UserFullNamePipe implements PipeTransform {
  constructor() {}

  transform(value: UserFragment, ...args: unknown[]): string {
    const fn = `${value.firstName} ${value.lastName}`.trim()
    return fn
  }
}
