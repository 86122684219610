<h6 class="subtitle-label">
  <ng-container *ngIf="facility"> facility / </ng-container>
  <ng-container *ngIf="room"> room / </ng-container>
  <ng-container *ngIf="scanner">scanner</ng-container>
</h6>

<h3 class="subtitle">
  <ng-container *ngIf="facility">
    <a (click)="viewFacility()">{{ facilityName }}</a> /
  </ng-container>
  <ng-container *ngIf="room"> {{ roomName }} / </ng-container>
  <a *ngIf="scanner" (click)="viewScanner()" class="pass">{{ scannerName }}</a>
</h3>
