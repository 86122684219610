import { gql } from 'apollo-angular'

export default gql`
  query phantomTests(
    $distinct_on: [phantom_test_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [phantom_test_order_by!]
    $where: phantom_test_bool_exp
  ) {
    phantom_test(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      dateCreated
      scanner {
        facility_room {
          name
        }
        facility {
          name
        }
      }
      study {
        modality {
          id
        }
      }
      scanner {
        name
      }
      phantom_test_routine {
        phantom
      }
      study {
        scanDate
      }
      result
      user {
        firstName
        lastName
      }
    }
    phantom_test_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
