import { Injectable } from '@angular/core'
import { join } from 'path'

@Injectable({
  providedIn: 'root',
})
export class IconService {
  srcPath = 'assets/icons'

  green = {
    green_blank_big: join(this.srcPath, 'green', 'green-blank-big.png'),
    green_blank_small: join(this.srcPath, 'green', 'green-blank-small.png'),
  }
  grey = {
    grey_blank_big: join(this.srcPath, 'grey', 'grey-blank-big.png'),
    grey_blank_small: join(this.srcPath, 'grey', 'grey-blank-small.png'),
    study_small: join(this.srcPath, 'grey', 'study-small.png'),
  }
  orange = {
    orange_blank_big: join(this.srcPath, 'orange', 'orange-blank-big.png'),
    orange_blank_small: join(this.srcPath, 'orange', 'orange-blank-small.png'),
    orange_cross_big: join(this.srcPath, 'orange', 'orange-cross-big.png'),
    orange_cross_small: join(this.srcPath, 'orange', 'orange-cross-small.png'),
    orange_exclamation_big: join(
      this.srcPath,
      'orange',
      'orange-exclamation-big.png',
    ),
    orange_exclamation_small: join(
      this.srcPath,
      'orange',
      'orange-exclamation-small.png',
    ),
    orange_tick_big: join(this.srcPath, 'orange', 'orange-tick-big.png'),
    orange_tick_small: join(this.srcPath, 'orange', 'orange-tick-small.png'),
  }
  purple = {
    purple_blank_big: join(this.srcPath, 'purple', 'purple-blank-big.png'),
    purple_blank_small: join(this.srcPath, 'purple', 'purple-blank-small.png'),
    purple_cross_big: join(this.srcPath, 'purple', 'purple-cross-big.png'),
    purple_cross_small: join(this.srcPath, 'purple', 'purple-cross-small.png'),
    purple_exclamation_big: join(
      this.srcPath,
      'purple',
      'purple-exclamation-big.png',
    ),
    purple_exclamation_small: join(
      this.srcPath,
      'purple',
      'purple-exclamation-small.png',
    ),
    purple_tick_big: join(this.srcPath, 'purple', 'purple-tick-big.png'),
    purple_tick_small: join(this.srcPath, 'purple', 'purple-tick-small.png'),
  }
  red = {
    red_blank_big: join(this.srcPath, 'red', 'red-blank-big.png'),
    red_blank_small: join(this.srcPath, 'red', 'red-blank-small.png'),
    red_cross_big: join(this.srcPath, 'red', 'red-cross-big.png'),
    red_cross_small: join(this.srcPath, 'red', 'red-cross-small.png'),
    red_exclamation_big: join(this.srcPath, 'red', 'red-exclamation-big.png'),
    red_exclamation_small: join(
      this.srcPath,
      'red',
      'red-exclamation-small.png',
    ),
    red_tick_big: join(this.srcPath, 'red', 'red-tick-big.png'),
    red_tick_small: join(this.srcPath, 'red', 'red-tick-small.png'),
  }

  passed = this.green.green_blank_small
  problem = {
    uncommented: this.orange.orange_exclamation_small,
    commented: this.orange.orange_tick_small,
  }
  fail = {
    uncommented: this.red.red_exclamation_small,
    commented: this.red.red_tick_small,
  }
  missed = {
    uncommented: this.purple.purple_exclamation_small,
    commented: this.purple.purple_tick_small,
  }
  untestedStudy = this.grey.study_small

  constructor() {}
}
