<app-survey-tests-edit
  *ngIf="editing"
  [survey_test]="survey_test"
  (saveEvent)="onSave($event)"
></app-survey-tests-edit>
<ng-container *ngIf="!editing">
  <div [clrLoading]="loading">
    <div *ngIf="survey_test">
      <!--  Header and actions -->
      <div
        class="clr-row"
        appPageHeader
        [title]="title"
        [actions]="actions"
        (clicks)="actionsHandler($event)"
      ></div>
      <app-record-subtitle
        [facility]="facility"
        [room]="room"
        [scanner]="scanner"
      ></app-record-subtitle>

      <div class="clr-row">
        <!-- Basic Info -->

        <!-- Questions -->
        <div class="clr-col-12" id="questions-display-container">
          <app-survey-test-details-questions
            [test]="survey_test"
          ></app-survey-test-details-questions>
        </div>

        <!-- Comments -->
        <div class="clr-col-12">
          <app-comments
            [scannerId]="scannerId"
            [surveyTestId]="survey_test.id"
          ></app-comments>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<clr-modal
  *ngIf="survey_test"
  [(clrModalOpen)]="modalOpened"
  [clrModalStaticBackdrop]="false"
>
  <div class="modal-body">
    <p>
      This assessment was performed on {{ testDate }}.<br />
      Which month's data should be included in the report?
    </p>
  </div>
  <div class="modal-footer">
    <div class="months-selection-section">
      Recent months: <br />
      <button
        class="btn btn-outline btn-months"
        (click)="generateReport(months[0])"
      >
        {{ months[0].format('MMM-YYYY') }}
      </button>
      <button
        class="btn btn-outline btn-months"
        (click)="generateReport(months[1])"
      >
        {{ months[1].format('MMM-YYYY') }}
      </button>
    </div>
    <div><hr /></div>
    <div class="months-selection-section">
      More months:<br />
      <button
        *ngFor="let momentObject of months.slice(2)"
        class="btn btn-outline btn-months"
        (click)="generateReport(momentObject)"
      >
        {{ momentObject.format('MMM-YYYY') }}
      </button>
    </div>
  </div>
</clr-modal>
