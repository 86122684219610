import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { DatagridManualState } from './datagrid-state.service'

interface NavOptions {
  blank?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  constructor(private router: Router) {}

  // YAGNI: only needed navigations have been added
  navtree = {
    users: () => {
      this.router.navigate(['home', 'users'])
    },
    surveyTests: () => {
      this.router.navigate(['home', 'surveyTests'])
    },
    reports: () => {
      this.router.navigate(['home', 'reports'])
    },
    scanners: () => {
      this.router.navigate(['home', 'scanners'])
    },
    workstations: () => {
      this.router.navigate(['home', 'workstations'])
    },
    home: () => {
      this.router.navigate(['home'])
    },
    dashboard: () => {
      this.router.navigate(['home', 'dashboard'])
    },
    inbox: (providedState?: DatagridManualState) =>
      this.router.navigate(['home', 'inbox'], {
        state: { providedState },
      }),
    studies: () => {
      this.router.navigate(['home', 'studies'])
    },
    facilityDetails: (id: string | number) =>
      this.router.navigate(['home', 'facilities', id]),
    phantomTests: (providedState?: DatagridManualState) =>
      this.router.navigate(['home', 'phantomTests'], {
        state: { providedState },
      }),
    scannerDetails: (id: string | number) =>
      this.router.navigate(['home', 'scanners', id]),
    studyDetails: (id: string | number) =>
      this.router.navigate(['home', 'studies', id]),
    missedPhantomTests: () =>
      this.router.navigate(['home', 'missedPhantomTests']),
    missedPhantomTestDetails: (id: string | number) =>
      this.router.navigate(['home', 'missedPhantomTests', id]),
    phantomTestDetails: (id: string | number, navoptions?: NavOptions) => {
      if (navoptions?.blank) {
        window.open(`/home/phantomTests/${id}`, '_blank')
      } else {
        this.router.navigate(['home', 'phantomTests', id])
      }
    },
    survey: (id: string | number) =>
      this.router.navigate(['home', 'survey', id]),
    surveyTestDetails: (id: string | number) =>
      this.router.navigate(['home', 'surveyTests', id]),
    reportDetails: (id: string | number) =>
      this.router.navigate(['home', 'reports', id]),
    userDetails: (id: string | number) =>
      this.router.navigate(['home', 'users', id]),
    workstationDetails: (id: string | number) =>
      this.router.navigate(['home', 'workstations', id]),
    facilities: () => this.router.navigate(['home', 'facilities']),
  }
}
