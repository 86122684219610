import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { HomeComponent } from './home.component'
import { StudiesComponent } from './studies/studies.component'
import { AuthGuard } from '../auth/auth.guard'
import { CoreModule } from '../core/core.module'
import { InboxComponent } from './inbox/inbox.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { PhantomTestsComponent } from './phantom-tests/phantom-tests.component'
import { SurveyTestsComponent } from './survey-tests/survey-tests.component'
import { ReportsComponent } from './reports/reports.component'
import { ScannersComponent } from './scanners/scanners.component'
import { WorkstationsComponent } from './workstations/workstations.component'
import { FacilitiesComponent } from './facilities/facilities.component'
import { UsersComponent } from './users/users.component'
import { SettingsComponent } from './settings/settings.component'
import { PhantomTestDetailsComponent } from './phantom-test-details/phantom-test-details.component'
import { FacilitiesEditComponent } from './facilities-edit/facilities-edit.component'
import { UserSettingsComponent } from './user-settings/user-settings.component'
import { StudiesDetailsComponent } from './studies-details/studies-details.component'
import { CommentCategoriesComponent } from './comment-categories/comment-categories.component'
import { SurveyComponent } from './survey/survey.component'
import { ReportsDetailsComponent } from './reports-details/reports-details.component'
import { ScannersDetailsComponent } from './scanners-details/scanners-details.component'
import { WorkstationsDetailsComponent } from './workstations-details/workstations-details.component'
import { WorkstationsCreateComponent } from './workstations-create/workstations-create.component'
import { SurveyTestsDetailsComponent } from './survey-tests-details/survey-tests-details.component'
import { MissedPhantomTestsComponent } from './missed-phantom-tests/missed-phantom-tests.component'
import { MissedPhantomTestsDetailsComponent } from './missed-phantom-tests-details/missed-phantom-tests-details.component'
import { FacilitiesDetailsComponent } from './facilities-details/facilities-details.component'
import { UsersDetailsComponent } from './users-details/users-details.component'
import { HomepageGuard } from '../homepage.guard'
import { TrendsComponent } from './trends/trends.component'
import { AdminGuard } from '../auth/admin.guard'
import { ProblemsComponent } from './problems/problems.component'
import { TrendsTabsComponent } from './trends-tabs/trends-tabs.component'
import { MetricsComponent } from './metrics/metrics.component'
import { StatusComponent } from './status/status.component'
import { SchedulesComponent } from './schedules/schedules.component'

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard, HomepageGuard],
    children: [
      { path: 'studies', component: StudiesComponent },
      { path: 'studies/:id', component: StudiesDetailsComponent },
      { path: 'inbox', component: InboxComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'metrics', component: MetricsComponent },
      { path: 'schedules', component: SchedulesComponent },
      { path: 'phantomTests', component: PhantomTestsComponent },
      { path: 'phantomTests/:id', component: PhantomTestDetailsComponent },
      { path: 'problems', component: ProblemsComponent },
      { path: 'surveyTests', component: SurveyTestsComponent },
      { path: 'surveyTests/:id', component: SurveyTestsDetailsComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'reports/:id', component: ReportsDetailsComponent },
      { path: 'trends', component: TrendsTabsComponent },
      { path: 'scanners', component: ScannersComponent },
      { path: 'scanners/:id', component: ScannersDetailsComponent },
      { path: 'workstations', component: WorkstationsComponent },
      { path: 'workstations/:id', component: WorkstationsDetailsComponent },
      { path: 'workstations/create', component: WorkstationsCreateComponent },
      { path: 'facilities', component: FacilitiesComponent },
      { path: 'facilities/:id', component: FacilitiesDetailsComponent },
      { path: 'facilities/create', component: FacilitiesEditComponent },
      { path: 'users', component: UsersComponent, canActivate: [AdminGuard] },
      {
        path: 'users/:id',
        component: UsersDetailsComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AdminGuard],
      },
      { path: 'userSettings', component: UserSettingsComponent },
      { path: 'commentCategories', component: CommentCategoriesComponent },
      { path: 'survey/:id', component: SurveyComponent },
      { path: 'missedPhantomTests', component: MissedPhantomTestsComponent },
      {
        path: 'missedPhantomTests/:id',
        component: MissedPhantomTestsDetailsComponent,
      },
      { path: 'status', component: StatusComponent },
    ],
    runGuardsAndResolvers: 'always',
  },
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes), CoreModule],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
