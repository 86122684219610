import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { MessageError } from 'src/app/core/errors/errors'
import { safeGet } from '../../util/util'
import deleteMutation from './delete-users-details.gql'
import { delete_user_by_pk } from './types/delete_user_by_pk'
import { usersDetails } from './types/usersDetails'
import query from './users-details.gql'
import { NavService } from 'src/app/core/nav.service'

@Component({
  selector: 'app-users-details',
  templateUrl: './users-details.component.html',
  styleUrls: ['./users-details.component.scss'],
})
export class UsersDetailsComponent implements OnInit {
  @Input() userId: number

  loading = false

  editing = false

  private queryResult: ApolloQueryResult<usersDetails>

  safeGet = safeGet

  @Output() updateEvent = new EventEmitter()

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private nav: NavService,
  ) {}

  ngOnInit() {
    if (!this.userId) {
      this.userId = this.route.snapshot.params.id
    }
    this.fetchusersDetails()
  }

  fetchusersDetails = () => {
    this.loading = true
    this.apollo
      .query<usersDetails>({ query, variables: { id: this.userId } })
      .subscribe({ next: this.onNext, error: this.onError })
  }

  onNext = (result: ApolloQueryResult<usersDetails>) => {
    this.loading = false
    this.queryResult = result
    if (result.errors) {
      throw result.errors[0]
    }
    if (!this.user) {
      throw new MessageError('user not found')
    }
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  get user() {
    return this.queryResult?.data?.user_by_pk
  }

  delete() {
    if (window.confirm('Delete User: are you sure?')) {
      this.apollo
        .mutate<delete_user_by_pk>({
          mutation: deleteMutation,
          variables: { id: this.userId },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.nav.navtree.users()
          },
        })
    }
  }

  editView() {
    this.editing = true
  }

  onSave($event) {
    this.editing = false
    this.updateEvent.emit($event)
    this.fetchusersDetails()
  }
}
