import { gql } from 'apollo-angular'

export default gql`
  query schedules_related {
    scanner {
      name
      id
    }
    phantom_test_routine {
      name
      id
      __typename
    }
    survey_test_routine {
      name
      id
      __typename
    }
  }
`
