import { seriesInfoConfig } from './seriesInfoConfig'
import { Component, Input, OnInit } from '@angular/core'
import { FileService } from 'src/app/core/file.service'
import {
  studiesDetails_study_by_pk,
  studiesDetails_study_by_pk_study_images,
} from '../studies-details/types/studiesDetails'
import cornerstone from 'cornerstone-core'
import dicomParser from 'dicom-parser'
import { TAG_DICT } from './dataDictionary'
import { EventsService } from 'src/app/events.service'
import { Subscription } from 'rxjs'
import { Dictionary, flatten, groupBy, sortBy } from 'lodash'
import moment from 'moment-timezone'
import { StringObjectLiteral } from 'src/app/util/util'
import stringFormat from 'string-format'
import fscreen from 'fscreen'
const format = stringFormat.create({
  format0f: (s) => (typeof s === 'number' ? s : parseFloat(s)).toFixed(0),
  format1f: (s) => (typeof s === 'number' ? s : parseFloat(s)).toFixed(1),
  format2f: (s) => (typeof s === 'number' ? s : parseFloat(s)).toFixed(2),
  format3f: (s) => (typeof s === 'number' ? s : parseFloat(s)).toFixed(3),
})

const TELE_ZOOM_LIMIT = 6
const MOUSE_SENSITITIVY_FACTOR = 1 / 3

const overlaySpecs: {
  [key: string]: {
    leftTop?: string[]
    rightTop?: string[]
    leftBottom?: string[]
    rightBottom?: string[]
  }
} = {
  CT: {
    leftTop: [
      'desc: {SeriesDescription}',
      'series: {SeriesNumber}',
      'position: {SliceLocation!format1f}',
      'thickness: {SliceThickness!format1f}',
      'mode: {ScanOptions}',
      'filter: {FilterType}',
    ],
    rightTop: [
      'W/L: {_window!format0f}/{_level!format0f}',
      'kVp: {KVP!format0f}',
      'mA: {XRayTubeCurrent!format0f}',
    ],
    rightBottom: ['Zoom: {_zoom!format2f}x'],
    leftBottom: ['{_pixelWidth!format0f}x{_pixelHeight!format0f}'],
  },
  MR: {
    leftTop: [
      'desc: {SeriesDescription}',
      'series: {SeriesNumber}',
      'position: {SliceLocation!format1f}',
      'thickness: {SliceThickness}',
      'coil: {TransmitCoilName}',
    ],
    rightTop: [
      'W/L: {_window!format0f}/{_level!format0f}',
      'TR: {RepetitionTime!format0f}',
      'TE: {EchoTime!format0f}',
      'FS: {MagneticFieldStrength!format0f}',
    ],
    rightBottom: ['Zoom: {_zoom!format2f}x'],
    leftBottom: ['{_pixelWidth!format0f}x{_pixelHeight!format0f}'],
  },
  MG: {
    leftTop: [
      'desc: {SeriesDescription}',
      'series: {SeriesNumber}',
      'AEC: {ExposureControlMode}',
      'anode: {AnodeTargetMaterial}',
      'filter: {FilterMaterial}',
      'compression: {CompressionForce}',
    ],
    rightTop: [
      'type: {PresentationIntentType}',
      'W/L: {_window!format0f}/{_level!format0f}',
      'kVp: {KVP!format0f}',
      'mA: {XRayTubeCurrent!format0f}',
    ],
    rightBottom: ['Zoom: {_zoom!format2f}x'],
    leftBottom: ['{_pixelWidth!format0f}x{_pixelHeight!format0f}'],
  },
  default: {
    leftTop: [
      'Date: {_studyDateYear}-{_studyDateMonth}-{_studyDateDay}',
      'Modality: {Modality}',
      'desc: {SeriesDescription}',
    ],
    rightTop: ['Oper: {OperatorsName}', 'Model: {ManufacturerModelName}'],
    rightBottom: ['Zoom: {_zoom!format2f}x'],
    leftBottom: ['{_pixelWidth!format0f}x{_pixelHeight!format0f}'],
  },
}

type study_image = studiesDetails_study_by_pk_study_images

type LUTShape = 'INVERSE' | 'IDENTITY'

interface OverlayItem {
  /*
  'tag' represents a string, or list of strings, representing the common dicom tag name
  eg 'SliceLocation'.
  'name is a common name for a non dicom tag data to display, eg 'Window' or 'Level'.
  This type of data is something (like WL) that will vary depending on test/display and
  needs to be handled case-by-case.
  The logic should be a) if 'tag' is present, use that.  If it's a list of strings, use the
  tag that first has a value.  b) if tag isn't present, use 'name' instead
  */
  tag?: string | string[]
  /* used for custom displays like window/level */
  name?: string
  /* label is the string human readable label displayed to identify the data */
  label?: string
  /* number of decimals to display if data is numerical */
  decimals?: number
}
interface OverlaySpec {
  /* defines overlay spec for a specific modality  or test*/
  leftTop: OverlayItem[]
  rightTop: OverlayItem[]
  leftBottom: OverlayItem[]
  rightBottom: OverlayItem[]
}
interface TestModalityOverlaySpec {
  testID: string
  spec: OverlaySpec
}
interface OverlaySpecs {
  specs: TestModalityOverlaySpec[]
}

interface CornerstoneImage {
  imageId?: string //The imageId associated with this image object
  minPixelValue?: number //the minimum stored pixel value in the image
  maxPixelValue?: number //the maximum stored pixel value in the image
  slope?: number //the rescale slope to convert stored pixel values to modality pixel values or 1 if not specified
  intercept?: number //the rescale intercept used to convert stored pixel values to modality values or 0 if not specified
  windowCenter?: number //the default windowCenter to apply to the image
  windowWidth?: number //the default windowWidth to apply to the image
  getPixelData?: Function //a function that returns the underlying pixel data. An array of integers for grayscale and an array of RGBA for color
  getImageData?: Function //a function that returns a canvas imageData object for the image. This is only needed for color images
  getCanvas?: Function //a function that returns a canvas element with the image loaded into it. This is only needed for color images.
  getImage?: Function //a function that returns a JavaScript Image object with the image data. This is optional and typically used for images encoded in standard web JPEG and PNG formats
  rows?: number //number of rows in the image. This is the same as height but duplicated for convenience
  columns?: number //number of columns in the image. This is the same as width but duplicated for convenience
  height?: number //the height of the image. This is the same as rows but duplicated for convenience
  width?: number //the width of the image. This is the same as columns but duplicated for convenience
  color?: boolean //true if pixel data is RGB, false if grayscale
  lut?: any //The Lookup Table
  rgba?: boolean //Is the color pixel data stored in RGBA?
  columnPixelSpacing?: number //horizontal distance between the middle of each pixel (or width of each pixel) in mm or undefined if not known
  rowPixelSpacing?: number //vertical distance between the middle of each pixel (or height of each pixel) in mm or undefined if not known
  invert?: boolean //true if the the image should initially be displayed be inverted, false if not. This is here mainly to support DICOM images with a photometric interpretation of MONOCHROME1
  sizeInBytes?: number //the number of bytes used to store the pixels for this image.
  falseColor?: boolean // Whether or not the image has undergone false color mapping
  origPixelData?: any[] // Original pixel data for an image after it has undergone false color mapping
  stats?: ImageStats // Statistics for the last redraw of the image
  cachedLut?: any //Cached Lookup Table for this image.
  colormap?: string | any //| Colormap)? an optional colormap ID or colormap object (from colors/colormap.js). This will be applied during rendering to convert the image to pseudocolor
  labelmap?: boolean // whether or not to render this image as a label map (i.e. skip modality and VOI LUT pipelines and use only a color lookup table)
  data: {
    byteArray: Uint8Array
    byteArrayParser: dicomParser.ByteArrayParser
    elements: {
      [key: string]: {
        tag: string
        vr: string
        length: number
        dataOffset: number
        parser?: dicomParser.ByteArrayParser
      }
    }
    warnings: any[]
  }
}

interface ImageStats {
  lastGetPixelDataTime?: number
  lastLutGenerateTime?: number
  lastPutImageDataTime?: number
  lastRenderTime?: number
  lastStoredPixelDataToCanvasImageDataTime?: number
}

interface Viewport {
  colormap?: any
  displayedArea: {
    brhc: { x: number; y: number }
    columnPixelSpacing: number
    presentationSizeMode: string
    rowPixelSpacing: number
    tlhc: { x: number; y: number }
  }
  hflip: boolean
  invert: boolean
  labelmap: boolean
  modalityLUT?: any
  pixelReplication: boolean
  rotation: number
  scale: number
  translation: { x: number; y: number }
  vflip: boolean
  voi: { windowWidth: number; windowCenter: number }
  voiLUT?: any
}

interface EventDetail {
  canvasContext: CanvasRenderingContext2D
  element: HTMLElement
  enabledElement: {
    canvas: CanvasRenderingContext2D
    data: any
    element: HTMLElement
    image: CornerstoneImage
    invalid: boolean
    lastImageTimeStamp: number
    layers: any[]
    needsRedraw: boolean
    options?: any
    renderingTools: {
      lastRenderedImageId: string
      lastRenderedIsColor: boolean
      lastRenderedViewport: Viewport
      renderCanvas: HTMLCanvasElement
      renderCanvasContext: CanvasRenderingContext2D
      renderCanvasData: ImageData
    }
    uuid: string
    viewport: Viewport
  }

  image: CornerstoneImage
  renderTimeInMs: number
  viewport: Viewport
}

interface ImageRenderedEvent extends Event {
  detail: EventDetail
  path: HTMLElement[]
  type: 'cornerstoneimagerendered'
}

interface AllImageData {
  wadouri: string
  studyImage: study_image
  cornerstoneImage: CornerstoneImage
  parsedImage: dicomParser.DataSet
  tagData: StringObjectLiteral<string>
}

function makeInverseLUT(bits: number) {
  const lut = {
    id: '1',
    firstValueMapped: 0,
    numBitsPerEntry: bits,
    lut: [],
  }

  for (let i = 0; i < 2 ** bits; i++) {
    lut.lut[i] = 2 ** bits - 1 - i
  }
}

const inverseLUTs = {
  '8': makeInverseLUT(8),
  '12': makeInverseLUT(12),
  '16': makeInverseLUT(16),
}

@Component({
  selector: 'app-dicom-viewer',
  templateUrl: './dicom-viewer.component.html',
  styleUrls: ['./dicom-viewer.component.scss'],
})
export class DicomViewerComponent implements OnInit {
  @Input() study: studiesDetails_study_by_pk

  imageList: AllImageData[] = []
  private _imageLookup: { [wadouri: string]: AllImageData } = {}
  /* typescript quirk: this._imageLookup[x] where x is a number doesn't raise a compile error */
  lookupImage(uri: string): AllImageData | undefined {
    return this._imageLookup[uri]
  }
  seriesListSortedEntries: [string, AllImageData[]][]
  overlays: {
    leftTop?: string[]
    rightTop?: string[]
    rightBottom?: string[]
    leftBottom?: string[]
  } = {}

  constructor(
    private fileService: FileService,
    private events: EventsService,
  ) {}

  currentImageIndex = 0
  currentImage: CornerstoneImage
  get currentSeries() {
    return this.thumbnails.series[this.thumbnails.highlightedSeriesIndex]
  }

  get seriesBoxLoopArray() {
    return Array(this.currentSeries.numImages)
      .fill(0)
      .map((_, i) => i)
  }

  sidebarSubscription: Subscription

  thumbnails: {
    highlightedSeriesIndex: number
    imageIndexInSeries: number
    series: {
      numImages: number
      imageSrc: string
      firstImageIndex: number
      lastImageIndex: number
    }[]
  } = {
    highlightedSeriesIndex: 0,
    imageIndexInSeries: 0,
    series: [],
  }

  seriesTagData: { title: string; value: string }[] = []
  imageTagData: {
    PresentationLUTShape?: string
  } = {}

  /** for loading progress bar */
  numImagesLoaded = 0
  numImages: number

  /** show or hide the overlays on the image */
  showOverlays = true
  /** invert the image */
  invert = false

  element: HTMLElement
  loading = true

  /** for zooming */
  firstZoomFactor: number | undefined

  updateTheImage: (imageIndex: number) => void

  ngOnDestroy(): void {
    this.sidebarSubscription?.unsubscribe()
  }

  resize(timeout: number = 0) {
    try {
      const element = document.getElementById('dicomImage')
      if (element) {
        setTimeout(() => {
          try {
            cornerstone.resize(element, true)
          } catch (e) {}
        }, timeout)
      }
    } catch (e) {}
  }

  async ngOnInit(): Promise<void> {
    this.numImages = this.study.study_images.length
    // @ts-ignore cornerstoneWADOImageLoader is loaded by a script in index.html
    window.cornerstoneWADOImageLoader.external.cornerstone = cornerstone
    // load, cache, parse, and save all the study images
    await Promise.all(
      this.study.study_images.map(async (studyImage) => {
        const wadouri = this.wadouri(studyImage)
        return cornerstone
          .loadAndCacheImage(wadouri)
          .then((csImage: CornerstoneImage) => {
            const parsedImage = dicomParser.parseDicom(csImage.data.byteArray)
            const imageData = {
              wadouri,
              studyImage: studyImage,
              cornerstoneImage: csImage,
              parsedImage,
              tagData: this.getAllTags(parsedImage),
            }
            this.imageList.push(imageData)
            this._imageLookup[wadouri] = imageData
            this.numImagesLoaded++
          })
      }),
    )
    this.loading = false

    // sort imageList by series and sliceLocation
    this.imageList = sortBy(this.imageList, [
      (i) => this.getTagValFromImage(i, 'SeriesInstanceUID'),
      (i) => parseFloat(this.getTagValFromImage(i, 'EchoTime')) || 0,
      (i) => parseFloat(this.getTagValFromImage(i, 'SliceLocation')) ?? 0,
    ])

    // create a grouping of images by series
    const seriesList = groupBy(this.imageList, this.seriesUniqueIdentifier)
    /* sort the series list by number of images */
    this.seriesListSortedEntries = Object.entries(seriesList).sort(
      (a, b) => b[1].length - a[1].length,
    )
    this.imageList = flatten(
      this.seriesListSortedEntries.map((entry) => entry[1]),
    )

    // get thumbnail info for each series
    this.seriesListSortedEntries
      .map((entry) => entry[1])
      .forEach((series) => {
        const middleImage = series[Math.floor(series.length / 2)]
        const firstImage = series[0]
        const middleImageThumbSrc = this.fileService.getHostedUrl(
          middleImage.studyImage.storedFileByRenderedfileid.key,
        )
        const firstImageIndex: number = this.imageList.findIndex(
          (i) => i.wadouri === firstImage.wadouri,
        )
        this.thumbnails.series.push({
          imageSrc: middleImageThumbSrc,
          numImages: series.length,
          firstImageIndex,
          lastImageIndex: firstImageIndex + series.length - 1,
        })
      })

    // image enable the element
    const element = document.getElementById('dicomImage')
    try {
      cornerstone.enable(element)
    } catch (e) {
      return
    }
    this.element = element

    // ensure the canvas scales properly if the window resizes
    window.addEventListener('resize', () => this.resize())
    // or if the sidebar is triggered
    this.sidebarSubscription = this.events.sidebarChange.subscribe(() =>
      this.resize(200),
    )

    // updates the image display
    const updateTheImage = async (imageIndex: number) => {
      const image: CornerstoneImage = await cornerstone.loadAndCacheImage(
        this.imageList[imageIndex].wadouri,
      )
      // get all data for image
      const allDataForImage = this.lookupImage(image.imageId)
      // keep track of which image is selected
      this.currentImageIndex = imageIndex
      this.currentImage = image
      // figure out which series to highlight
      const seriesKeys = this.seriesListSortedEntries.map((entry) => entry[0])
      const imageSeriesKey = this.seriesUniqueIdentifier(allDataForImage)
      seriesKeys.forEach((key, index) => {
        if (imageSeriesKey === key)
          this.thumbnails.highlightedSeriesIndex = index
        const currSeries = this.thumbnails.highlightedSeriesIndex
        const firstInSeries = this.thumbnails.series[currSeries].firstImageIndex
        this.thumbnails.imageIndexInSeries =
          this.currentImageIndex - firstInSeries
      })
      // grab relevant tags for each individual image
      const presentationLutShape: string | undefined = this.getTagValFromImage(
        allDataForImage,
        'PresentationLUTShape',
      )
      if (typeof presentationLutShape === 'string') {
        this.imageTagData.PresentationLUTShape = presentationLutShape
        if (presentationLutShape === 'INVERSE') {
          if (!this.invert) {
            this.invert = true
            this.onInvert()
          }
        } else if (presentationLutShape === 'IDENTITY') {
          if (this.invert) {
            this.invert = false
            this.onInvert()
          }
        }
      }

      // grab series info tags
      const modality = this.study.modalityId
      const config = seriesInfoConfig[modality]
      if (config) {
        this.seriesTagData = []
        // add number of images
        this.seriesTagData.push({
          title: 'Images',
          value: this.thumbnails.series[
            this.thumbnails.highlightedSeriesIndex
          ].numImages.toFixed(0),
        })
        // add items from config
        config.forEach((item) => {
          const title = item.title
          let value = this.getTagValFromImage(allDataForImage, item.tag)
          if (!isNaN(parseFloat(value))) {
            if (value.includes('.')) {
              value = parseFloat(value).toFixed(2)
            } else {
              value = parseFloat(value).toFixed(0)
            }
          }
          this.seriesTagData.push({ title, value })
        })
      }
      const viewport: Viewport = cornerstone.getViewport(element)

      // display the image
      cornerstone.displayImage(element, image, viewport)
    }
    this.updateTheImage = updateTheImage

    // setup handlers before we display the image
    const onImageRendered = (e: ImageRenderedEvent) => {
      const eventData = e.detail
      // set the canvas context to the image coordinate system
      cornerstone.setToPixelCoordinateSystem(
        eventData.enabledElement,
        eventData.canvasContext,
      )
      const image = this.lookupImage(e.detail.image.imageId)
      if (!image) throw new Error('no image')
      const studyDate = moment(this.study.scanDate)
      const nonDicomProperties = {
        _window: eventData.viewport.voi.windowWidth,
        _level: eventData.viewport.voi.windowCenter,
        _zoom: eventData.viewport.scale,
        _pixelWidth: image.cornerstoneImage.width,
        _pixelHeight: image.cornerstoneImage.height,
        _studyDateYear: studyDate.format('YYYY'),
        _studyDateMonth: studyDate.format('MMM'),
        _studyDateDay: studyDate.format('D'),
      }
      const dicomProperties = image.tagData
      const overlaySpec =
        overlaySpecs[this.study.modalityId] ?? overlaySpecs.default
      Object.entries(overlaySpec).forEach(([position, strings]) => {
        this.overlays[position] = strings.map((s) =>
          format(s, { ...dicomProperties, ...nonDicomProperties }),
        )
      })
    }

    element.addEventListener('cornerstoneimagerendered', onImageRendered)
    element.addEventListener('cornerstonenewimage', (e: any) => {
      const eventData = e.detail
      eventData.viewport.displayedArea.brhc.x = eventData.image.width
      eventData.viewport.displayedArea.brhc.y = eventData.image.height
    })

    // load and display the image
    await updateTheImage(0)

    // add handlers for mouse events once the image is loaded.
    // add event handlers to pan image on mouse move
    element.addEventListener('mousedown', (e) => {
      let lastX = e.pageX
      let lastY = e.pageY
      const mouseButton = e.button

      const mouseMoveHandler = (e: MouseEvent) => {
        const deltaX = (e.pageX - lastX) * MOUSE_SENSITITIVY_FACTOR
        const deltaY = (e.pageY - lastY) * MOUSE_SENSITITIVY_FACTOR
        lastX = e.pageX
        lastY = e.pageY

        const adjustWindowLevel = mouseButton === 0 && !e.ctrlKey && !e.shiftKey
        const adjustPan =
          mouseButton === 1 || (mouseButton === 0 && !e.ctrlKey && e.shiftKey)
        const adjustZoom =
          mouseButton === 2 || (mouseButton === 0 && e.ctrlKey && !e.shiftKey)

        if (adjustWindowLevel) {
          // left
          let viewport = cornerstone.getViewport(element)
          const maxPixelValue = this.currentImage.maxPixelValue
          const minPixelValue = this.currentImage.minPixelValue
          const range = maxPixelValue - minPixelValue
          const newWindow = Math.min(
            range,
            viewport.voi.windowWidth + deltaX / viewport.scale,
          )
          const newLevel = Math.min(
            Math.max(
              minPixelValue,
              viewport.voi.windowCenter + deltaY / viewport.scale,
            ),
            maxPixelValue,
          )
          viewport.voi.windowWidth = newWindow
          viewport.voi.windowCenter = newLevel
          cornerstone.setViewport(element, viewport)
        } else if (adjustPan) {
          // middle
          let viewport = cornerstone.getViewport(element)
          viewport.translation.x += deltaX / viewport.scale
          viewport.translation.y += deltaY / viewport.scale
          cornerstone.setViewport(element, viewport)
        } else if (adjustZoom) {
          // right
          let viewport = cornerstone.getViewport(element)
          viewport.scale += deltaY / 100

          // for zoom control
          const scale = viewport?.scale
          if (scale !== undefined) {
            if (this.firstZoomFactor === undefined) {
              this.firstZoomFactor = scale
            } else {
              if (
                scale > this.firstZoomFactor &&
                scale < this.firstZoomFactor * TELE_ZOOM_LIMIT
              ) {
                cornerstone.setViewport(element, viewport)
              }
            }
          }
        }
      }

      const mouseUpHandler = () => {
        document.removeEventListener('mouseup', mouseUpHandler)
        document.removeEventListener('mousemove', mouseMoveHandler)
      }

      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)
    })

    const mouseWheelEvents = ['mousewheel', 'DOMMouseScroll']
    mouseWheelEvents.forEach((eventType) => {
      element.addEventListener(
        eventType,
        (e: MouseEvent & { wheelDelta?: number }) => {
          // Prevent page from scrolling
          e.preventDefault()
          e.stopPropagation()

          // Firefox e.detail > 0 scroll back, < 0 scroll forward
          // chrome/safari e.wheelDelta < 0 scroll back, > 0 scroll forward
          const firstInSeries = this.currentSeries.firstImageIndex
          const lastInSeries = this.currentSeries.lastImageIndex
          if (e.wheelDelta < 0 || e.detail > 0) {
            if (
              this.currentImageIndex < this.imageList.length - 1 &&
              (this.study.modalityId === 'MG' ||
                this.currentImageIndex !== lastInSeries)
            ) {
              this.currentImageIndex++
              updateTheImage(this.currentImageIndex)
            }
          } else {
            if (
              this.currentImageIndex > 0 &&
              (this.study.modalityId === 'MG' ||
                this.currentImageIndex !== firstInSeries)
            ) {
              this.currentImageIndex--
              updateTheImage(this.currentImageIndex)
            }
          }

          return false
        },
      )
    })

    this.resize()
  }

  onInvert() {
    let viewport = cornerstone.getViewport(this.element)
    if (viewport) {
      viewport.invert = !viewport.invert
      cornerstone.setViewport(this.element, viewport)
    }
  }

  enableFullscreen = fscreen.fullscreenEnabled
  onFullscreen() {
    if (fscreen.fullscreenEnabled) {
      if (fscreen.fullscreenElement) {
        fscreen.exitFullscreen()
      } else {
        const container = document.getElementById('container')
        fscreen.requestFullscreen(container)
      }
      const element = document.getElementById('dicomImage')
      this.firstZoomFactor = undefined
      cornerstone.resize(element, true)
    }
  }

  getAllTags(image: dicomParser.DataSet): StringObjectLiteral<string> {
    const obj: StringObjectLiteral<string> = {}
    Object.values(TAG_DICT).forEach((entry) => {
      const convertedTagId = `x${entry.tag.slice(1, 5)}${entry.tag.slice(
        6,
        10,
      )}`.toLowerCase()
      const tagName = entry.name
      const value = image.string(convertedTagId)
      obj[tagName] = value
    })
    return obj
  }

  /** returns the string result of a dicom tag value, or undefined if not exists */
  getTagValFromImage(image: AllImageData, tagName: string): string | undefined {
    try {
      const parsed = image.parsedImage
      const tagId = Object.values(TAG_DICT).find(
        (entry) => entry.name === tagName,
      ).tag
      const convertedTagId = `x${tagId.slice(1, 5)}${tagId.slice(
        6,
        10,
      )}`.toLowerCase()
      return parsed.string(convertedTagId)
    } catch (e) {
      console.warn(e)
      return undefined
    }
  }

  /** returns the url to get the jpeg image for a study image */
  renderedFileSrc = (image: AllImageData): string =>
    this.fileService.getHostedUrl(
      image.studyImage.storedFileByRenderedfileid.key,
    )

  /** returns the value for the first tag that gives a value */
  getSeriesInfoValueForImage(
    image: AllImageData,
    config: OverlayItem,
  ): string | undefined {
    const tags = Array.isArray(config.tag) ? config.tag : [config.tag]
    let val: string | undefined
    for (const tag of tags) {
      val = this.getTagValFromImage(image, tag)
      // return the first value we find
      if (typeof val === 'string') break
    }
    return val
  }

  wadouri = (image: study_image): string =>
    `wadouri:${this.fileService.getHostedUrl(image.storedFileByDcmfileid.key)}`

  seriesUniqueIdentifier = (i: AllImageData) =>
    `UID: ${this.getTagValFromImage(
      i,
      'SeriesInstanceUID',
    )} EchoTime: ${this.getTagValFromImage(i, 'EchoTime')}`

  onThumbnailClick(imageIndex: number) {
    this.onChangeSeries(imageIndex)
  }

  onSeriesBoxClick(imageIndex: number) {
    this.onChangeImage(this.currentSeries.firstImageIndex + imageIndex)
  }

  onChangeSeries(imageIndex: number) {
    this.onChangeImage(imageIndex)
    // const viewport = cornerstone.getViewport(this.element)
    // if (viewport) {
    //   viewport.voi.windowWidth = this.currentImage.windowCenter
    //   viewport.voi.windowCenter = this.currentImage.windowWidth
    //   cornerstone.setViewport(this.element, viewport)
    // }
  }

  onChangeImage(imageIndex: number) {
    this.firstZoomFactor = undefined
    this.updateTheImage(imageIndex)
    this.resize()
  }
}
