import { gql } from 'apollo-angular'

export default gql`
  query calendar($scannerId: Int!, $start: timestamp!, $end: timestamp!) {
    scanner_by_pk(id: $scannerId) {
      studies(
        order_by: { scanDate: asc }
        where: {
          _and: [
            { scanDate: { _gte: $start } }
            { scanDate: { _lte: $end } }
            { _not: { phantom_tests: { id: { _gte: 0 } } } }
          ]
        }
      ) {
        id
        scanDate
        phantom_tests_aggregate {
          aggregate {
            count
          }
        }
      }
      phantom_tests(
        order_by: { study: { scanDate: asc } }
        where: {
          _and: [
            { study: { scanDate: { _gte: $start } } }
            { study: { scanDate: { _lte: $end } } }
          ]
        }
      ) {
        id
        result
        phantom_test_routine {
          name
        }
        study {
          scanDate
        }
      }
      survey_tests(
        order_by: { dateCreated: asc }
        where: {
          _and: [
            { dateCreated: { _gte: $start } }
            { dateCreated: { _lte: $end } }
          ]
        }
      ) {
        id
        result
        dateCreated
        survey_test_routine {
          name
        }
      }
      missed_phantom_tests(
        order_by: { scheduledDate: asc }
        where: {
          _and: [
            { scheduledDate: { _gte: $start } }
            { scheduledDate: { _lte: $end } }
          ]
        }
      ) {
        id
        result
        scheduledDate
      }
    }
  }
`
