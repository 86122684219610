import { gql } from 'apollo-angular'

export default gql`
  mutation deleteCustomValue($path: [String]!, $routineId: Int!) {
    update_phantom_test_routine(
      where: { id: { _eq: $routineId } }
      _delete_at_path: { customValues: $path }
    ) {
      returning {
        customValues
      }
    }
  }
`
