<div class="clr-row" *ngIf="user">
  <div class="clr-col-md-6">
    <h3>User Settings</h3>
    <app-users-edit [user]="user" (saveEvent)="onSave($event)"></app-users-edit>
  </div>
  <div *ngIf="auth.isNativeAccount()" class="clr-col-md-6">
    <h3>Change Password</h3>
    <app-change-password></app-change-password>
  </div>
</div>
