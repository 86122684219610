import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import RRuleGenerator from '../../../../react-rrule-generator'

export const defaultSchedule = 'RRULE:FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1'

@Component({
  selector: 'app-rrule-generator',
  templateUrl: './rrule-generator.component.html',
  styleUrls: ['./rrule-generator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RruleGeneratorComponent
  implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('RRuleGenerator') reactContainerRef: ElementRef
  unsavedRrule: string
  @Input() schedule: string
  @Output() scheduleChange = new EventEmitter<string>()

  ngOnChanges(changes: SimpleChanges): void {
    if (this.reactContainerRef) {
      this.renderReactComponents()
    }
  }

  ngAfterViewInit() {
    this.renderReactComponents()
  }

  ngOnDestroy() {
    ReactDOM.unmountComponentAtNode(this.reactContainerRef.nativeElement)
  }

  private renderReactComponents() {
    ReactDOM.render(
      <React.StrictMode>
        <RRuleGenerator
          value={this.schedule || defaultSchedule}
          config={{
            hideStart: true,
            hideEnd: true,
          }}
          onChange={(change: string) => {
            this.unsavedRrule = change
            this.scheduleChange.emit(this.unsavedRrule)
          }}
        />
      </React.StrictMode>,
      this.reactContainerRef.nativeElement,
    )
  }
}
