<div class="clr-row" appPageHeader [title]="title"></div>
<clr-datagrid
  class="datagrid-striped"
  #datagrid
  (clrDgRefresh)="refreshGrid($event, fetchItems)"
  [clrDgLoading]="loading"
>
  <clr-dg-placeholder>Nothing to Show</clr-dg-placeholder>

  <clr-dg-row *ngFor="let item of items">
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.facility?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.facility_room?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.modality?.id
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanner_manufacturer?.name
    }}</clr-dg-cell>
    <clr-dg-cell appClickToView (click)="view(item)">{{
      item?.scanner_model?.name
    }}</clr-dg-cell>
  </clr-dg-row>

  <!-- Facility Column -->
  <clr-dg-column [clrDgField]="properties.facility.sort">
    <ng-container>Facility</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.facility.filter]?.exact"
        [property]="properties.facility.filter"
        [options]="options.facilities"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Room Column -->
  <clr-dg-column [clrDgField]="properties.facility_room.sort">
    <ng-container>Room</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.facility_room.filter]?.exact"
        [property]="properties.facility_room.filter"
        [options]="options.rooms"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Modality Column -->
  <clr-dg-column [clrDgField]="properties.modality">
    <ng-container>Modality</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.modality]?.exact"
        [property]="properties.modality"
        [options]="options.modalities"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Scanner Column -->
  <clr-dg-column
    [clrFilterValue]="filterState[properties.scanner]?.value"
    [clrDgField]="properties.scanner"
  >
    <ng-container>Scanner</ng-container>
  </clr-dg-column>

  <!-- Manufacturer Column -->
  <clr-dg-column [clrDgField]="properties.scanner_manufacturer.sort">
    <ng-container>Manufacturer</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.scanner_manufacturer.filter]?.exact"
        [property]="properties.scanner_manufacturer.filter"
        [options]="options.scanner_manufacturers"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Model Column -->
  <clr-dg-column [clrDgField]="properties.scanner_model.sort">
    <ng-container>Model</ng-container>
    <clr-dg-filter>
      <app-select-filter
        [exact]="filterState[properties.scanner_model.filter]?.exact"
        [property]="properties.scanner_model.filter"
        [options]="options.scanner_models"
      ></app-select-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <!-- Footer -->
  <clr-dg-footer>
    {{ pagination.firstItem + 1 | number }} -
    {{ pagination.lastItem + 1 | number }} of {{ totalItems | number }} items
    <clr-dg-pagination
      #pagination
      [clrDgTotalItems]="totalItems"
    ></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
