import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { DatagridStateService } from 'src/app/core/datagrid-state.service'
import { NavService } from 'src/app/core/nav.service'
import { DatagridComponent } from 'src/app/util/datagrid.class'
import { TimezoneService } from 'src/app/timezone.service'
import { KeyVal, userFullname } from '../../util/util'
import facility_get_related_entitiesGql from '../facilities-edit/facility_get_related_entities.gql'
import { facility_get_related_entities } from '../facilities-edit/types/facility_get_related_entities'
import { PageHeaderAction } from '../page-header/page-header.component'
import query from './facilities.gql'
import { facilities, facilities_facility } from './types/facilities'

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.scss'],
})
export class FacilitiesComponent extends DatagridComponent {
  title = 'facilities'

  actions: PageHeaderAction[] = [{ text: 'Create Facility' }]

  creatingFacility = false

  getUserFullname = userFullname

  static properties = {
    name: 'name',
    address: 'address',
    phone: 'phone',
    facilityId: 'facilityId',
  }
  properties = FacilitiesComponent.properties

  constructor(
    private apollo: Apollo,
    private nav: NavService,
    protected router: Router,
    protected dgStateService: DatagridStateService,
    protected timezoneService: TimezoneService,
  ) {
    super(dgStateService, router, timezoneService)
    // add default if necessary
    this.defaultState = {
      sort: {
        by: this.properties.name,
        reverse: false,
      },
    }
  }

  actionsHandler($event: string) {
    switch ($event) {
      case 'Create Facility':
        this.creatingFacility = true
        break
      default:
        break
    }
  }

  items: facilities_facility[]
  totalItems: number

  fetchItems = () => {
    this.loading = true
    this.apollo
      .query<facilities>({ query, variables: this.variables })
      .subscribe({
        next: (result: ApolloQueryResult<facilities>) => {
          this.loading = false
          if (result.errors) {
            throw result.errors[0]
          }
          this.items = result.data.facility
          this.totalItems = result.data.facility_aggregate.aggregate.count
        },
        error: this.onError,
      })
    this.apollo
      .query<facility_get_related_entities>({
        query: facility_get_related_entitiesGql,
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.assembleRelatedEntityOptions(result.data)
        },
        error: this.onError,
      })
  }

  onUpdate($event: string) {
    switch ($event) {
      case 'delete':
      case 'edit':
        this.fetchItems()
        break
      case 'create':
      case 'back':
        this.creatingFacility = false
        this.fetchItems()
        break
      default:
        break
    }
  }

  options: {
    users: KeyVal[]
  } = {
    users: [],
  }

  assembleRelatedEntityOptions(data: facility_get_related_entities) {
    this.options = {
      users: data.user.map((u) => ({
        key: u.id,
        val: this.getUserFullname(u),
      })),
    }
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  view(facility: facilities_facility) {
    this.nav.navtree.facilityDetails(facility.id)
  }
}
