<ng-container *ngIf="test">
  <!-- Header and actions -->
  <div
    class="clr-row"
    appPageHeader
    [title]="title"
    [actions]="actions"
    (clicks)="actionsHandler($event)"
  ></div>
  <app-record-subtitle
    [facility]="facility"
    [room]="room"
    [scanner]="scanner"
  ></app-record-subtitle>

  <div class="clr-row">
    <!-- Basic Info -->
    <div class="clr-col-6">
      <table class="table">
        <tbody>
          <tr>
            <td class="left">Study Date:</td>
            <td class="left">
              <app-action-link
                (clicks)="actionsHandler($event)"
                [actions]="studyLinkActions"
                [text]="studyDate | customDate"
              ></app-action-link>
            </td>
          </tr>
          <tr>
            <td class="left">Test Date:</td>
            <td class="left">{{ testDate | customDate }}</td>
          </tr>
          <tr>
            <td class="left">Facility:</td>
            <td class="left">
              <app-action-link
                (clicks)="actionsHandler($event)"
                [actions]="facilityLinkActions"
                [text]="facilityName"
              >
              </app-action-link>
            </td>
          </tr>
          <tr *ngIf="room">
            <td class="left">Room:</td>
            <td class="left">{{ roomName }}</td>
          </tr>
          <tr>
            <td class="left">Scanner:</td>
            <td class="left">
              <app-action-link
                (clicks)="actionsHandler($event)"
                [actions]="scannerLinkActions"
                [text]="scannerName"
              >
              </app-action-link>
            </td>
          </tr>
          <tr>
            <td class="left">
              Last Calibration Date:
              <span *ngIf="daysSinceLastCalibration"
                >({{ daysSinceLastCalibration }} days since last)</span
              >
            </td>
            <td class="left">{{ lastCalibrationDate }}</td>
          </tr>
          <tr>
            <td class="left">Phantom:</td>
            <td class="left">{{ phantom | friendlyPhantomName }}</td>
          </tr>
          <tr>
            <td class="left">Tester:</td>
            <td class="left">{{ tester | userFullName | titlecase }}</td>
          </tr>
          <tr class="{{ testResult }}">
            <td class="left"><strong>Result:</strong></td>
            <td class="left">
              <strong>{{ testResult }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="testHasResult(); else naTestOptions" id="testingOutput">
        <app-testing-output [test]="test"></app-testing-output>
      </div>
    </div>
    <div id="images" class="clr-col-6">
      <app-series-viewer [result]="rawOutput"></app-series-viewer>
    </div>
    <div class="clr-col-12">
      <app-comments
        [scannerId]="scannerId"
        [phantomTestId]="phantomTestId"
      ></app-comments>
    </div>
  </div>
</ng-container>

<ng-template #naTestOptions>
  <app-na-test-options [test]="test"></app-na-test-options>
</ng-template>
