import { Pipe, PipeTransform } from '@angular/core'
import { Phantom } from 'common/aqsTypes'

@Pipe({
  name: 'friendlyPhantomName',
})
export class FriendlyPhantomNamePipe implements PipeTransform {
  phantomNames: { [key in Phantom]: string } = {
    siemens: 'Siemens',
    catphan: 'Catphan',
    catphanXrct: 'Catphan XRCT',
    ct_acr: 'ACR (CT)',
    ge_big: 'GE Big',
    ge_small: 'GE Small',
    mr_acr: 'ACR (MR)',
    toshiba: 'Toshiba',
    mg: 'MG',
    canon: 'Canon',
    cr: 'CR/DR/RF',
    ct_ge_optima_540: 'GE CT540',
  }

  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value === 'string') {
      if (this.phantomNames[value]) {
        return this.phantomNames[value]
      }
    }
    return value
  }
}
