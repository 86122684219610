import { gql } from 'apollo-angular'

export default gql`
  query study_get_related_entities {
    facility_room(order_by: { name: asc }) {
      id
      name
    }

    modality(order_by: { id: asc }) {
      id
    }

    scanner(order_by: { name: asc }) {
      id
      name
    }

    facility(order_by: { name: asc }) {
      id
      name
    }
  }
`
