<table id="reports-display" class="table">
  <thead>
    <th class="left" colspan="4">Test Reports</th>
  </thead>
  <tbody>
    <tr>
      <td class="left" colspan="3">Generated Report</td>
      <td>
        <a href="{{ reportFile }}"
          ><clr-icon class="link" shape="download"></clr-icon
        ></a>
      </td>
    </tr>
  </tbody>
</table>
