import { gql } from 'apollo-angular'

export default gql`
  mutation deleteFacilityRoom($id: Int!) {
    delete_facility_room(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`
