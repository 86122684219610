import { gql } from 'apollo-angular'

export default gql`
  mutation insert_schedule($schedule: schedule_insert_input!) {
    insert_schedule(objects: [$schedule]) {
      returning {
        id
      }
    }
  }
`
