<div class="clr-row" appPageHeader [title]="title"></div>
<div class="clr-row">
  <div class="clr-col-md-6">
    <div class="card">
      <div class="card-block">
        <div class="clr-row">
          <div class="clr-col-6">Gateway</div>
          <div class="clr-col-6" style="text-align: right;">
            <ng-container *ngIf="gatewayStatus !== undefined; else loading">
              <span class="status-dot status-{{ gatewayStatus }}"></span>
              <span class="status-label status-{{ gatewayStatus }}">{{
                statusText(gatewayStatus)
              }}</span>
            </ng-container>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-12"></div>
        </div>
      </div>
      <div class="card-block">
        <div class="clr-row">
          <div class="clr-col-6">Image Server</div>
          <div class="clr-col-6" style="text-align: right;">
            <ng-container *ngIf="serverStatus !== undefined; else loading">
              <span class="status-dot status-{{ serverStatus }}"></span>
              <span class="status-label status-{{ serverStatus }}">{{
                statusText(serverStatus)
              }}</span>
            </ng-container>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-12"></div>
        </div>
      </div>
      <div class="card-block">
        <div class="clr-row">
          <div class="clr-col-6">API</div>
          <div class="clr-col-6" style="text-align: right;">
            <ng-container *ngIf="apiStatus !== undefined; else loading">
              <span class="status-dot status-{{ apiStatus }}"></span>
              <span class="status-label status-{{ apiStatus }}">{{
                statusText(apiStatus)
              }}</span>
            </ng-container>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-12"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <clr-spinner [clrInverse]="true" [clrInline]="true"></clr-spinner>
  <span class="spinner-label">Connecting...</span>
</ng-template>
