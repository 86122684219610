import { Component, OnInit, Input } from '@angular/core'
import {
  surveyTestsDetails_survey_test_by_pk,
  surveyTestsDetails_survey_test_by_pk_answered_survey_test_questions as Question,
} from '../types/surveyTestsDetails'

interface Sections {
  [key: string]: Question[]
}

@Component({
  selector: 'app-survey-test-details-questions',
  templateUrl: './survey-test-details-questions.component.html',
  styleUrls: ['./survey-test-details-questions.component.scss'],
})
export class SurveyTestDetailsQuestionsComponent implements OnInit {
  @Input() test: surveyTestsDetails_survey_test_by_pk

  sections: Sections = {}

  constructor() {}

  ngOnInit() {
    this.assembleSections()
  }

  get questions() {
    return this.test.answered_survey_test_questions
  }

  assembleSections() {
    this.questions.forEach((question) => {
      const section = question.section || ''
      if (!this.sections[section]) {
        this.sections[section] = []
      }
      this.sections[section].push(question)
    })
  }
}
