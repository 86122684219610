import { Directive, Output, EventEmitter, HostListener } from '@angular/core'

@Directive({
  selector: '[appDragDrop]',
})
export class DragDropDirective {
  @Output() onFileDropped = new EventEmitter<FileList>()

  @Output() onDragover = new EventEmitter<undefined>()

  @Output() onDragleave = new EventEmitter<undefined>()

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault()
    evt.stopPropagation()
    this.onDragover.emit()
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault()
    evt.stopPropagation()
    this.onDragleave.emit()
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt: DragEvent) {
    evt.preventDefault()
    evt.stopPropagation()
    this.onDragleave.emit()
    if (evt.dataTransfer !== null) {
      const { files } = evt.dataTransfer
      if (files.length > 0) {
        this.onFileDropped.emit(files)
      }
    }
  }
}
