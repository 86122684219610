import { gql } from 'apollo-angular'

export default gql`
  query exportPhantomTests(
    $distinct_on: [phantom_test_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [phantom_test_order_by!]
    $where: phantom_test_bool_exp
  ) {
    phantom_test(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      scanner {
        facility_room {
          name
        }
        name
      }
      rawOutput
      evaluationItems
    }
    firstTest: phantom_test(
      distinct_on: $distinct_on
      limit: 1
      offset: $offset
      order_by: { dateCreated: desc }
      where: $where
    ) {
      scanner {
        facility_room {
          name
        }
        name
      }
    }
  }
`
