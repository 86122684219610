import { NgModule } from '@angular/core'
import { CoreModule } from '../core/core.module'
import { DateFilterComponent } from './date-filter/date-filter.component'
import { ExactNumberFilterComponent } from './exact-number-filter/exact-number-filter.component'
import { SelectFilterComponent } from './select-filter/select-filter.component'
import { ComboboxComponent } from './combobox/combobox.component'
import { NumberFilterComponent } from './number-filter/number-filter.component'

@NgModule({
  declarations: [
    DateFilterComponent,
    SelectFilterComponent,
    ExactNumberFilterComponent,
    ComboboxComponent,
    NumberFilterComponent,
  ],
  imports: [CoreModule],
  exports: [
    DateFilterComponent,
    SelectFilterComponent,
    ExactNumberFilterComponent,
    ComboboxComponent,
    NumberFilterComponent,
  ],
})
export class FiltersModule {}
