export const seriesInfoConfig: {
  [modalityId: string]: { description: string; title: string; tag: string }[]
} = {
  CT: [
    {
      description: 'series description ',
      title: 'Desc',
      tag: 'SeriesDescription',
    },
    { description: 'thickness ', title: 'Thick', tag: 'SliceThickness' },
    { description: 'kvp ', title: 'kVp', tag: 'KVP' },
    { description: 'mas ', title: 'mAs', tag: 'XRayTubeCurrent' },
    { description: 'FOV ', title: 'FOV', tag: 'ReconstructionDiameter' },
  ],
  MR: [
    {
      description: 'series description',
      title: 'Desc',
      tag: 'SeriesDescription',
    },
    { description: 'thickness', title: 'Thick', tag: 'SliceThickness' },
    { description: 'TR', title: 'TR', tag: 'RepetitionTime' },
    { description: 'TE', title: 'TE', tag: 'EchoTime' },
    { description: 'coil', title: 'Coil', tag: 'TransmitCoilName' },
  ],
  MG: [
    {
      description: 'presentation intent type',
      title: 'type',
      tag: 'PresentationIntentType',
    },
    { description: 'kvp', title: 'KVP', tag: 'KVP' },
    { description: 'mas', title: 'mAs', tag: 'XRayTubeCurrent' },
    { description: 'filter', title: 'Filter', tag: 'FilterType' },
    { description: 'anode', title: 'Anode', tag: 'AnodeTargetMaterial' },
  ],
}
