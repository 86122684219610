<div class="icons" id="legend">
  <table class="table">
    <tbody>
      <tr>
        <td *ngFor="let section of sections" class="section-title">
          {{ section.title }}
        </td>
      </tr>
    </tbody>
    <tbody>
      <tr>
        <td *ngFor="let section of sections">
          <div *ngFor="let icon of section.icons" class="icon-container">
            <img src="{{ icon.path }}" class="icon" alt="" />
            <span class="icon-label">{{ icon.label }}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
