import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { FullCalendarComponent } from '@fullcalendar/angular'
import dayGridPlugin from '@fullcalendar/daygrid'

import { cloneDeep } from 'lodash'
import * as moment from 'moment-timezone'
import { TimezoneService } from 'src/app/timezone.service'
import { CustomDatePipe } from '../custom-date.pipe'
import calendarGql from './calendar.gql'
import {
  calendar,
  calendarVariables,
  calendar_scanner_by_pk_missed_phantom_tests,
  calendar_scanner_by_pk_phantom_tests,
  calendar_scanner_by_pk_survey_tests,
  calendar_scanner_by_pk_studies,
} from './types/calendar'

interface CalendarEvent {
  title: string
  start: Date
  end: Date
  color: string
  extendedProps:
    | calendar_scanner_by_pk_phantom_tests
    | calendar_scanner_by_pk_survey_tests
    | calendar_scanner_by_pk_missed_phantom_tests
    | calendar_scanner_by_pk_studies
}

@Component({
  selector: 'app-scanner-calendar',
  templateUrl: './scanner-calendar.component.html',
  styleUrls: ['./scanner-calendar.component.scss'],
})
export class ScannerCalendarComponent implements OnInit {
  _scannerId: number
  get scannerId(): number {
    return this._scannerId
  }
  @Input() set scannerId(scannerId: number) {
    this._scannerId = scannerId
    this.ngOnInit()
  }

  @ViewChild('calendar') calendar: FullCalendarComponent

  calendarPlugins = [dayGridPlugin]

  events: CalendarEvent[]

  readonly defaultVariables: calendarVariables = {
    scannerId: 0,
    start: moment()
      .tz(this.timezoneService.cachedTz)
      .startOf('month')
      .toISOString(),
    end: moment()
      .tz(this.timezoneService.cachedTz)
      .endOf('month')
      .toISOString(),
  }

  variables: calendarVariables = cloneDeep(this.defaultVariables)

  queryResult: ApolloQueryResult<calendar>

  colors = {
    pass: '#60b515',
    problem: '#fdd006',
    fail: '#f54f47',
    missed: '#B10DC9',
    na: '#6d7884',
  }

  constructor(
    private apollo: Apollo,
    private customDatePipe: CustomDatePipe,
    private router: Router,
    private timezoneService: TimezoneService,
  ) {}

  ngOnInit() {
    this.fetchItems()
  }

  fetchItems() {
    this.variables.scannerId = this.scannerId
    const query = calendarGql
    const { variables } = this
    this.apollo
      .query<calendar>({ query, variables })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.queryResult = result
          this.assembleEvents()
        },
      })
  }

  assembleEvents() {
    const evts = this.queryResult.data.scanner_by_pk.phantom_tests.map(
      (test: calendar_scanner_by_pk_phantom_tests): CalendarEvent => {
        const event = {
          title: test.phantom_test_routine.name,
          start: moment.parseZone(test.study.scanDate).toDate(),
          end: moment.parseZone(test.study.scanDate).add(1, 'minute').toDate(),
          color: this.getColor(test.result),
          extendedProps: test,
        }
        return event
      },
    )
    this.events = evts
    this.events = this.events.concat(
      this.queryResult.data.scanner_by_pk.survey_tests.map(
        (test: calendar_scanner_by_pk_survey_tests) => {
          const event = {
            title: test.survey_test_routine.name,
            start: moment.parseZone(test.dateCreated).toDate(),
            end: moment.parseZone(test.dateCreated).add(1, 'minute').toDate(),
            color: this.getColor(test.result),
            extendedProps: test,
          }
          return event
        },
      ),
    )
    this.events = this.events.concat(
      this.queryResult.data.scanner_by_pk.missed_phantom_tests.map(
        (test: calendar_scanner_by_pk_missed_phantom_tests) => {
          const event = {
            title: 'missed test',
            start: moment.parseZone(test.scheduledDate).toDate(),
            end: moment.parseZone(test.scheduledDate).add(1, 'minute').toDate(),
            color: this.getColor(test.result),
            extendedProps: test,
          }
          return event
        },
      ),
    )
    this.events = this.events.concat(
      this.queryResult.data.scanner_by_pk.studies.map(
        (study: calendar_scanner_by_pk_studies) => {
          const event = {
            title: 'untested study',
            start: moment.parseZone(study.scanDate).toDate(),
            end: moment.parseZone(study.scanDate).add(1, 'minute').toDate(),
            color: this.colors.na,
            extendedProps: study,
          }
          return event
        },
      ),
    )
  }

  getColor(result: string) {
    return this.colors[result] || ''
  }

  ngAfterViewInit() {}

  async onDatesDestroy($event) {
    await this.setDates($event)
    this.fetchItems()
  }

  async setDates($event) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const start: moment.Moment = $event.view.currentStart
        const end: moment.Moment = $event.view.currentEnd
        this.variables.start = start.toISOString()
        this.variables.end = end.toISOString()
        resolve()
      }, 0)
    })
  }

  onEventClick($event) {
    const item:
      | calendar_scanner_by_pk_phantom_tests
      | calendar_scanner_by_pk_survey_tests
      | calendar_scanner_by_pk_missed_phantom_tests
      | calendar_scanner_by_pk_studies = $event.event.extendedProps
    if (item.__typename === 'phantom_test') {
      this.router.navigate(['/home/phantomTests', item.id])
    } else if (item.__typename === 'survey_test') {
      this.router.navigate(['/home/surveyTests', item.id])
    } else if (item.__typename === 'missed_phantom_test') {
      this.router.navigate(['/home/missedPhantomTests', item.id])
    } else if (item.__typename === 'study') {
      this.router.navigate(['/home/studies', item.id])
    }
  }
}
