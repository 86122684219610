import { gql } from 'apollo-angular'

export default gql`
  mutation updateScannerSupportContact($scannerId: Int!, $contacts: jsonb!) {
    update_scanner(
      where: { id: { _eq: $scannerId } }
      _set: { supportContacts: $contacts }
    ) {
      returning {
        supportContacts
      }
    }
  }
`
