import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { cloneDeep } from 'lodash'
import { AuthService } from 'src/app/auth/auth.service'
import { CommService } from 'src/app/core/comm.service'
import { RegisterInput, UserRole } from 'src/types/graphql-global-types'
import { camel, safeGet, StringObjectLiteral } from '../../util/util'
import { usersDetails_user_by_pk } from '../users-details/types/usersDetails'
import resetPwMutation from './reset_pw.gql'
import { edit_user_by_pk } from './types/edit_user_by_pk'
import { registerUser } from './types/registerUser'
import { reset_pw, reset_pwVariables } from './types/reset_pw'
import { user_get_related_entities } from './types/user_get_related_entities'
import editMutation from './users-edit.gql'
import insertMutation from './users-insert.gql'
import relatedEntityQuery from './user_get_related_entities.gql'

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss'],
})
export class UsersEditComponent implements OnInit {
  createNew = false

  @Input() user: usersDetails_user_by_pk

  newUser: Partial<usersDetails_user_by_pk> = {
    role: UserRole.tech,
  }

  private _backup_user: usersDetails_user_by_pk

  @Output() saveEvent = new EventEmitter<string>()

  safeGet = safeGet

  objectKeys = Object.keys

  adminRoles = [UserRole.admin, UserRole.support]

  relatedEntityOptionsQueryResult: ApolloQueryResult<user_get_related_entities>

  constructor(
    private apollo: Apollo,
    private comm: CommService,
    public auth: AuthService,
  ) {}

  fetchRelatedEntityOptions() {
    this.apollo
      .query<user_get_related_entities>({ query: relatedEntityQuery })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.relatedEntityOptionsQueryResult = result
        },
      })
  }

  get relatedEntityOptions(): user_get_related_entities {
    return this.relatedEntityOptionsQueryResult.data
  }

  ngOnInit() {
    if (!this.user) {
      this.createNew = true
    }
    if (!this.createNew) {
      this._backup_user = cloneDeep(this.user)
    }
    this._backup_user = cloneDeep(this.user)
    this.fetchRelatedEntityOptions()
  }

  save() {
    if (this.createNew) {
      this.apollo
        .mutate<registerUser>({
          mutation: insertMutation,
          variables: { data: this.newUser },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.newUser = {}
            const password = result?.data?.registerUser
            this.comm.alert('success', `New password: ${password}`, -1)
            this.saveEvent.emit('create')
          },
        })
    } else {
      this.apollo
        .mutate<edit_user_by_pk>({
          mutation: editMutation,
          variables: {
            id: this.user.id,
            _set: this.stripInput(this.user),
          },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.saveEvent.emit('edit')
          },
        })
    }
  }

  back() {
    if (!this.createNew) {
      this.user = cloneDeep(this._backup_user)
    } else {
      this.newUser = {}
    }
    this.saveEvent.emit('back')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripInput = (obj: StringObjectLiteral<any>) =>
    Object.keys(obj).reduce((newObj, key) => {
      if (
        key !== 'id' &&
        key !== 'dateCreated' &&
        key !== 'dateUpdated' &&
        key !== '__typename'
      ) {
        if (
          typeof obj[key] === 'string' ||
          typeof obj[key] === 'boolean' ||
          typeof obj[key] === 'number' ||
          (obj[key] === null && key.slice(-2) === 'Id')
        ) {
          newObj[camel(key)] = obj[key]
        }
      }
      return newObj
    }, {})

  resetPw() {
    if (
      window.confirm("Are you sure you want to reset this user's password?")
    ) {
      const variables: reset_pwVariables = {
        requesterId: this.auth.user.id,
        userId: this.user.id,
      }
      this.apollo
        .mutate<reset_pw>({ mutation: resetPwMutation, variables })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.comm.alert(
              'success',
              `New password: ${result.data.resetPassword}`,
              -1,
            )
          },
        })
    }
  }

  getModel():
    | Partial<usersDetails_user_by_pk>
    | (RegisterInput & { status?: string }) {
    return this.createNew ? this.newUser : this.user
  }

  setModel(key: string, val: string) {
    this.getModel()[key] = val
  }

  get roles() {
    return Object.entries(UserRole).filter((r) => r[1] !== UserRole.support)
  }
}
