import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'
import { UserAgentApplication, authResponseCallback } from 'msal'
import { CookieService } from 'ngx-cookie-service'
import { UserRole } from 'src/types/graphql-global-types'
import { DatagridStateService } from '../core/datagrid-state.service'
import { TimezoneService } from '../timezone.service'
import nativeLoginGql from './login.gql'
import microsoftLoginGql from './microsoftLogin.gql'
import { login, login_login_user } from './types/login'
import { environment } from '../../environments/environment'
import { microsoftLogin, microsoftLoginVariables } from './types/microsoftLogin'
import { usersDetails_user_by_pk } from '../home/users-details/types/usersDetails'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _loginData?: login
  private myMSALObj: UserAgentApplication
  private redirectUrl: string

  constructor(
    private router: Router,
    private apollo: Apollo,
    private cookieService: CookieService,
    private timezoneService: TimezoneService,
    private datagridState: DatagridStateService,
  ) {
    const authRedirectCallBack: authResponseCallback = (error, response) => {
      if (error) {
        console.log(error)
      } else {
        console.log(response)
        if (response.tokenType === 'id_token') {
          if (this.myMSALObj.getAccount()) {
            console.log(this.myMSALObj.getAccount())
            this.handleMSLoginResponse()
          }
        }
      }
    }
    this.myMSALObj = new UserAgentApplication(environment.msalConfig)
    this.myMSALObj.handleRedirectCallback(authRedirectCallBack)
  }

  async handleMSLoginResponse() {
    const account = this.myMSALObj.getAccount()
    // contact app server to issue a login token
    const variables: microsoftLoginVariables = {
      accountIdentifier: account.accountIdentifier,
      homeAccountIdentifier: account.homeAccountIdentifier,
      userName: account.userName,
      name: account.name,
      environment: account.environment,
    }
    return new Promise<void>((resolve, reject) => {
      this.apollo
        .use('app')
        .query<microsoftLogin>({
          query: microsoftLoginGql,
          variables,
        })
        .subscribe({
          next: (result: ApolloQueryResult<microsoftLogin>) => {
            if (result.errors) {
              reject(result.errors[0])
              console.log('graphql error')
              return
            }
            this.loginData = { login: result.data.microsoftLogin }
            this.router.navigateByUrl(this.redirectUrl || '/')
            resolve()
          },
          error: (e) => reject(e),
        })
    })
  }

  msalLogin(redirectUrl?: string) {
    this.redirectUrl = redirectUrl
    this.myMSALObj.loginRedirect({
      // for more, visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/docs/scopes.md
      scopes: ['openid', 'profile', 'User.Read'],
    })
  }

  set loginData(data: login | undefined) {
    this._loginData = data
    if (data) {
      this.cookieService.set('loginData', JSON.stringify(this._loginData))
    } else {
      this.cookieService.delete('loginData', '/')
    }
  }

  get loginData(): login | undefined {
    const cookievalue = this.cookieService.get('loginData')
    try {
      this._loginData = JSON.parse(cookievalue)
    } catch (err) {
      return undefined
    }
    return this._loginData
  }

  tokenExpired(): boolean {
    const helper = new JwtHelperService()
    const isExpired = helper.isTokenExpired(this.token)
    return isExpired
  }

  get isLoggedIn(): boolean {
    return !!this.loginData && !this.tokenExpired()
  }

  get user(): login_login_user | undefined {
    return this.loginData?.login.user
  }

  get token(): string | undefined {
    return this.loginData?.login.token
  }

  get permissions(): string[] | undefined {
    return this.loginData?.login.permissions
  }

  async login(
    variables: { username: string; password: string },
    url: string,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apollo
        .use('app')
        .query<login>({
          query: nativeLoginGql,
          variables,
        })
        .subscribe({
          next: (result: ApolloQueryResult<login>) => {
            if (result.errors) {
              reject(result.errors[0])
              console.log('graphql error')
              return
            }
            this.loginData = result.data
            console.log(result.data)
            this.router.navigateByUrl(url)
            resolve()
          },
          error: (e) => reject(e),
        })
    })
  }

  logout() {
    this.clearLocals()
    this.router.navigateByUrl('/')
  }

  clearLocals() {
    this.loginData = undefined
    localStorage.clear()
    this.cookieService.deleteAll()
    this.datagridState.clear()
  }

  checkrole(roles: UserRole[] | UserRole): boolean {
    if (!this.user) return false
    if (!Array.isArray(roles)) {
      roles = [roles]
    }
    if (!this.user) return false
    return roles.includes(this.user.role)
  }

  isNativeAccount(
    user?: { accountType: string } | Partial<usersDetails_user_by_pk>,
  ) {
    return (user || this.user)?.accountType === 'native'
  }
}
