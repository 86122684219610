export enum FilterType {
  select,
  date,
  number,
  exactNumber,
}

export interface ExactNumberFilterInterface {
  type: FilterType.exactNumber
  exact: number
  property: string
}
export function isExactNumberFilter(
  f: FilterInterface,
): f is ExactNumberFilterInterface {
  return f.type === FilterType.exactNumber
}

export interface NumberFilterInterface {
  type: FilterType.number
  min?: number
  max?: number
  property: string
}
export function isNumberFilter(f: FilterInterface): f is NumberFilterInterface {
  return f.type === FilterType.number
}

export interface DateFilterInterface {
  type: FilterType.date
  earliest?: Date
  latest?: Date
  property: string
}
export function isDateFilter(f: FilterInterface): f is DateFilterInterface {
  return f.type === FilterType.date
}

export interface SelectFilterInterface {
  type: FilterType.select
  exact: string | number
  property: string
}
export function isSelectFilter(f: FilterInterface): f is SelectFilterInterface {
  return f.type === FilterType.select
}

/**
 * This is the shape of data clarity's filters have
 * It might be better to write my own string filter
 * and implement it specifically
 */
export interface ClrDefaultStringFilterInterface {
  type: undefined
  property: string
  value: string
}
export function isClrDefaultStringFilter(
  f: FilterInterface,
): f is ClrDefaultStringFilterInterface {
  return (
    typeof f.property === 'string' &&
    'value' in f &&
    typeof f.type === 'undefined'
  )
}

export type FilterInterface =
  | DateFilterInterface
  | SelectFilterInterface
  | ExactNumberFilterInterface
  | ClrDefaultStringFilterInterface
  | NumberFilterInterface
