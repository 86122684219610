import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import rrule, { Options } from 'rrule'
import { MessageError } from 'src/app/core/errors/errors'
import delete_scheduleGql from '../delete_schedule.gql'
import insert_scheduleGql from '../insert_schedule.gql'
import { ScannerFriendly, Schedule, TaskFriendly } from '../schedules.component'
import schedules_relatedGql from '../schedules_related.gql'
import {
  delete_schedule_by_pk,
  delete_schedule_by_pkVariables,
} from '../types/delete_schedule_by_pk'
import {
  insert_schedule,
  insert_scheduleVariables,
} from '../types/insert_schedule'
import {
  schedules_schedule,
  schedules_schedule_schedule_phantom_test_routines_phantom_test_routines,
  schedules_schedule_schedule_phantom_test_routines_phantom_test_routines_phantom_test_routine,
  schedules_schedule_schedule_scanners_scanners,
  schedules_schedule_schedule_scanners_scanners_scanner,
  schedules_schedule_schedule_survey_test_routines_survey_test_routines,
  schedules_schedule_schedule_survey_test_routines_survey_test_routines_survey_test_routine,
} from '../types/schedules'
import {
  schedules_related,
  schedules_related_phantom_test_routine,
  schedules_related_scanner,
  schedules_related_survey_test_routine,
} from '../types/schedules_related'
import {
  schedule_insert_input,
  schedule_phantom_test_routines_phantom_test_routine_arr_rel_insert_input,
  schedule_scanners_scanner_arr_rel_insert_input,
  schedule_set_input,
  schedule_survey_test_routines_survey_test_routine_arr_rel_insert_input,
} from './../../../../types/graphql-global-types'

type Task =
  | schedules_schedule_schedule_phantom_test_routines_phantom_test_routines
  | schedules_schedule_schedule_survey_test_routines_survey_test_routines
type Ptr = {
  __typename: 'schedule_phantom_test_routines_phantom_test_routine'
  /**
   * An object relationship
   */
  phantom_test_routine: Partial<
    schedules_schedule_schedule_phantom_test_routines_phantom_test_routines_phantom_test_routine
  >
}
type Str = {
  __typename: 'schedule_survey_test_routines_survey_test_routine'
  /**
   * An object relationship
   */
  survey_test_routine: Partial<
    schedules_schedule_schedule_survey_test_routines_survey_test_routines_survey_test_routine
  >
}
type Scr = {
  __typename: 'schedule_scanners_scanner'
  /**
   * An object relationship
   */
  scanner: Partial<schedules_schedule_schedule_scanners_scanners_scanner>
}
@Component({
  selector: 'app-single-schedule',
  templateUrl: './single-schedule.component.html',
  styleUrls: ['./single-schedule.component.scss'],
})
export class SingleScheduleComponent implements OnInit {
  state: 'view' | 'edit' = 'view'

  @Input() allScanners: ScannerFriendly[]
  @Input() allTasks: TaskFriendly[]

  @Input() schedule: Schedule
  @Input() new = false
  private originalSchedule: Schedule
  @Output() deleteEvent = new EventEmitter<number>()
  @Output() saveEvent = new EventEmitter<schedules_schedule>()

  constructor(private apollo: Apollo) {}

  tasks(schedule: schedules_schedule): Task[] {
    return [
      ...schedule.schedule_phantom_test_routines_phantom_test_routines,
      ...schedule.schedule_survey_test_routines_survey_test_routines,
    ]
  }

  separateTasksAndScanners() {
    const tasks: any[] = cloneDeep(this.schedule.tasks)
    this.schedule.tasks = tasks.filter((t) => !t.__typename)
    this.schedule.schedule_phantom_test_routines_phantom_test_routines = tasks.filter(
      (t) => t.__typename === 'phantom_test_routine',
    )
    this.schedule.schedule_survey_test_routines_survey_test_routines = tasks.filter(
      (t) => t.__typename === 'survey_test_routine',
    )
    console.log(this.schedule)
  }

  ngOnInit(): void {
    if (this.new) {
      this.state = 'edit'
    }
  }

  cancel() {
    if (this.new) {
      this.deleteEvent.emit(this.schedule.id)
    } else {
      this.schedule = cloneDeep(this.originalSchedule)
      this.state = 'view'
    }
  }

  edit() {
    this.originalSchedule = cloneDeep(this.schedule)
    this.state = 'edit'
  }

  async save() {
    if (!this.schedule) throw new MessageError('Schedule must be set')
    if (!this.schedule.title) throw new MessageError('Title must be set')
    if (this.schedule.scannersFriendly.length < 1)
      throw new MessageError('Must select at least one scanner')
    if (this.schedule.tasksFriendly.length < 1)
      throw new MessageError('Must select at least one task')
    if (!this.new) {
      // delete then insert is easier than update with all these many-to-many relationships
      await this.deleteMutation()
    }
    const insertScheduleVariables: insert_scheduleVariables = {
      schedule: this.transformScheduleForInsert(this.schedule),
    }
    this.apollo
      .mutate<insert_schedule>({
        mutation: insert_scheduleGql,
        variables: insertScheduleVariables,
      })
      .subscribe({
        next: (data) => {
          if (data.errors) {
            throw data.errors
          }
          this.schedule.id = data.data.insert_schedule.returning[0].id
          this.new = false
          this.state = 'view'
        },
      })
  }

  private async deleteMutation() {
    return new Promise<void>((resolve, reject) => {
      const variables: delete_schedule_by_pkVariables = {
        id: this.schedule.id,
      }
      this.apollo
        .mutate<delete_schedule_by_pk>({
          mutation: delete_scheduleGql,
          variables,
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              reject(result.errors)
            }
            resolve()
          },
          error: (err) => {
            reject(err)
          },
        })
    })
  }

  async delete() {
    if (window.confirm('Delete schedule: are you sure?')) {
      await this.deleteMutation()
    }
    this.deleteEvent.emit(this.schedule.id)
  }

  rruleNext(rruleStr: string) {
    let options: Partial<Options>
    try {
      options = rrule.parseString(rruleStr)
    } catch (err) {
      console.log(`invalid rrule: ${rruleStr}`)
      return ''
    }
    options.count = 1
    const rule = new rrule(options)
    const first = rule.all()[0]
    return moment(first).utc().format('ddd D MMM YYYY')
  }

  rruleLast(rruleStr: string) {}

  transformScheduleForInsert(schedule: Schedule): schedule_insert_input {
    // remember, schedule_scanners_scanners, schedule_phantom_test_routines_phantom_test_routines, and schedule_survey_test_routines_survey_test_routines get copied over to schedulesFriendly and tasksFriendly, so that's the real list. This function takes schedulesFriendly and tasksFriendly and turns them into the proper input type for insert

    const scanners = schedule.scannersFriendly
    const ptrs = schedule.tasksFriendly.filter(
      (t) => t.__typename === 'phantom_test_routine',
    )
    const strs = schedule.tasksFriendly.filter(
      (t) => t.__typename === 'survey_test_routine',
    )

    const phantomTestRoutineToInsertInput = (
      ptrs: TaskFriendly[],
    ): schedule_phantom_test_routines_phantom_test_routine_arr_rel_insert_input => ({
      data: ptrs.map((ptr) => ({ phantomTestRoutineId: ptr.id })),
    })
    const surveyTestRoutineToInsertInput = (
      strs: TaskFriendly[],
    ): schedule_survey_test_routines_survey_test_routine_arr_rel_insert_input => ({
      data: strs.map((str) => ({ surveyTestRoutineId: str.id })),
    })
    const scannerToInsertInput = (
      scrs: ScannerFriendly[],
    ): schedule_scanners_scanner_arr_rel_insert_input => ({
      data: scrs.map((scr) => ({ scannerId: scr.id })),
    })
    const cleaned: Partial<Schedule> = {
      ...schedule,
      scannersFriendly: undefined,
      tasksFriendly: undefined,
      new: undefined,
      __typename: undefined,
    }
    return {
      ...cleaned,
      schedule_phantom_test_routines_phantom_test_routines: phantomTestRoutineToInsertInput(
        ptrs,
      ),
      schedule_survey_test_routines_survey_test_routines: surveyTestRoutineToInsertInput(
        strs,
      ),
      schedule_scanners_scanners: scannerToInsertInput(scanners),
    }
  }
}
