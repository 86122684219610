import { Apollo, gql } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Injectable } from '@angular/core'

import { Router } from '@angular/router'
import { getTimezone } from './types/getTimezone'

const DEFAULT_TZ = 'utc'

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  cachedTz = DEFAULT_TZ
  constructor(private apollo: Apollo, private router: Router) {}

  async retrieveTzFromServer(): Promise<void> {
    this.apollo
      .query<getTimezone>({
        query: gql`
          query getTimezone {
            setting(where: { key: { _eq: "timezone" } }) {
              value
            }
          }
        `,
      })
      .subscribe({
        next: (result: ApolloQueryResult<getTimezone>) => {
          this.cachedTz = result.data?.setting[0]?.value || DEFAULT_TZ
        },
        error: (e) => {
          console.log(e)
          this.cachedTz = DEFAULT_TZ
        },
      })
  }

  setTz(tz: string) {
    this.cachedTz = tz
  }
}
