import { Component, OnInit, Input } from '@angular/core'
import { ClrDatagridFilter, ClrDatagridFilterInterface } from '@clr/angular'
import { Subject } from 'rxjs'
import { DevError } from 'src/app/core/errors/errors'
import { ExactNumberFilterInterface, FilterType } from '../filters.util'

@Component({
  selector: 'app-exact-number-filter',
  templateUrl: './exact-number-filter.component.html',
  styleUrls: ['./exact-number-filter.component.scss'],
})
export class ExactNumberFilterComponent
  implements
    OnInit,
    ClrDatagridFilterInterface<number>,
    ExactNumberFilterInterface {
  type: FilterType.exactNumber = FilterType.exactNumber
  changes = new Subject<number>()
  @Input() exact: number
  @Input() property: string

  constructor(private filterContainer: ClrDatagridFilter) {
    filterContainer.setFilter(this)
  }

  isActive(): boolean {
    return !!this.exact
  }

  accepts(value: number): boolean {
    if (value !== null && typeof value !== 'number') {
      throw new DevError('value not a number')
    }
    this.exact = value
    this.changes.next(this.exact)
    return true
  }

  ngOnInit() {}
}
