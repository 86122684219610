import { gql } from 'apollo-angular'

export default gql`
  query reportsDetails($id: Int!) {
    report_by_pk(id: $id) {
      stored_file {
        key
      }
      id
      survey_test {
        scanner {
          id
          name
          facility {
            id
            name
          }
          facility_room {
            id
            name
          }
        }
      }
      dateCreated
      survey_test {
        survey_test_routine {
          name
        }
      }
      month
      year
    }
  }
`
