import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
} from '@angular/core'
import { phantomTestDetails_phantom_test_by_pk } from '../phantom-test-details/types/phantomTestDetails'
import { TestDisplay, TableDisplay, Result } from 'common/aqsTypes'

export type ToggleState = 'expand' | 'collapse' | undefined

@Component({
  selector: 'app-generic-test-display',
  templateUrl: './generic-test-display.component.html',
  styleUrls: ['./generic-test-display.component.scss'],
})
export class GenericTestDisplayComponent implements OnInit {
  @Input() data: TestDisplay
  @Input() test: phantomTestDetails_phantom_test_by_pk
  private _stateChanges: EventEmitter<ToggleState>
  get stateChanges() {
    return this._stateChanges
  }
  @Input() set stateChanges(s: EventEmitter<ToggleState>) {
    this._stateChanges = s
    this.stateChanges.subscribe((change) => {
      this.onStateChange(change)
    })
  }
  expanded: boolean // state
  expandedTables: { [key: number]: boolean | undefined } = {}

  numberPipeString(decimalPlaces?: number) {
    return decimalPlaces !== undefined
      ? `1.${decimalPlaces}-${decimalPlaces}`
      : '1.0-2'
  }

  toggleExpandTable(num: number) {
    if (this.expandedTables[num] === undefined) {
      this.expandedTables[num] = false
    }
    this.expandedTables[num] = !this.expandedTables[num]
  }

  constructor() {}

  ngOnInit(): void {
    this.resetState()
  }

  ngOnDestroy(): void {
    this.stateChanges.unsubscribe()
  }

  resetState() {
    this.expanded = this.data.result !== Result.pass
  }

  calculateNumColumns = (display: TableDisplay): number =>
    Math.max(...display.body.map((arr) => arr.length))

  isNumber = (val: unknown): val is number => typeof val === 'number'
  isString = (val: unknown): val is string => typeof val === 'string'

  toggle() {
    this.expanded = !this.expanded
  }

  onStateChange(state: ToggleState) {
    switch (state) {
      case 'expand':
        this.expanded = true
        break
      case 'collapse':
        this.expanded = false
        break
      case undefined:
        this.resetState()
        break
    }
  }
}
