import { gql } from 'apollo-angular'

export default gql`
  query scanner_get_related_entities {
    facility(order_by: { name: asc }) {
      id
      name
    }

    modality(order_by: { id: asc }) {
      id
    }

    facility_room(order_by: { name: asc }) {
      id
      facilityId
      name
    }

    scanner_manufacturer(order_by: { name: asc }) {
      id
      name
    }

    scanner_model(order_by: { name: asc }) {
      id
      name
    }
  }
`
