<div class="">
  <form clrForm>
    <clr-input-container>
      <label>Name</label>
      <input
        clrInput
        name="Name"
        [ngModel]="getModel().name"
        (ngModelChange)="setModel('name', $event)"
      />
    </clr-input-container>

    <clr-select-container>
      <label>Color</label>
      <select
        clrSelect
        name="Color"
        [ngModel]="getModel().color"
        (ngModelChange)="setModel('color', $event)"
      >
        <option
          *ngFor="let key of colorKeys"
          [ngStyle]="{ color: colors[key] }"
          value="{{ key }}"
          >{{ key }}</option
        >
      </select>
    </clr-select-container>
  </form>

  <button (click)="save()" class="btn btn-success-outline">Save</button>
  <button (click)="back()" class="btn btn-outline">Back</button>
</div>
