import { gql } from 'apollo-angular'

export default gql`
  query schedules {
    schedule {
      dateCreated
      graceAmount
      graceType
      id
      interval
      priorToAmount
      priorToType
      reminderAmount
      reminderType
      tasks
      title
      schedule_phantom_test_routines_phantom_test_routines {
        phantom_test_routine {
          id
          name
          modalityId
        }
      }
      schedule_scanners_scanners {
        scanner {
          id
          name
          facility_room {
            name
          }
        }
      }
      schedule_survey_test_routines_survey_test_routines {
        survey_test_routine {
          id
          modalityId
          name
        }
      }
    }
  }
`
