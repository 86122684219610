import { gql } from 'apollo-angular'

export default gql`
  query trendsScannerDetails($scannerIds: [Int!]!) {
    scanner(where: { id: { _in: $scannerIds } }) {
      id
      facility {
        name
      }
      facility_room {
        name
      }
      name
      scanner_phantom_test_routines_phantom_test_routines {
        phantom_test_routine {
          name
          id
          phantom
          customValues
          phantom_tests(limit: 1, order_by: { dateCreated: desc }) {
            rawOutput
          }
          phantom_tests_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      survey_tests {
        dateCreated
        answered_survey_test_questions {
          answer
          recordId
          result
          text
        }
        survey_test_routine {
          id
          name
          survey_test_routine_questions_survey_test_questions {
            survey_test_question {
              min
              max
              expected
              text
              type
            }
          }
        }
      }
      phantom_tests(
        order_by: [{ study: { scanDate: asc } }, { dateCreated: asc }]
      ) {
        id
        dateCreated
        phantom_test_routine {
          id
          name
          phantom
          customValues
        }
        evaluationItems
        study {
          scanDate
        }
        rawOutput
      }
    }
  }
`
