import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { Result } from 'common/aqsTypes'
import { DatagridStateService } from 'src/app/core/datagrid-state.service'
import { NavService } from 'src/app/core/nav.service'
import { DatagridComponent } from 'src/app/util/datagrid.class'
import { TimezoneService } from 'src/app/timezone.service'
import { KeyVal, userFullname } from '../../util/util'
import { surveyTestsDetails_survey_test_by_pk } from '../survey-tests-details/types/surveyTestsDetails'
import survey_test_get_related_entitiesGql from '../survey-tests-edit/survey_test_get_related_entities.gql'
import { survey_test_get_related_entities } from '../survey-tests-edit/types/survey_test_get_related_entities'
import query from './survey-tests.gql'
import {
  surveyTests,
  surveyTests_survey_test,
  surveyTests_survey_test_routine,
} from './types/surveyTests'

@Component({
  selector: 'app-survey-tests',
  templateUrl: './survey-tests.component.html',
  styleUrls: ['./survey-tests.component.scss'],
})
export class SurveyTestsComponent extends DatagridComponent {
  title = 'surveys'

  getUserFullname = userFullname

  static properties = {
    facility: { filter: 'scanner.facility.id', sort: 'scanner.facility.name' },
    facility_room: {
      filter: 'scanner.facility_room.id',
      sort: 'scanner.facility_room.name',
    },
    modality: 'scanner.modality.id',
    scanner: { filter: 'scanner.id', sort: 'scanner.name' },
    dateCreated: 'dateCreated',
    survey: {
      filter: 'survey_test_routine.id',
      sort: 'survey_test_routine.name',
    },
    result: 'result',
    user: { sort: 'user.lastName', filter: 'user.id' },
  }
  properties = SurveyTestsComponent.properties

  constructor(
    private apollo: Apollo,
    private nav: NavService,
    protected router: Router,
    protected dgStateService: DatagridStateService,
    protected timezoneService: TimezoneService,
  ) {
    super(dgStateService, router, timezoneService)
    this.defaultState = {
      sort: {
        by: this.properties.dateCreated,
        reverse: true,
      },
    }
  }

  items: surveyTests_survey_test[]
  surveyTestRoutines: surveyTests_survey_test_routine[]
  totalItems: number

  fetchItems = () => {
    this.loading = true
    this.apollo
      .query<surveyTests>({ query, variables: this.variables })
      .subscribe({
        next: (result: ApolloQueryResult<surveyTests>) => {
          this.loading = false
          if (result.errors) {
            throw result.errors[0]
          }
          this.items = result.data.survey_test
          this.surveyTestRoutines = result.data.survey_test_routine
          this.totalItems = result.data.survey_test_aggregate.aggregate.count
        },
        error: this.onError,
      })
    this.apollo
      .query<survey_test_get_related_entities>({
        query: survey_test_get_related_entitiesGql,
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.assembleRelatedEntityOptions(result.data)
        },
        error: this.onError,
      })
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  options: {
    facilities: KeyVal[]
    rooms: KeyVal[]
    modalities: KeyVal[]
    scanners: KeyVal[]
    surveys: KeyVal[]
    results: KeyVal[]
    users: KeyVal[]
  } = {
    facilities: [],
    rooms: [],
    modalities: [],
    scanners: [],
    surveys: [],
    results: [],
    users: [],
  }

  assembleRelatedEntityOptions(data: survey_test_get_related_entities) {
    this.options = {
      facilities: data.facility.map((f) => ({
        key: f.id,
        val: f.name,
      })),
      rooms: data.facility_room.map((r) => ({
        key: r.id,
        val: r.name,
      })),
      modalities: data.modality.map((m) => ({
        key: m.id,
        val: m.id,
      })),
      scanners: data.scanner.map((s) => ({
        key: s.id,
        val: s.name,
      })),
      surveys: data.survey_test_routine.map((s) => ({
        key: s.id,
        val: s.name,
      })),
      results: Object.values(Result).map((r): KeyVal => ({ key: r, val: r })),
      users: data.user.map((u) => ({
        key: u.id,
        val: this.getUserFullname(u),
      })),
    }
  }

  view(test: surveyTestsDetails_survey_test_by_pk) {
    this.nav.navtree.surveyTestDetails(test.id)
  }

  runRoutine(routine: surveyTests_survey_test_routine) {
    this.nav.navtree.survey(routine.id)
  }
}
