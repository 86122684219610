import { gql } from 'apollo-angular'

export default gql`
  query usersDetails($id: Int!) {
    user_by_pk(id: $id) {
      id
      accountType
      firstName
      lastName
      username
      role
      email
      phone
      status
      facility {
        name
      }
      facilityId
      restrictedIP
    }
  }
`
