import { gql } from 'apollo-angular'

export default gql`
  query phantomTestDetails($id: Int!) {
    phantom_test_by_pk(id: $id) {
      id
      result
      phantom_test_images(order_by: { label: asc }) {
        id
        label
        stored_file {
          key
        }
      }
      scanner {
        id
        name
        extra
        scanner_model {
          id
          name
        }
        facility_room {
          name
          id
        }
        facility {
          name
          id
        }
      }
      study {
        id
        scanDate
      }
      dateCreated
      user {
        firstName
        lastName
      }
      evaluationItems
      rawOutput
      phantom_test_routine {
        id
        method
        name
        phantom
        customValues
      }
      answered_phantom_test_questions {
        answer
        result
        text
      }
    }
  }
`
