import { PercentPipe } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { YearDataByMonth } from 'src/app/metrics.service'

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss'],
  providers: [PercentPipe],
})
export class BarComponent implements OnInit {
  @Input() yearDataByMonth: YearDataByMonth[]
  @Input() display: 'absolute' | 'percent'
  chartOptions: any

  constructor(private percentPipe: PercentPipe) {}

  ngOnInit(): void {
    const xValues = this.yearDataByMonth.map((d) => d.label)
    const yValues = this.yearDataByMonth.map((d) => d.value)

    this.chartOptions = {
      grid: {
        left: 40,
        top: 10,
        right: 40,
        bottom: 20,
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'hsl(0, 0%, 10%)' /* var(--aqs-bg-color) */,
        textStyle: {
          color: 'white',
        },
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params) => {
          const series = params[0]
          const value =
            this.display === 'absolute'
              ? series.value
              : this.percentPipe.transform(series.value) ?? '0%'
          return `${series.marker} ${value}`
        },
      },
      xAxis: {
        data: xValues,
        type: 'category',
        axisLabel: {
          inside: false,
          textStyle: {
            color: '#fff',
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        z: 10,
      },
      yAxis: {
        splitLine: {
          lineStyle: {
            color: '#999',
            type: 'solid',
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          formatter: (value) =>
            this.display === 'absolute'
              ? value
              : this.percentPipe.transform(value),
          textStyle: {
            color: '#fff',
          },
        },
      },

      series: [
        {
          type: 'bar',
          showBackground: true,
          itemStyle: {
            color: '#4aaed9',
          },
          emphasis: {
            itemStyle: {
              color: '#188df0',
            },
          },
          data: yValues,
        },
      ],
    }
  }
}
