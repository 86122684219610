import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { AfterViewInit, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { UserRole, UserStatus } from 'common/aqsTypes'
import { cloneDeep } from 'lodash'
import { order_by } from 'src/types/graphql-global-types'
import { NavService } from 'src/app/core/nav.service'
import { DatagridStateService } from 'src/app/core/datagrid-state.service'
import { DatagridComponent } from 'src/app/util/datagrid.class'
import { TimezoneService } from 'src/app/timezone.service'
import { safeGet, KeyVal } from '../../util/util'
import { PageHeaderAction } from '../page-header/page-header.component'
import { user_get_related_entities } from '../users-edit/types/user_get_related_entities'
import user_get_related_entitiesGql from '../users-edit/user_get_related_entities.gql'
import { users, usersVariables, users_user } from './types/users'
import query from './users.gql'

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent extends DatagridComponent {
  title = 'users'

  actions: PageHeaderAction[] = [{ text: 'Create User' }]

  creatingUser = false

  static properties = {
    firstName: 'firstName',
    lastName: 'lastName',
    username: 'username',
    role: 'role',
    email: 'email',
    phone: 'phone',
    status: 'status',
    facility: { filter: 'facility.id', sort: 'facility.name' },
    accountType: 'accountType',
  }
  properties = UsersComponent.properties

  constructor(
    private apollo: Apollo,
    private nav: NavService,
    protected router: Router,
    protected dgStateService: DatagridStateService,
    protected timezoneService: TimezoneService,
  ) {
    super(dgStateService, router, timezoneService)
    // add default if necessary
    this.defaultState = {
      sort: {
        by: this.properties.lastName,
        reverse: false,
      },
    }
  }

  actionsHandler($event: string) {
    switch ($event) {
      case 'Create User':
        this.creatingUser = true
        break
      default:
        break
    }
  }

  items: users_user[]
  totalItems: number

  fetchItems = () => {
    this.loading = true
    this.apollo
      .query<users>({ query, variables: this.variables })
      .subscribe({
        next: (result: ApolloQueryResult<users>) => {
          this.loading = false
          if (result.errors) {
            throw result.errors[0]
          }
          this.items = result.data.user
          this.totalItems = result.data.user_aggregate.aggregate.count
        },
        error: this.onError,
      })
    this.apollo
      .query<user_get_related_entities>({ query: user_get_related_entitiesGql })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.assembleRelatedEntityOptions(result.data)
        },
        error: this.onError,
      })
  }

  onUpdate($event: string) {
    switch ($event) {
      case 'delete':
      case 'edit':
        this.fetchItems()
        break
      case 'create':
      case 'back':
        this.creatingUser = false
        this.fetchItems()
        break
      default:
        break
    }
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  options: {
    roles: KeyVal[]
    statuses: KeyVal[]
    facilities: KeyVal[]
  } = {
    roles: [],
    statuses: [],
    facilities: [],
  }

  assembleRelatedEntityOptions(data: user_get_related_entities) {
    this.options = {
      facilities: data.facility.map((f) => ({
        key: f.id,
        val: f.name,
      })),
      roles: Object.values(UserRole).map(
        (r): KeyVal => ({
          key: r,
          val: r,
        }),
      ),
      statuses: Object.values(UserStatus).map(
        (r): KeyVal => ({
          key: r,
          val: r,
        }),
      ),
    }
  }

  view(user: users_user) {
    this.nav.navtree.userDetails(user.id)
  }
}
