import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'
import exportPhantomTestsGql from './home/phantom-tests/export-phantom-tests.gql'
import {
  exportPhantomTests,
  exportPhantomTestsVariables,
  exportPhantomTests_firstTest,
} from './home/phantom-tests/types/exportPhantomTests'
import { TimezoneService } from './timezone.service'
import moment from 'moment-timezone'
import { FileService } from './core/file.service'

@Injectable({
  providedIn: 'root',
})
export class ExportTestsService {
  constructor(
    private apollo: Apollo,
    private timezoneService: TimezoneService,
    private fileService: FileService,
  ) {}
  generateFilename(
    firstTest: exportPhantomTests_firstTest | undefined,
  ): string {
    const timestamp = moment.tz(this.timezoneService.cachedTz).toISOString()
    let filename = `aqs_data_export_${timestamp}`
    if (firstTest) {
      const scannerName = firstTest.scanner?.name
      const roomName = firstTest.scanner?.facility_room?.name
      if (scannerName) filename = `${scannerName}_${filename}`
      if (roomName) filename = `${roomName}_${filename}`
    }
    return filename
  }

  export(variables: exportPhantomTestsVariables) {
    return new Promise<void>((resolve, reject) => {
      this.apollo
        .query<exportPhantomTests>({
          query: exportPhantomTestsGql,
          variables,
        })
        .subscribe({
          next: (result: ApolloQueryResult<exportPhantomTests>) => {
            if (result.error) {
              reject(result.error)
              return
            }
            if (result.errors) {
              reject(result.errors[0])
              return
            }
            const tests = result.data.phantom_test
            // now assemble and export thze data
            let firstTest: exportPhantomTests_firstTest | undefined
            if (variables.where?.scannerId !== undefined) {
              firstTest = result.data.firstTest[0]
            }
            const filename = this.generateFilename(firstTest)
            const jsonData = tests
            this.fileService.writeStringToFileAndDownload(
              JSON.stringify(jsonData, undefined, 2),
              filename + '.json',
              'text/json',
            )
            resolve()
          },
          error: (e) => {
            reject(e)
          },
        })
    })
  }
}
