<ng-container *ngIf="images; else loading">
  <div *ngIf="!dev()" id="images">
    <img
      *ngFor="let image of images; let i = index"
      [src]="image.url"
      (click)="openGallery(i)"
    />
  </div>
  <ngx-image-gallery
    [images]="images"
    [conf]="conf"
    (onOpen)="galleryOpened($event)"
    (onClose)="galleryClosed()"
    (onImageClicked)="galleryImageClicked($event)"
    (onImageChange)="galleryImageChanged($event)"
    (onDelete)="deleteImage($event)"
  ></ngx-image-gallery>
</ng-container>

<ng-template #loading>loading images...</ng-template>
