<div class="clr-row" appPageHeader [title]="title"></div>

<div class="clr-row">
  <div class="clr-col-xl-6">
    <!-- Timezone -->
    <div appSbox title="Timezone" (save)="save('timezone', $event)">
      <code>System time is <app-clock [tz]="getTz()"></app-clock></code>
      <br />
      <code
        >&nbsp;Local time is <app-clock [tz]="getUserTz()"></app-clock>
      </code>
      <clr-select-container>
        <label> set system time</label>
        <select clrSelect [(ngModel)]="settings.timezone" name="timezone">
          <option *ngFor="let timezone of timezones" [ngValue]="timezone">{{
            timezone
          }}</option>
        </select>
      </clr-select-container>
    </div>

    <!-- IP Whitelist -->
    <div appSbox title="Login IP Whitelist" (save)="saveIPWhitelist($event)">
      Enter IP addresses or hostnames. <br />
      Use wildcards to specify
      <a class="link" (click)="showIPModal = true">ranges.</a> <br />
      Examples: <code>192.168.1.*</code> or <code>*.domain.com</code> <br />
      Press enter to add.
      <tag-input
        #taginput
        [(ngModel)]="settings.ipWhitelist"
        [modelAsStrings]="true"
        [theme]="'clr-theme'"
        [placeholder]="'+ add another'"
        [secondaryPlaceholder]="'Enter an IP or Host'"
      ></tag-input>
    </div>

    <!-- SSO Domains -->
    <div appSbox title="Single-Sign-On" (save)="saveSSO($event)">
      To enable Single-Sign-On, type in a domain. <br />
      Example: if your work email is <code>jane.smith@hospital.com</code>, type
      <code class="pass">hospital.com</code>. <br />Everyone with an
      <code>@hospital.com</code> email address will be able to log in to AQS
      with their account.<br />
      Press enter to add.
      <tag-input
        #ssoTaginput
        [(ngModel)]="settings.ssoDomains"
        [modelAsStrings]="true"
        [theme]="'clr-theme'"
        [placeholder]="'+ add another'"
        [secondaryPlaceholder]="'Enter a domain'"
      ></tag-input>
    </div>

    <!-- Support Contact -->
    <div appSbox title="Support Contacts" (save)="saveSupportContact($event)">
      These emails will be notified when test failures or other problems occur
      anywhere within your organization. You can also add support contacts for
      individual facilities and scanners.
      <br />
      Press enter to add.
      <tag-input
        #supportContactTaginput
        [(ngModel)]="settings.supportContact"
        [modelAsStrings]="true"
        [theme]="'clr-theme'"
        [placeholder]="'+ add another'"
        [secondaryPlaceholder]="'Enter an email'"
      ></tag-input>
    </div>

    <!-- Scanner Naming -->
    <div
      appSbox
      title="Scanner Naming Convention"
      (save)="save('scannerNamingConvention', $event)"
    >
      <clr-radio-wrapper>
        <input
          type="radio"
          clrRadio
          [(ngModel)]="settings.scannerNamingConvention"
          value="StationName"
          name="options"
        />
        <label>StationName: <code>Room 1</code></label>
      </clr-radio-wrapper>
      <clr-radio-wrapper>
        <input
          type="radio"
          clrRadio
          [(ngModel)]="settings.scannerNamingConvention"
          value="InstitutionNameStationName"
          name="options"
        />
        <label
          >InstitutionName/StationName: <code>Hillcrest/Room 1</code></label
        >
      </clr-radio-wrapper>
    </div>

    <!-- Features -->
    <div
      appSbox
      title="Enable/Disable New Features"
      (save)="save('features', $event)"
    >
      <clr-toggle-container>
        <clr-toggle-wrapper>
          <input
            type="checkbox"
            clrToggle
            [(ngModel)]="settings.features.dicomViewer"
          />
          <label>Dicom Image Viewer</label>
        </clr-toggle-wrapper>
      </clr-toggle-container>
    </div>
  </div>
</div>

<!-- IP Modal -->
<clr-modal [(clrModalOpen)]="showIPModal">
  <h3 class="modal-title">IP Address / Domain Ranges</h3>
  <div class="modal-body">
    <p>
      You can use brackets for single characters. Example:
      <code class="pass">192.168.1.13[0-9]</code> or
      <code class="pass">192.168.1.[0-2][0-9][0-9]</code> but not
      <code class="fail">192.168.1.[0-255]</code>
    </p>
    <p>
      You can also specify CIDR subnets, but cannot mix and match. Example:
      <code class="pass">192.168.1.134/26</code> or
      <code class="pass">192.168.1.1*</code> but not
      <code class="fail">192.168.1.1*/26</code> or
      <code class="fail">192.168.1.13[0-9]/26</code>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="showIPModal = false">
      Ok
    </button>
  </div>
</clr-modal>
