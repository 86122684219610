import { gql } from 'apollo-angular'

export default gql`
  mutation insertSetting($key: String!, $value: String, $json: jsonb) {
    insert_setting(objects: { key: $key, value: $value, json: $json }) {
      affected_rows
      returning {
        key
        value
      }
    }
  }
`
