import { Apollo } from 'apollo-angular'
import { EventEmitter, Injectable } from '@angular/core'
import { ClrLoadingState } from '@clr/angular'

import { AuthService } from '../auth/auth.service'
import associate_s_ptrGql from '../home/studies-details/associate_s_ptr.gql'
import evaluateTestGql from '../home/studies-details/evaluateTest.gql'
import testStudyGql from '../home/studies-details/testStudy.gql'
import {
  associate_s_ptr,
  associate_s_ptrVariables,
} from '../home/studies-details/types/associate_s_ptr'
import { evaluateTest } from '../home/studies-details/types/evaluateTest'
import {
  studiesDetails_study_by_pk_modality_phantom_test_routines,
  studiesDetails_study_by_pk_modality_phantom_test_routines_phantom_test_routine_questions_phantom_test_questions_phantom_test_question as question,
} from '../home/studies-details/types/studiesDetails'
import { testStudy } from '../home/studies-details/types/testStudy'
import { CommService } from './comm.service'

export interface TestStudyState {
  routineSelected: number | undefined
  unevaluatedTestArrived: number | undefined
  answersSubmitted: boolean
  submitButton: ClrLoadingState
  questions: question[]
  answers: Answers
}

export interface PhantomTestingEvent {
  studyId: number
  testId: number
  type: 'image analysis complete' | 'test evaluation complete'
}

interface Answers {
  [questionId: number]: string | number
}

@Injectable({
  providedIn: 'root',
})
export class PhantomTestingService {
  events = new EventEmitter<PhantomTestingEvent>()

  state: {
    [studyId: number]: TestStudyState
  } = {}

  constructor(
    private auth: AuthService,
    private apollo: Apollo,
    private comm: CommService,
  ) {}

  testStudy(studyId: number, routineId: number) {
    const state = this.state[studyId]
    state.routineSelected = routineId
    const user = this.auth.user
    if (!user) {
      return
    }
    const variables = {
      studyId,
      userId: user.id,
      routineId,
    }
    const mutation = testStudyGql
    this.apollo
      .mutate<testStudy>({
        mutation,
        variables,
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            this.resetState(studyId)
            throw result.errors[0]
          }
          console.log('unevaluated test came in')
          state.unevaluatedTestArrived = result.data.testStudy.id
          const testId = state.unevaluatedTestArrived
          this.events.emit({
            studyId,
            testId,
            type: 'image analysis complete',
          })
          if (!this.onStudyDetailsPage()) {
            this.comm.alert(
              'success',
              `Test complete`,
              -1,
              `/home/phantomTests/${testId}`,
            )
          }
          if (state.answersSubmitted) {
            this.evaluateTest(studyId)
          }
        },
        error: (err) => {
          this.resetState(studyId)
          throw err
        },
      })
  }

  onStudyDetailsPage() {
    const studyDetailsRegex = /\/home\/studies\/\d+/gm
    return studyDetailsRegex.test(window.location.pathname)
  }

  associate(routineId: number, scannerId: number) {
    const variables: associate_s_ptrVariables = {
      routineId,
      scannerId,
    }
    const mutation = associate_s_ptrGql
    this.apollo
      .mutate<associate_s_ptr>({
        mutation,
        variables,
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            console.warn(
              `associating scanner ${variables.scannerId} and routine ${variables.routineId} failed`,
            )
          }
        },
        error: (err) => {
          console.warn(
            `associating scanner ${variables.scannerId} and routine ${variables.routineId} failed`,
          )
        },
      })
  }

  allQuestionsAnswered(studyId: number) {
    return (
      Object.values(this.state[studyId].answers).filter(
        (v) => v !== undefined && v !== null,
      ).length === this.state[studyId].questions.length
    )
  }

  submitQuestions(studyId: number) {
    const state = this.state[studyId]
    if (this.allQuestionsAnswered(studyId)) {
      state.submitButton = ClrLoadingState.LOADING
      state.answersSubmitted = true
      if (state.unevaluatedTestArrived) {
        return this.evaluateTest(studyId)
      }
    }
    return undefined
  }

  evaluateTest(studyId: number) {
    const state = this.state[studyId]
    const phantomTestId = state.unevaluatedTestArrived
    const answers = JSON.stringify(state.answers)
    this.apollo
      .mutate<evaluateTest>({
        mutation: evaluateTestGql,
        variables: { phantomTestId, answers },
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            this.resetState(studyId)
            throw result.errors[0]
          }
          state.submitButton = ClrLoadingState.SUCCESS
          this.events.emit({
            studyId,
            testId: result.data.evaluateTest.id,
            type: 'test evaluation complete',
          })
        },
        error: (err) => {
          this.resetState(studyId)
          throw err
        },
      })
  }

  resetState(studyId: number, initialState?: TestStudyState) {
    this.state[studyId] = initialState || {
      routineSelected: undefined,
      unevaluatedTestArrived: undefined,
      answersSubmitted: false,
      submitButton: ClrLoadingState.DEFAULT,
      questions: undefined,
      answers: {},
    }
  }

  assembleQuestions(
    studyId: number,
    routineId: number,
    routines: studiesDetails_study_by_pk_modality_phantom_test_routines[],
  ) {
    this.state[studyId].questions = routines
      .find((routine) => routine.id === routineId)
      .phantom_test_routine_questions_phantom_test_questions.map(
        (manyToManyRel) => manyToManyRel.phantom_test_question,
      )
  }
}
