import { Component, OnInit, Input } from '@angular/core'
import { firstItemUpper } from 'src/app/util/util'
import { Router } from '@angular/router'
import { studiesDetails_study_by_pk_phantom_tests } from '../types/studiesDetails'

@Component({
  selector: 'app-recent-tests',
  templateUrl: './recent-tests.component.html',
  styleUrls: ['./recent-tests.component.scss'],
})
export class RecentTestsComponent implements OnInit {
  @Input() tests: studiesDetails_study_by_pk_phantom_tests[]

  constructor(private router: Router) {}

  ngOnInit() {}

  tester(test: studiesDetails_study_by_pk_phantom_tests) {
    const firstName = firstItemUpper(test.user.firstName)
    const lastName = firstItemUpper(test.user.lastName)
    return `${firstName} ${lastName}`
  }

  view(test: studiesDetails_study_by_pk_phantom_tests) {
    this.router.navigate([`/home/phantomTests/${test.id}`])
  }
}
