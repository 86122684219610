<!-- title -->
<h4 *ngIf="data.title" class="{{ data.result }}">
  {{ data.title }}
  <a (click)="toggle()">
    <clr-icon shape="caret" dir="{{ expanded ? 'down' : 'right' }}"></clr-icon>
  </a>
</h4>

<div class="{{ expanded ? '' : 'contracted' }}">
  <!-- displayed values -->
  <ng-container *ngIf="data.display">
    <table *ngFor="let display of data.display; let i = index" class="table">
      <thead *ngIf="display.head">
        <th
          class="title"
          *ngFor="let th of display.head"
          [attr.colspan]="
            display.head.length === 1 ? calculateNumColumns(display) : 1
          "
        >
          {{ th | humanReadable }}
          <a *ngIf="display.collapsed" (click)="toggleExpandTable(i)">
            <clr-icon
              shape="caret"
              dir="{{ expandedTables[i] ? 'down' : 'right' }}"
            ></clr-icon>
          </a>
        </th>
      </thead>
      <tbody
        *ngIf="display.body || display.bodyWithDecimalConfig"
        class="{{
          expandedTables[i] || !display.collapsed ? '' : 'contracted'
        }}"
      >
        <tr *ngFor="let row of display.body">
          <td class="left" *ngFor="let td of row">
            <ng-container *ngIf="isNumber(td)">
              {{ td | number: '1.0-1' }}
            </ng-container>
            <ng-container *ngIf="!isNumber(td)">
              {{ td | humanReadable }}
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <!-- evaluated values -->
  <table
    class="table"
    *ngIf="data.evaluatedNumbers && data.evaluatedNumbers.length > 0"
  >
    <thead>
      <th class="title left">Measurement</th>
      <th class="title left" colspan="2">Min</th>
      <th class="title left">Actual</th>
      <th class="title left" colspan="2">Max</th>
      <th class="title left">Result</th>
    </thead>
    <tbody>
      <tr *ngFor="let num of data.evaluatedNumbers">
        <td class="left">{{ num.title | humanReadable }}</td>
        <td
          class="left"
          appCustomLimit
          [decimalPlaces]="num.decimalPlaces"
          [test]="test"
          [currentLimit]="num.low"
          limitKey="{{ num.limitKey }}.low"
          [attr.colspan]="num.problemLow === undefined ? 2 : 1"
        ></td>
        <td
          class="left"
          appCustomLimit
          [decimalPlaces]="num.decimalPlaces"
          [test]="test"
          [currentLimit]="num.problemLow"
          limitKey="{{ num.limitKey }}.problemLow"
          *ngIf="num.problemLow !== undefined"
        ></td>
        <td class="{{ num.result }} left">
          {{ num.actual | number: numberPipeString(num.decimalPlaces) }}
        </td>
        <td
          class="left"
          appCustomLimit
          [decimalPlaces]="num.decimalPlaces"
          [test]="test"
          [currentLimit]="num.problemHigh"
          limitKey="{{ num.limitKey }}.problemHigh"
          *ngIf="num.problemHigh !== undefined"
        ></td>
        <td
          class="left"
          appCustomLimit
          [decimalPlaces]="num.decimalPlaces"
          [test]="test"
          [currentLimit]="num.high"
          limitKey="{{ num.limitKey }}.high"
          [attr.colspan]="num.problemHigh === undefined ? 2 : 1"
        ></td>
        <td class="{{ num.result }} left">{{ num.result }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="indent" *ngIf="data.children">
  <!-- children -->
  <app-generic-test-display
    *ngFor="let child of data.children"
    [data]="child"
    [test]="test"
    [stateChanges]="stateChanges"
  ></app-generic-test-display>
</div>
