<ng-container *ngIf="!ie && !edge">
  <app-alert></app-alert>
  <app-progress-bar></app-progress-bar>
  <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="ie || edge">
  <div class="border">
    <img class="logo" src="../../assets/aqs-logo-white.svg" alt="" />
    <section class="title">
      <h3 class="welcome">Welcome to</h3>
      Advanced Quality Systems
      <p>
        AQS uses features that are not compatible with Internet Explorer or
        Microsoft Edge versions 18 and below. AQS works best with Google Chrome,
        Mozilla Firefox, or Microsoft Edge version 79 or above.
      </p>
    </section>
  </div>
</ng-container>
