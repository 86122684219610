import { gql } from 'apollo-angular'

export default gql`
  mutation updateFacilitySupportContact($facilityId: Int!, $contacts: jsonb!) {
    update_facility(
      where: { id: { _eq: $facilityId } }
      _set: { supportContacts: $contacts }
    ) {
      returning {
        supportContacts
      }
    }
  }
`
