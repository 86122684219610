import { gql } from 'apollo-angular'

export default gql`
  query scanners(
    $distinct_on: [scanner_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [scanner_order_by!]
    $where: scanner_bool_exp
  ) {
    scanner(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      facility {
        name
      }
      modality {
        id
      }
      facility_room {
        name
      }
      name
      scanner_manufacturer {
        name
      }
      scanner_model {
        name
      }
    }
    scanner_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    scanner_manufacturer {
      name
    }
    scanner_model {
      name
    }
  }
`
