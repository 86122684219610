import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-icon-legend',
  templateUrl: './icon-legend.component.html',
  styleUrls: ['./icon-legend.component.scss'],
})
export class IconLegendComponent implements OnInit {
  sections: Array<{
    colspan: number
    title: string
    icons: Array<{
      label: string
      path: string
    }>
  }> = [
    {
      colspan: 3,
      title: 'No test done',
      icons: [
        {
          label: 'no study',
          path: 'assets/icons/grey/grey-blank-small.png',
        },
        {
          label: 'study',
          path: 'assets/icons/grey/study-small.png',
        },
      ],
    },
    {
      colspan: 5,
      title: 'Test status',
      icons: [
        {
          label: 'pass',
          path: 'assets/icons/green/green-blank-small.png',
        },
        {
          label: 'problem',
          path: 'assets/icons/orange/orange-blank-small.png',
        },
        {
          label: 'fail',
          path: 'assets/icons/red/red-blank-small.png',
        },
        {
          label: 'missed',
          path: 'assets/icons/purple/purple-blank-small.png',
        },
      ],
    },
    {
      colspan: 2,
      title: 'Comments',
      icons: [
        {
          label: 'commented',
          path: 'assets/icons/purple/purple-tick-small.png',
        },
        {
          label: 'no comment',
          path: 'assets/icons/purple/purple-exclamation-small.png',
        },
      ],
    },
  ]
  constructor() {}

  ngOnInit() {}
}
