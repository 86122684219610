import { gql } from 'apollo-angular'

export default gql`
  mutation updateScannerSchedule(
    $scannerId: Int!
    $schedule: String!
    $scheduleGrace: Int!
  ) {
    update_scanner(
      where: { id: { _eq: $scannerId } }
      _set: { schedule: $schedule, scheduleGrace: $scheduleGrace }
    ) {
      returning {
        schedule
        scheduleGrace
      }
    }
  }
`
