import { Pipe, PipeTransform } from '@angular/core'
import rrule from 'rrule'

@Pipe({
  name: 'rruleText',
})
export class RruleTextPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    try {
      const opts = rrule.parseString(value)
      const rule = new rrule(opts)
      return rule.toText()
    } catch (e) {
      return 'invalid rrule'
    }
  }
}
