import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ApolloQueryResult } from '@apollo/client/core'
import { Apollo, gql } from 'apollo-angular'
import { AuthService } from '../auth/auth.service'
import { checkSso } from './types/checkSso'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: {
    username: string
    password: string
  } = {
    username: '',
    password: '',
  }

  returnUrl: string

  error: Error

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private apollo: Apollo,
  ) {}

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/'
  }

  msSignIn() {
    this.auth.msalLogin(this.returnUrl)
  }
  async onSubmit() {
    try {
      await this.auth.login(this.form, this.returnUrl)
    } catch (err) {
      this.error = err
    }
  }
}
