import { Apollo } from 'apollo-angular'
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  ElementRef,
  HostListener,
} from '@angular/core'
import { AuthService } from 'src/app/auth/auth.service'

import { Router } from '@angular/router'
import { ClrForm } from '@clr/angular'
import {
  PhantomTestingService,
  TestStudyState,
  PhantomTestingEvent,
} from 'src/app/core/phantom-testing.service'
import { Subscription } from 'rxjs'
import {
  studiesDetails,
  studiesDetails_study_by_pk_modality_phantom_test_routines_phantom_test_routine_questions_phantom_test_questions_phantom_test_question as question,
} from '../types/studiesDetails'

@Component({
  selector: 'app-perform-test',
  templateUrl: './perform-test.component.html',
  styleUrls: ['./perform-test.component.scss'],
})
export class PerformTestComponent implements OnInit, OnDestroy {
  @Input() data: studiesDetails

  @Input() initialState: TestStudyState

  studyId: number

  @ViewChild(ClrForm) clrForm: ClrForm

  highlightedQuestionIndex: number | undefined
  get highlightedQuestion() {
    return this.state.questions[this.highlightedQuestionIndex]
  }

  @HostListener('document:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.state.routineSelected && !this.state.answersSubmitted) {
      switch (event.key) {
        case 'y':
          if (this.highlightedQuestion.type === 'PhantomTestYesNoQuestion') {
            this.state.answers[this.highlightedQuestion.id] = 'yes'
            this.highlightNextQuestion()
          }
          break
        case 'n':
          if (this.highlightedQuestion.type === 'PhantomTestYesNoQuestion') {
            this.state.answers[this.highlightedQuestion.id] = 'no'
            this.highlightNextQuestion()
          }
          break
        case 'ArrowDown':
          this.highlightNextQuestion()
          this.focusInput()
          break
        case 'ArrowUp':
          this.highlightPreviousQuestion()
          this.focusInput()
          break
        case 'Enter':
          this.submitQuestions()
          break
        default:
          break
      }
    }
  }

  allPass() {
    this.state.questions.forEach((q) => {
      if (q.expected) {
        this.state.answers[q.id] = q.expected
      } else if (q.exact) {
        this.state.answers[q.id] = q.exact
      } else if (q.defaultAnswer) {
        this.state.answers[q.id] = q.defaultAnswer
      }
    })
    this.submitQuestions()
  }

  ignoreNavKeys($event: KeyboardEvent) {
    const navKeys = ['ArrowUp', 'ArrowDown']
    if (navKeys.includes($event.key)) {
      $event.preventDefault()
    }
  }

  get numQuestions() {
    return this.state.questions?.length ?? 0
  }

  focusInput() {
    const element = document.getElementById(
      `ptq-${this.highlightedQuestionIndex}`,
    )
    if (element) {
      if (
        element.tagName === 'INPUT' &&
        (element as HTMLInputElement).type === 'number'
      ) {
        element.focus()
      }
    }
  }

  highlightNextQuestion() {
    if (this.numQuestions > 0) {
      if (this.highlightedQuestionIndex === undefined) {
        this.highlightedQuestionIndex = 0
      } else if (this.highlightedQuestionIndex < this.numQuestions - 1) {
        this.highlightedQuestionIndex++
      }
    }
  }
  highlightPreviousQuestion() {
    if (this.numQuestions > 0 && this.highlightedQuestionIndex > 0) {
      this.highlightedQuestionIndex--
    }
  }

  phantomTestingEvents: Subscription

  get state() {
    return this.phantomTestingService.state[this.studyId]
  }

  set state(state: TestStudyState) {
    this.phantomTestingService.state[this.studyId] = state
  }

  constructor(
    private router: Router,
    private phantomTestingService: PhantomTestingService,
  ) {}

  ngOnInit() {
    this.studyId = this.data.study_by_pk.id
    this.phantomTestingEvents = this.phantomTestingService.events.subscribe(
      this.handleEvent,
    )
    this.reset()
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.phantomTestingEvents?.unsubscribe()
  }

  handleEvent = (event: PhantomTestingEvent) => {
    if (event.studyId === this.studyId) {
      switch (event.type) {
        case 'test evaluation complete': {
          const phantomTestId = this.state.unevaluatedTestArrived
          this.router.navigateByUrl(`home/phantomTests/${phantomTestId}`)
          break
        }
        default:
          break
      }
    }
  }

  reset() {
    this.phantomTestingService.resetState(this.studyId, this.initialState)
  }

  test(routineId: number) {
    this.phantomTestingService.assembleQuestions(
      this.studyId,
      routineId,
      this.data.study_by_pk.modality.phantom_test_routines,
    )
    this.phantomTestingService.testStudy(this.studyId, routineId)
  }

  submitQuestions() {
    this.clrForm.markAsTouched()
    this.phantomTestingService.submitQuestions(this.studyId)
  }

  associateAndTest(providedRoutineId: string) {
    const routineId = parseInt(providedRoutineId, 10)
    this.test(routineId)
    this.phantomTestingService.associate(routineId, this.scannerId)
  }

  get study() {
    return this.data.study_by_pk
  }

  get scannerId() {
    return this.study.scanner.id
  }

  get modality() {
    return this.data.study_by_pk.modalityId
  }

  get associatedRoutinesForModality() {
    return this.data.study_by_pk.scanner.scanner_phantom_test_routines_phantom_test_routines
      .map((manyToManyRoutine) => manyToManyRoutine.phantom_test_routine)
      .filter((routine) => routine.modality.id === this.study.modality.id)
  }

  get routinesForModality() {
    return this.data.study_by_pk.modality.phantom_test_routines
  }

  get anyAssociatedRoutines() {
    return this.associatedRoutinesForModality.length
  }

  handleRadioKeypress($event) {
    $event.preventDefault()
  }
}
