<table id="questions-display" class="table">
  <thead>
    <th class="title left" colspan="3">Question</th>
    <th class="title">Answer</th>
  </thead>
  <tbody>
    <tr *ngFor="let question of questions">
      <td class="left" colspan="3">{{ question.text }}</td>
      <td class="{{ question.result }}">{{ question.answer }}</td>
    </tr>
  </tbody>
</table>
