<div class="border">
  <img class="logo" src="../../assets/aqs-logo-white.svg" alt="" />
</div>
<div class="login-wrapper">
  <form clrForm (submit)="onSubmit()" class="login">
    <section class="title">
      <h3 class="welcome">Welcome to</h3>
      Advanced Quality Systems
    </section>
    <div class="login-group">
      <clr-input-container>
        <label class="clr-sr-only">Username</label>
        <input
          type="text"
          name="username"
          clrInput
          placeholder="Username"
          [(ngModel)]="form.username"
        />
        <clr-control-error>Please enter your username</clr-control-error>
      </clr-input-container>
      <clr-password-container>
        <label class="clr-sr-only">Password</label>
        <input
          type="password"
          name="password"
          clrPassword
          placeholder="Password"
          [(ngModel)]="form.password"
        />
        <clr-control-error>Please enter your password</clr-control-error>
      </clr-password-container>
      <div *ngIf="error" class="error active">
        {{ error.message }}
      </div>
      <button id="classic-login" type="submit" class="btn btn-success">
        Log in
      </button>
      <span class="or">- or -</span>
      <div class="ms-sign-in-container">
        <img
          class="ms-sign-in"
          (click)="msSignIn()"
          src="../../assets/ms-symbollockup_signin_dark.svg"
          alt=""
        />
      </div>
    </div>
  </form>
</div>
