import { gql } from 'apollo-angular'

export default gql`
  query commentCategoriesDetails($id: Int!) {
    comment_category_by_pk(id: $id) {
      id
      name
      color
    }
  }
`
