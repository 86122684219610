<div class="">
  <form clrForm>
    <clr-input-container>
      <label>First Name</label>
      <input
        clrInput
        name="First Name"
        [ngModel]="getModel().firstName"
        (ngModelChange)="setModel('firstName', $event)"
      />
    </clr-input-container>
    <clr-input-container>
      <label>Last Name</label>
      <input
        clrInput
        name="Last Name"
        [ngModel]="getModel().lastName"
        (ngModelChange)="setModel('lastName', $event)"
      />
    </clr-input-container>
    <clr-input-container>
      <label>Username</label>
      <input
        clrInput
        name="Username"
        [ngModel]="getModel().username"
        (ngModelChange)="setModel('username', $event)"
      />
    </clr-input-container>
    <clr-select-container *ngIf="auth.checkrole(adminRoles)">
      <label>Role</label>
      <select
        clrSelect
        name="Role"
        [ngModel]="getModel().role"
        (ngModelChange)="setModel('role', $event)"
      >
        <option *ngFor="let role of roles" value="{{ role[0] }}">{{
          role[1]
        }}</option>
      </select>
    </clr-select-container>
    <clr-input-container *ngIf="!createNew && auth.checkrole(adminRoles)">
      <label>Status</label>
      <input
        clrInput
        name="Status"
        [ngModel]="getModel().status"
        (ngModelChange)="setModel('status', $event)"
      />
    </clr-input-container>
    <clr-input-container>
      <label>Email (optional)</label>
      <input
        clrInput
        name="email"
        [ngModel]="getModel().email"
        (ngModelChange)="setModel('email', $event)"
      />
    </clr-input-container>
    <clr-input-container>
      <label>Phone (optional)</label>
      <input
        clrInput
        name="Phone"
        [ngModel]="getModel().phone"
        (ngModelChange)="setModel('phone', $event)"
      />
    </clr-input-container>
    <clr-select-container>
      <label>Facility (optional)</label>
      <select
        *ngIf="relatedEntityOptionsQueryResult"
        clrSelect
        name="Facility"
        [ngModel]="getModel().facilityId"
        (ngModelChange)="setModel('facilityId', $event)"
      >
        <option
          *ngFor="let facility of relatedEntityOptions.facility"
          [ngValue]="facility.id"
          >{{ facility.name }}
        </option>
      </select>
    </clr-select-container>
    <clr-checkbox-container *ngIf="auth.checkrole(adminRoles)">
      <label>Subject to IP restrictions</label>
      <clr-checkbox-wrapper>
        <input
          name="restrictedIP"
          type="checkbox"
          clrCheckbox
          [ngModel]="getModel().restrictedIP"
          (ngModelChange)="setModel('restrictedIP', $event)"
        />
      </clr-checkbox-wrapper>
    </clr-checkbox-container>
  </form>

  <button (click)="save()" class="btn btn-success-outline">Save</button>
  <ng-container
    *ngIf="auth.checkrole(adminRoles) && auth.isNativeAccount(getModel())"
  >
    <button (click)="back()" class="btn btn-outline">Back</button>
    <button *ngIf="user" (click)="resetPw()" class="btn btn-warning-outline">
      Reset Password
    </button></ng-container
  >
</div>
