import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'
import { intersectionBy, unionBy, uniq } from 'lodash'
import * as pp from 'papaparse'
import scannersGql from './home/scanners/scanners.gql'
import {
  CSVDemoData,
  trends_phantom_test_routine,
  trends_survey_test_routine,
} from './home/trends/helpers'
import trendsScannerDetailsGql from './home/trends/trendsScannerDetails.gql'
import {
  trendsScannerDetails,
  trendsScannerDetails_scanner,
} from './home/trends/types/trendsScannerDetails'
import {
  trendsScanners,
  trendsScanners_scanner,
} from './home/trends/types/trendsScanners'

@Injectable({
  providedIn: 'root',
})
export class TrendsService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  async allScanners() {
    return new Promise<{
      scanners: trendsScanners_scanner[]
      roomNames: string[]
    }>((resolve, reject) => {
      function getRoomNames(scanners: trendsScanners_scanner[]) {
        return uniq(scanners.map((s) => s.facility_room?.name)).filter(
          (roomName) => !!roomName,
        )
      }
      this.apollo
        .query<trendsScanners>({
          query: scannersGql,
        })
        .subscribe({
          next: (result: ApolloQueryResult<trendsScanners>) => {
            if (result.errors) {
              reject(result.errors)
            } else {
              const scanners = result.data.scanner
              const roomNames = getRoomNames(scanners)
              resolve({ scanners, roomNames })
            }
          },
          error: (e) => reject(e),
        })
    })
  }

  /** returns result in order of scannerIds */
  async selectedScannerDetails(scannerIds: number[]) {
    return new Promise<{
      scanners: trendsScannerDetails_scanner[]
      routineIntersection: trends_phantom_test_routine[]
      routineUnion: trends_phantom_test_routine[]
      surveyRoutineIntersection: trends_survey_test_routine[]
      surveyRoutineUnion: trends_survey_test_routine[]
    }>((resolve, reject) => {
      this.apollo
        .query<trendsScannerDetails>({
          query: trendsScannerDetailsGql,
          variables: { scannerIds },
        })
        .subscribe({
          error: (e) => reject(e),
          next: (result: ApolloQueryResult<trendsScannerDetails>) => {
            if (result.errors) {
              reject(result.errors)
            } else {
              const scanners = result.data.scanner.sort(
                (a, b) => scannerIds.indexOf(a.id) - scannerIds.indexOf(b.id),
              )
              const routinesPerScanner: trends_phantom_test_routine[][] = scanners.map(
                (s) =>
                  s.scanner_phantom_test_routines_phantom_test_routines.map(
                    (p) => p.phantom_test_routine,
                  ),
              )
              const routineIntersection: trends_phantom_test_routine[] = intersectionBy(
                ...routinesPerScanner,
                'id',
              )
              const routineUnion: trends_phantom_test_routine[] = unionBy(
                ...routinesPerScanner,
                // @ts-ignore this is probably a problem in lodash's types
                'id',
              )
              const surveyTestRoutinesPerScanner: trends_survey_test_routine[][] = scanners.map(
                (s) => s.survey_tests.map((p) => p.survey_test_routine),
              )
              const surveyRoutineIntersection: trends_survey_test_routine[] = intersectionBy(
                ...surveyTestRoutinesPerScanner,
                'id',
              )
              const surveyRoutineUnion: trends_survey_test_routine[] = unionBy(
                ...surveyTestRoutinesPerScanner,
                // @ts-ignore this is probably a problem in lodash's types
                'id',
              )
              resolve({
                scanners,
                routineIntersection,
                routineUnion,
                surveyRoutineIntersection,
                surveyRoutineUnion,
              })
            }
          },
        })
    })
  }

  public async getCsvDemoData(): Promise<CSVDemoData> {
    return new Promise((resolve, reject) => {
      this.http.get('assets/sorted.csv', { responseType: 'text' }).subscribe({
        next: (data: string) =>
          resolve(pp.parse(data, { dynamicTyping: true }).data as CSVDemoData),
        error: reject,
      })
    })
  }
}
