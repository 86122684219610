<div class="studyIconsContainer">
  <ng-container *ngFor="let day of days">
    <ng-container *ngIf="day.length > 1">
      <clr-signpost>
        <img
          clrSignpostTrigger
          class="studyIcon"
          src="{{ getIcon(day[0]) }}"
          title="{{ getDate(day[0]) | customDate }}"
        />
        <clr-signpost-content clrPosition="right-middle" *clrIfOpen>
          <div *ngFor="let item of day">
            <a (click)="viewItem(item)" class="link">
              <img class="studyIcon" src="{{ getIcon(item) }}" />
              {{ getDate(item) | customDate }}
            </a>
          </div>
        </clr-signpost-content>
      </clr-signpost>
    </ng-container>

    <ng-container *ngIf="day.length === 1">
      <img
        (click)="viewItem(day[0])"
        class="studyIcon"
        src="{{ getIcon(day[0]) }}"
        title="{{ getDate(day[0]) | customDate }}"
      />
    </ng-container>

    <ng-container *ngIf="day.length < 1">
      <img class="studyIcon" src="{{ getBlankIcon() }}" title="no test" />
    </ng-container>
  </ng-container>
</div>
