import { gql } from 'apollo-angular'

export default gql`
  mutation edit_facility_by_pk($id: Int!, $_set: facility_set_input) {
    update_facility(where: { id: { _eq: $id } }, _set: $_set) {
      affected_rows
      returning {
        id
      }
    }
  }
`
