import { Apollo, gql } from 'apollo-angular'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { generateReport, generateReportVariables } from './types/generateReport'
import { TimezoneService } from '../timezone.service'

const mutation = gql`
  mutation generateReport($data: GenerateReportInput!) {
    generateReport(data: $data) {
      id
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(
    private router: Router,
    private apollo: Apollo,
    private timezoneService: TimezoneService,
  ) {}

  generateReport({
    scannerId,
    surveyTestId,
    month,
    selectedRoutine,
    periodLength,
  }: {
    scannerId: number
    surveyTestId: number
    month: Date
    selectedRoutine: string | null
    periodLength: number
  }) {
    const clientTz = this.timezoneService.cachedTz
    const variables: generateReportVariables = {
      data: {
        surveyTestId,
        month,
        clientTz,
        scannerId,
        selectedRoutine,
        periodLength,
      },
    }
    this.apollo
      .mutate<generateReport>({ mutation, variables })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          const { id } = result.data.generateReport
          this.router.navigate(['/home/reports', id])
          console.log(result)
        },
      })
  }
}
