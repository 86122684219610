<app-comments-edit
  [scannerId]="scannerId"
  [phantomTestId]="phantomTestId"
  [surveyTestId]="surveyTestId"
  [missedPhantomTestId]="missedPhantomTestId"
  (saveEvent)="onUpdate($event)"
  *ngIf="addingComment"
></app-comments-edit>
<ng-container *ngIf="!addingComment">
  <div class="clr-row">
    <div class="clr-col-6">
      <h3>Comments</h3>
    </div>
    <div class="clr-col-6">
      <button
        id="add-button"
        (click)="addComment()"
        class="btn btn-icon btn-outline"
      >
        <clr-icon shape="plus"></clr-icon> Add Comment
      </button>
    </div>
  </div>
  <clr-datagrid
    class="datagrid-striped"
    #datagrid
    (clrDgRefresh)="refreshGrid($event, fetchItems)"
    [clrDgLoading]="loading"
  >
    <clr-dg-placeholder>Nothing to Show</clr-dg-placeholder>

    <clr-dg-row *ngFor="let item of items">
      <clr-dg-cell>{{ item.dateCreated | customDate }}</clr-dg-cell>
      <clr-dg-cell>{{ getUserFullname(item.user) }}</clr-dg-cell>
      <clr-dg-cell>{{ item.subject }}</clr-dg-cell>
      <clr-dg-cell [ngStyle]="{ color: getCategoryColor(item) }">{{
        item.comment_category?.name
      }}</clr-dg-cell>
      <clr-dg-cell *ngIf="isScannersView"
        ><app-view-test [comment]="item"></app-view-test
      ></clr-dg-cell>
      <clr-dg-cell>{{
        item.comment_files_aggregate?.aggregate?.count
      }}</clr-dg-cell>

      <app-comments-details
        (updateEvent)="onUpdate($event)"
        *clrIfExpanded
        [commentId]="item.id"
        ngProjectAs="clr-dg-row-detail"
      ></app-comments-details>
    </clr-dg-row>

    <!-- Date Column -->
    <clr-dg-column [clrDgField]="properties.dateCreated">
      <ng-container>Date</ng-container>
      <clr-dg-filter>
        <app-date-filter
          [property]="properties.dateCreated"
          [earliest]="filterState[properties.dateCreated]?.earliest"
          [latest]="filterState[properties.dateCreated]?.latest"
        ></app-date-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <!-- User Column -->
    <clr-dg-column [clrDgField]="properties.user.sort">
      <ng-container>User</ng-container>
      <clr-dg-filter>
        <app-select-filter
          [exact]="filterState[properties.user.filter]?.exact"
          [property]="properties.user.filter"
          [options]="options.users"
        ></app-select-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column
      [clrDgField]="properties.subject"
      [clrFilterValue]="filterState[properties.subject]?.value"
      >Subject</clr-dg-column
    >

    <!-- Category Column -->
    <clr-dg-column [clrDgField]="properties.comment_category.sort">
      <ng-container>Category</ng-container>
      <clr-dg-filter>
        <app-select-filter
          [exact]="filterState[properties.comment_category.filter]?.exact"
          [property]="properties.comment_category.filter"
          [options]="options.comment_categories"
        ></app-select-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column *ngIf="isScannersView">Test</clr-dg-column>
    <clr-dg-column>Files</clr-dg-column>

    <clr-dg-footer>
      {{ pagination.firstItem + 1 | number }} -
      {{ pagination.lastItem + 1 | number }} of {{ totalItems | number }} items
      <clr-dg-pagination
        #pagination
        [clrDgTotalItems]="totalItems"
      ></clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</ng-container>
