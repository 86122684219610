
    <ng-container [ngSwitch]="state">
      <span *ngSwitchCase="buttonState.LOADING">
        <span @spinner class="spinner spinner-inline"></span>
      </span>
      <span *ngSwitchCase="buttonState.SUCCESS">
        <span
          @validated
          (@validated.done)="this.loadingStateChange(this.buttonState.DEFAULT)"
          class="spinner spinner-inline spinner-check"
        ></span>
      </span>
      <span *ngSwitchCase="buttonState.DEFAULT" @defaultButton>
        <ng-content></ng-content>
      </span>
    </ng-container>
  