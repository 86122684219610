<div class="primary-container">
  <span class="link-text">
    <span (click)="emitDefaultClick()" title="{{ defaultTitle }}">{{
      text
    }}</span>
    <div class="link-actions-container">
      <ng-container *ngFor="let action of actions">
        <clr-icon
          title="{{ action.text }}"
          class="link-action"
          [attr.shape]="action.shape"
          (click)="emitClick(action)"
        >
        </clr-icon>
      </ng-container>
    </div>
  </span>
</div>
