import { gql } from 'apollo-angular'

export default gql`
  query studiesDetails($id: Int!) {
    study_by_pk(id: $id) {
      scannerId
      scanner {
        name
        id
        facility {
          name
          id
        }
        facility_room {
          name
          id
        }
      }
      extra
      id
      modalityId
      modality {
        id
        phantom_test_routines(order_by: { name: asc }) {
          id
          name
          phantom_test_routine_questions_phantom_test_questions(
            order_by: { phantom_test_question: { order: asc_nulls_first } }
          ) {
            phantom_test_question {
              id
              text
              type
              options
              expected
              exact
              defaultAnswer
            }
          }
        }
      }
      phantom_tests(order_by: { study: { scanDate: desc } }) {
        id
        study {
          scanDate
        }
        result
        user {
          firstName
          lastName
        }
        dateCreated
      }
      dateCreated
      scanDate
      scanner {
        facility_room {
          name
        }
        id
        name
        facility {
          id
          name
        }
        scanner_phantom_test_routines_phantom_test_routines(
          order_by: { phantom_test_routine: { name: asc } }
        ) {
          phantom_test_routine {
            modality {
              id
            }
            name
            id
            phantom_test_routine_questions_phantom_test_questions(
              order_by: { phantom_test_question: { order: asc_nulls_first } }
            ) {
              phantom_test_question {
                text
                type
              }
            }
          }
        }
      }
      study_images {
        id
        label
        storedFileByRenderedfileid {
          key
        }
        storedFileByDcmfileid {
          key
        }
      }
    }
  }
`
