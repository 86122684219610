<div
  *ngIf="chartOptions"
  class="chart"
  echarts
  [theme]="'dark'"
  [options]="chartOptions"
  [autoResize]="true"
  #echart
  style="height: 150px;"
></div>
