import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { join } from 'path'
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http'
import { StringObjectLiteral } from '../util/util'
import { AuthService } from '../auth/auth.service'

interface HttpClientOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[]
      }
  observe?: 'body'
  params?:
    | HttpParams
    | {
        [param: string]: string | string[]
      }
  reportProgress?: boolean
  responseType?: 'json'
  withCredentials?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient, private auth: AuthService) {}

  getHostedUrl(key: string): string {
    let url: string
    try {
      const appUri = environment.appUri || `https://${window.location.host}`
      const { hostedFilesPath } = environment
      const path = join(hostedFilesPath, key)
      url = `${appUri}${path}`
    } catch (err) {
      url = ''
    }
    const encoded = encodeURI(url)
    return encoded
  }

  uploadFileToAQS(
    data: StringObjectLiteral<string | Blob>,
    files: File[],
    options: HttpClientOptions = {},
  ) {
    const uri = '/sendFiles'
    const prefix = environment.appUri
    const url = prefix + uri
    console.log(`uploadFile: ${url}`)
    const formData: FormData = new FormData()
    files.forEach((file) => {
      formData.append('multerUploadFile', file, file.name)
    })
    Object.keys(data).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        formData.append(key, data[key])
      }
    })
    let headers = new HttpHeaders()
    headers = headers.append('Accept', 'application/json')
    headers = headers.append('Authorization', this.auth.token)
    options.headers = headers
    return this.http.post(prefix + uri, formData, options)
  }

  download(key: string) {
    const { appUri } = environment
    const { hostedFilesPath } = environment
    // Don't use this - join turns http:// into http:/
    // const url: string = join(appUri, hostedFilesPath, key);
    const url = `${appUri}${join(hostedFilesPath, key)}`
    const link = document.createElement('a')
    link.download = key
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  writeStringToFileAndDownload(
    content: string,
    filename: string,
    filetype: 'text/json' | 'text/csv' | 'text/plain',
  ) {
    var dataStr = `data:${filetype};charset=utf-8,${encodeURIComponent(
      content,
    )}`
    var downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', dataStr)
    downloadAnchorNode.setAttribute('download', filename)
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }
}
