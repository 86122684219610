import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, Input, OnInit } from '@angular/core'

import * as dot from 'dot-object'
import { clearEmpties, safeGet } from 'src/app/util/util'
import { phantomTestDetails_phantom_test_by_pk } from '../../../types/phantomTestDetails'
import deleteCustomValuesMutation from './deleteCustomValues.gql'
import {
  deleteCustomValue,
  deleteCustomValueVariables,
} from './types/deleteCustomValue'
import { setCustomValue, setCustomValueVariables } from './types/setCustomValue'
import updateCustomValuesMutation from './updateCustomValues.gql'

@Component({
  selector: '[appCustomLimit]',
  templateUrl: './custom-limit.component.html',
  styleUrls: ['./custom-limit.component.scss'],
})
export class CustomLimitComponent implements OnInit {
  @Input() limitKey: string
  @Input() decimalPlaces?: number
  @Input() test: phantomTestDetails_phantom_test_by_pk
  @Input() currentLimit: number | undefined
  allScannersCustomLimit: number | undefined | null
  scannerModelCustomLimit: number | undefined | null
  thisScannerCustomLimit: number | undefined | null
  editing = false
  @Input() actual: number

  constructor(private apollo: Apollo) {}

  numberPipeString() {
    return this.decimalPlaces !== undefined
      ? `1.${this.decimalPlaces}-${this.decimalPlaces}`
      : '1.0-2'
  }

  ngOnInit() {
    this.getLimits()
  }

  get scanner() {
    return this.test.scanner
  }
  get scannerId() {
    return this.scanner?.id
  }
  get scannerName() {
    return this.scanner?.name
  }
  get scannerModel() {
    return this.scanner?.scanner_model
  }
  get scannerModelId() {
    return this.scanner?.scanner_model?.id
  }
  get scannerModelIdString() {
    return `model-${this.scannerModelId}`
  }
  get scannerModelName() {
    return this.scanner?.scanner_model?.name
  }
  get customLimitIsSet() {
    return !!(
      this.thisScannerCustomLimit ||
      this.scannerModelCustomLimit ||
      this.allScannersCustomLimit
    )
  }

  getLimit(item: unknown, path: string): number | undefined {
    const limit: unknown = safeGet(item, path)
    if (typeof limit === 'number' || typeof limit === 'undefined') {
      return limit
    }
    console.warn(`${path} is of type ${typeof limit} and value ${limit}`)
    return undefined
  }

  getLimits() {
    // get the limit specified by the routine for all scanners
    this.allScannersCustomLimit = this.getLimit(
      this.test.phantom_test_routine.customValues[0],
      this.limitKey,
    )
    // get the limit specified by the routine for this scanner model
    this.scannerModelCustomLimit = this.getLimit(
      this.test.phantom_test_routine.customValues[this.scannerModelIdString],
      this.limitKey,
    )
    // get the limit specified by the routine for this scanner
    this.thisScannerCustomLimit = this.getLimit(
      this.test.phantom_test_routine.customValues[this.scannerId],
      this.limitKey,
    )
  }

  edit() {
    this.editing = true
  }

  save() {
    this.editing = false
    const providedAllScannersCustomLimit = this.allScannersCustomLimit
    const providedScannerModelCustomLimit = this.scannerModelCustomLimit
    const providedThisScannerCustomLimit = this.thisScannerCustomLimit
    this.getLimits() // resets limits on component
    // set the provided all scanners limit
    if (
      providedAllScannersCustomLimit === null &&
      this.allScannersCustomLimit !== undefined
    ) {
      // it ended blank. It was not blank at some point.
      // the user wants to remove the limit
      this.removeLimit(0)
    }
    if (
      providedAllScannersCustomLimit &&
      providedAllScannersCustomLimit !== this.allScannersCustomLimit
    ) {
      // we must set the limit in the routine if it is different from the one in the routine
      this.setLimit(0, providedAllScannersCustomLimit)
    }

    // again for the provided scanner model limit
    if (
      providedScannerModelCustomLimit === null &&
      this.scannerModelCustomLimit !== undefined
    ) {
      this.removeLimit(this.scannerModelIdString)
    }
    if (
      providedScannerModelCustomLimit &&
      providedScannerModelCustomLimit !== this.scannerModelCustomLimit
    ) {
      this.setLimit(this.scannerModelIdString, providedScannerModelCustomLimit)
    }

    // again for the provided this scanner limit
    if (
      providedThisScannerCustomLimit === null &&
      this.thisScannerCustomLimit !== undefined
    ) {
      this.removeLimit(this.scannerId)
    }
    if (
      providedThisScannerCustomLimit &&
      providedThisScannerCustomLimit !== this.thisScannerCustomLimit
    ) {
      this.setLimit(this.scannerId, providedThisScannerCustomLimit)
    }
    this.getLimits()
  }

  cancel() {
    this.editing = false
    this.getLimits()
  }

  removeLimit(limitKey: string | number) {
    if (limitKey === 0) {
      console.log(`removing limit for all scanners`)
    } else {
      console.log(`removing limit for scanner ${limitKey}`)
    }
    const jsonbPath = `${limitKey}.${this.limitKey}`
    dot.del(jsonbPath, this.test.phantom_test_routine.customValues)
    clearEmpties(this.test.phantom_test_routine.customValues[limitKey])
    console.log(this.test.phantom_test_routine.customValues)
    this.pushToDB(
      this.test.phantom_test_routine.customValues[limitKey],
      limitKey,
    )
  }

  setLimit(limitKey: string | number, newValue: number) {
    if (limitKey === 0) {
      console.log(`setting limit for all scanners to ${newValue}`)
    } else {
      console.log(`setting limit for scanner ${limitKey} to ${newValue}`)
    }
    const jsonbPath = `${limitKey}.${this.limitKey}`
    dot.set(jsonbPath, newValue, this.test.phantom_test_routine.customValues)
    console.log(this.test.phantom_test_routine.customValues)
    this.pushToDB(
      this.test.phantom_test_routine.customValues[limitKey],
      limitKey,
    )
  }

  pushToDB(object: unknown, limitKey: string | number) {
    const subscriber = {
      next: (result: ApolloQueryResult<setCustomValue | deleteCustomValue>) => {
        if (result.errors) {
          throw result.errors[0]
        }
        this.test.phantom_test_routine.customValues =
          result.data.update_phantom_test_routine.returning[0].customValues
      },
    }

    const routineId = this.test.phantom_test_routine.id
    if (Object.keys(object).length === 0) {
      // if there aren't any properties left
      const mutation = deleteCustomValuesMutation
      const variables: deleteCustomValueVariables = {
        routineId,
        path: [limitKey.toString()],
      }
      this.apollo
        .mutate<deleteCustomValue>({ mutation, variables })
        .subscribe(subscriber)
    } else {
      const mutation = updateCustomValuesMutation
      const data = {
        [limitKey.toString()]: object,
      }
      const variables: setCustomValueVariables = { routineId, data }
      this.apollo
        .mutate<setCustomValue>({ mutation, variables })
        .subscribe(subscriber)
    }
  }
}
