<app-users-edit
  (saveEvent)="onUpdate($event)"
  *ngIf="creatingUser"
></app-users-edit>
<ng-container *ngIf="!creatingUser">
  <div
    class="clr-row"
    appPageHeader
    [title]="title"
    [actions]="actions"
    (clicks)="actionsHandler($event)"
  ></div>
  <clr-datagrid
    class="datagrid-striped"
    #datagrid
    (clrDgRefresh)="refreshGrid($event, fetchItems)"
    [clrDgLoading]="loading"
  >
    <clr-dg-placeholder>Nothing to Show</clr-dg-placeholder>

    <!-- Row Def -->
    <clr-dg-row *ngFor="let item of items">
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.firstName
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.lastName
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.username
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.role
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.email
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.phone
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.status
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.facility?.name
      }}</clr-dg-cell>
      <clr-dg-cell appClickToView (click)="view(item)">{{
        item?.accountType
      }}</clr-dg-cell>
    </clr-dg-row>

    <!-- First Name Column -->
    <clr-dg-column
      [clrDgField]="properties.firstName"
      [clrFilterValue]="filterState[properties.firstName]?.value"
      ><ng-container>First Name</ng-container></clr-dg-column
    >

    <!-- Last Name Column -->
    <clr-dg-column
      [clrDgField]="properties.lastName"
      [clrFilterValue]="filterState[properties.lastName]?.value"
      ><ng-container>Last Name</ng-container></clr-dg-column
    >

    <!-- Username Column -->
    <clr-dg-column
      [clrDgField]="properties.username"
      [clrFilterValue]="filterState[properties.username]?.value"
      ><ng-container>Username</ng-container></clr-dg-column
    >

    <!-- Role Column -->
    <clr-dg-column [clrDgField]="properties.role"
      ><ng-container>Role</ng-container>
      <clr-dg-filter>
        <app-select-filter
          [exact]="filterState[properties.role]?.exact"
          [property]="properties.role"
          [options]="options.roles"
        ></app-select-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <!-- email Column -->
    <clr-dg-column
      [clrDgField]="properties.email"
      [clrFilterValue]="filterState[properties.email]?.value"
      ><ng-container>email</ng-container></clr-dg-column
    >

    <!-- Phone Column -->
    <clr-dg-column
      [clrDgField]="properties.phone"
      [clrFilterValue]="filterState[properties.phone]?.value"
      ><ng-container>Phone</ng-container></clr-dg-column
    >

    <!-- Status Column -->
    <clr-dg-column [clrDgField]="properties.status"
      ><ng-container>Status</ng-container>
      <clr-dg-filter>
        <app-select-filter
          [exact]="filterState[properties.status]?.exact"
          [property]="properties.status"
          [options]="options.statuses"
        ></app-select-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <!-- Facility Column -->
    <clr-dg-column [clrDgField]="properties.facility.sort">
      <ng-container>Facility</ng-container>
      <clr-dg-filter>
        <app-select-filter
          [exact]="filterState[properties.facility.filter]?.exact"
          [property]="properties.facility.filter"
          [options]="options.facilities"
        ></app-select-filter>
      </clr-dg-filter>
    </clr-dg-column>

    <!-- Account Type Column -->
    <clr-dg-column
      [clrDgField]="properties.accountType"
      [clrFilterValue]="filterState[properties.accountType]?.value"
      ><ng-container>Account Type</ng-container></clr-dg-column
    >

    <!-- Footer -->
    <clr-dg-footer>
      {{ pagination.firstItem + 1 | number }} -
      {{ pagination.lastItem + 1 | number }} of {{ totalItems | number }} items
      <clr-dg-pagination
        #pagination
        [clrDgTotalItems]="totalItems"
      ></clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</ng-container>
