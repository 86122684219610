<div class="card" id="container">
  <!-- thumbnails -->
  <div class="card-block" id="thumbnail-block" *ngIf="!loading">
    <div
      class="thumbnail-container"
      *ngFor="let series of thumbnails.series; let i = index"
      (click)="onThumbnailClick(series.firstImageIndex)"
    >
      <img
        src="{{ series.imageSrc }}"
        class="thumbnail"
        [ngClass]="{ highlight: i === thumbnails.highlightedSeriesIndex }"
        alt=""
      />
      <span class="thumbnail-overlay">{{ i + 1 }}/{{ series.numImages }}</span>
    </div>
  </div>
  <!-- series info -->
  <div class="card-block" *ngIf="!loading">
    <div class="clr-row">
      <div class="clr-col-6">
        <table class="series-info">
          <tbody>
            <tr *ngFor="let item of seriesTagData | evens">
              <td>{{ item.title }}:</td>
              <td *ngIf="item.value !== undefined">{{ item.value }}</td>
              <td *ngIf="item.value === undefined"><em>none</em></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="clr-col-6">
        <table class="series-info">
          <tbody>
            <tr *ngFor="let item of seriesTagData | odds">
              <td>{{ item.title }}:</td>
              <td *ngIf="item.value !== undefined">{{ item.value }}</td>
              <td *ngIf="item.value === undefined"><em>none</em></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- Series Image indicator -->
  <div class="card-block no-padding" id="series-box-container" *ngIf="!loading">
    <span
      *ngFor="let index of seriesBoxLoopArray"
      (click)="onSeriesBoxClick(index)"
      class="series-box {{
        index === thumbnails.imageIndexInSeries ? 'highlight' : ''
      }}"
    ></span>
  </div>
  <!-- main container -->
  <div class="card-block no-padding flex-height">
    <div
      id="dicomImageWrapper"
      class="flex-height"
      oncontextmenu="return false"
      onmousedown="return false"
    >
      <div id="dicomImage" class="flex-height"></div>
      <div
        *ngIf="!loading"
        [ngStyle]="{ visibility: showOverlays ? 'initial' : 'hidden' }"
      >
        <div id="topleft" class="overlay" style="top: 5px; left: 5px;">
          <div *ngFor="let item of overlays.leftTop">{{ item }}</div>
        </div>
        <div id="topright" class="overlay" style="top: 5px; right: 5px;">
          <div *ngFor="let item of overlays.rightTop">{{ item }}</div>
        </div>
        <div id="bottomright" class="overlay" style="bottom: 5px; right: 5px;">
          <div *ngFor="let item of overlays.rightBottom">{{ item }}</div>
        </div>
        <div id="bottomleft" class="overlay" style="bottom: 5px; left: 5px;">
          <div *ngFor="let item of overlays.leftBottom">{{ item }}</div>
        </div>
      </div>
      <div id="loading" *ngIf="loading">
        <div class="box">
          <span id="progress-bar-label">
            Downloading Images: {{ numImagesLoaded }} / {{ numImages }}
          </span>
          <clr-progress-bar
            [clrMax]="numImages"
            [clrValue]="numImagesLoaded"
            id="dicom-viewer-progress-bar"
            [clrLabeled]="false"
          ></clr-progress-bar>
        </div>
      </div>
    </div>
  </div>
  <!-- controls -->
  <div><span id="coords"></span></div>
  <div class="card-block" *ngIf="!loading">
    <div class="clr-row">
      <div class="clr-col-6">
        <form clrForm class="no-padding">
          <clr-checkbox-wrapper>
            <label>Show Overlays</label>
            <input
              type="checkbox"
              clrCheckbox
              name="showOverlays"
              [(ngModel)]="showOverlays"
            />
          </clr-checkbox-wrapper>
          <clr-checkbox-wrapper>
            <label>Invert</label>
            <input
              type="checkbox"
              clrCheckbox
              name="invert"
              id="invert"
              [(ngModel)]="invert"
              (ngModelChange)="onInvert()"
            />
          </clr-checkbox-wrapper>
        </form>
      </div>
      <div class="clr-col-6">
        <button
          *ngIf="enableFullscreen"
          id="fullscreen-button"
          class="btn btn-link"
          (click)="onFullscreen()"
        >
          fullscreen
        </button>
      </div>
    </div>
  </div>
</div>
