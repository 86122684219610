import { gql } from 'apollo-angular'

export default gql`
  query reports(
    $distinct_on: [report_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [report_order_by!]
    $where: report_bool_exp
  ) {
    report(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      survey_test {
        scanner {
          facility {
            name
          }
          facility_room {
            name
          }
          name
        }
      }
      dateCreated
      survey_test {
        survey_test_routine {
          name
        }
      }
      month
      year
    }
    report_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
