import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { AfterViewInit, Component, OnInit } from '@angular/core'

import { cloneDeep } from 'lodash'
import { order_by } from 'src/types/graphql-global-types'
import { DatagridComponent } from 'src/app/util/datagrid.class'
import { DatagridStateService } from 'src/app/core/datagrid-state.service'
import { Router } from '@angular/router'
import { TimezoneService } from 'src/app/timezone.service'
import { colors } from '../../../config/colors'
import { safeGet } from '../../util/util'
import query from './comment-categories.gql'
import {
  commentCategories,
  commentCategoriesVariables,
  commentCategories_comment_category,
} from './types/commentCategories'
import { PageHeaderAction } from '../page-header/page-header.component'

@Component({
  selector: 'app-comment-categories',
  templateUrl: './comment-categories.component.html',
  styleUrls: ['./comment-categories.component.scss'],
})
export class CommentCategoriesComponent extends DatagridComponent {
  title = 'comment categories'
  colors = colors
  creatingCategory = false

  actions: PageHeaderAction[] = [{ text: 'Create Category' }]

  static properties = {
    name: 'name',
    color: 'color',
  }
  properties = CommentCategoriesComponent.properties

  constructor(
    private apollo: Apollo,
    protected router: Router,
    protected dgStateService: DatagridStateService,
    protected timezoneService: TimezoneService,
  ) {
    super(dgStateService, router, timezoneService)
    // add default if necessary
    this.defaultState = {
      sort: {
        by: this.properties.name,
        reverse: true,
      },
    }
  }

  items: commentCategories_comment_category[]
  totalItems: number

  fetchItems = () => {
    this.loading = true
    this.apollo
      .query<commentCategories>({ query, variables: this.variables })
      .subscribe({
        next: (result: ApolloQueryResult<commentCategories>) => {
          this.loading = false
          if (result.errors) {
            throw result.errors[0]
          }
          this.items = result.data.comment_category
          this.totalItems =
            result.data.comment_category_aggregate.aggregate.count
        },
        error: this.onError,
      })
  }

  actionsHandler($event: string) {
    switch ($event) {
      case 'delete':
      case 'edit':
        this.fetchItems()
        break
      case 'create':
      case 'back':
        this.creatingCategory = false
        this.fetchItems()
        break
      case 'Create Category':
        this.createCategory()
        break
      default:
        break
    }
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  createCategory() {
    this.creatingCategory = true
  }
}
