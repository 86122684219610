<div class="card" *ngIf="state === 'view'">
  <div class="card-header schedule-card-title">
    {{ schedule.title }}
    <button class="btn btn-link" (click)="edit()">Edit</button>
  </div>
  <div class="card-block">
    <div class="clr-row">
      <div class="clr-col-6">
        scanners: {{ schedule.scannersFriendly | join }}
      </div>
      <div class="clr-col-6">
        test or survey: {{ schedule.tasksFriendly | join }}
      </div>
    </div>
  </div>
  <div class="card-block">
    <div class="clr-row">
      <div class="clr-col-6">
        <div>schedule: {{ schedule.interval | rruleText }}</div>
        <div>next: {{ rruleNext(schedule.interval) }}</div>
      </div>
      <div class="clr-col-6">
        <div>
          Early Period:
          <ng-container
            *ngIf="schedule.priorToAmount && schedule.priorToType; else none"
            >{{ schedule.priorToAmount }}
            {{ schedule.priorToType }}</ng-container
          >&nbsp;
          <clr-signpost>
            <clr-icon
              shape="info-standard"
              class="link-icon"
              clrSignpostTrigger
            ></clr-icon>
            <clr-signpost-content [clrPosition]="'bottom-left'" *clrIfOpen>
              <ng-container
                *ngIf="
                  schedule.priorToAmount && schedule.priorToType;
                  else elseEarlyPeriod
                "
              >
                If this task is done up to
                {{ schedule.priorToAmount }} {{ schedule.priorToType }}
                <strong>before</strong> the due date, it will be marked as done.
              </ng-container>
              <ng-template #elseEarlyPeriod>
                How long prior to the due date doing this task will mark the
                task as done.
              </ng-template>
            </clr-signpost-content>
          </clr-signpost>
        </div>
        <div>
          Grace period:
          <ng-container
            *ngIf="schedule.graceAmount && schedule.graceType; else none"
            >{{ schedule.graceAmount }} {{ schedule.graceType }}</ng-container
          >&nbsp;
          <clr-signpost>
            <clr-icon
              shape="info-standard"
              class="link-icon"
              clrSignpostTrigger
            ></clr-icon>
            <clr-signpost-content [clrPosition]="'bottom-left'" *clrIfOpen>
              <ng-container
                *ngIf="
                  schedule.graceAmount && schedule.graceType;
                  else elseGracePeriod
                "
              >
                If this task is done up to
                {{ schedule.graceAmount }} {{ schedule.graceType }}
                <strong>after</strong> the due date, it is considered on time.
              </ng-container>
              <ng-template #elseGracePeriod>
                How long after the due date that the task can still be done to
                be considered done on time.
              </ng-template>
            </clr-signpost-content>
          </clr-signpost>
        </div>
        <div>
          Reminder:
          <ng-container
            *ngIf="schedule.reminderAmount && schedule.reminderType; else none"
            >{{ schedule.reminderAmount }}
            {{ schedule.reminderType }}</ng-container
          >&nbsp;
          <clr-signpost>
            <clr-icon
              shape="info-standard"
              class="link-icon"
              clrSignpostTrigger
            ></clr-icon>
            <clr-signpost-content [clrPosition]="'bottom-left'" *clrIfOpen>
              <ng-container
                *ngIf="
                  schedule.reminderAmount && schedule.reminderType;
                  else elseReminderPeriod
                "
              >
                An email reminder will be sent to support contacts
                {{ schedule.reminderAmount }} {{ schedule.reminderType }}
                before the task is due
              </ng-container>
              <ng-template #elseReminderPeriod>
                How long after the due date that the task can still be done to
                be considered done on time
              </ng-template>
            </clr-signpost-content>
          </clr-signpost>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #none>none</ng-template>

<div class="card" *ngIf="state === 'edit'">
  <div class="card-header schedule-card-title">
    <input
      clrInput
      placeholder="Title"
      name="title"
      class="title"
      [(ngModel)]="schedule.title"
    />
    <div class="button-group">
      <button class="btn btn-link btn-link-fail" (click)="delete()">
        Delete
      </button>
      <button class="btn btn-link" (click)="cancel()">Cancel</button>
      <button class="btn btn-link btn-link-success" (click)="save()">
        Save
      </button>
    </div>
  </div>
  <div class="card-block">
    <div class="clr-row">
      <div class="clr-col-6">
        scanners:
        <tag-input
          [(ngModel)]="schedule.scannersFriendly"
          [theme]="'clr-theme'"
          [placeholder]="'+ add another scanner'"
          [secondaryPlaceholder]="'By scanner or room name'"
          [identifyBy]="'id'"
          [displayBy]="'name'"
          [onlyFromAutocomplete]="true"
        >
          <tag-input-dropdown
            [autocompleteItems]="allScanners"
            [identifyBy]="'id'"
            [showDropdownIfEmpty]="true"
            [keepOpen]="false"
            [displayBy]="'name'"
          ></tag-input-dropdown
        ></tag-input>
      </div>
      <div class="clr-col-6">
        test or survey:
        <tag-input
          [(ngModel)]="schedule.tasksFriendly"
          [theme]="'clr-theme'"
          [placeholder]="'+ add another task'"
          [secondaryPlaceholder]="'Add a task'"
          [identifyBy]="'id'"
          [displayBy]="'name'"
          [onlyFromAutocomplete]="true"
        >
          <tag-input-dropdown
            [autocompleteItems]="allTasks"
            [identifyBy]="'id'"
            [showDropdownIfEmpty]="true"
            [keepOpen]="false"
            [displayBy]="'name'"
          ></tag-input-dropdown
        ></tag-input>
      </div>
    </div>
  </div>
  <div class="card-block">
    <div class="clr-row">
      <div class="clr-col-6">
        <app-rrule-generator
          [(schedule)]="schedule.interval"
        ></app-rrule-generator>
      </div>
      <div class="clr-col-6">
        <div class="d-flex duration-container">
          Early Period:
          <app-duration-picker
            [(amount)]="schedule.priorToAmount"
            [(type)]="schedule.priorToType"
          ></app-duration-picker>
          <a class="delete-icon"
            ><clr-icon
              shape="times"
              class="fail"
              (click)="
                schedule.priorToAmount = undefined;
                schedule.priorToType = undefined
              "
            ></clr-icon
          ></a>
          <clr-signpost>
            <clr-icon
              shape="info-standard"
              class="link-icon"
              clrSignpostTrigger
            ></clr-icon>
            <clr-signpost-content [clrPosition]="'bottom-left'" *clrIfOpen>
              <ng-container
                *ngIf="
                  schedule.priorToAmount && schedule.priorToType;
                  else elseEarlyPeriod
                "
              >
                If this task is done up to
                {{ schedule.priorToAmount }} {{ schedule.priorToType }}
                <strong>before</strong> the due date, it will be marked as done.
              </ng-container>
              <ng-template #elseEarlyPeriod>
                How long prior to the due date doing this task will mark the
                task as done.
              </ng-template>
            </clr-signpost-content>
          </clr-signpost>
        </div>
        <div class="d-flex duration-container">
          Grace period:
          <app-duration-picker
            [(amount)]="schedule.graceAmount"
            [(type)]="schedule.graceType"
          ></app-duration-picker>
          <a class="delete-icon"
            ><clr-icon
              shape="times"
              class="fail"
              (click)="
                schedule.graceAmount = undefined; schedule.graceType = undefined
              "
            ></clr-icon
          ></a>
          <clr-signpost>
            <clr-icon
              shape="info-standard"
              class="link-icon"
              clrSignpostTrigger
            ></clr-icon>
            <clr-signpost-content [clrPosition]="'bottom-left'" *clrIfOpen>
              <ng-container
                *ngIf="
                  schedule.graceAmount && schedule.graceType;
                  else elseGracePeriod
                "
              >
                If this task is done up to
                {{ schedule.graceAmount }} {{ schedule.graceType }}
                <strong>after</strong> the due date, it is considered on time.
              </ng-container>
              <ng-template #elseGracePeriod>
                How long after the due date that the task can still be done to
                be considered done on time.
              </ng-template>
            </clr-signpost-content>
          </clr-signpost>
        </div>
        <div class="d-flex duration-container">
          Reminder:
          <app-duration-picker
            [(amount)]="schedule.reminderAmount"
            [(type)]="schedule.reminderType"
          ></app-duration-picker>
          <a class="delete-icon"
            ><clr-icon
              shape="times"
              class="fail"
              (click)="
                schedule.reminderAmount = undefined;
                schedule.reminderType = undefined
              "
            ></clr-icon
          ></a>
          <clr-signpost>
            <clr-icon
              shape="info-standard"
              class="link-icon"
              clrSignpostTrigger
            ></clr-icon>
            <clr-signpost-content [clrPosition]="'bottom-left'" *clrIfOpen>
              <ng-container
                *ngIf="
                  schedule.reminderAmount && schedule.reminderType;
                  else elseReminderPeriod
                "
              >
                An email reminder will be sent to support contacts
                {{ schedule.reminderAmount }} {{ schedule.reminderType }}
                before the task is due
              </ng-container>
              <ng-template #elseReminderPeriod>
                How long after the due date that the task can still be done to
                be considered done on time
              </ng-template>
            </clr-signpost-content>
          </clr-signpost>
        </div>
      </div>
    </div>
  </div>
</div>
