import { gql } from 'apollo-angular'

export default gql`
  query users(
    $distinct_on: [user_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [user_order_by!]
    $where: user_bool_exp
  ) {
    user(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      accountType
      firstName
      lastName
      username
      role
      email
      phone
      status
      facility {
        name
      }
    }
    user_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
