<div class="">
  <form clrForm>
    <clr-select-container>
      <label>Scanner</label>
      <select
        *ngIf="relatedEntityOptionsQueryResult"
        clrSelect
        name="Scanner"
        [(ngModel)]="survey_test.scannerId"
      >
        <option
          *ngFor="let scanner of relatedEntityOptions.scanner"
          value="{{ scanner.id }}"
          >{{ scanner.name }}</option
        >
      </select>
    </clr-select-container>
    <clr-input-container>
      <label>Test Date</label>
      <input clrInput name="Test Date" [(ngModel)]="survey_test.dateCreated" />
    </clr-input-container>

    <clr-input-container>
      <label>Result</label>
      <input clrInput name="Result" [(ngModel)]="survey_test.result" />
    </clr-input-container>
    <clr-select-container>
      <label>Tester</label>
      <select
        *ngIf="relatedEntityOptionsQueryResult"
        clrSelect
        name="Tester"
        [(ngModel)]="survey_test.userId"
      >
        <option
          *ngFor="let user of relatedEntityOptions.user"
          value="{{ user.id }}"
          >{{ user.firstName }}</option
        >
      </select>
    </clr-select-container>
  </form>

  <button (click)="save()" class="btn btn-success-outline">Save</button>
  <button (click)="back()" class="btn btn-outline">Back</button>
</div>
