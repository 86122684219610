import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { CommService } from 'src/app/core/comm.service'
import { RruleGeneratorComponent } from '../rrule-generator/rrule-generator.component'
import { scannersDetails_scanner_by_pk } from '../scanners-details/types/scannersDetails'
import {
  updateScannerSchedule,
  updateScannerScheduleVariables,
} from './types/updateScannerSchedule'
import updateScannerScheduleGql from './updateScannerSchedule.gql'

@Component({
  selector: 'app-scanner-rrule-generator',
  templateUrl: './scanner-rrule-generator.component.html',
  styleUrls: ['./scanner-rrule-generator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScannerRruleGeneratorComponent {
  @ViewChild('RRuleGenerator') rruleGenerator: RruleGeneratorComponent
  @Input() scanner: scannersDetails_scanner_by_pk

  defaultSchedule = 'RRULE:FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1'
  get unsavedRrule(): string {
    return this.rruleGenerator.unsavedRrule
  }
  private _unsavedGrace: number
  get unsavedGrace() {
    if (this._unsavedGrace === undefined) {
      this._unsavedGrace = this.scanner.scheduleGrace ?? 0
    }
    return this._unsavedGrace
  }
  set unsavedGrace(val) {
    if (typeof val === 'string') {
      val = parseInt(val)
    }
    if (typeof val === 'number') {
      this._unsavedGrace = val
    }
  }

  get enableSchedule(): boolean | undefined {
    if (!this.scanner) return undefined
    return !!this.scanner?.schedule
  }
  set enableSchedule(val: boolean) {
    this.scanner.schedule = val ? this.defaultSchedule : ''
  }

  constructor(private apollo: Apollo, private comm: CommService) {}

  save() {
    if (
      this.unsavedRrule === undefined &&
      this.scanner.scheduleGrace === this.unsavedGrace
    ) {
      this.comm.alert('info', 'No changes')
      return
    }
    const schedule = this.unsavedRrule ?? this.scanner.schedule
    const scheduleGrace = this.unsavedGrace
    const variables: updateScannerScheduleVariables = {
      scannerId: this.scanner.id,
      schedule,
      scheduleGrace,
    }
    const mutation = updateScannerScheduleGql
    console.log(schedule)
    this.apollo
      .mutate<updateScannerSchedule>({ mutation, variables })
      .subscribe({
        next: (result) => {
          this.comm.alert('success', `Schedule saved`)
          this.scanner.schedule =
            result.data.update_scanner.returning[0].schedule
          this.scanner.scheduleGrace =
            result.data.update_scanner.returning[0].scheduleGrace
        },
      })
  }
}
