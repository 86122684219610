import { Directive, ElementRef } from '@angular/core'

@Directive({
  selector: '[appClickToView]',
})
export class ClickToViewDirective {
  constructor(el: ElementRef<HTMLElement>) {
    el.nativeElement.title = 'click to view'
    el.nativeElement.style.cursor = 'pointer'
  }
}
