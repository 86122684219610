import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { KeyVal } from 'src/app/util/util'

type keyType = string | number | undefined | null

type Option = KeyVal<keyType, string>

@Component({
  selector: 'app-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.scss'],
})
export class ComboboxComponent {
  /**
   * provide a value to this if you want to initialize the combobox with a value
   */
  @Input() selectedKey: keyType
  /**
   * Event fires when the selected key is changed
   */
  @Output() selectedKeyChange = new EventEmitter<keyType>()

  /**
   * The list of options available to choose from
   */
  private _options: Option[] = []
  get options() {
    return this._options
  }

  /**
   * When new options come down from the parent,
   * re-filter the options array to account for the new options
   */
  @Input() set options(options: Option[]) {
    this._options = options
    this.filteredOptions = generateFilterOptions(
      this.userTypedFilterValue,
      this.options,
      this.selectedKey,
    )
  }

  /**
   * the set of options actually shown to the user,
   * filtered from this.options by their typed input
   */
  filteredOptions: Option[] = []

  /**
   * When the user types some input:
   * 1. the options list must be filtered
   * 2. the first item in the new list becomes highlighted
   */
  _userTypedFilterValue: string
  get userTypedFilterValue(): string {
    return this._userTypedFilterValue
  }
  set userTypedFilterValue(newValue: string) {
    this._userTypedFilterValue = newValue
    this.filteredOptions = generateFilterOptions(
      this._userTypedFilterValue,
      this.options,
      this.selectedKey,
    )
    this.currentlyHighlightedIndex = 0
  }

  /**
   * The highlighted option is the one that is selected
   * (and output to the parent as an event) when 'Enter' is pressed
   * The user can use the arrow keys to navigate through options
   */
  _currentlyHighlightedIndex = 0
  get currentlyHighlightedIndex(): number {
    return this._currentlyHighlightedIndex
  }
  /**
   * This ensures the index doesn't go out of bounds
   */
  set currentlyHighlightedIndex(newIndex: number) {
    if (newIndex < 0) {
      this._currentlyHighlightedIndex = 0
    } else if (newIndex >= this.filteredOptions.length) {
      this._currentlyHighlightedIndex = this.filteredOptions.length - 1
    } else {
      this._currentlyHighlightedIndex = newIndex
    }
  }
  /**
   * Returns whichever option the user has highlighted
   */
  get currentlyHighlitedOption(): Option | undefined {
    return this.filteredOptions[this.currentlyHighlightedIndex]
  }

  /**
   * The user can press 'Enter' to select the currentlyHighlitedOption
   */
  userPressedEnter() {
    this.userSelectedAnOption(this.currentlyHighlitedOption)
  }

  /**
   * The user can press 'ArrowUp' to highlight the option above the current one
   */
  userPressedArrowUp() {
    this.currentlyHighlightedIndex -= 1
  }

  /**
   * The user can press 'ArrowDown' to highlight the option below the current one
   */
  userPressedArrowDown() {
    this.currentlyHighlightedIndex += 1
  }

  /**
   * When the user selects an option:
   * 1. set the selected key
   * 2. notify the parent by emitting an event
   * 3. re-generate the filtered options list
   */
  userSelectedAnOption(option: Option) {
    this.selectedKey = option.key
    this.selectedKeyChange.emit(this.selectedKey)
    if (option.key === undefined) {
      // this also re-generates the filtered options input
      // and sets the selected index to 0
      this.userTypedFilterValue = ''
    } else {
      this.filteredOptions = generateFilterOptions(
        this.userTypedFilterValue,
        this.options,
        this.selectedKey,
      )
    }
  }

  // methods that do not modify the internal state of this component

  @ViewChild('filterInputElement') filterInputElement: ElementRef<
    HTMLInputElement
  >
  focus() {
    this.filterInputElement?.nativeElement?.focus()
  }
}

// only pure functions below

function generateFilterOptions(
  userTypedFilterValue: string | undefined,
  originalOptions: Option[],
  currentlySelectedKey: keyType,
): Option[] {
  let newFilteredOptions: Option[]
  if (userTypedFilterValue) {
    newFilteredOptions = originalOptions.filter((option) =>
      option.val.toLowerCase().startsWith(userTypedFilterValue.toLowerCase()),
    )
  } else {
    newFilteredOptions = originalOptions
  }
  if (currentlySelectedKey !== undefined && currentlySelectedKey !== null) {
    newFilteredOptions = addClearOption(newFilteredOptions)
  }
  return newFilteredOptions
}

function addClearOption(options: Option[]): Option[] {
  const clearOption: Option = { key: undefined, val: 'clear' }
  const clearOptionAlreadyAdded: boolean = options[0]?.val === clearOption.val
  if (!clearOptionAlreadyAdded) {
    return [clearOption, ...options]
  }
  return options
}
