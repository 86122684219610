<div class="clr-col">
  <h2>{{ title | titlecase }}</h2>
</div>
<div class="clr-col-6 actions">
  <ng-content></ng-content>
  <button
    *ngFor="let action of actions"
    (click)="clicks.emit(action.text)"
    class="btn btn-{{ action.class }}-outline"
  >
    {{ action.text }}
  </button>
</div>
