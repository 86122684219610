import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import * as moment from 'moment-timezone'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'

import { GALLERY_IMAGE } from 'ngx-image-gallery'
import { Subscription } from 'rxjs'
import { LinkAction } from 'src/app/core/action-link/action-link.component'
import { MessageError } from 'src/app/core/errors/errors'
import { FileService } from 'src/app/core/file.service'
import { NavService } from 'src/app/core/nav.service'
import { DatagridManualState } from 'src/app/core/datagrid-state.service'
import { FilterType } from 'src/app/filters/filters.util'
import { PageHeaderAction } from '../page-header/page-header.component'
import deleteMutation from './delete-phantom-tests-details.gql'
import downloadImagesGql from './downloadImages.gql'
import phantomTestDetailsQuery from './phantom-test-details.gql'
import { delete_phantom_test_by_pk } from './types/delete_phantom_test_by_pk'
import { downloadImgaes } from './types/downloadImgaes'
import {
  phantomTestDetails,
  phantomTestDetails_phantom_test_by_pk_phantom_test_images,
} from './types/phantomTestDetails'
import { PhantomTestsComponent } from '../phantom-tests/phantom-tests.component'
import { TestResult } from 'common/testing/generated'
import { UserRole } from 'src/types/graphql-global-types'
import { AuthService } from 'src/app/auth/auth.service'

@Component({
  selector: 'app-phantom-test-details',
  templateUrl: './phantom-test-details.component.html',
  styleUrls: ['./phantom-test-details.component.scss'],
})
export class PhantomTestDetailsComponent implements OnInit {
  daysSinceLastCalibration: number

  title = 'test details'

  actions: PageHeaderAction[] = [
    {
      text: 'Delete Test',
      class: 'danger',
      authorized: [UserRole.admin, UserRole.support],
    },
    { text: 'Download Images', class: 'success' },
    { text: 'Download Raw Data', class: 'success' },
  ]

  facilityLinkActions: LinkAction[] = [
    { name: 'facilityDetails', shape: 'cog', text: 'Facility details' },
  ]

  scannerLinkActions: LinkAction[] = [
    {
      name: 'phantomTestsForScanner',
      shape: 'tasks',
      text: `Tests on this scanner`,
    },
    { name: 'scannerDetails', shape: 'cog', text: 'Scanner details' },
  ]

  studyLinkActions: LinkAction[] = [
    { name: 'studyDetails', shape: 'cog', text: 'Study details' },
  ]

  phantomTestId: number

  result: ApolloQueryResult<phantomTestDetails>

  routerEventSub: Subscription

  images: GALLERY_IMAGE[]

  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo,
    private fileService: FileService,
    private router: Router,
    private nav: NavService,
    private auth: AuthService,
  ) {}

  /* Getters */

  get test() {
    return this.result?.data?.phantom_test_by_pk
  }

  get testDate() {
    return this.test.dateCreated
  }

  get testResult() {
    return this.test.result
  }

  get rawOutput() {
    return this.test.rawOutput
  }

  get scanner() {
    return this.test.scanner
  }

  get scannerId() {
    return this.scanner.id
  }

  get scannerName() {
    return this.scanner.name
  }

  get study() {
    return this.test.study
  }

  get studyDate() {
    return this.study.scanDate
  }

  get tester() {
    return this.test.user
  }

  get facility() {
    return this.scanner.facility
  }

  get facilityName() {
    return this.facility?.name
  }

  get room() {
    return this.scanner.facility_room
  }

  get roomName() {
    return this.room.name
  }

  get routine() {
    return this.test.phantom_test_routine
  }

  get phantom() {
    return this.routine.phantom
  }

  get helicalContrastScale() {
    const val = this.test.evaluationItems.lowHigh.HC_helical_ContrastDiff
    if (!val) {
      console.warn('helicalContrastScale undefined')
      console.log(this.test.evaluationItems)
    }
    return val
  }

  get isNewFrameworkTest() {
    return this.rawOutputIsNewFramework(this.test?.rawOutput)
  }

  get extra() {
    return this.test.scanner.extra
  }

  get lastCalibrationDate() {
    return this.extra['Last Calibration Date']
  }

  /* Lifecycle Hooks */

  ngOnInit() {
    this.routerEventSub = this.router.events.subscribe((event) =>
      this.handleRouterEvent(event),
    )
    const { id } = this.route.snapshot.params
    if (typeof id === 'string') {
      this.phantomTestId = parseInt(id, 10)
    } else {
      throw new Error(`Phantom Test Not Found`)
    }
    this.fetchPhantomTest()
  }

  ngOnDestroy() {
    this.routerEventSub?.unsubscribe()
  }

  /* Nav */

  viewFacility() {
    this.nav.navtree.facilityDetails(this.facility?.id)
  }

  viewScanner() {
    this.nav.navtree.scannerDetails(this.scanner.id)
  }

  /* Methods */

  testHasResult() {
    return this.test.result !== 'na'
  }

  actionsHandler($event: string) {
    switch ($event) {
      case 'studyDetails':
        this.nav.navtree.studyDetails(this.test.study.id)
        break
      case 'Delete Test':
        this.deleteTest()
        break
      case 'Download Images':
        this.downloadImages()
        break
      case 'Download Raw Data':
        this.downloadRaw()
        break
      case 'facilityDetails':
        this.nav.navtree.facilityDetails(this.test.scanner.facility?.id)
        break
      case 'scannerDetails':
        this.nav.navtree.scannerDetails(this.test.scanner.id)
        break
      case 'phantomTestsForScanner': {
        const state: DatagridManualState = {
          filters: [
            {
              type: FilterType.select,
              property: PhantomTestsComponent.properties.scanner.filter,
              exact: this.test.scanner.id,
            },
          ],
        }
        this.nav.navtree.phantomTests(state)
        break
      }
      default:
        break
    }
  }

  handleRouterEvent(event: unknown) {
    if (event instanceof NavigationEnd) {
      this.ngOnDestroy()
      this.ngOnInit()
    }
  }

  fetchPhantomTest() {
    const query = phantomTestDetailsQuery
    const variables = {
      id: this.phantomTestId,
    }
    this.apollo
      .query<phantomTestDetails>({ query, variables })
      .subscribe({
        next: (result) => {
          this.result = result
          if (result.errors) {
            throw result.errors[0]
          }
          if (!this.test) {
            throw new MessageError('test not found')
          }
          this.images = result.data.phantom_test_by_pk.phantom_test_images.map(
            (i): GALLERY_IMAGE => ({
              title: i.label,
              url: this.src(i),
            }),
          )
          this.updateDaysSinceLastCalibration()
        },
      })
  }

  src(
    image: phantomTestDetails_phantom_test_by_pk_phantom_test_images,
  ): string {
    return this.fileService.getHostedUrl(image.stored_file.key)
  }

  deleteTest() {
    if (window.confirm('Delete Test: are you sure?')) {
      this.apollo
        .mutate<delete_phantom_test_by_pk>({
          mutation: deleteMutation,
          variables: { id: this.phantomTestId },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            window.history.back()
          },
        })
    }
  }

  downloadImages() {
    this.apollo
      .query<downloadImgaes>({
        query: downloadImagesGql,
        variables: { phantomTestId: this.test.id, userId: this.auth.user.id },
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.fileService.download(result.data.downloadPhantomTestImages)
        },
      })
  }

  downloadRaw() {
    var textFile = null,
      makeTextFile = function (text) {
        var data = new Blob([text], { type: 'application/json' })

        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (textFile !== null) {
          window.URL.revokeObjectURL(textFile)
        }

        textFile = window.URL.createObjectURL(data)

        // returns a URL you can use as a href
        return textFile
      }
    const url = makeTextFile(
      JSON.stringify(
        {
          rawOutput: this.test.rawOutput,
          evaluationItems: this.test.evaluationItems,
        },
        null,
        2,
      ),
    )
    const link = document.createElement('a')
    link.download = `${this.test.scanner.name}-${moment(
      this.study.scanDate,
    ).format('LL')}-${this.test.id}`.replace(/[^a-z0-9\-]/gi, '_')
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  rawOutputIsNewFramework(rawOutput: {
    series?: unknown
  }): rawOutput is TestResult {
    return 'series' in rawOutput && Array.isArray(rawOutput.series)
  }

  updateDaysSinceLastCalibration() {
    const today = new Date()
    const lcDate = new Date(this.lastCalibrationDate)
    // number of days difference
    this.daysSinceLastCalibration = Math.floor(
      (today.getTime() - lcDate.getTime()) / (1000 * 60 * 60 * 24),
    )
  }
}
