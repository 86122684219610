/**
 * If this error is thrown, it means a developer messed up
 */
export class DevError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'Developer Error'
  }
}

/**
 * When this type of error is thrown, the error message will be displayed verbatim to the user
 */
export class MessageError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'Error'
  }
}
