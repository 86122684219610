import { gql } from 'apollo-angular'

export default gql`
  query facilities(
    $distinct_on: [facility_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [facility_order_by!]
    $where: facility_bool_exp
  ) {
    facility(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      name
      address
      phone
      facilityId
    }
    facility_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
