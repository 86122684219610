export const colors = {
  BLUE: '#0074D9',
  AQUA: '#7FDBFF',
  TEAL: '#39CCCC',
  OLIVE: '#3D9970',
  GREEN: '#2ECC40',
  LIME: '#01FF70',
  YELLOW: '#FFDC00',
  ORANGE: '#FF851B',
  RED: '#FF4136',
  MAROON: '#85144b',
  FUCHSIA: '#F012BE',
  GRAY: '#AAAAAA',
  SILVER: '#DDDDDD',
}
