import { Apollo } from 'apollo-angular'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { cloneDeep } from 'lodash'
import { comment_category_insert_input } from 'src/types/graphql-global-types'
import { colors } from '../../../config/colors'
import { camel, safeGet, StringObjectLiteral } from '../../util/util'
import { commentCategoriesDetails_comment_category_by_pk } from '../comment-categories-details/types/commentCategoriesDetails'
import editMutation from './comment-categories-edit.gql'
import insertMutation from './comment_categories-insert.gql'
import { edit_comment_category_by_pk } from './types/edit_comment_category_by_pk'
import { insert_comment_category } from './types/insert_comment_category'

@Component({
  selector: 'app-comment-categories-edit',
  templateUrl: './comment-categories-edit.component.html',
  styleUrls: ['./comment-categories-edit.component.scss'],
})
export class CommentCategoriesEditComponent implements OnInit {
  createNew = false

  newComment_category: comment_category_insert_input = {}

  @Input() comment_category: commentCategoriesDetails_comment_category_by_pk

  private _backup_comment_category: commentCategoriesDetails_comment_category_by_pk

  @Output() saveEvent = new EventEmitter()

  safeGet = safeGet

  objectKeys = Object.keys

  colors = colors

  constructor(private apollo: Apollo) {}

  get colorKeys(): string[] {
    return Object.keys(colors)
  }

  ngOnInit() {
    // figure out if we're editing or creating a comment_category
    if (!this.comment_category) {
      this.createNew = true
    }
    if (!this.createNew) {
      this._backup_comment_category = cloneDeep(this.comment_category)
    }
  }

  save() {
    if (this.createNew) {
      this.apollo
        .mutate<insert_comment_category>({
          mutation: insertMutation,
          variables: { comment_category: this.newComment_category },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.newComment_category = {}
            this.saveEvent.emit('create')
          },
        })
    } else {
      this.apollo
        .mutate<edit_comment_category_by_pk>({
          mutation: editMutation,
          variables: {
            id: this.comment_category.id,
            _set: this.stripInput(this.comment_category),
          },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.saveEvent.emit('edit')
          },
        })
    }
  }

  back() {
    this.comment_category = cloneDeep(this._backup_comment_category)
    this.saveEvent.emit('back')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripInput = (obj: StringObjectLiteral<any>) =>
    Object.keys(obj).reduce((newObj, key) => {
      if (
        key !== 'id' &&
        key !== 'dateCreated' &&
        key !== 'dateUpdated' &&
        key !== '__typename'
      ) {
        if (
          typeof obj[key] === 'string' ||
          typeof obj[key] === 'number' ||
          (obj[key] === null && key.slice(-2) === 'Id')
        ) {
          newObj[camel(key)] = obj[key]
        }
      }
      return newObj
    }, {})

  getModel():
    | commentCategoriesDetails_comment_category_by_pk
    | comment_category_insert_input {
    return this.createNew ? this.newComment_category : this.comment_category
  }

  setModel(key: string, val: string) {
    this.getModel()[key] = val
  }
}
