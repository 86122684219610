<ng-container *ngFor="let section of sections | keyvalue">
  <div class="card">
    <!-- No title for the questions without a section -->
    <div *ngIf="section.key !== ''" class="card-header">
      {{ section.key }}
    </div>

    <!-- Section body -->
    <div class="card-block">
      <div class="clr-row">
        <ng-container *ngFor="let question of section.value">
          <div class="clr-col-6">
            <div class="clr-row">
              <div class="clr-col-6">
                {{ question.text }}
              </div>
              <div class="clr-col-6">
                <span class="{{ question.result }}">{{ question.answer }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
