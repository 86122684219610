import { Apollo } from 'apollo-angular'
import { Location } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { ClrForm } from '@clr/angular'

import { AuthService } from 'src/app/auth/auth.service'
import { CommService } from '../../core/comm.service'
import changePwMutation from './change_pw.gql'
import { change_pw, change_pwVariables } from './types/change_pw'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  _open: boolean

  @Output() openChange = new EventEmitter<boolean>()

  @Output() passwordChanged = new EventEmitter()

  @ViewChild(ClrForm) clrForm: ClrForm

  pw: {
    old?: string
    new?: string
    con?: string
  } = {}

  constructor(
    private comm: CommService,
    private apollo: Apollo,
    private auth: AuthService,
    private location: Location,
  ) {}

  ngOnInit() {}

  @Input() set open(val: boolean) {
    this._open = val
    this.openChange.emit(this.open)
  }

  get open() {
    return this._open
  }

  passwordsMatch(): boolean {
    const match = this.pw.new === this.pw.con
    return match
  }

  submitPw() {
    const variables: change_pwVariables = {
      userId: this.auth.user.id,
      oldPassword: this.pw.old,
      newPassword: this.pw.new,
      confirmPassword: this.pw.con,
    }
    this.clrForm.markAsTouched()
    if (this.pw.new && this.pw.old && this.pw.con) {
      this.apollo
        .mutate<change_pw>({ mutation: changePwMutation, variables })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.comm.alert('success', 'Password changed')
            this.open = false
            this.openChange.emit(this.open)
          },
        })
    }
  }

  cancel() {
    this.open = false
    this.location.back()
  }
}
