import { isObjectLiteral } from 'src/app/util/util'

export const isAuthError = (e: any) =>
  e?.extensions?.code === 'invalid-jwt'
export const isNetworkError = (e: unknown) =>
  e instanceof Error && /Network error:.*0 Unknown Error/gm.test(e?.message)
export const isMissingFieldError = (e: unknown) =>
  e instanceof Error &&
  /expecting a value for non-nullable variable.*/gm.test(e?.message)
export const isQueryError = (e: unknown) =>
  isObjectLiteral(e) &&
  typeof e.message === 'string' &&
  /field.*not found in.*/gm.test(e.message)
export const isNavError = (e: unknown) =>
  isObjectLiteral(e) &&
  typeof e.message === 'string' &&
  /.*Cannot match any routes.*/gm.test(e.message)
