import { ApolloQueryResult } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'
import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/auth/auth.service'
import { CommService } from 'src/app/core/comm.service'

import { usersDetails } from '../users-details/types/usersDetails'
import query from '../users-details/users-details.gql'

import { MessageError } from 'src/app/core/errors/errors'

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  constructor(
    public auth: AuthService,
    private comm: CommService,
    private apollo: Apollo,
  ) {}
  private queryResult: ApolloQueryResult<usersDetails>
  loading = false

  fetchusersDetails = () => {
    this.loading = true
    this.apollo
      .query<usersDetails>({ query, variables: { id: this.auth.user.id } })
      .subscribe({ next: this.onNext, error: this.onError })
  }

  onNext = (result: ApolloQueryResult<usersDetails>) => {
    this.loading = false
    this.queryResult = result
    if (result.errors) {
      throw result.errors[0]
    }
    if (!this.user) {
      throw new MessageError('user not found')
    }
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  get user() {
    return this.queryResult?.data?.user_by_pk
  }

  ngOnInit() {
    this.fetchusersDetails()
  }

  onSave($event: string) {
    if ($event === 'edit') {
      this.comm.alert('success', 'Saved')
    }
  }
}
