import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { DatagridStateService } from 'src/app/core/datagrid-state.service'
import { DevError } from 'src/app/core/errors/errors'
import { NavService } from 'src/app/core/nav.service'
import { DatagridComponent } from 'src/app/util/datagrid.class'
import { colors } from 'src/config/colors'
import { TimezoneService } from 'src/app/timezone.service'
import { KeyVal, userFullname } from '../../util/util'
import relatedEntityQuery from '../comments-edit/comment_get_related_entities.gql'
import { comment_get_related_entities } from '../comments-edit/types/comment_get_related_entities'
import query from './comments.gql'
import { comments, commentsVariables, comments_comment } from './types/comments'

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent extends DatagridComponent implements OnInit {
  invariants: commentsVariables = {}
  title: string
  @Input() scannerId: number
  @Input() phantomTestId?: number
  @Input() missedPhantomTestId?: number
  @Input() surveyTestId?: number
  addingComment = false
  colors = colors
  getUserFullname = userFullname

  static properties = {
    dateCreated: 'dateCreated',
    user: { sort: 'user.lastName', filter: 'user.id' },
    subject: 'subject',
    comment_category: {
      sort: 'comment_category.name',
      filter: 'comment_category.id',
    },
  }
  properties = CommentsComponent.properties

  constructor(
    private apollo: Apollo,
    protected router: Router,
    protected dgStateService: DatagridStateService,
    protected timezoneService: TimezoneService,
  ) {
    super(dgStateService, router, timezoneService)
    // add default if necessary
    this.defaultState = {
      sort: {
        by: this.properties.dateCreated,
        reverse: true,
      },
    }
  }

  ngOnInit() {
    this.validateInput()
    this.title = `comments-${this.scannerId}`
    if (this.phantomTestId) {
      this.invariants.where = { phantomTestId: { _eq: this.phantomTestId } }
      this.title = `${this.title}-phantomTest-${this.phantomTestId}`
    }
    if (this.surveyTestId) {
      this.invariants.where = { surveyTestId: { _eq: this.surveyTestId } }
      this.title = `${this.title}-surveyTest-${this.surveyTestId}`
    }
    if (this.missedPhantomTestId) {
      this.invariants.where = {
        missedPhantomTestId: { _eq: this.missedPhantomTestId },
      }
      this.title = `${this.title}-missedPhantomTest-${this.missedPhantomTestId}`
    }
    this.invariants.where = {
      ...this.invariants.where,
      scannerId: { _eq: this.scannerId },
    }
  }

  validateInput() {
    if (!this.scannerId) {
      throw new DevError('you must specify a scannerId')
    }
    let childrenCount: number = 0
    if (this.phantomTestId) {
      childrenCount += 1
    }
    if (this.missedPhantomTestId) {
      childrenCount += 1
    }
    if (this.surveyTestId) {
      childrenCount += 1
    }
    if (childrenCount > 1) {
      throw new DevError(
        'you may only specify one of phantomTestId, missedPhantomTestId, or surveyTestId',
      )
    }
  }

  items: comments_comment[]
  totalItems: number

  fetchItems = () => {
    this.loading = true
    this.apollo
      .query<comments>({ query, variables: this.variables })
      .subscribe({
        next: (result: ApolloQueryResult<comments>) => {
          this.loading = false
          if (result.errors) {
            throw result.errors[0]
          }
          this.items = result.data.comment
          this.totalItems = result.data.comment_aggregate.aggregate.count
        },
        error: this.onError,
      })
    this.apollo
      .query<comment_get_related_entities>({ query: relatedEntityQuery })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.assembleRelatedEntityOptions(result.data)
        },
      })
  }

  options: {
    comment_categories: KeyVal[]
    users: KeyVal[]
  } = {
    comment_categories: [],
    users: [],
  }

  assembleRelatedEntityOptions(data: comment_get_related_entities) {
    this.options = {
      comment_categories: data.comment_category.map((c) => ({
        key: c.id,
        val: c.name,
      })),
      users: data.user.map((u) => ({
        key: u.id,
        val: this.getUserFullname(u),
      })),
    }
  }

  onUpdate($event: string) {
    switch ($event) {
      case 'delete':
      case 'edit':
        this.fetchItems()
        break
      case 'create':
      case 'back':
        this.addingComment = false
        this.fetchItems()
        break
      default:
        break
    }
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  addComment() {
    this.addingComment = true
  }

  getCategoryColor(comment: comments_comment): string | undefined {
    return comment.comment_category?.color
  }

  get isScannersView() {
    return (
      !this.phantomTestId && !this.surveyTestId && !this.missedPhantomTestId
    )
  }

  testDate(item: comments_comment) {
    return (
      item.phantom_test?.study.scanDate ||
      item.survey_test?.dateCreated ||
      item.missed_phantom_test?.scheduledDate
    )
  }
}
