import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, Input, OnInit } from '@angular/core'
import { ClrLoadingState } from '@clr/angular'

import {
  PhantomTestingService,
  TestStudyState,
} from 'src/app/core/phantom-testing.service'
import { phantomTestDetails_phantom_test_by_pk } from '../phantom-test-details/types/phantomTestDetails'
import studiesDetailsGql from '../studies-details/studies-details.gql'
import { studiesDetails } from '../studies-details/types/studiesDetails'

@Component({
  selector: 'app-na-test-options',
  templateUrl: './na-test-options.component.html',
  styleUrls: ['./na-test-options.component.scss'],
})
export class NaTestOptionsComponent implements OnInit {
  @Input() test: phantomTestDetails_phantom_test_by_pk

  studiesDetails: studiesDetails

  phantomTestingState: TestStudyState

  constructor(
    private apollo: Apollo,
    private phantomTestingService: PhantomTestingService,
  ) {}

  ngOnInit() {
    this.fetchstudiesDetails()
  }

  fetchstudiesDetails = () => {
    const query = studiesDetailsGql
    this.apollo
      .query<studiesDetails>({ query, variables: { id: this.test.study.id } })
      .subscribe({ next: (result) => this.setup(result) })
  }

  setup(result: ApolloQueryResult<studiesDetails>) {
    if (result.errors) {
      throw result.errors[0]
    }
    const studyId = result.data.study_by_pk.id
    this.phantomTestingState = {
      routineSelected: this.test.phantom_test_routine.id,
      unevaluatedTestArrived: this.test.id,
      answersSubmitted: false,
      questions: undefined,
      answers: {},
      submitButton: ClrLoadingState.DEFAULT,
    }
    this.phantomTestingService.state[studyId] = this.phantomTestingState
    this.studiesDetails = result.data
    this.phantomTestingService.assembleQuestions(
      studyId,
      this.test.phantom_test_routine.id,
      result.data.study_by_pk.modality.phantom_test_routines,
    )
  }
}
