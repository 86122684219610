import { Component } from '@angular/core'

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  dragover = false

  files: File[] = []

  uploadFile(event: FileList) {
    console.log(event)
    for (let index = 0; index < event.length; index += 1) {
      const element = event[index]
      this.files.push(element)
    }
  }

  deleteAttachment(index) {
    this.files.splice(index, 1)
  }

  onDragOver() {
    this.dragover = true
  }

  onDragLeave() {
    this.dragover = false
  }
}
