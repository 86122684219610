import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, ViewChild } from '@angular/core'
import { Router } from '@angular/router'

import { Phantom, Result } from 'common/aqsTypes'
import { DatagridStateService } from 'src/app/core/datagrid-state.service'
import { FriendlyPhantomNamePipe } from 'src/app/core/friendly-phantom-name.pipe'
import { NavService } from 'src/app/core/nav.service'
import { TimezoneService } from 'src/app/timezone.service'
import { DatagridComponent } from 'src/app/util/datagrid.class'
import { KeyVal, userFullname } from '../../util/util'
import phantom_test_get_related_entitiesGql from '../phantom-tests-edit/phantom_test_get_related_entities.gql'
import { phantom_test_get_related_entities } from '../phantom-tests-edit/types/phantom_test_get_related_entities'
import query from './problems.gql'
import { problems, problems_problem } from './types/problems'
import { FilterType, NumberFilterInterface } from 'src/app/filters/filters.util'
import { NumberFilterComponent } from 'src/app/filters/number-filter/number-filter.component'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-problems',
  templateUrl: './problems.component.html',
  styleUrls: ['./problems.component.scss'],
})
export class ProblemsComponent extends DatagridComponent {
  title = 'problems'
  numberFilterSubscription: Subscription

  @ViewChild('numberFilter') numberFilter: NumberFilterComponent
  withoutComments = true
  setWithoutComments(value: boolean) {
    this.withoutComments = value
    this.numberFilter.accepts(value ? 0 : undefined, 'max')
  }

  static properties = {
    facilityName: 'facilityName',
    facilityRoomName: 'facilityRoomName',
    modalityName: 'modalityName',
    scannerName: 'scannerName',
    testDate: 'testDate',
    result: 'result',
    commentsCount: 'commentsCount',
  }
  properties = ProblemsComponent.properties

  constructor(
    private apollo: Apollo,
    private nav: NavService,
    protected router: Router,
    protected dgStateService: DatagridStateService,
    private friendlyPhantomNamePipe: FriendlyPhantomNamePipe,
    protected timezoneService: TimezoneService,
  ) {
    super(dgStateService, router, timezoneService)
    this.defaultState = {
      sort: {
        by: this.properties.testDate,
        reverse: true,
      },
      filters: [
        {
          type: FilterType.number,
          max: 0,
          property: 'commentsCount',
        },
      ],
    }
  }

  items: problems_problem[]
  totalItems: number

  selectedScannerId: number | undefined

  ngAfterViewInit(): void {
    super.ngAfterViewInit()
    this.numberFilterSubscription = this.numberFilter.changes.subscribe({
      next: (change) => {
        if (this.numberFilter.max === 0) {
          this.withoutComments = true
        } else {
          this.withoutComments = false
        }
      },
    })
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    this.numberFilterSubscription.unsubscribe()
  }

  fetchItems = () => {
    this.loading = true
    this.apollo
      .query<problems>({ query, variables: this.variables })
      .subscribe({
        next: (result: ApolloQueryResult<problems>) => {
          this.loading = false
          if (result.errors) {
            throw result.errors[0]
          }
          this.items = result.data.problem
          this.totalItems = result.data.problem_aggregate.aggregate.count
        },
        error: this.onError,
      })
    this.apollo
      .query<phantom_test_get_related_entities>({
        query: phantom_test_get_related_entitiesGql,
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.assembleRelatedEntityOptions(result.data)
        },
        error: this.onError,
      })
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  options: {
    facilities: KeyVal<string, string>[]
    rooms: KeyVal<string, string>[]
    modalities: KeyVal<string, string>[]
    scanners: KeyVal<string, string>[]
    results: KeyVal<string, string>[]
  } = {
    facilities: [],
    rooms: [],
    modalities: [],
    scanners: [],
    results: [],
  }

  assembleRelatedEntityOptions(data: phantom_test_get_related_entities) {
    this.options = {
      facilities: data.facility.map((f) => ({
        key: f.name,
        val: f.name,
      })),
      rooms: data.facility_room.map((r) => ({
        key: r.name,
        val: r.name,
      })),
      modalities: data.modality.map((m) => ({
        key: m.id,
        val: m.id,
      })),
      scanners: data.scanner.map((s) => ({
        key: s.name,
        val: s.name,
      })),
      results: Object.values(Result).map(
        (r): KeyVal<string, string> => ({ key: r, val: r }),
      ),
    }
  }

  view(test: problems_problem) {
    if (test.tableName === 'phantom_test') {
      this.nav.navtree.phantomTestDetails(test.id)
    } else if (test.tableName === 'missed_phantom_test') {
      this.nav.navtree.missedPhantomTestDetails(test.id)
    }
  }
}
