<app-missed-phantom-tests-edit
  *ngIf="editing"
  [missed_phantom_test]="missedPhantomTest"
  (saveEvent)="onSave($event)"
>
</app-missed-phantom-tests-edit>
<ng-container *ngIf="!editing">
  <div [clrLoading]="loading">
    <div *ngIf="missedPhantomTest">
      <!-- Header and actions -->
      <div
        class="clr-row"
        appPageHeader
        [title]="title"
        [actions]="actions"
        (clicks)="actionsHandler($event)"
      ></div>
      <app-record-subtitle
        [facility]="facility"
        [room]="room"
        [scanner]="scanner"
      ></app-record-subtitle>

      <div class="clr-row">
        <!-- Basic Info -->
        <div class="clr-col-6">
          <table class="table">
            <tbody>
              <tr>
                <td class="left">Scheduled Date:</td>
                <td class="left">{{ scheduledDate | customDate }}</td>
              </tr>
              <tr>
                <td class="left">Facility:</td>
                <td class="left">{{ facilityName }}</td>
              </tr>
              <tr *ngIf="room">
                <td class="left">Room:</td>
                <td class="left">{{ roomName }}</td>
              </tr>
              <tr class="{{ testResult }}">
                <td class="left"><strong>Result:</strong></td>
                <td class="left">
                  <strong>{{ testResult }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="clr-col-12">
          <app-comments
            [scannerId]="scannerId"
            [missedPhantomTestId]="missed_phantom_testId"
          ></app-comments>
        </div>
      </div>
    </div>
  </div>
</ng-container>
