<div *ngIf="missed_phantom_test">
  <form clrForm>
    <clr-select-container>
      <label>Scanner</label>
      <select
        *ngIf="relatedEntityOptionsQueryResult"
        clrSelect
        name="Scanner"
        [(ngModel)]="missed_phantom_test.scannerId"
      >
        <option
          *ngFor="let scanner of relatedEntityOptions.scanner"
          value="{{ scanner.id }}"
          >{{ scanner.name }}</option
        >
      </select>
    </clr-select-container>
    <clr-input-container>
      <label>Scheduled Date</label>
      <input
        clrInput
        name="Scheduled Date"
        [(ngModel)]="missed_phantom_test.scheduledDate"
      />
    </clr-input-container>
  </form>

  <button (click)="save()" class="btn btn-success-outline">Save</button>
  <button (click)="back()" class="btn btn-outline">Back</button>
</div>
