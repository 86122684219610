import { Component, OnInit, Input, EventEmitter } from '@angular/core'
import { phantomTestDetails_phantom_test_by_pk } from '../types/phantomTestDetails'
import { isEmpty } from 'lodash'
import { ToggleState } from '../../generic-test-display/generic-test-display.component'
import { TestDisplay } from 'common/aqsTypes'

@Component({
  selector: 'app-testing-output',
  templateUrl: './testing-output.component.html',
  styleUrls: ['./testing-output.component.scss'],
})
export class TestingOutputComponent implements OnInit {
  @Input() test: phantomTestDetails_phantom_test_by_pk
  collapsableView: boolean = true
  stateEmitter: EventEmitter<ToggleState> = new EventEmitter()

  isEmpty = isEmpty

  constructor() {}

  ngOnInit() {}

  get rawOutput() {
    return this.test.rawOutput
  }

  get questions() {
    return this.test.answered_phantom_test_questions
  }

  setState(state: ToggleState) {
    this.stateEmitter.emit(state)
  }

  get displayItems(): TestDisplay[] {
    const testDisplay: TestDisplay | TestDisplay[] | undefined = this.test
      .evaluationItems?.testDisplay
    if (isEmpty(testDisplay)) {
      return []
    }
    return Array.isArray(testDisplay) ? testDisplay : [testDisplay]
  }
}
