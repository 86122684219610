import { Injectable } from '@angular/core'
import { AlertType, AlertService } from './alert/alert.service'

/**
 * This service handles all communication with the user of the app including alerts, loading bars, and such.
 */
@Injectable({
  providedIn: 'root',
})
export class CommService {
  private progressBarSem = 0

  constructor(private alertService: AlertService) {}

  get progressBar() {
    return this.progressBarSem > 0
  }

  startTask() {
    this.progressBarSem += 1
  }

  finishTask() {
    if (this.progressBarSem > 0) {
      this.progressBarSem -= 1
    } else {
      console.warn('progress bar tried to go below zero')
    }
  }

  forceCancel() {
    this.progressBarSem = 0
  }

  alert(type: AlertType, text: string, duration?: number, viewLink?: string) {
    this.alertService.alert(type, text, duration, viewLink)
  }
}
