import { gql } from 'apollo-angular'

export default gql`
  mutation edit_comment_category_by_pk(
    $id: Int!
    $_set: comment_category_set_input
  ) {
    update_comment_category(where: { id: { _eq: $id } }, _set: $_set) {
      affected_rows
      returning {
        id
      }
    }
  }
`
