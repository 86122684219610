import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Apollo } from 'apollo-angular'
import * as moment from 'moment-timezone'
import { rrulestr } from 'rrule'

import { AuthService } from '../auth/auth.service'
import { UserRole } from '../../types/graphql-global-types'
import { version } from '../../../package.json'
import { NavService } from '../core/nav.service'
import { TimezoneService } from '../timezone.service'
import { EventsService } from '../events.service'
import { CommService } from 'src/app/core/comm.service'

import { schedules, schedules_schedule } from './schedules/types/schedules'
import allSchedulesGql from './schedules/schedules.gql'
import {
  currUserEmail,
  scannerDetails,
  globalSupport,
  lookupPtExist,
  lookupStExist,
} from './checkSchedule.gql'
import { resolve } from 'dns'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  currUserEmail: String | null
  globalSupportList: String[] = []

  collapsed = false

  modalOpen = false
  version = version
  versionExpanded: [string, string, string] = ['0', '0', '0']

  adminRoles = [UserRole.admin, UserRole.support]
  constructor(
    private apollo: Apollo,
    private nav: NavService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public auth: AuthService,
    private timezoneService: TimezoneService,
    private events: EventsService,
    private comm: CommService,
  ) {}

  ngOnInit() {
    console.log(`running version ${this.version}`)
    const versionSplit = this.version.split('.')
    this.versionExpanded = [
      versionSplit[0] || '0',
      versionSplit[1] || '0',
      versionSplit[2] || '0',
    ]
    this.timezoneService.retrieveTzFromServer()
    this.sendScheduleAlert()
  }

  getUserEmail() {
    return new Promise<string | null>((resolve, reject) => {
      this.apollo
        .query<any>({
          query: currUserEmail,
          variables: { userId: this.userid },
        })
        .subscribe({
          next: (result) => {
            if (!result.data?.user[0]) {
              this.auth.logout()
              resolve(null)
            } else {
              resolve(result.data.user[0].email)
            }
          },
          error: () => {
            console.log('Failed to get user email')
            resolve(null)
          },
        })
    })
  }

  getGlobalSupportList() {
    return new Promise<String[]>((resolve, reject) => {
      this.apollo
        .query<any>({
          query: globalSupport,
        })
        .subscribe({
          next: (result) => {
            resolve(result.data.setting[0].json)
          },
          error: () => {
            console.log('Fail to retrieve global support list')
            resolve([])
          },
        })
    })
  }

  async sendScheduleAlert() {
    this.currUserEmail = await this.getUserEmail()

    // Skip alerts if no user email
    if (this.currUserEmail) {
      this.globalSupportList = await this.getGlobalSupportList()
      let schedulesToDisplay: String = ''
      const schedulesPromise: any = await this.getSchedules()

      const schedulesList: schedules_schedule[] = schedulesPromise.data.schedule

      for (const sch of schedulesList) {
        const schedulesDue: Date[] = this.checkSchedulesDue(sch)
        if (schedulesDue.length > 0) {
          const dueDates: Date[] = schedulesDue
          const scannersList: String[] = await this.getScannersDue(
            sch,
            dueDates,
          )
          if (scannersList.length > 0) {
            const scannersListToDisplay: String = scannersList.reduce(
              (prev, curr) => {
                return prev + ', ' + curr
              },
            )
            schedulesToDisplay +=
              sch.title + '(' + scannersListToDisplay + '), '
          }
        }
      }

      //send alert if schedules
      if (schedulesToDisplay) {
        // remove last ', '
        schedulesToDisplay = schedulesToDisplay.slice(0, -2)
        this.comm.alert(
          'warning',
          `List of schedules available to do: \r
      ${schedulesToDisplay}`,
          -1,
          `/home/schedules/`,
        )
      }
    }
  }

  async getScannersDue(sch, dueDates: Date[]) {
    const ptRoutineList: number[] = []
    const stRoutineList: number[] = []

    // get list of phantom routines on schedule
    for (const routine of sch.schedule_phantom_test_routines_phantom_test_routines) {
      ptRoutineList.push(routine.phantom_test_routine.id)
    }

    // get list of survey routines on schedule
    for (const routine of sch.schedule_survey_test_routines_survey_test_routines) {
      stRoutineList.push(routine.survey_test_routine.id)
    }

    let resultScannersList = []

    for (const scanner of sch.schedule_scanners_scanners) {
      const scannerId = scanner.scanner.id
      // check if curr user is responsible for scanner
      const userIsResponsible = await this.userResponsibleForScanner(scannerId)

      if (userIsResponsible) {
        const scannerHasSurvey = await this.checkScannerHasSurvey(
          scanner,
          dueDates[0],
          sch,
          stRoutineList,
        )
        const scannerHasPhantomTest = await this.checkScannerHasPhantomTest(
          scanner,
          dueDates[0],
          sch,
          ptRoutineList,
        )

        if (!(scannerHasSurvey || scannerHasPhantomTest)) {
          resultScannersList.push(scanner.scanner.name)
        }
      }
    }
    return resultScannersList
  }

  checkScannerHasSurvey(
    scanner,
    dueDate,
    sch,
    stRoutineList,
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Find date to start checking for test exist
      const earlyType: any = sch.priorToType ? sch.priorToType : 'day'
      const earlyAmount = sch.priorToAmount | 0
      const startDate = moment(dueDate).add(-earlyAmount, earlyType).toDate()

      // check survey exist
      this.apollo
        .query<any>({
          query: lookupStExist,
          variables: {
            scannerID: scanner.scanner.id,
            routineID: stRoutineList,
          },
        })
        .subscribe({
          next: (result) => {
            const survey = result.data.survey_test
            if (survey.length > 0) {
              const testDate = survey[0].dateCreated

              if (new Date(testDate) > startDate) {
                resolve(true)
              }
            }
            resolve(false)
          },
          error: () => {
            console.log('Failed to check survey')
            resolve(false)
          },
        })
    })
  }

  checkScannerHasPhantomTest(
    scanner,
    dueDate,
    sch,
    ptRoutineList,
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Find date to start checking for test exist
      const earlyType: any = sch.priorToType ? sch.priorToType : 'day'
      const earlyAmount = sch.priorToAmount | 0
      const startDate = moment(dueDate).add(-earlyAmount, earlyType).toDate()

      //check phantom test exist
      this.apollo
        .query<any>({
          query: lookupPtExist,
          variables: {
            scannerID: scanner.scanner.id,
            routineID: ptRoutineList,
          },
        })
        .subscribe({
          next: (result) => {
            const phantom = result.data.phantom_test
            if (phantom.length > 0) {
              const testDate = phantom[0].dateCreated
              if (new Date(testDate) > startDate) {
                resolve(true)
              }
            }
            resolve(false)
          },
          error: () => {
            console.log('Failed to check phantom tests')
            resolve(false)
          },
        })
    })
  }

  userResponsibleForScanner(scannerId) {
    return new Promise((resolve, reject) => {
      // check scanner support emails
      this.apollo
        .query<any>({
          query: scannerDetails,
          variables: {
            id: scannerId,
          },
        })
        .subscribe({
          next: (result) => {
            // check settings (global) emails
            if (this.globalSupportList.includes(this.currUserEmail)) {
              resolve(true)
            }

            // check individual scanner support
            const supportList = result.data.scanner_by_pk.supportContacts
            if (supportList.includes(this.currUserEmail)) {
              resolve(true)
            }
            resolve(false)
          },
          error: () => {
            console.log('Failed to check scanner support list')
          },
        })
    })
  }

  getSchedules() {
    return new Promise((resolve, reject) => {
      this.apollo
        .query<schedules>({ query: allSchedulesGql })
        .subscribe({
          next: (result) => {
            resolve(result)
          },
          error: () => {
            console.log(`Failed to retrieve schedules`)
            reject('Failed to check schedules')
          },
        })
    })
  }

  checkSchedulesDue(sch: schedules_schedule): Date[] {
    // Get today
    const today = moment().endOf('day')

    // find if any due dates exist between start and end range
    const earlyType: any = sch.priorToType ? sch.priorToType : 'day'
    const earlyAmount = sch.priorToAmount | 0
    const graceType: any = sch.graceType ? sch.graceType : 'day'
    const graceAmount = sch.graceAmount | 1

    const startRange = today.clone().add(-graceAmount, graceType).toDate()
    const endRange = today.clone().add(earlyAmount, earlyType).toDate()

    // returns an array of due dates
    const rruleDatesBetween = rrulestr(sch.interval).between(
      startRange,
      endRange,
      true,
    )

    // return list of due dates
    return rruleDatesBetween
  }

  get username() {
    const name = this.auth.user.username
    return name
  }

  get userid() {
    return this.auth.user.id
  }

  logout() {
    return this.auth.logout()
  }

  notifySidebarChange() {
    this.events.sidebarChange.emit(this.collapsed)
  }

  expandSidebar() {
    this.collapsed = false
  }

  collapseSidebar() {
    this.collapsed = true
  }

  home() {
    this.nav.navtree.home()
  }
  suffix(num: string) {
    if (num === '1') return 'st'
    if (num === '2') return 'nd'
    if (num === '3') return 'rd'
    return 'th'
  }
}
