import { gql } from 'apollo-angular'

export default gql`
  query phantom_test_get_related_entities {
    facility_room(order_by: { name: asc }) {
      id
      name
    }
    study(order_by: { scanDate: asc }) {
      id
      scanDate
    }
    user(order_by: { firstName: asc }) {
      id
      firstName
      lastName
    }
    facility(order_by: { name: asc }) {
      id
      name
    }
    modality(order_by: { id: asc }) {
      id
    }
    scanner(order_by: { name: asc }) {
      id
      name
    }
  }
`
