import { Pipe, PipeTransform } from '@angular/core'
const valInPercent = (val: number) => (isNaN(val) ? 0 : val * 100)

@Pipe({
  name: 'percentage',
})
export class PercentagePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value === 'number') {
      const multiplied = valInPercent(value)
      let str = ''
      if (multiplied >= 0) str += '+'
      if (Math.abs(multiplied) >= 10) str += multiplied.toFixed(0)
      if (Math.abs(multiplied) < 10)
        str += multiplied.toFixed(multiplied === 0 ? 0 : 1)
      str += '%'
      return str
    } else {
      console.warn('percentage pipe was called with a non-numerical value')
      return value
    }
  }
}
