<form clrForm>
  <div class="presets-container">
    <a (click)="preset('today')">today</a>
    <a (click)="preset('yesterday')">yesterday</a>
    <a (click)="preset('month')">this month</a>
    <a (click)="preset('year')">this year</a>
    <a (click)="preset('reset')">reset</a>
  </div>
  <clr-date-container>
    <label>Earliest</label>
    <input
      type="date"
      name="earliest"
      [clrDate]="earliest"
      (clrDateChange)="accepts($event, 'earliest')"
      clrDate
    />
  </clr-date-container>
  <clr-date-container>
    <label>Latest</label>
    <input
      type="date"
      name="latest"
      [clrDate]="latest"
      (clrDateChange)="accepts($event, 'latest')"
      clrDate
    />
  </clr-date-container>
</form>
