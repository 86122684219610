<div class="">
  <form clrForm>
    <clr-select-container>
      <label>Modality</label>
      <select
        *ngIf="relatedEntityOptionsQueryResult"
        clrSelect
        name="Modality"
        [(ngModel)]="study.modalityId"
      >
        <option
          *ngFor="let modality of relatedEntityOptions.modality"
          value="{{ modality.id }}"
          >{{ modality.id }}
        </option>
      </select>
    </clr-select-container>
    <clr-select-container>
      <label>Scanner</label>
      <select
        *ngIf="relatedEntityOptionsQueryResult"
        clrSelect
        name="Scanner"
        [(ngModel)]="study.scannerId"
      >
        <option
          *ngFor="let scanner of relatedEntityOptions.scanner"
          value="{{ scanner.id }}"
          >{{ scanner.name }}</option
        >
      </select>
    </clr-select-container>
  </form>

  <button (click)="save()" class="btn btn-success-outline">Save</button>
  <button (click)="back()" class="btn btn-outline">Back</button>
</div>
