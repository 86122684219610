import { isApolloError, ApolloError } from '@apollo/client/core'
import bugsnag from '@bugsnag/js'
import { ErrorHandler, Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

import { Router } from '@angular/router'
import { CommService } from '../comm.service'
import {
  isNetworkError,
  isMissingFieldError,
  isQueryError,
  isNavError,
  isAuthError,
} from './helpers'
import { MessageError } from './errors'

/**
 * How to add a new type of recognized error:
 * 1. Add to enum below
 * 2. Add a handler to GlobalErrorHandler.handlers
 * 3. Add logic in GlobalErrorHandler.findErrorType to classify your error
 */
enum ErrorType {
  'AuthError',
  'UnknownError',
  'NetworkError',
  'TypeError',
  'MissingFieldError',
  'ApolloError',
  'QueryError',
  'NavError',
  'MessageError',
  'ServerApolloError',
}

interface ServerApolloError {
  extensions: any
  locations: any[]
  message: string
  path: string[]
}
function isServerApolloError(e: any): e is ServerApolloError {
  return (
    typeof e === 'object' &&
    e !== null &&
    !!e.extensions &&
    Array.isArray(e.locations) &&
    typeof e.message === 'string' &&
    Array.isArray(e.path)
  )
}

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  private bugsnagClient = bugsnag.start(environment.bugsnagApiKey)

  /**
   * Add a handler here for your error
   */
  private handlers: { [key in ErrorType]: (e: unknown) => void } = {
    [ErrorType.AuthError]: () => 
      this.comm.alert('warning', "Session expired, please log in again"),
    [ErrorType.QueryError]: () =>
      this.comm.alert('danger', "Query Error: couldn't fetch data"),
    [ErrorType.UnknownError]: () =>
      this.comm.alert(
        'danger',
        'Something unexpected happened. Please try again later',
      ),
    [ErrorType.NetworkError]: () =>
      this.comm.alert(
        'danger',
        'Network Error: Are you connected to the internet?',
      ),
    [ErrorType.TypeError]: () =>
      this.comm.alert(
        'danger',
        'Something went wrong, try refreshing the page',
      ),
    [ErrorType.MissingFieldError]: () =>
      this.comm.alert('danger', 'Please fill out all fields'),
    [ErrorType.ApolloError]: (e: ApolloError) => {
      e.graphQLErrors.forEach((gqle) => {
        const code = gqle.extensions?.code
        if (code === 'INTERNAL_SERVER_ERROR') {
          this.comm.alert(
            'danger',
            'Something went wrong on our end. Please try again later',
          )
        } else {
          // it was a known error
          this.comm.alert('danger', gqle.message) // this message must have been written for the user
        }
      })
    },
    [ErrorType.ServerApolloError]: (e: ServerApolloError) => {
      const code = e.extensions?.code
      if (code === 'INTERNAL_SERVER_ERROR') {
        this.comm.alert(
          'danger',
          'Something went wrong on our end. Please try again later',
        )
      } else {
        // it was a known error
        this.comm.alert('danger', e.message) // this message must have been written for the user
      }
    },
    [ErrorType.NavError]: () =>
      // there's probably a better way to do this
      this.router.navigateByUrl(''),
    [ErrorType.MessageError]: (e: MessageError) =>
      this.comm.alert('danger', e.message),
  }

  constructor(private comm: CommService, private router: Router) {
    super()
  }

  handleError(e: unknown) {
    if (e instanceof Error && !!(e as any).rejection) {
      e = (e as any).rejection
    }
    super.handleError(e)
    this.alertUser(e)
    this.sendToIssueTracker(e)
  }

  /**
   * Add logic here to classify your error
   * @param e Error to classify
   */
  private findErrorType(e: unknown): ErrorType {
    if (isAuthError(e)) return ErrorType.AuthError
    if (e instanceof MessageError) return ErrorType.MessageError
    if (e instanceof TypeError) return ErrorType.UnknownError
    if (e instanceof Error && isApolloError(e)) return ErrorType.ApolloError
    if (isQueryError(e)) return ErrorType.QueryError
    if (isNetworkError(e)) return ErrorType.NetworkError
    if (isMissingFieldError(e)) return ErrorType.MissingFieldError
    if (isNavError(e)) return ErrorType.NavError
    if (isServerApolloError(e)) return ErrorType.ServerApolloError
    return ErrorType.UnknownError
  }

  private alertUser(e: unknown) {
    // find error type
    const errorType = this.findErrorType(e)
    // execute the handler for that error type
    this.handlers[errorType](e)
  }

  // Distinguish different falsy values
  private checkErrorType(e: any): string {
    let identifiedErrorType: string

    if (e === null) identifiedErrorType = 'Value of null'
    else if (e === undefined) identifiedErrorType = 'Value of undefined'
    else if (e === false) identifiedErrorType = 'Boolean value of false'
    else if (e === 0) identifiedErrorType = 'Number value of 0'
    else if (e === '') identifiedErrorType = 'Empty string'
    else identifiedErrorType = typeof e

    return identifiedErrorType
  }

  private customNotifyErrorMessage(e: any): string {
    let issueTrackerErrorType: string
    let errorDetails: string

    // identify type of error
    issueTrackerErrorType = this.checkErrorType(e)

    // extract error details
    try {
      // error will be thrown if cyclical object (references self)
      errorDetails = JSON.stringify(e)
      // if success but nothing returned
      if (!errorDetails) errorDetails = 'No error details parsed'
    } catch (err) {
      errorDetails = 'Circular error during JSON.stringify(errorDetails)'
    }

    const errorMessage = `ErrorType: ${issueTrackerErrorType}
    ErrorDetails: ${errorDetails}`

    return errorMessage
  }

  private sendToIssueTracker(e: any) {
    if (environment.production) {
      if (e instanceof Error) {
        // handles Error, string, or obj with 'name' and 'message' fields
        this.bugsnagClient.notify(e)
      } else if (e && e.message && e.stack) {
        // common guard if instaceof fails
        this.bugsnagClient.notify(e)
      } else {
        // create custom error if e is not Error type
        const errorMessage = this.customNotifyErrorMessage(e)
        this.bugsnagClient.notify(new Error(errorMessage))
      }
    }
  }
}
