import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { DatagridStateService } from 'src/app/core/datagrid-state.service'
import { NavService } from 'src/app/core/nav.service'
import { DatagridComponent } from 'src/app/util/datagrid.class'
import { TimezoneService } from 'src/app/timezone.service'
import { KeyVal } from '../../util/util'
import missed_phantom_test_get_related_entitiesGql from '../missed-phantom-tests-edit/missed_phantom_test_get_related_entities.gql'
import { missed_phantom_test_get_related_entities } from '../missed-phantom-tests-edit/types/missed_phantom_test_get_related_entities'
import query from './missed-phantom-tests.gql'
import {
  missedPhantomTests,
  missedPhantomTests_missed_phantom_test,
} from './types/missedPhantomTests'

@Component({
  selector: 'app-missed-phantom-tests',
  templateUrl: './missed-phantom-tests.component.html',
  styleUrls: ['./missed-phantom-tests.component.scss'],
})
export class MissedPhantomTestsComponent extends DatagridComponent {
  title = 'missed'

  static properties = {
    facility: { filter: 'scanner.facility.id', sort: 'scanner.facility.name' },
    facility_room: {
      filter: 'scanner.facility_room.id',
      sort: 'scanner.facility_room.name',
    },
    scanner: { filter: 'scanner.id', sort: 'scanner.name' },
    scheduledDate: 'scheduledDate',
  }
  properties = MissedPhantomTestsComponent.properties

  constructor(
    private apollo: Apollo,
    private nav: NavService,
    protected router: Router,
    protected dgStateService: DatagridStateService,
    protected timezoneService: TimezoneService,
  ) {
    super(dgStateService, router, timezoneService)
    this.defaultState = {
      sort: {
        by: this.properties.scheduledDate,
        reverse: true,
      },
    }
  }

  items: missedPhantomTests_missed_phantom_test[]
  totalItems: number

  fetchItems = () => {
    this.loading = true
    this.apollo
      .query<missedPhantomTests>({ query, variables: this.variables })
      .subscribe({
        next: (result: ApolloQueryResult<missedPhantomTests>) => {
          this.loading = false
          if (result.errors) {
            throw result.errors[0]
          }
          this.items = result.data.missed_phantom_test
          this.totalItems =
            result.data.missed_phantom_test_aggregate.aggregate.count
        },
        error: this.onError,
      })
    this.apollo
      .query<missed_phantom_test_get_related_entities>({
        query: missed_phantom_test_get_related_entitiesGql,
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.assembleRelatedEntityOptions(result.data)
        },
        error: this.onError,
      })
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  options: {
    facilities: KeyVal[]
    rooms: KeyVal[]
    scanners: KeyVal[]
  } = {
    facilities: [],
    rooms: [],
    scanners: [],
  }

  assembleRelatedEntityOptions(data: missed_phantom_test_get_related_entities) {
    this.options = {
      facilities: data.facility.map((f) => ({
        key: f.id,
        val: f.name,
      })),
      rooms: data.facility_room.map((r) => ({
        key: r.id,
        val: r.name,
      })),
      scanners: data.scanner.map((s) => ({
        key: s.id,
        val: s.name,
      })),
    }
  }

  view(test: missedPhantomTests_missed_phantom_test) {
    this.nav.navtree.missedPhantomTestDetails(test.id)
  }
}
