<form clrForm>
  <clr-input-container>
    <label>Min</label>
    <input
      clrInput
      type="number"
      name="min"
      [ngModel]="min"
      (ngModelChange)="accepts($event, 'min')"
    />
  </clr-input-container>
  <clr-input-container>
    <label>Max</label>
    <input
      clrInput
      type="number"
      name="max"
      [ngModel]="max"
      (ngModelChange)="accepts($event, 'max')"
    />
  </clr-input-container>
</form>
