<ng-container *ngIf="scanners">
  <div id="scanner-card" class="card">
    <div class="card-header">
      {{ title }}
      <clr-icon
        [ngStyle]="{ display: contentExpanded ? 'none' : 'initial' }"
        (click)="contentExpanded = true"
        class="expand-toggle"
        shape="caret right"
      ></clr-icon>
      <clr-icon
        [ngStyle]="{ display: !contentExpanded ? 'none' : 'initial' }"
        (click)="contentExpanded = false"
        class="expand-toggle"
        shape="caret down"
      ></clr-icon>
    </div>
    <div *ngIf="contentExpanded" class="card-block">
      <tag-input
        [(ngModel)]="selected"
        [theme]="'clr-theme'"
        [placeholder]="'+ add another'"
        [secondaryPlaceholder]="'By scanner or room name'"
        [identifyBy]="'id'"
        [displayBy]="'name'"
        [onlyFromAutocomplete]="true"
        [maxItems]="maxItems"
      >
        <tag-input-dropdown
          [autocompleteItems]="scannersWithRooms"
          [identifyBy]="'id'"
          [showDropdownIfEmpty]="true"
          [keepOpen]="false"
          [displayBy]="'name'"
        ></tag-input-dropdown
      ></tag-input>
    </div>
  </div>
</ng-container>
