import { cloneDeep } from 'lodash'

import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { MessageError } from 'src/app/core/errors/errors'
import { safeGet } from '../../util/util'
import deleteMutation from '../scanners-details/delete-scanners-details.gql'
import query from '../scanners-details/scanners-details.gql'
import { delete_scanner_by_pk } from '../scanners-details/types/delete_scanner_by_pk'
import {
  scannersDetails,
  scannersDetails_survey_test_routine,
} from '../scanners-details/types/scannersDetails'
import { NavService } from 'src/app/core/nav.service'
import { UserRole } from 'src/types/graphql-global-types'
import { AuthService } from 'src/app/auth/auth.service'
import { TagInputComponent } from 'ngx-chips'
import { FilterType, SelectFilterInterface } from 'src/app/filters/filters.util'
import { exportPhantomTestsVariables } from '../phantom-tests/types/exportPhantomTests'
import moment from 'moment-timezone'
import { TimezoneService } from 'src/app/timezone.service'
import { ExportTestsService } from 'src/app/export-tests.service'

@Component({
  selector: 'app-workstations-details',
  templateUrl: './workstations-details.component.html',
  styleUrls: ['./workstations-details.component.scss'],
})
export class WorkstationsDetailsComponent implements OnInit {
  daysSinceLastCalibration: number

  @Input() scannerId: number
  @ViewChild('supportContactTaginput') supportContactTaginput: TagInputComponent
  supportContacts: string[]

  editScannerRoles = [UserRole.admin, UserRole.support]

  loading = false

  editing = false

  private queryResult: ApolloQueryResult<scannersDetails>

  safeGet = safeGet

  @Output() updateEvent = new EventEmitter()

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private router: Router,
    private nav: NavService,
    private timezone: TimezoneService,
    public auth: AuthService,
    private exportTests: ExportTestsService,
  ) {}

  updateDaysSinceLastCalibration() {
    const today = new Date()
    const lcDate = new Date(this.lastCalibrationDate)
    // number of days difference
    this.daysSinceLastCalibration = Math.floor(
      (today.getTime() - lcDate.getTime()) / (1000 * 60 * 60 * 24),
    )
  }

  ngOnInit() {
    if (!this.scannerId) {
      this.scannerId = this.route.snapshot.params.id
    }
    this.fetchscannersDetails()
  }

  fetchscannersDetails = () => {
    this.loading = true
    this.apollo
      .query<scannersDetails>({ query, variables: { id: this.scannerId } })
      .subscribe({ next: this.onNext, error: this.onError })
  }

  onNext = (result: ApolloQueryResult<scannersDetails>) => {
    this.loading = false
    this.queryResult = result
    if (result.errors) {
      throw result.errors[0]
    }
    if (!this.scanner) {
      throw new MessageError('scanner not found')
    }
    //Need to find scanner before getting last calibration date
    this.updateDaysSinceLastCalibration()
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  get scanner() {
    return this.queryResult?.data?.scanner_by_pk
  }

  get scannerName() {
    return this.scanner.name
  }

  get roomName() {
    return this.scanner.facility_room?.name
  }

  saveSupportContact($event: string) {
    const currentSupportContactInputText = this.supportContactTaginput.inputText
    if (currentSupportContactInputText) {
      if (Array.isArray(this.supportContacts)) {
        this.supportContacts.push(currentSupportContactInputText)
        this.supportContactTaginput.setInputValue('')
      }
    }
    // this.save('supportContact', $event)
    throw new Error('not implemented')
  }

  delete() {
    if (
      window.confirm(
        `Delete workstation: are you sure? This will delete all studies and tests from this ${this.scanner.name}. This action cannot be undone.`,
      )
    ) {
      this.apollo
        .mutate<delete_scanner_by_pk>({
          mutation: deleteMutation,
          variables: { id: this.scannerId },
        })
        .subscribe({
          next: (result) => {
            if (result.errors) {
              throw result.errors[0]
            }
            this.nav.navtree.workstations()
          },
          error: (err) => console.log(err),
        })
    }
  }

  editView() {
    this.editing = true
  }

  onSave($event) {
    this.editing = false
    this.updateEvent.emit($event)
    this.fetchscannersDetails()
  }

  get extra() {
    return this.scanner.extra
  }

  get lastCalibrationDate() {
    return this.extra['Last Calibration Date']
  }

  get manufacturerName() {
    return this.scanner.scanner_manufacturer?.name
  }

  get modelName() {
    return this.scanner.scanner_model?.name
  }

  get facilityName() {
    return this.scanner.facility?.name
  }

  get mostRecentPhantomTestDate() {
    const test = this.scanner.phantom_tests[0]
    return test?.study.scanDate
  }

  get modalityName() {
    return this.scanner.modalityId
  }

  get surveyTestRoutines() {
    return this.queryResult?.data.survey_test_routine
  }

  runRoutine(routine: scannersDetails_survey_test_routine) {
    this.router.navigate(['/home/survey', routine.id])
  }

  navigateToExportTests() {
    const filter: SelectFilterInterface = {
      type: FilterType.select,
      property: 'scanner.id',
      exact: this.scannerId,
    }
    this.nav.navtree.phantomTests({
      filters: [filter],
    })
    // this.nav.navtree.home()
  }

  showExportModal = false
  exportLoading = false

  showExportTestsModal() {
    this.showExportModal = true
  }

  async presetExportTests(preset: 'month' | 'lastMonth' | 'year' | 'all') {
    let early: moment.Moment
    let late: moment.Moment
    switch (preset) {
      case 'month':
        early = moment.tz(this.timezone.cachedTz).startOf('month')
        late = moment.tz(this.timezone.cachedTz).endOf('month')
        break
      case 'lastMonth':
        early = moment
          .tz(this.timezone.cachedTz)
          .add(-1, 'months')
          .startOf('month')
        late = moment
          .tz(this.timezone.cachedTz)
          .add(-1, 'months')
          .endOf('month')
        break
      case 'year':
        early = moment.tz(this.timezone.cachedTz).startOf('year')
        late = moment.tz(this.timezone.cachedTz).endOf('year')
        break
      case 'all':
      default:
        break
    }
    const variables: exportPhantomTestsVariables = {
      where: {
        scannerId: { _eq: this.scannerId },
        study: {
          scanDate: {
            _lte: late?.toISOString(),
            _gte: early?.toISOString(),
          },
        },
      },
    }
    this.exportLoading = true
    try {
      await this.exportTests.export(variables)
      this.exportLoading = false
      this.showExportModal = false
    } catch (e) {
      this.exportLoading = false
      throw e
    }
  }
}
