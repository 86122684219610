<app-facilities-edit
  *ngIf="editing"
  [facility]="facility"
  (saveEvent)="onSave($event)"
></app-facilities-edit>
<ng-container *ngIf="!editing">
  <div [clrLoading]="loading">
    <div *ngIf="facility">
      <!-- Buttons -->
      <div class="clr-row">
        <div class="clr-col-6">
          <h2 id="heading">Facility Details</h2>
        </div>
        <div id="actions" class="clr-col-6">
          <button class="btn btn-danger-outline" (click)="delete()">
            Delete Facility
          </button>
          <button class="btn btn-outline" (click)="editView()">
            Edit Facility
          </button>
        </div>
      </div>

      <div class="clr-row">
        <!-- Basic Info -->
        <div class="clr-col-md-6">
          <table class="table">
            <tbody>
              <tr>
                <td class="left">Name</td>
                <td class="left">{{ safeGet(facility, 'name') }}</td>
              </tr>
              <tr>
                <td class="left">Address</td>
                <td class="left">{{ safeGet(facility, 'address') }}</td>
              </tr>
              <tr>
                <td class="left">Phone</td>
                <td class="left">{{ safeGet(facility, 'phone') }}</td>
              </tr>
              <tr>
                <td class="left">Facility ID</td>
                <td class="left">{{ safeGet(facility, 'facilityId') }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Rooms -->
        <div class="clr-col-md-6">
          <table class="table">
            <thead>
              <th>Rooms</th>
            </thead>
            <tbody>
              <tr *ngFor="let room of rooms">
                <td class="hover-container">
                  {{ room.name
                  }}<clr-icon
                    (click)="deleteRoom(room)"
                    title="delete"
                    class="hover-item fail"
                    shape="trash"
                  ></clr-icon>
                </td>
              </tr>
              <tr>
                <td id="room-input">
                  <div class="clr-row">
                    <div class="clr-col-6">
                      <input
                        clrInput
                        placeholder="add a room"
                        name="roomInput"
                        [(ngModel)]="roomInput"
                      />
                    </div>
                    <div class="clr-col-6">
                      <button
                        (click)="onRoomSave()"
                        class="btn btn-sm btn-success-outline"
                      >
                        save
                      </button>
                      <button
                        (click)="onRoomCancel()"
                        class="btn btn-sm btn-outline"
                      >
                        cancel
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="clr-row">
        <div class="clr-col-xl-6">
          <app-facility-support-contacts
            [facility]="facility"
          ></app-facility-support-contacts>
        </div>
      </div>
    </div>
  </div>
</ng-container>
