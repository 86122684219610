<ng-container
  *ngIf="type === 'singleScanner'; then singleScanner; else multipleScanners"
>
</ng-container>
<div class="clr-row" *ngIf="userHasSelectedScannersAndTrendKey">
  <div class="clr-col-12">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="downloadData()"
    >
      Download Data
    </button>
  </div>
</div>

<ng-template #singleScanner>
  <div class="clr-row" id="scanner-selection">
    <div class="clr-col-4 dropdown-container">
      <app-scanner-multi-select
        class="dropdown-content"
        #scannerSelect
        (valueChange)="scannerSelectChange()"
        [scanners]="allScanners"
        [roomNames]="allRoomNames"
        [maxItems]="1"
        [title]="'Select a Scanner'"
      ></app-scanner-multi-select>
    </div>
    <div class="clr-col-4 dropdown-container">
      <app-side-controls
        [disabled]="!selectedScanner"
        class="dropdown-content"
        #sideControlLeft
        (valueChange)="sideControlChange()"
        [title]="'Select a measurement'"
        [position]="'left'"
        [phantomTestRoutines]="routineUnion"
        [surveyTestRoutines]="surveyTestRoutineUnion"
        [showYAxis]="false"
        [defaultTrendKey]="['materials', 'water', 'mean']"
      ></app-side-controls>
    </div>
    <div class="clr-col-4 dropdown-container">
      <app-side-controls
        class="dropdown-content"
        [disabled]="!firstParamSelected"
        #sideControlRight
        (valueChange)="sideControlChange()"
        [title]="'Select another (optional)'"
        [position]="'right'"
        [phantomTestRoutines]="routineUnion"
        [surveyTestRoutines]="surveyTestRoutineUnion"
      ></app-side-controls>
    </div>
  </div>
  <div class="clr-row" id="main-display">
    <div class="clr-col-12">
      <div
        *ngIf="chartOptions"
        class="chart"
        echarts
        [theme]="'dark'"
        [options]="chartOptions"
        [autoResize]="true"
        (chartClick)="onChartClick($event)"
        #echart
      ></div>
    </div>
  </div>
</ng-template>

<ng-template #multipleScanners>
  <div class="clr-row" id="scanner-selection">
    <div class="clr-col-6 dropdown-container">
      <app-scanner-multi-select
        #scannerSelect
        class="dropdown-content"
        (valueChange)="scannerSelectChange()"
        [scanners]="allScanners"
        [roomNames]="allRoomNames"
        [title]="'Select one or more Scanners'"
      ></app-scanner-multi-select>
    </div>
    <div class="clr-col-6 dropdown-container">
      <app-side-controls
        #sideControlLeft
        [disabled]="!selectedScanner"
        class="dropdown-content"
        (valueChange)="sideControlChange()"
        [title]="'Select a measurement'"
        [position]="'right'"
        [phantomTestRoutines]="routineUnion"
        [surveyTestRoutines]="surveyTestRoutineUnion"
        [showYAxis]="false"
        [defaultTrendKey]="['materials', 'water', 'mean']"
      ></app-side-controls>
    </div>
  </div>
  <div class="clr-row" id="main-display">
    <div class="clr-col-xl-12">
      <div
        *ngIf="chartOptions"
        class="chart"
        echarts
        [theme]="'dark'"
        [options]="chartOptions"
        [autoResize]="true"
        (chartClick)="onChartClick($event)"
        #echart
      ></div>
    </div>
  </div>
</ng-template>

<ng-template #chart>
  <div
    *ngIf="chartOptions"
    class="chart"
    echarts
    [theme]="'dark'"
    [options]="chartOptions"
    [autoResize]="true"
    (chartClick)="onChartClick($event)"
    #echart
  ></div>
</ng-template>
