<div class="clr-col-md-6">
  <form clrForm clrLayout="vertical">
    <clr-select-container>
      <label class="clr-col-md-6">Category</label>
      <select
        class="clr-col-md-6"
        *ngIf="relatedEntityOptionsQueryResult"
        clrSelect
        name="Category"
        [ngModel]="categoryId"
        (ngModelChange)="setModel('categoryId', $event)"
      >
        <option
          *ngFor="let category of relatedEntityOptions.comment_category"
          value="{{ category.id }}"
          [ngStyle]="{ color: colors[category.color] }"
          >{{ category.name }}</option
        >
      </select>
    </clr-select-container>
    <button class="btn btn-link" (click)="manageCategories()">
      Manage Categories
    </button>

    <clr-input-container>
      <label class="clr-col-md-6">Subject</label>
      <input
        required
        class="clr-col-md-12"
        clrInput
        name="Subject"
        [ngModel]="getModel().subject"
        (ngModelChange)="setModel('subject', $event)"
      />
    </clr-input-container>

    <clr-textarea-container>
      <label class="clr-col-md-6">Content</label>
      <textarea
        class="clr-col-md-12"
        clrTextarea
        name="Content"
        [ngModel]="getModel().content"
        (ngModelChange)="setModel('content', $event)"
      ></textarea>
    </clr-textarea-container>

    <div class="upload-files">
      <app-upload-file></app-upload-file>
    </div>
  </form>
  <button (click)="save()" class="btn btn-success-outline">Save</button>
  <button (click)="back()" class="btn btn-outline">Back</button>
</div>
