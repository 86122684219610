import { Apollo } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { safeGet } from '../../util/util'
import query from './comment-categories-details.gql'
import deleteMutation from './delete-comment-categories-details.gql'
import { commentCategoriesDetails } from './types/commentCategoriesDetails'
import { delete_comment_category_by_pk } from './types/delete_comment_category_by_pk'

@Component({
  selector: 'app-comment-categories-details',
  templateUrl: './comment-categories-details.component.html',
  styleUrls: ['./comment-categories-details.component.scss'],
})
export class CommentCategoriesDetailsComponent implements OnInit {
  @Input() comment_categoryId: number

  loading = false

  editing = false

  private queryResult: ApolloQueryResult<commentCategoriesDetails>

  safeGet = safeGet

  @Output() updateEvent = new EventEmitter()

  constructor(private apollo: Apollo) {}

  ngOnInit() {
    this.fetchcommentCategoriesDetails()
  }

  fetchcommentCategoriesDetails = () => {
    this.loading = true
    this.apollo
      .query<commentCategoriesDetails>({
        query,
        variables: { id: this.comment_categoryId },
      })
      .subscribe({ next: this.onNext, error: this.onError })
  }

  onNext = (result: ApolloQueryResult<commentCategoriesDetails>) => {
    this.loading = false
    this.queryResult = result
    if (result.errors) {
      throw result.errors[0]
    }
  }

  onError = (err: unknown) => {
    this.loading = false
    throw err
  }

  get comment_category() {
    return this.queryResult.data.comment_category_by_pk
  }

  delete() {
    this.apollo
      .mutate<delete_comment_category_by_pk>({
        mutation: deleteMutation,
        variables: { id: this.comment_categoryId },
      })
      .subscribe({
        next: (result) => {
          if (result.errors) {
            throw result.errors[0]
          }
          this.updateEvent.emit('delete')
        },
        error: (err) => {
          throw err
        },
      })
  }

  editView() {
    this.editing = true
  }

  onSave($event) {
    this.editing = false
    this.updateEvent.emit($event)
  }
}
