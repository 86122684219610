import { Component, OnInit, Input } from '@angular/core'
import { TestDisplay, NumEval } from 'common/aqsTypes'
import { phantomTestDetails_phantom_test_by_pk } from '../phantom-test-details/types/phantomTestDetails'

function joinStrings(...args: string[]): string {
  const strings = args.filter((s) => !!s)
  return strings.join(' / ')
}

@Component({
  selector: 'app-test-summary',
  templateUrl: './test-summary.component.html',
  styleUrls: ['./test-summary.component.scss'],
})
export class TestSummaryComponent implements OnInit {
  @Input() set data(data: TestDisplay[]) {
    this.assembleEvaluatedNumbers(data)
  }
  @Input() test: phantomTestDetails_phantom_test_by_pk
  evaluatedNumbers: NumEval[] = []

  constructor() {}

  ngOnInit(): void {}

  assembleEvaluatedNumbers(data: TestDisplay[], prefix?: string) {
    for (const testDisplay of data) {
      for (const num of testDisplay.evaluatedNumbers) {
        const newTitle = joinStrings(prefix, testDisplay.title, num.title)
        this.evaluatedNumbers.push({ ...num, title: newTitle })
      }
      this.assembleEvaluatedNumbers(
        testDisplay.children,
        joinStrings(prefix, testDisplay.title),
      )
    }
  }
}
