import { Pipe, PipeTransform } from '@angular/core'
import { firstItemUpper } from '../util/util'

export function humanReadable(value: unknown, ...args: unknown[]) {
  if (value === undefined || value === null || value === '') {
    return ''
  }
  if (typeof value === 'string') {
    if (!isNaN(parseFloat(value))) {
      return value
    }
    return value
      .replace(/_/gm, ' ')
      .replace(/-/gm, ' ')
      .split(' ')
      .map(firstItemUpper)
      .join(' ')
  } else {
    return value
  }
}

@Pipe({
  name: 'humanReadable',
})
export class HumanReadablePipe implements PipeTransform {
  transform = humanReadable
}
