import { Component, OnInit, Input } from '@angular/core'
import { phantomTestDetails_phantom_test_by_pk_answered_phantom_test_questions } from '../../../types/phantomTestDetails'

@Component({
  selector: 'app-questions-display',
  templateUrl: './questions-display.component.html',
  styleUrls: ['./questions-display.component.scss'],
})
export class QuestionsDisplayComponent implements OnInit {
  @Input()
  questions: phantomTestDetails_phantom_test_by_pk_answered_phantom_test_questions[]

  constructor() {}

  ngOnInit() {}
}
