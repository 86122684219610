import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { AlertService } from 'src/app/core/alert/alert.service'

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
  ) {}

  get alerts() {
    return this.alertService.alerts
  }

  ngOnInit(): void {
    this.alertService.alertRemoved.subscribe(() => {
      console.log('detecting changes')
      this.cdr.detectChanges()
    })
  }

  removeAlert(id) {
    this.alertService.removeAlert(id)
  }

  onClose($event, id) {
    if ($event === true) {
      this.removeAlert(id)
    }
  }
}
