import { environment } from '../../../environments/environment'
import { GlobalErrorHandler } from '../errors/errorHandler'

import { NgModule } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { APOLLO_OPTIONS, Apollo } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { InMemoryCache, ApolloLink } from '@apollo/client/core'

const appEndpoint =
  `${environment.appUri}/graphql` || `https://${window.location.host}/graphql`

@NgModule({
  imports: [
    // ... other modules
    HttpClientModule,
  ],
  providers: [
    GlobalErrorHandler,
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          defaultOptions: {
            query: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            },
          },
          link: httpLink.create({
            uri: environment.hasuraUri,
          }),
        }
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {
  constructor(apollo: Apollo, httpLink: HttpLink) {
    const appHttpLink = httpLink.create({ uri: appEndpoint })
    const appLink = ApolloLink.from([appHttpLink])
    apollo.createNamed('app', {
      link: appLink,
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    })
  }
}
