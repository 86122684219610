<div class="clr-row" appPageHeader [title]="title"></div>
<div class="clr-row" *ngIf="summary">
  <div class="clr-col-xl-12">
    <div class="card">
      <div class="card-block">
        <div class="clr-row">
          <div class="clr-col-xl-6">
            <h5>
              <strong>Overall QC Health</strong>
              <br />
              <em>change vs. last month</em>
              <em></em>
            </h5>
            <div class="clr-row">
              <div
                class="clr-col-xl-3 clr-col-lg-6"
                *ngFor="let item of summary.items"
              >
                <app-gague
                  [config]="{
                    label: item.shortName,
                    value: item.changeVsLastMonth
                  }"
                ></app-gague>
              </div>
            </div>
            <div class="clr-row">
              <div class="clr-col-12">
                <h5>
                  <strong>Metrics</strong><br />
                  <em>value this month, change vs. last month</em>
                  <em></em>
                </h5>
                <table id="summaries-table" class="table">
                  <tbody>
                    <tr *ngFor="let item of summary.items">
                      <td>{{ item.name }}</td>
                      <td *ngIf="item.type === 'absolute'">{{ item.abs }}</td>
                      <td *ngIf="item.type === 'percent'">
                        {{ item.abs | percent }}
                      </td>
                      <app-metric-cell
                        [val]="item.changeVsLastMonth"
                      ></app-metric-cell>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="clr-col-xl-6" *ngIf="overallBreakdown">
            <h5>
              <strong>Average of All Metrics </strong> <br />
              <em> change vs. last month</em>
            </h5>
            <app-metric-groups [config]="overallBreakdown"></app-metric-groups>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="clr-row">
  <div class="clr-col-xl-6" *ngFor="let metric of metricsList">
    <div class="card">
      <div class="card-header">{{ metric.metric.title }}</div>
      <div class="card-block">
        <div class="clr-row">
          <div class="clr-col-8">
            <h5><strong>Summary</strong></h5>
            <app-by-metric-summary
              [config]="metric.data.absoluteValueOfMetric"
              [display]="metric.metric.type"
            ></app-by-metric-summary>
          </div>
          <div class="clr-col-4">
            <app-gague
              [config]="{
                label: 'vs. last month',
                value: metric.data.changeInMetric.vsLastMonth
              }"
            ></app-gague>
          </div>
        </div>
      </div>
      <div class="card-block">
        <div class="clr-row">
          <div class="clr-col-12">
            <h5><strong>Past 12 Months</strong></h5>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-12">
            <app-bar
              [display]="metric.metric.type"
              [yearDataByMonth]="metric.data.yearDataByMonth"
            ></app-bar>
          </div>
        </div>
      </div>
      <div class="card-block">
        <div class="clr-row">
          <div class="clr-col-12">
            <h5>
              <strong>Breakdown</strong> <br /><em>change vs. last month</em>
            </h5>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-12">
            <app-metric-groups
              [config]="metric.data.breakdown"
            ></app-metric-groups>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
