
    <button
      type="button"
      class="calendar-btn month"
      *ngFor="let month of monthNames; let monthIndex = index"
      (click)="changeMonth(monthIndex)"
      [class.is-selected]="monthIndex === calendarMonthIndex"
      [attr.tabindex]="getTabIndex(monthIndex)"
    >
      {{ month }}
    </button>
  