<full-calendar
  id="calendar"
  #calendar
  defaultView="dayGridMonth"
  [height]="500"
  [plugins]="calendarPlugins"
  [events]="events"
  (eventClick)="onEventClick($event)"
  (datesDestroy)="onDatesDestroy($event)"
  [eventTimeFormat]="{
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false,
    hour12: false
  }"
></full-calendar>
