import { gql } from 'apollo-angular'

export default gql`
  mutation associate_s_ptr($routineId: Int!, $scannerId: Int!) {
    insert_scanner_phantom_test_routines_phantom_test_routine(
      objects: { phantomTestRoutineId: $routineId, scannerId: $scannerId }
    ) {
      returning {
        phantomTestRoutineId
        scannerId
      }
    }
  }
`
