import { gql } from 'apollo-angular'

export default gql`
  mutation insert_scanner($workstation: scanner_insert_input!) {
    insert_scanner(objects: [$workstation]) {
      returning {
        id
      }
    }
  }
`
