
    <button
      type="button"
      class="datagrid-column-handle"
      [attr.aria-label]="commonString.keys.columnSeparatorAriaLabel"
      [attr.aria-describedby]="descriptionId"
      clrDraggable
      [clrGroup]="columnSeparatorId"
      (clrDragStart)="showTracker()"
      (clrDragMove)="moveTracker($event.dragPosition.moveX)"
      (clrDragEnd)="hideTracker()"
      #columnHandle
    ></button>
    <span class="clr-sr-only" [attr.id]="descriptionId">
      {{ commonString.keys.columnSeparatorDescription }}
    </span>
    <div class="datagrid-column-resize-tracker" #resizeTracker></div>
  