
    <div class="year-switchers">
      <button
        class="calendar-btn switcher"
        type="button"
        (click)="previousDecade()"
        [attr.aria-label]="commonStrings.keys.datepickerPreviousDecade"
      >
        <cds-icon shape="angle" direction="left" [attr.title]="commonStrings.keys.datepickerPreviousDecade"></cds-icon>
      </button>
      <button
        class="calendar-btn switcher"
        type="button"
        (click)="currentDecade()"
        [attr.aria-label]="commonStrings.keys.datepickerCurrentDecade"
      >
        <cds-icon shape="event" [attr.title]="commonStrings.keys.datepickerCurrentDecade"></cds-icon>
      </button>
      <button
        class="calendar-btn switcher"
        type="button"
        (click)="nextDecade()"
        [attr.aria-label]="commonStrings.keys.datepickerNextDecade"
      >
        <cds-icon shape="angle" direction="right" [attr.title]="commonStrings.keys.datepickerNextDecade"></cds-icon>
      </button>
    </div>
    <div class="years">
      <button
        *ngFor="let year of yearRangeModel.yearRange"
        type="button"
        class="calendar-btn year"
        [attr.tabindex]="getTabIndex(year)"
        [class.is-selected]="year === calendarYear"
        (click)="changeYear(year)"
      >
        {{ year }}
      </button>
    </div>
  